import { Col, Row } from "antd";
import React from "react";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { PageHeading } from "../campaigns/CampaignStyle";
import { Count, DashboardTile, DashboardTiles, Label } from "./DashboardStyle";
import ORG_URL from "../../../../api/urls/ORG_URL";
import useFetch from "../../../../api/hooks/useFetch";
import LoaderDashboard from "../../../loader/LoaderDashboard";

const AdminDashboard = () => {
  const { response, error, loading, refetch } = useFetch({
    path: ORG_URL.DASHBOARD,
    onCompleted: () => {},
    onError: () => {},
  });
  return (
    <>
      {loading ? (
        <LoaderDashboard />
      ) : (
        <>
          <MainContent>
            <PageHeading> Dashboard</PageHeading>
            <DashboardTiles>
              <Row gutter={[20, 20]}>
                <Col span={24} sm={{ span: 12 }} lg={{ span: 6 }}>
                  <DashboardTile>
                    <Label>Events </Label>
                    <Count>{response?.data?.campaignCount}</Count>
                  </DashboardTile>
                </Col>
                <Col span={24} sm={{ span: 12 }} lg={{ span: 6 }}>
                  <DashboardTile>
                    <Label>Donors </Label>
                    <Count>{response?.data?.donorCount}</Count>
                  </DashboardTile>
                </Col>
                <Col span={24} sm={{ span: 12 }} lg={{ span: 6 }}>
                  <DashboardTile>
                    <Label>Clients </Label>
                    <Count>{response?.data?.seekerCount}</Count>
                  </DashboardTile>
                </Col>
                <Col span={24} sm={{ span: 12 }} lg={{ span: 6 }}>
                  <DashboardTile>
                    <Label>Donations</Label>
                    <Count>{response?.data?.donationCount}</Count>
                  </DashboardTile>
                </Col>
              </Row>
            </DashboardTiles>
          </MainContent>
        </>
      )}
    </>
  );
};

export default AdminDashboard;
