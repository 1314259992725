import { Col, Form, Row } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { errorMessages } from "../../utils/errorMessages";
import Button from "../elements/button/Button";
import DatePickerB from "../elements/datePicker/DatePicker";
import Select from "../elements/select/Select";
import { Container, StyledForm } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";

const PageHeading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  padding: 15px 0 0;
`;

const Wishes = styled.h3`
  margin: 10px 0;
`;

const WishItem = styled.ul`
  padding-left: 15px;
  list-style: disc !important;
`;

const WishList = styled.li`
  margin-bottom: 6px !important;
  list-style: disc !important;
`;

export const FamlyMemberWishesModal = (close) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const Options = [
    {
      value: "New York",
      key: 1,
    },
    {
      value: "Los Angeles",
      key: 2,
    },
    {
      value: "Chicago",
      key: 3,
    },
  ];
  const onSubmit = (values) => {
    navigate("/family-list");
  };
  const today = moment();
  const disablePastDate = (current) => {
    return current.isBefore(today);
  };
  return (
    <Container style={{ padding: "0 30px" }}>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        form={form}
        onFinish={onSubmit}
      >
        <Row>
          <Col span={24}>
            <PageHeading>Selected Wishes</PageHeading>
          </Col>
          <Col span={24}>
            <Wishes>Household Wishes :-</Wishes>
            <WishItem>
              <WishList>
                I need one automatic battery operated remote controlled
                helicopter in any size whichever will be available
              </WishList>
              <WishList>
                I need one automatic battery operated remote controlled
                helicopter in any size whichever will be available
              </WishList>
              <WishList>
                I need one automatic battery operated remote controlled
                helicopter in any size whichever will be available
              </WishList>
            </WishItem>
          </Col>
          <Col span={24}>
            <Wishes>Member1 Wishes :-</Wishes>
            <WishItem>
              <WishList>
                I need one automatic battery operated remote controlled
                helicopter in any size whichever will be available
              </WishList>
              <WishList>
                I need one automatic battery operated remote controlled
                helicopter in any size whichever will be available
              </WishList>
            </WishItem>
          </Col>
          <Col span={24}>
            <Wishes>Member2 Wishes :-</Wishes>
            <WishItem>
              <WishList>
                I need one automatic battery operated remote controlled
                helicopter in any size whichever will be available
              </WishList>
              <WishList>
                I need one automatic battery operated remote controlled
                helicopter in any size whichever will be available
              </WishList>
            </WishItem>
          </Col>

          <Col span={24}>
            <br />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <DatePickerB
              name="dateOFBirthOfHeadHouseHold"
              label="DropOff Date"
              picker="HeadofHouseholdDateofBirth"
              required={true}
              disabledDate={disablePastDate}
              requiredMessage={errorMessages.DROPOFFDATE}
            />
          </Col>

          <Col span={24} md={{ span: 11 }}>
            <Select
              type="text"
              label="Drop-of Location"
              name="member2contactNumber"
              option={Options}
              required={true}
              requiredMessage={errorMessages.DROPOFLOCATION}
            />
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button text="Save" htmlType="submit" />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

export default FamlyMemberWishesModal;
