import React from "react";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import styled from "styled-components";
import SearchDropdown, {
  SearchDropdownTypes,
} from "../searchDropdown/SearchDropdown";
import { Form as antForm } from "antd";

const Container = styled.div`
  width: 100%;
  z-index: 100000;
  padding-left: 10px;
  margin-bottom: 20px;
  .ant-form-item-label {
    padding: 0 0 0 10px !important;
  }
  .ant-form-item {
    margin: 0px !important;
  }
`;

export const Form = styled(antForm)`
  .ant-form-item-label {
    padding: 0 !important;
  }
`;

export default function AddressSearchDropdown({
  value,
  onChange,
  width,
  label,
  tip,
  name,
  required,
  addressError = "",
  requiredMessage,
}) {
  /* Hooks */
  let {
    value: innerValue,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "us" },
    },
    debounce: 300,
  });

  React.useEffect(() => {
    let placeName = value?.placeName ? value?.placeName : "";
    setValue(placeName);
    innerValue = placeName;
  }, []);

  const selectAddress = React.useCallback(
    (address) => {
      setValue(address);
      const doEffect = async () => {
        try {
          const [result] = await getGeocode({
            address,
          });
          const {
            geometry: { location },
          } = result;

          const addressObject = {
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            lat: location.lat(),
            lng: location.lng(),
            placeId: result.place_id,
            placeName: address,
          };
          result.address_components.forEach((ac) => {
            if (ac.types.includes("street_number")) {
              addressObject.address1 = ac.short_name;
            }
            if (ac.types.includes("route")) {
              addressObject.address1 = `${addressObject.address1} ${ac.short_name}`;
            }
            if (ac.types.includes("subpremise")) {
              addressObject.address2 = ac.short_name;
            }
            if (
              ac.types.includes("locality") &&
              ac.types.includes("political")
            ) {
              addressObject.city = ac.short_name;
            }
            if (
              ac.types.includes("administrative_area_level_1") &&
              ac.types.includes("political")
            ) {
              addressObject.state = ac.short_name;
            }
            if (
              ac.types.includes("country") &&
              ac.types.includes("political")
            ) {
              addressObject.country = ac.short_name;
            }
            if (ac.types.includes("postal_code")) {
              addressObject.zip = ac.short_name;
            }
          });
          onChange(addressObject);
        } catch (e) {
          // HANDLE ERROR
          console.error(e);
        }
      };
      doEffect();
    },
    [onChange, setValue]
  );

  React.useEffect(() => {
    let intialAddress = {};
    if (!innerValue) onChange(intialAddress);
  }, [innerValue]);

  /* Render */
  let items = [];

  if (data.length) {
    items = data.map((suggestion) => ({
      text: suggestion.description,
      value: suggestion.description,
    }));
  }

  return (
    <Container>
      <Form.Item
        label={<>{label}</>}
        name={name}
        rules={[
          {
            required: required,
            message: required && requiredMessage,
          },
        ]}
      >
        <SearchDropdown
          type={SearchDropdownTypes.SingleSelect}
          onChange={selectAddress}
          value={innerValue}
          searchQuery={innerValue}
          setSearchQuery={setValue}
          placeholder="Search for an address"
          items={items}
          addressError={addressError}
          width={width}
          tip={tip}
        />
      </Form.Item>
    </Container>
  );
}
