import { Col, Image, Input, Row } from "antd";
import colors from "../../../../../styles/Colors";
import {
  MainContent,
  Section,
  SectionHeading,
} from "../../../donor/familyList/FamilyListStyle";
// import SideBar from "../../../superAdmin/sideBar/SideBar";
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  DetailsWrapper,
  FamilyData,
  FamilyDetail,
  FamilyMemberDetail,
  Label,
  MainHeading,
} from "../../../donor/familyDetails/FamilyDetailsStyle";
import Button from "../../../../elements/button/Button";
import Modal, { ModalList } from "../../../../modal/basemodal/BaseModal";
import Search from "antd/lib/input/Search";
import SEEKER_URLS from "../../../../../api/urls/SEEKER_URL";
import useMutate from "../../../../../api/hooks/useMutate";
import {
  capitalFirstLetter,
  formatDate,
  getAgeFromTs,
} from "../../../../../utils/dateTime";
import { genderAPIFormat } from "../../../../../utils/genderFormat";
import ORG_URL from "../../../../../api/urls/ORG_URL";
import { DocItem, Documents } from "../../../seeker/profile/Profile";
import { EyeOutlined, FilePdfOutlined } from "@ant-design/icons";
import LoaderDashboard from "../../../../loader/LoaderDashboard";
import CAMPAIGN_URL from "../../../../../api/urls/CAMPAIGN_URL";
import { debounce } from "../../../../../utils/Debounce";
import { formatPhoneNumber } from "../../../../../utils/phoneNumber";
import { ActionIcon } from "../../donors/DonorsStyle";

export const COLUMN_WIDTH = 130;

const ClientTable = styled.div`
  margin-top: 30px;
`;
export const EventsDetailstab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;
  @media screen and (max-width: 567px) {
    flex-direction: column;
    gap: 0;
    padding: 0 0 10px;
  }
`;

export const InputFullWidthMobile = styled.div`
  @media screen and (max-width: 567px) {
    width: 100%;
  }
`;

export const Documentlabel = styled.div`
  margin: 10px 0;
  font-weight: 600;
  min-width: 150px;
  padding-right: 10px;
  @media screen and (max-width: 767px) {
    min-width: 80px;
  }
`;

export const Unapprovedrequest = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 1280px) {
    flex-direction: column;
    align-items: inherit;
  }
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 7px 30px 7px 0;
  .secondary {
    padding: 4px 10px;
    font-size: 13px;
  }
`;

export const Gdata = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1111px) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-top: 10px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: 100%;
  @media only screen and (max-width: 767px) {
    margin-top: 10px;
  }

  @media only screen and (max-width: 400px) {
    flex-direction: column;

    margin-top: 10px;
  }
`;
const MemberRequest = styled.div``;
const SubHeading = styled.h2`
  margin: 30px 0 0px;
  color: ${colors.primary};
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
`;
let pagination = "";
let availableCampaignPagination = "";
export const ViewclientDetails = () => {
  const navigate = useNavigate();
  const [statusData, setStatusData] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [ApprovedFamilyMembers, setApprovedFamilyMembers] = useState([]);
  const [memberStatus, setMemberStatus] = useState({});
  const [UnApprovedFamilyMembers, setUnApprovedFamilyMembers] = useState([]);
  const [page, setPage] = useState(1);
  const [sortTable, setSortTable] = useState({
    column: "eventsName",
    order: "ASC",
  });
  const [availableCampaignsearch, setAvailableCampaignsearch] = useState("");
  const [availableEventPage, setAvailableEventPage] = useState(1);
  const [sortAvailableCampaigns, setSortAvailableCampaigns] = useState({
    column: "eventsName",
    order: "ASC",
  });
  const [getCampaignsList, { response, loading }] = useMutate({
    path: CAMPAIGN_URL.CAMPAIGNBYSEEKER,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [
    approvedMembers,
    { response: approvedResponse, loading: approveLoading },
  ] = useMutate({
    successToast: true,
    errorToast: true,
    path: ORG_URL.APPROVEMEMBERS,
    onCompleted: () => {},
    onError: () => {},
  });
  const [
    getAvailableCampaigns,
    {
      response: availableCampaignsResponse,
      loading: availableCampaignsLoading,
    },
  ] = useMutate({
    method: "get",
    path: SEEKER_URLS.CAMPAIGNS,
    onCompleted: () => {},
    onError: () => {},
  });
  useEffect(() => {
    const param = {
      pageNo: availableEventPage || 1,
      PageSize: 100,
      searchValue: availableCampaignsearch,
      sortColumn: sortAvailableCampaigns.column,
      sortOrder: sortAvailableCampaigns.order,
      seekerId: id,
    };
    getAvailableCampaigns({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [availableEventPage, sortAvailableCampaigns, availableCampaignsearch]);
  availableCampaignPagination = availableCampaignsResponse?.data?.pagination;

  pagination = response?.data?.pagination;
  const [
    getFamilyDetails,
    { response: familyResponse, loading: familyLoading },
  ] = useMutate({
    path: SEEKER_URLS.SEEKER_FAMILY_DETAILS,
    method: "get",
  });
  const [
    updateStatus,
    { response: statusResponse, error, loading: statusLoading },
  ] = useMutate({
    path: ORG_URL.UPDATE_SEEKER_APPROVAL,
    method: "post",
    errorToast: true,
    successToast: true,
  });

  const familyDetails = familyResponse?.data;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const id = query.get("id");
  const [searchValue, setSearchValue] = useState("");
  const [
    openNewHouseDetailModalExclamation,
    setOpenNewHouseDetailModalExclamation,
  ] = useState(false);

  const [memberId, setMemberId] = useState("");
  useEffect(() => {
    setApprovedFamilyMembers([]);
    setUnApprovedFamilyMembers([]);
    const params = {
      userId: id,
    };

    if (!id) return;

    getFamilyDetails({
      params,
      onCompleted: (familyResponse) => {
        familyResponse?.members?.map((info) => {
          if (info.isApproved) {
            setApprovedFamilyMembers((mem) => [...mem, info] || []);
          } else {
            setUnApprovedFamilyMembers((mem) => [...mem, info] || []);
          }
          return info;
        });
      },
      onError: () => {},
    });
    setIsSuccess(false);
  }, [isSuccess]);
  const [openHouseDetailCheckModal, setOpenHouseDetailCheckModal] =
    useState(false);

  const HouseDetailModalExclamation = () => {
    setOpenNewHouseDetailModalExclamation(true);
  };

  const HouseDetailCheckModal = () => {
    setOpenHouseDetailCheckModal(true);
  };
  const param = {
    pageNo: page || 1,
    PageSize: 100,
    searchValue: searchValue,
    sortColumn: sortTable.column,
    sortOrder: sortTable.order,
    seekerId: id,
  };
  useEffect(() => {
    getCampaignsList({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [page, sortTable, searchValue]);

  const AvailableEventsColumns = [
    {
      title: "Event Name",
      dataIndex: "campaignName",
      sorter: true,
      defaultSortOrder: "ascend",
    },
    {
      title: "Start Date",
      dataIndex: "campaignStartDate",
      sorter: true,
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "campaignEndDate",
      sorter: true,
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
    },

    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIcon
          title="View"
          onClick={() =>
            navigate(
              `/add-family-details?id=${record.campaignId}&seekerId=${id}&admin=true`
            )
          }
        >
          <EyeOutlined />
        </ActionIcon>
      ),
    },
  ];

  const OptedEventsColumns = [
    {
      title: "Event Name",
      dataIndex: "campaignName",
      sorter: true,
      defaultSortOrder: "ascend",
      // render: (text) => <Link text={text} to={"/view-family-details"} />,
    },
    {
      title: "Start Date",
      dataIndex: "campaignStartDate",
      sorter: true,
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "campaignEndDate",
      sorter: true,
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
    },
    {
      title: "Attended Event",
      dataIndex: "donationStatus",
      sorter: true,
      render: (date) => <span>Yes</span>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIcon
          title="View"
          onClick={() =>
            navigate(
              `/client-donation-details-admin?id=${record.campaignId}&seekerId=${id}&admin=true`
            )
          }
        >
          <EyeOutlined />
        </ActionIcon>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const handleAvailableEventChange = (pagination, filters, sorter) => {
    setAvailableEventPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortAvailableCampaigns({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };

  useEffect(() => {
    if (Object.keys(statusData)?.length) {
      let variables = {
        famId: "",
        userId: id,
        ...statusData,
        ActionPerformedBy: "test",
      };

      updateStatus({
        variables,
        onCompleted: (data) => {
          setOpenHouseDetailCheckModal(false);
          setOpenNewHouseDetailModalExclamation(false);
          setApprovedFamilyMembers([]);
          setUnApprovedFamilyMembers([]);
          setIsSuccess(true);
          setStatusData({});
        },
        onError: () => {},
      });
    }
  }, [statusData]);

  useEffect(() => {
    const variables = {
      memberId: "",
      isApproved: false,
      reason: null,
      ...memberStatus,
    };
    if (Object.keys(memberStatus).length) {
      approvedMembers({
        variables,
        onCompleted: () => {
          setIsSuccess(true);
          setOpenNewHouseDetailModalExclamation(false);
        },
        onError: () => {},
      });
    }
  }, [memberStatus]);

  const handleApprovedMembers = (id) => {
    setMemberStatus({
      memberId: id,
      isApproved: true,
    });
  };
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };
  const search = debounce(handleSearch);
  const handleEventSearch = (event) => {
    setAvailableCampaignsearch(event.target.value);
    setAvailableEventPage(1);
  };
  const searchAvailableEvent = debounce(handleEventSearch);

  return (
    <>
      {/* <Header />
      <SideBar /> */}
      {!familyLoading ? (
        <MainContent>
          <Section>
            <SectionHeading>
              Clients Details
              <Button text={"Back"} onClick={() => navigate(-1)} />
            </SectionHeading>
            <FamilyDetail>
              <MainHeading>Household details</MainHeading>
              <FamilyData>
                <Row>
                  <Col span={24} md={{ span: 24 }}>
                    {/* <div style={{ textAlign: "right", width: "100%" }}> */}
                    <ButtonContainer>
                      <Button
                        className="secondary"
                        text={"Impersonate"}
                        onClick={() =>
                          navigate(`/client-profile?id=${id}&admin=true`)
                        }
                        margin="-15px -10px 5px auto"
                      />
                    </ButtonContainer>
                    {/* </div> */}
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Name :</Label>
                      {capitalFirstLetter(familyDetails?.firstName)}{" "}
                      {capitalFirstLetter(familyDetails?.lastName)}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>FAM # :</Label>
                      {familyDetails?.userName ? (
                        familyDetails?.userName
                      ) : (
                        <>
                          <Gdata>
                            <Button
                              text="Assign FAM ID"
                              onClick={HouseDetailCheckModal}
                              className="secondary"
                            />

                            <Button
                              text="Decline"
                              onClick={HouseDetailModalExclamation}
                              className=""
                            />
                          </Gdata>
                        </>
                      )}
                    </Info>
                  </Col>

                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Gender :</Label>
                      {familyDetails?.gender
                        ? genderAPIFormat(familyDetails?.gender)
                        : "Male"}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Date of birth :</Label>
                      {familyDetails?.dob
                        ? formatDate(familyDetails?.dob, "MM/DD/YYYY")
                        : "12/12/2000"}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Age :</Label>
                      {getAgeFromTs(familyDetails?.dob)}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>No. of Members :</Label>
                      {familyDetails?.members?.length + 1 || 1}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Phone Number :</Label>
                      {formatPhoneNumber(familyDetails?.phoneNumber)}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Email Address :</Label>
                      {familyDetails?.email}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Street Address :</Label>
                      {familyDetails?.placeName}
                    </Info>
                  </Col>
                  <Col span={24}>
                    <Documentlabel>Document:</Documentlabel>
                    {familyDetails?.documents?.[0]?.file?.length && (
                      <Documents documents={familyDetails?.documents} />
                    )}
                  </Col>
                </Row>
              </FamilyData>
              {UnApprovedFamilyMembers.length > 0 && (
                <MemberRequest>
                  <SubHeading>Unapproved Member Requests</SubHeading>
                  <Row gutter={[30, 20]}>
                    {UnApprovedFamilyMembers?.map((memberInfo, memberIndex) => {
                      return (
                        <Col span={24} md={{ span: 12 }}>
                          <FamilyMemberDetail>
                            <MainHeading>
                              {capitalFirstLetter(memberInfo?.firstName) ||
                                ` Member ${memberIndex + 1} `}
                            </MainHeading>
                            <FamilyData>
                              <Row>
                                <Col span={24}>
                                  <Info>
                                    <Label>Name :</Label>
                                    {capitalFirstLetter(
                                      memberInfo.firstName
                                    )}{" "}
                                    {capitalFirstLetter(memberInfo.lastName)}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Relation :</Label>
                                    {capitalFirstLetter(memberInfo.relation)}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Gender :</Label>
                                    {genderAPIFormat(memberInfo.gender)}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Date of birth :</Label>
                                    {formatDate(memberInfo?.dob, "MM/DD/YYYY")}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Age :</Label>
                                    {getAgeFromTs(
                                      memberInfo?.dob,
                                      "MM/DD/YYYY"
                                    )}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Phone Number :</Label>
                                    {formatPhoneNumber(memberInfo?.phoneNumber)}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Documentlabel>Document:</Documentlabel>
                                  {memberInfo?.documents?.[0]?.file?.length && (
                                    <Documents
                                      documents={memberInfo?.documents}
                                    />
                                  )}
                                </Col>
                                <Col span={24}>
                                  {familyDetails?.userName &&
                                  !memberInfo?.isApproved ? (
                                    <ButtonWrapper
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Button
                                        text="Approve"
                                        loading={
                                          memberId === memberInfo.seekerMemberId
                                            ? approveLoading
                                            : false
                                        }
                                        onClick={() => {
                                          setMemberId(
                                            memberInfo?.seekerMemberId
                                          );
                                          handleApprovedMembers(
                                            memberInfo?.seekerMemberId
                                          );
                                        }}
                                        className="secondary"
                                      />
                                      <Button
                                        text="Decline"
                                        onClick={() => {
                                          setMemberId(
                                            memberInfo?.seekerMemberId
                                          );
                                          setOpenNewHouseDetailModalExclamation(
                                            true
                                          );
                                        }}
                                        className=""
                                      />
                                    </ButtonWrapper>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            </FamilyData>
                          </FamilyMemberDetail>
                        </Col>
                      );
                    })}
                  </Row>
                </MemberRequest>
              )}

              {ApprovedFamilyMembers?.length > 0 && (
                <DetailsWrapper>
                  <Row gutter={[20, 10]}>
                    <Col span={24} md={{ span: 24 }}>
                      <SubHeading>Approved Member Requests</SubHeading>
                    </Col>
                    {ApprovedFamilyMembers?.map((memberInfo, memberIndex) => {
                      return (
                        <Col span={24} md={{ span: 12 }}>
                          <FamilyMemberDetail>
                            <MainHeading>
                              {capitalFirstLetter(memberInfo?.firstName) ||
                                ` Member ${memberIndex + 1} `}
                            </MainHeading>
                            <FamilyData>
                              <Row>
                                <Col span={24}>
                                  <Info>
                                    <Label>Name :</Label>
                                    {capitalFirstLetter(
                                      memberInfo.firstName
                                    )}{" "}
                                    {capitalFirstLetter(memberInfo.lastName)}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Relation :</Label>
                                    {memberInfo.relation}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Gender :</Label>
                                    {genderAPIFormat(memberInfo.gender)}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Date of birth :</Label>
                                    {formatDate(memberInfo?.dob, "MM/DD/YYYY")}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Age :</Label>
                                    {getAgeFromTs(
                                      memberInfo?.dob,
                                      "MM/DD/YYYY"
                                    )}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Info>
                                    <Label>Phone Number :</Label>
                                    {formatPhoneNumber(memberInfo?.phoneNumber)}
                                  </Info>
                                </Col>

                                <Col span={24}>
                                  <Documentlabel>Document:</Documentlabel>

                                  {memberInfo?.documents?.[0]?.file?.length && (
                                    <Documents
                                      documents={memberInfo?.documents}
                                    />
                                  )}
                                </Col>
                                <Col span={24}>
                                  {familyDetails?.userName &&
                                  !memberInfo?.isApproved ? (
                                    <ButtonWrapper
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Button
                                        text="Approve"
                                        loading={
                                          memberId === memberInfo.seekerMemberId
                                            ? approveLoading
                                            : false
                                        }
                                        onClick={() => {
                                          setMemberId(
                                            memberInfo?.seekerMemberId
                                          );
                                          handleApprovedMembers(
                                            memberInfo?.seekerMemberId
                                          );
                                        }}
                                        className="secondary"
                                      />
                                      <Button
                                        text="Decline"
                                        onClick={() => {
                                          setMemberId(
                                            memberInfo?.seekerMemberId
                                          );
                                          setOpenNewHouseDetailModalExclamation(
                                            true
                                          );
                                        }}
                                        className=""
                                      />
                                    </ButtonWrapper>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            </FamilyData>
                          </FamilyMemberDetail>
                        </Col>
                      );
                    })}
                  </Row>
                </DetailsWrapper>
              )}
            </FamilyDetail>
          </Section>
          <ClientTable>
            <EventsDetailstab>
              <SectionHeading>Activity History</SectionHeading>
              <InputFullWidthMobile>
                <Input
                  placeholder="Search by Event Name"
                  style={
                    {
                      // width: "auto",
                    }
                  }
                  onChange={search}
                />
              </InputFullWidthMobile>
            </EventsDetailstab>

            <Table
              dataSource={response?.data?.records}
              loading={loading}
              onChange={handleChange}
              sortDirections={["ascend", "descend", "ascend"]}
              columns={OptedEventsColumns}
              scroll={{ y: 400, x: OptedEventsColumns.length * COLUMN_WIDTH }}
              showSorterTooltip={false}
              pagination={{
                pageSize: 100,
                defaultCurrent: page,
                total: pagination?.totalRecordsCount,
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
            />
          </ClientTable>
          <ClientTable>
            <EventsDetailstab>
              <SectionHeading> Available Events</SectionHeading>
              <InputFullWidthMobile>
                <Input
                  placeholder="Search by Event Name"
                  style={
                    {
                      // width: "auto",
                    }
                  }
                  onChange={searchAvailableEvent}
                />
              </InputFullWidthMobile>
            </EventsDetailstab>

            <Table
              dataSource={availableCampaignsResponse?.data?.records}
              loading={availableCampaignsLoading}
              onChange={handleAvailableEventChange}
              sortDirections={["ascend", "descend", "ascend"]}
              columns={AvailableEventsColumns}
              scroll={{
                y: 400,
                x: AvailableEventsColumns.length * COLUMN_WIDTH,
              }}
              showSorterTooltip={false}
              pagination={{
                pageSize: 100,
                defaultCurrent: availableEventPage,
                total: availableCampaignPagination?.totalRecordsCount,
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
            />
          </ClientTable>
        </MainContent>
      ) : (
        <LoaderDashboard />
      )}
      <Modal
        title="Decline Request"
        open={openNewHouseDetailModalExclamation}
        setOpenModal={setOpenNewHouseDetailModalExclamation}
        modalType={ModalList.HouseDetailModalExclamation}
        setStatusData={setStatusData}
        id={memberId}
        setMemberStatus={setMemberStatus}
        loader={statusLoading || approveLoading}
      />
      <Modal
        title="Assign Fam ID"
        open={openHouseDetailCheckModal}
        setStatusData={setStatusData}
        setOpenModal={setOpenHouseDetailCheckModal}
        modalType={ModalList.HouseDetailCheckModal}
        loader={statusLoading}
      />
    </>
  );
};
export default ViewclientDetails;
