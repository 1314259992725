import { Col, Form, Row, Input as AntInput } from "antd";
import React from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import useMutate from "../../api/hooks/useMutate";
import DONOR_URLS from "../../api/urls/DONOR_URL";
import colors from "../../styles/Colors";
import { errorMessages } from "../../utils/errorMessages";
import { genderAPIFormat } from "../../utils/genderFormat";
import { regErrorMessages } from "../../utils/regErrorMessages";
import { regExpPattern } from "../../utils/regExpPattern";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import Button from "../elements/button/Button";
import Input from "../elements/input/Input";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import Select from "../elements/select/Select";
import { Requiredwrapper } from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { Container, StyledForm } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";

export const DateFromTo = styled.div`
  p {
    padding: 0 10px 0;
    margin-bottom: 7px;
  }
  .ant-form-item-label {
    display: none;
  }
`;

export const To = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding: 0px 0px 15px 12px;
`;

export const EditDonorModal = ({ header, info, close, data, setIsSuccess }) => {
  const [addressError, setAddressError] = useState("");
  const isOrgLoaded = useRef(false);

  const [address, setAddress] = useState({
    placeName: data?.placeName,
    address1: data?.address1,
    address2: data?.address2,
    lat: data?.latitude,
    lng: data?.longitude,
    placeId: data?.placeId,
    state: data?.state,
    zip: data?.zip,
    city: data?.city,
    country: data?.country,
  });

  const [EditDonorAPI, { response, error, loading }] = useMutate({
    path: DONOR_URLS.SIGNUP,
    onCompleted: () => {},
    onError: () => {},
  });
  // const navigate = useNavigate();
  const [form] = Form.useForm();
  const onSubmit = (values) => {
    const variables = {
      userId: data.userId,
      firstname: values?.firstName,
      lastname: values?.lastName,
      email: values?.email,
      password: values?.Membergender,
      ...address,
      phoneno: values?.contactNumber,
      TermAndCondition: true,
    };
    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }
    EditDonorAPI({
      variables,
      onCompleted: (data) => {
        setIsSuccess(true);
        localStorage.setItem("accessToken", data.token);
        close();
      },
      onError: () => {},
    });
  };
  return (
    <Container>
      <StyledForm
        form={form}
        onFinish={onSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label={"First Name"}
              required={true}
              name="firstName"
              requiredMessage={errorMessages?.FIRSTNAME}
              initialValue={data?.firstName}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label={"Last Name"}
              required={true}
              name="lastName"
              requiredMessage={errorMessages?.LASTNAME}
              initialValue={data?.lastName}
            />
          </Col>

          <Col span={24} sm={{ span: 12 }}>
            <Requiredwrapper>
              <span className="validdata">*</span>
            </Requiredwrapper>
            <AddressSearchDropdown
              onChange={(address) => {
                if (Object?.keys(address)?.length) {
                  if (address?.city?.length) {
                    setAddressError("");
                  } else {
                    setAddressError("Please select address with city");
                  }
                } else {
                  if (isOrgLoaded.current) {
                    setAddressError("Please select address");
                  }
                }
                setAddress(address);
                isOrgLoaded.current = true;
              }}
              value={address}
              required={false}
              requiredMessage={errorMessages.ADDRESS}
              label=" Organisation Address"
              addressError={addressError}
            />
          </Col>

          <Col span={24} sm={{ span: 12 }}>
            <PhoneNumberInput
              type="tel"
              label="Phone Number"
              name="contactNumber"
              required={true}
              initialValue={data?.phoneNo}
              requiredMessage={errorMessages.CONTACTNUMBER}
              width="100%"
            />
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Input
              label="Email"
              name="email"
              required={true}
              type="email"
              initialValue={data?.email}
              requiredMessage={errorMessages.EMAIL}
              pattern={regExpPattern.EMAIL}
              regExpMessage={regErrorMessages.EMAIL}
            />
          </Col>
          {/* <Col span={24} sm={{ span: 12 }}>
            <Input
              type="password"
              label="Password"
              name="password"
              required={true}
              initialValue={data?.password}
              pattern={regExpPattern.PASSWORD}
              regExpMessage={regErrorMessages.PASSWORD}
              requiredMessage={errorMessages.PASSWORD}
            />
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              initialValue={data?.password}
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password does not match !")
                    );
                  },
                }),
              ]}
            >
              <AntInput.Password />
            </Form.Item>
          </Col> */}
        </Row>

        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button text="Update" htmlType="submit" loading={loading} />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

export default EditDonorModal;
