import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const Heading = styled.h2`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.primary};
  padding: 0 0 5px;
  @media screen and (max-width: 567px) {
    font-size: 15px;
  }
`;
export const Label = styled.div`
  font-weight: 600;
  min-width: 150px;
  padding-right: 10px;

  @media screen and (max-width: 991px) {
    min-width: 142px;
  }
  @media screen and (max-width: 367px) {
    min-width: 115px;
  }
`;

export const SubHeading = styled.h3`
  color: ${colors.primary};
  padding: 10px 15px 0;
`;

export const CampaignsDescription = styled.p`
  font-size: 15px;
  font-weight: 700;
  padding: 10px 0;
  @media screen and (max-width: 567px) {
    font-size: 14px;
  }
`;
