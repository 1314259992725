import styled from "styled-components";
import colors from "../../styles/Colors";
import { Dropdown as AntDropdown } from "antd";

export const HeaderContainer = styled.header`
  padding: 7px 40px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-image: ${(props) =>
    `linear-gradient(179.56deg, rgba(0, 0, 0, 0) 58.09%, rgba(0, 0, 0, 0.5) 98.13%), url(${props.src})`}; */
  background-color: ${colors.primary1} !important;
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  @media screen and (max-width: 991px) {
    height: 70px;
    padding: 0 20px;
  }
`;

export const Notificaionicon = styled.div`
  font-size: 20px;
  position: relative;
`;
export const LogoContainer = styled.div`
  @media screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NavButtons = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  @media screen and (max-width: 475px) {
    gap: 16px;
  }
`;

export const Notifications = styled.div`
  font-size: 16px;
  .ant-dropdown-trigger {
    color: ${colors.white};
  }
  svg {
    color: ${colors.white};
  }
`;

export const Dropdown = styled(AntDropdown)`
  .ant-dropdown-trigger {
    color: ${colors.white};
  }
`;

export const Notification = styled.div`
  padding: 0 20px;
  font-size: 21px;
  color: #fff;
`;

export const UserName = styled.div`
  font-size: 16px;
  color: ${colors.white};
  padding: 0 10px;
  span {
    font-weight: 700;
  }
  @media screen and (max-width: 767px) {
    display: none;
    width: min-content;
  }
`;

export const Languagechange = styled.div`
  @media screen and (max-width: 767px) {
    // display: none;
  }
`;

export const Badge = styled.div`
  background: ${colors.secondary};
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  position: absolute;
  top: 1px;
  font-size: 12px;
  text-align: center;
  top: -4px;
  left: 11px;
`;
