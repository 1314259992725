import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Col, Row, Form, Image } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../utils/errorMessages";
import Button from "../elements/button/Button";
import DatePickerB from "../elements/datePicker/DatePicker";
import Input from "../elements/input/Input";
import Select from "../elements/select/Select";
import {
  ImagePlaceholder,
  Note,
  PlaceHolderWrappper,
  Requiredwrapper,
} from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { StyledForm } from "../pages/admin/signUp/SingupStlyes";
import { Container } from "./EditCampaignModal";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import { genderAPIFormat, genderFormat } from "../../utils/genderFormat";
import { ButtonContainer } from "./NewSeekerModal";
import { getTs, initialDateTime } from "../../utils/dateTime";
import useMutate from "../../api/hooks/useMutate";
import SEEKER_URLS from "../../api/urls/SEEKER_URL";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import { useRef } from "react";
import toast from "react-hot-toast";
import { regExpPattern } from "../../utils/regExpPattern";
import { regErrorMessages } from "../../utils/regErrorMessages";
import samplePdf from "../../assets/images/pdf.png";
import { ClientDocImage } from "../pages/seeker/profile/ProfileStyle";
import styled from "styled-components";

const ClientDocImageAnchor = styled.a`
  display: flex;
  width: 100%;
  flex-direction: column;

  img {
    height: 300px;
  }
`;

const DocumentsHandler = ({ document, fileUpload, handleRemoveImage }) => {
  return (
    <>
      <PlaceHolderWrappper>
        {!document?.preview?.length > 0 ? (
          <>
            <Form.Item
              label=""
              name="memberImages"
              rules={[
                {
                  required: true,
                  message: "Please insert the file !",
                },
              ]}
            >
              <ImagePlaceholder>
                <CloudUploadOutlined />
                Upload File
              </ImagePlaceholder>
              <input
                name="documents"
                type={"file"}
                className={"uploadImage"}
                accept=".png,.jpg,.jpeg, .pdf"
                onChange={(e) => fileUpload(e)}
              />
            </Form.Item>
          </>
        ) : (
          <>
            {console.log(document, "uploadMemberDoucment")}

            {document?.type === "application/pdf" ? (
              <ClientDocImageAnchor>
                <img src={samplePdf} />
                <p style={{ width: "100%", textAlign: "center" }}>
                  {document.value.name}
                </p>
              </ClientDocImageAnchor>
            ) : (
              <ClientDocImage>
                <Image src={document?.preview} />
              </ClientDocImage>
            )}
            <CloseOutlined onClick={handleRemoveImage} />
          </>
        )}
      </PlaceHolderWrappper>

      {!document?.preview?.length && (
        <Note>
          <span>
            (Please upload one of the following files: Driver's License, Utility
            Bill, Birth Certificate.)
          </span>
        </Note>
      )}
    </>
  );
};

const EditHouseHoldDetailsModal = ({
  header,
  info,
  close,
  setIsSuccess,
  data,
  id,
}) => {
  const [uploadMemberDoucment, setUploadMemberDoucment] = useState({
    preview: data?.documents?.[0]?.file,
    value: "",
    type: data?.documents?.[0]?.type,
  });

  const [address, setAddress] = useState({
    placeName: data?.placeName,
    address1: data?.address1,
    address2: data?.address2,
    lat: data?.latitude,
    lng: data?.longitude,
    placeId: data?.placeId,
    state: data?.state,
    zip: data?.zip,
    city: data?.city,
    country: data?.country,
  });
  const [addressError, setAddressError] = useState("");
  const isOrgLoaded = useRef(false);
  const [editHouseHold, { response, loading }] = useMutate({
    path: SEEKER_URLS.EDITSEEKER,
    method: "post",
    errorToast: true,
  });
  const [form] = Form.useForm();

  const BirthDateFormate = (d) => {
    return !d || d.isAfter(new Date()) || d.isSameOrBefore("1960-01-01");
  };
  const GenderOptions = [
    {
      key: "1",
      value: "Male",
    },
    {
      value: "Female",
      key: 2,
    },
    {
      value: "Other",
      key: 3,
    },
  ];
  const fileUpload = (e) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadMemberDoucment({
        value: files,
        preview: reader.result,
        type: files.type,
      });
    };
    reader.readAsDataURL(files);
  };
  const handleRemoveImage = () => {
    setUploadMemberDoucment({
      value: "",
      type: "",
      preview: "",
    });
  };
  const onSubmit = (values) => {
    const variables = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.MemberEmail,
      seekerId: id,
      dob: getTs(values?.dateOFBirthOfDaughter),
      phoneNumber: values?.contactNumber?.replace(/\s+/g, ""),
      document: [
        {
          file: uploadMemberDoucment?.preview,
          type: uploadMemberDoucment?.type,
        },
      ],

      ...address,

      longitude: address?.lng,
      latitude: address?.lat,

      gender: genderFormat(values?.gender),
    };

    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }
    editHouseHold({
      variables,
      onCompleted: () => {
        toast.success("Updated Successfully");
        setIsSuccess(true);
        close();
      },
      onError: () => {},
    });
  };

  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        form={form}
        onFinish={onSubmit}
      >
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="First Name"
              name="firstName"
              required={true}
              initialValue={data?.firstName}
              requiredMessage={errorMessages.FIRSTNAME}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Last Name"
              name="lastName"
              initialValue={data?.lastName}
              required={true}
              requiredMessage={errorMessages.LASTNAME}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Email Address"
              name="MemberEmail"
              pattern={regExpPattern.EMAIL}
              required={true}
              initialValue={data?.email}
              regExpMessage={regErrorMessages.EMAIL}
              requiredMessage={errorMessages.EMAIL}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <PhoneNumberInput
              type="tel"
              label="Phone Number"
              name="contactNumber"
              initialValue={data?.phoneNumber}
              required={false}
              requiredMessage={errorMessages.CONTACTNUMBER}
              width="100%"
            />
          </Col>
          <Col span={24}>
            <Requiredwrapper>
              <span className="validdata">*</span>
            </Requiredwrapper>
            <AddressSearchDropdown
              onChange={(address) => {
                if (Object?.keys(address)?.length) {
                  if (address?.city?.length) {
                    setAddressError("");
                  } else {
                    setAddressError("Please select address with city");
                  }
                } else {
                  if (isOrgLoaded.current) {
                    setAddressError("Please select address");
                  }
                }
                setAddress(address);
                isOrgLoaded.current = true;
              }}
              value={address}
              requiredMessage={errorMessages.ADDRESS}
              label="Street Address"
              addressError={addressError}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <DatePickerB
              name="dateOFBirthOfDaughter"
              label="Date of Birth"
              disabled={data?.dob ? true : false}
              // picker="dateOFBirthOfDaughter"
              onChange={() => {}}
              initialValue={data?.dob ? initialDateTime(data?.dob) : ""}
              disabledDate={BirthDateFormate}
              required={data?.dob ? false : true}
              requiredMessage={errorMessages.DATEOFBIRTH}
            />
          </Col>

          <Col span={24} md={{ span: 12 }}>
            <Select
              placeholder="Select Gender"
              name={"gender"}
              label={"Gender"}
              option={GenderOptions}
              required={true}
              requiredMessage={errorMessages.SELECTGENDER}
              initialValue={genderAPIFormat(data?.gender)}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <DocumentsHandler
              document={uploadMemberDoucment}
              fileUpload={fileUpload}
              handleRemoveImage={handleRemoveImage}
            />
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button
            text="Update"
            type="button"
            onClick={() => {
              if (!Object?.keys(address)?.length) {
                setAddressError("Please enter the address !");
              }
              form.submit();
            }}
            loading={loading}
          />
        </ButtonContainer>
      </StyledForm>

      {info && (
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>{info}</p>
      )}
    </Container>
  );
};

export default EditHouseHoldDetailsModal;
