import styled from "styled-components";
import colors from "../../../../styles/Colors";
import { MinusOutlined as Minus } from "@ant-design/icons";

export const AddingInfo = styled.div`
  width: 49%;
  display: flex;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
  .ant-form-item-required {
  }
`;
export const Addkidicon = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: center;
  font-size: 25px;
  margin-top: 50px;
  flex-direction: column-reverse;
  @media screen and (max-width: 1100px) {
    width: 100%;
  
  }
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }
 
.anticon-plus{
  padding 20px;
  border:2px solid ${colors.bordercolor};
  border-radius: 50%;
  color: ${colors.bordercolor};
  transition:all.5s;
  &:hover{
    color: ${colors.primary};
    border:2px solid ${colors.primary};
  }
}
`;

export const Kidinformationbox = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const Addkidtext = styled.h3`
  font-size: 16px;
  margin: 0px 0 0;
`;

export const KidWish = styled.div`
  display: flex;
  width: 100%;
`;

export const AddKidWishText = styled.h3`
  font-size: 13px;
  margin: 0px 0 0;
`;

export const ButtonContainer = styled.div`
  text-align: right;
  padding: 10px 0;
`;

export const Bold = styled.div`
  font-weight: 600;
  padding-right: 10px;
`;

export const WishesLabel = styled.div`
  margin-bottom: 10px;
  position: absolute;
  top: -14px;
  background: ${colors.white};
  width: auto;
  padding: 0 10px;
  font-size: 17px;
  font-weight: 500;
`;

export const Wishes = styled.div`
  padding: 0 20px 30px;
  border: 1px solid ${colors.background};
  border-radius: 8px;
  position: relative;
  margin: 20px 0 0px;
  background-color: ${colors.grey4};
  .ant-form-item {
    margin: 0 0 5px;
  }
`;
export const AddNewMember = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.primary};
  justify-content: flex-end;
  padding: 0 10px;
  svg {
    font-size: 28px;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 10px;
    font-size: 18px;
  }
`;
export const NotEligible = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
