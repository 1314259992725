import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const LayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  body {
    background-color: "#FAFAFA" !important;
  }
`;

export const InnerContent = styled.div`
  width: 70%;
  min-height: 60%;
  max-height: 75%;
  border: 1px solid #ccc;
  background: ${colors.white};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 991px) {
    padding: 20px 0;
  }
  @media screen and (max-width: 576px) {
    width: 90%;
    padding: 20px 10px;
  }
`;

export const OuterWrapper = styled.div`
  width: 100%;
  height: 60%;
`;
export const Heading = styled.div`
  font-size: 35px;
  font-weight: 700;
  padding: 0 10px;
  background: -webkit-linear-gradient(
    45deg,
    ${colors.primary},
    ${colors.secondary} 60%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
`;

export const RightContainer = styled.div`
  padding: 20px;
  .ant-form-item-explain-error {
    color: #ff4d4f;
    position: absolute;
    font-size: 12px;
    line-height: 17px;
    @media screen and (max-width: 991px) {
      position: relative;
    }
  }
  label.ant-radio-wrapper {
    height: 100%;
    align-items: center;
  }
`;

export const BottomForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  padding: 0 10px;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  button {
    margin: initial !important;
    cursor: pointer;
  }
`;

export const Customcheckbox = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 20px;

  .ant-checkbox-wrapper {
    &:hover,
    &:focus {
      border-color: ${colors.primary};
      box-shadow: none;
    }
    &:focus {
      box-shadow: none;
      border-color: ${colors.primary};
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${colors.primary};
    border-color: ${colors.primary};
    &:hover,
    &:focus {
      border-color: ${colors.primary} !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;
