import { Row, Col, Space, Table, Checkbox } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MainContent,
  Section,
  CampaignDescription,
  CampaignHeading,
  Filters,
  CampaignImage,
  ActionIcon,
  Dropddownfilters,
  Bottominputfilter,
  Status,
  SectionHeading,
} from "./FamilyListStyle";
import Select from "../../../elements/select/Select";
import { DownOutlined, EyeOutlined } from "@ant-design/icons";
import { CampaignData } from "../../../elements/cards/CardStyle";
import Button from "../../../elements/button/Button";
import { ButtonContainer } from "../familyDetails/FamilyDetailsStyle";
import { ActionIconWrapper } from "../../admin/donors/DonorsStyle";
import { useEffect, useState } from "react";
import useMutate from "../../../../api/hooks/useMutate";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import { formatDate, getAgeFromTs } from "../../../../utils/dateTime";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import toast from "react-hot-toast";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import LoaderDashboard from "../../../loader/LoaderDashboard";
import { genderAPIFormat } from "../../../../utils/genderFormat";
import Input from "../../../elements/input/Input";
import { COLUMN_WIDTH } from "../../admin/seekers/viewclient/Viewclient";
// import { Link } from "../../elements/links/Links";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let pagination = "";
function FamilyList() {
  const query = useQuery();
  const id = query.get("id");
  const admin = query.get("admin");
  const donorId = query.get("donorId");
  const [selectedFamiles, setSelectedFamiles] = useState({
    seekers: [],
  });
  const [page, setPage] = useState(1);
  const [sortTable, setSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [
    getCityList,
    { response: cityResponse, loading: cityResponseLoading },
  ] = useMutate({
    path: DONOR_URLS.GETCITYFROMSEEKERS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [
    getAvailableSeekers,
    { response: availableSeekers, loading: availableSeekersLoading },
  ] = useMutate({
    method: "get",
    path: DONOR_URLS.DONOR_LIST,
    onCompleted: () => {},
    onError: () => {},
  });
  const [openConfirmModal, setOpenConfirmMiodal] = useState(false);
  const [filterCity, setFilterCity] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [getCampaignDetail, { response, loading: campaignLoading }] = useMutate(
    {
      path: SEEKER_URLS.CAMPAIGNDETAIL,
      method: "get",
      onCompleted: () => {},
      onError: () => {},
    }
  );

  useEffect(() => {
    getCityList({
      params: {
        campaignId: id,
      },
      onCompleted: () => {},
      onError: () => {},
    });
  }, []);
  const getCityFromCityResponse =
    cityResponse?.data?.map((info, index) => {
      info.value = info.city;
      info.key = index + 2;
      return info;
    }) || [];
  getCityFromCityResponse.unshift({ value: "Select", key: 1 });

  const [addFamilyToDonate, { loading: addFamilyToDonateLoading }] = useMutate({
    path: DONOR_URLS.ADD_SELECTED_FAMILY,
    method: "post",
    onCompleted: () => {},
    onError: () => {},
  });
  const navigate = useNavigate();

  const columns = [
    {
      title: "# of Children",
      dataIndex: "noOfChildrens",
      width: "25%",
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
      width: "25%",
    },
    {
      title: "Select Families",
      dataIndex: "selectFamilies",
      width: "25%",
      render: (_, record) => (
        <ActionIconWrapper>
          <Checkbox
            checked={selectedFamiles.seekers.find((check) => {
              return check === record.userId;
            })}
            onChange={(e) => selectFamily(e, record)}
          >
            Select family
          </Checkbox>
        </ActionIconWrapper>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIconWrapper>
          <ActionIcon
            onClick={() => {
              if (admin) {
                return navigate(
                  `/family-details?clientId=${record?.userId}&campaignId=${id}&admin=true&donorId=${donorId}`
                );
              }

              navigate(
                `/family-details?clientId=${record?.userId}&campaignId=${id}`
              );
            }}
          >
            <EyeOutlined />
          </ActionIcon>
        </ActionIconWrapper>
      ),
      width: "25%",
    },
  ];

  const selectFamily = (e, record) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedFamiles((val) => ({
        ...val,
        seekers: [...val.seekers, record.userId],
      }));
    } else {
      const filteredSeekers = selectedFamiles.seekers.filter((family) => {
        return family !== record.userId;
      });
      setSelectedFamiles((val) => ({
        ...val,
        seekers: [...filteredSeekers],
      }));
    }
  };

  useEffect(() => {
    getCampaignDetail({
      params: {
        campaignId: id,
      },
      onCompleted: () => {},
      onError: () => {},
    });
  }, []);
  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const addSelectedFamilies = () => {
    const variables = {
      campaignId: id,
      seekers: JSON.stringify(selectedFamiles.seekers),
    };

    if (donorId) {
      variables.donorId = donorId;
    }

    addFamilyToDonate({
      variables,
      onCompleted: () => {
        setOpenConfirmMiodal(false);
        toast.success("Families selected successfully");
        navigate(-1);
      },
      onError: () => {
        setOpenConfirmMiodal(false);
      },
    });
  };
  const handleSubmit = () => {
    if (selectedFamiles?.seekers?.length) {
      setOpenConfirmMiodal(true);
    } else {
      toast.error("Please select a family for donation.");
    }
  };

  useEffect(() => {
    const param = {
      pageNo: page || 1,
      PageSize: 100,
      searchValue: searchValue,
      sortColumn: sortTable.column,
      sortOrder: sortTable.order,
      campaignId: id,
      filter: filterCity,
    };
    getAvailableSeekers({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [page, sortTable, filterCity, searchValue]);
  pagination = availableSeekers?.data?.pagination;
  const handleCity = (value) => {
    if (value === "Select") {
      setSearchValue("");
    } else {
      setSearchValue(value);
    }
  };
  const handleChildrenCount = (value) => {
    if (value === "Select") {
      setFilterCity("");
    } else {
      setFilterCity(value);
    }
  };

  const memberOptions = [
    { key: 1, value: "Select" },
    { key: 2, value: "1" },
    { key: 3, value: "2" },
    { key: 4, value: "3" },
    { key: 5, value: "4" },
    { key: 6, value: "5" },
    { key: 7, value: "6" },
    { key: 8, value: "7" },
    { key: 9, value: "8" },
    { key: 10, value: "9" },
    { key: 11, value: "10" },
  ];

  return (
    <>
      {!(campaignLoading || cityResponseLoading) ? (
        <MainContent>
          <Section>
            <Row gutter="10">
              <Col xl={{ span: 24 }} span={24}>
                <SectionHeading>
                  {response?.data?.campaignName}
                  <Button text={"Back"} onClick={() => navigate(-1)} />
                </SectionHeading>
                <CampaignImage src={response?.data?.imageFile} />
              </Col>
              <Col xl={{ span: 24 }} span={24}>
                <CampaignDescription>
                  {response?.data?.description}
                </CampaignDescription>
              </Col>
              <Col xl={{ span: 12 }} span={24}>
                <CampaignData>
                  <span className="bold"> Start Date: </span>
                  {response?.data?.campaignStartDate
                    ? formatDate(
                        response?.data?.campaignStartDate,
                        "MM/DD/YYYY"
                      )
                    : ""}
                </CampaignData>
              </Col>
              <Col xl={{ span: 12 }} span={24}>
                <CampaignData>
                  <span className="bold"> End Date: </span>
                  {response?.data?.campaignEndDate
                    ? formatDate(response?.data?.campaignEndDate, "MM/DD/YYYY")
                    : ""}
                </CampaignData>
              </Col>
              <Col xl={{ span: 12 }} span={24}>
                <CampaignData>
                  <span className="bold">Enrollment period:</span>{" "}
                  {response?.data?.enrollmentPeriodStartDate &&
                    formatDate(
                      response?.data?.enrollmentPeriodStartDate,
                      "MM/DD/YYYY"
                    )}{" "}
                  To{" "}
                  {response?.data?.enrollmentPeriodEndDate &&
                    formatDate(
                      response?.data?.enrollmentPeriodEndDate,
                      "MM/DD/YYYY"
                    )}
                  {/* <CalendarOutlined title="Add to calender" /> */}
                </CampaignData>
              </Col>
              <Col xl={{ span: 12 }} span={24}>
                <CampaignData>
                  <span className="bold"> Donation drop-off: </span>
                  {response?.data?.dropOfAddress}
                </CampaignData>
              </Col>
            </Row>
            &nbsp;
            <hr />
          </Section>
          <Row>
            <Col md={{ span: 4 }} span={24}>
              {/* <p style={{ marginBottom: 0 }}>&nbsp;</p> */}
              <CampaignHeading>Family List</CampaignHeading>
            </Col>
            <Col md={{ span: 20 }} span={24}>
              <Filters>
                <Dropddownfilters>
                  {/* <Status> Max Children</Status> */}
                  <Bottominputfilter>
                    <Select
                      style={{ margin: 0 }}
                      option={memberOptions}
                      trigger={["click"]}
                      onSelect={(value) => handleChildrenCount(value)}
                      defaultValue={"Select"}
                      label="Max Children"
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <DownOutlined />
                        </Space>
                      </a>
                    </Select>
                  </Bottominputfilter>
                </Dropddownfilters>

                <Dropddownfilters>
                  {/* <Status> City </Status> */}
                  <Bottominputfilter>
                    <Select
                      style={{ margin: 0 }}
                      option={getCityFromCityResponse}
                      trigger={["click"]}
                      onSelect={(value) => handleCity(value)}
                      defaultValue={"Select"}
                      label="City "
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <DownOutlined />
                        </Space>
                      </a>
                    </Select>
                  </Bottominputfilter>
                </Dropddownfilters>
              </Filters>
            </Col>
          </Row>
          <Table
            dataSource={availableSeekers?.data?.records}
            columns={columns}
            loading={availableSeekersLoading}
            scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
            onChange={handleChange}
            pagination={{
              pageSize: 100,
              defaultCurrent: page,
              total: pagination?.totalRecordsCount,
              showSizeChanger: false,
              hideOnSinglePage: true,
            }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend", "ascend"]}
          />
          <ButtonContainer>
            <Button
              text="Select Families"
              onClick={handleSubmit}
              disabled={!selectedFamiles?.seekers?.length ? true : false}
            />
          </ButtonContainer>
        </MainContent>
      ) : (
        <LoaderDashboard />
      )}
      <Modal
        title="Select Families"
        open={openConfirmModal}
        setOpenModal={setOpenConfirmMiodal}
        loading={addFamilyToDonateLoading}
        modalType={ModalList.ConfirmSeekerSelection}
        onConfirm={addSelectedFamilies}
        successIcon={true}
        noDelete
      />
    </>
  );
}
export default FamilyList;
