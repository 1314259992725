import React, { useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import { Space } from "antd";
import {
  EyeOutlined,
  ClockCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { ActionIcon } from "../../admin/donors/DonorsStyle";
import { PageHeading } from "../../admin/campaigns/CampaignStyle";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { Moreicon } from "./SeekersStyle";
import { COLUMN_WIDTH } from "../../admin/seekers/viewclient/Viewclient";

const SeekersList = () => {
  const [openViewSeekerModal, setOpenViewSeekerModal] = useState(false);
  const viewSeeker = () => {
    setOpenViewSeekerModal(true);
  };

  const dataSource = [
    {
      id: "IFS25703257",
      famId: "OE3607R178F",
      name: "William",
      OptedinCampaigns: "5",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
      notOptedinCampaigns: "4",
    },

    {
      id: "IFS25703257",
      famId: "HE3607R178F",
      OptedinCampaigns: "6",
      notOptedinCampaigns: "4",
      name: "Henry",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "AS3607R178F",
      OptedinCampaigns: "9",
      notOptedinCampaigns: "1",
      name: "Olivia",
      requeststatus: "Rejected",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "OQ3607R178F",
      notOptedinCampaigns: "8",
      OptedinCampaigns: "2",
      name: "James",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "GH3607R178F",
      name: "Sophia",
      OptedinCampaigns: "1",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
      notOptedinCampaigns: "4",
    },
    {
      id: "IFS25703257",
      famId: "YH3607R178F",
      name: "Lucas",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
      OptedinCampaigns: "5",
      notOptedinCampaigns: "4",
    },
    {
      id: "IFS25703257",
      famId: "SJ3607R178F",
      notOptedinCampaigns: "4",
      name: "William",
      requeststatus: "Rejected",
      OptedinCampaigns: "3",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "IOEGH607R178F",
      name: "Henry",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
      notOptedinCampaigns: "4",
      OptedinCampaigns: "5",
    },
    {
      id: "IFS25703257",
      famId: "DCE3607R178F",
      name: "Olivia",
      requeststatus: "Rejected",
      OptedinCampaigns: "7",
      notOptedinCampaigns: "4",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "NJE3607R178F",
      OptedinCampaigns: "5",
      notOptedinCampaigns: "4",
      name: "James",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "JK3607R178F",
      notOptedinCampaigns: "4",
      name: "Sophia",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
      OptedinCampaigns: "5",
    },
    {
      id: "IFS25703257",
      famId: "KLE3607R178F",
      OptedinCampaigns: "5",
      notOptedinCampaigns: "4",
      name: "Lucas",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
    },
    {
      OptedinCampaigns: "5",
      famId: "JH3607R178F",
      notOptedinCampaigns: "4",
      name: "Emma",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "KE3607R178F",
      name: "Henry",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
      notOptedinCampaigns: "4",
      OptedinCampaigns: "5",
    },
    {
      id: "IFS25703257",
      famId: "IH3607R178F",
      OptedinCampaigns: "5",
      notOptedinCampaigns: "4",
      name: "Olivia",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "JIE3607R178F",
      name: "James",
      requeststatus: "Approved",
      notOptedinCampaigns: "4",
      caontactNo: "(111)-111-1111",
      OptedinCampaigns: "5",
    },
    {
      id: "IFS25703257",
      famId: "UL3607R178F",
      name: "Sophia",
      notOptedinCampaigns: "4",
      OptedinCampaigns: "5",
      requeststatus: "Approved",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "IFS25703257",
      famId: "UY3607R178F",
      name: "Lucas",
      OptedinCampaigns: "5",
      requeststatus: "Approved",
      notOptedinCampaigns: "4",
      caontactNo: "(111)-111-1111",
    },
  ];
  const ActionMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <ActionIcon title="View" onClick={() => viewSeeker()}>
              <EyeOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "",
          label: (
            <ActionIcon title="Decline">
              <ClockCircleOutlined />
            </ActionIcon>
          ),
        },
      ]}
    />
  );

  const columns = [
    {
      title: "Unique ID#",
      dataIndex: "id",

      sorter: {
        compare: (a, b) => {
          if (a.id < b.id) return -1;
          if (b.id < a.id) return 1;
          return 0;
        },
      },
      defaultSortOrder: "ascend",
    },

    {
      title: "FAM #",
      dataIndex: "famId",

      sorter: {
        compare: (a, b) => {
          if (a.famId < b.famId) return -1;
          if (b.famId < a.famId) return 1;

          return 0;
        },
      },
      defaultSortOrder: "ascend",
    },

    {
      title: "Name",
      dataIndex: "name",

      sorter: {
        compare: (a, b) => {
          if (a.name < b.name) return -1;
          if (b.name < a.name) return 1;

          return 0;
        },
      },
      defaultSortOrder: "ascend",
    },
    // {
    //   title: "Last Name",
    //   dataIndex: "tName",

    //   sorter: {
    //     compare: (a, b) => {
    //       if (a.turn 1;

    //       return 0;
    //     },
    //   },
    //   defaultSortOrder: "ascend",
    // },
    {
      title: "Request Status",
      dataIndex: "requeststatus",
      sorter: {
        compare: (a, b) => a.requeststatus - b.requeststatus,
      },
    },

    // {
    //   title: "x: "
    //     compare: (a, b) => a.
    // },
    // {
    //   title: "Phone Number ",
    //   dataIndex: "caontactNo",
    //   sorter: {
    //     compare: (a, b) => a.
    // },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            onClick={(e) => {
              e.preventDefault();
              // setActionData(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <MainContent>
        <PageHeading>
          Clients
          {/* <Button text={"Add New Client"} onClick={addNewMember} /> */}
        </PageHeading>
        <Table
          dataSource={dataSource}
          sortDirections={["ascend", "descend", "ascend"]}
          columns={columns}
          scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
          showSorterTooltip={false}
          pagination={{
            pageSize: 100,
            hideOnSinglePage: true,
          }}
        />
      </MainContent>

      <Modal
        title="View Client"
        open={openViewSeekerModal}
        setOpenModal={setOpenViewSeekerModal}
        modalType={ModalList.ViewSeekerModal}
      />
    </>
  );
};

export default SeekersList;
