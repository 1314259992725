import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axiosInstance from "../axios";
export const HTTP_METHODS = {
  GET: "get",
  POST: "post",
  FORMDATA: "form-data",
  PUT: "put",
  PATCH: "patch",
};

const useMutate = ({
  method = "post",
  path,
  config = {},
  isFormData = false,
  errorToast = false,
  successToast = false,
}) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState();

  const mutate = async ({ variables, params, onCompleted, onError }) => {
    try {
      const ctrl = new AbortController();
      setController(ctrl);
      setLoading(true);

      if (params) {
        const queryString = new URLSearchParams(params).toString();
        path = `${path}?${queryString}`;
      }

      let res;
      if (method.toLowerCase() === "get") {
        res = await axiosInstance[method.toLowerCase()](path, {
          ...config,
          signal: ctrl.signal,
        });
      } else {
        // request with body

        if (isFormData) {
          const formData = convertToFormData({ ...variables });
          res = await axiosInstance[method.toLowerCase()](path, formData, {
            ...config,
            "Content-Type": "multipart/form-data",
            signal: ctrl.signal,
          });
        } else {
          res = await axiosInstance[method.toLowerCase()](
            path,
            { ...variables },
            {
              ...config,
              signal: ctrl.signal,
            }
          );
        }
      }

      if (res.data.code != 200) {
        throw new Error(res?.data?.message);
      }

      if (successToast) {
        toast.success(res?.data?.message);
      }

      setResponse(res.data);
      if (onCompleted && typeof onCompleted === "function") {
        onCompleted(res.data.data);
      }
    } catch (err) {
      if (errorToast) {
        toast.error(err.message);
      }

      setError(err.message);
      if (onError && typeof onError === "function") {
        onError();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return [mutate, { response, error, loading }];
};

const convertToFormData = (obj) => {
  let formData = new FormData();
  for (const [key, value] of Object.entries(obj)) {
    formData = objectToFormData(value, key, formData);
  }

  return formData;
};

const objectToFormData = (obj, rootName, formData) => {
  const appendFormData = (data, root) => {
    root = root || "";
    if (data instanceof File) {
      formData.append(root, data);
    } else if (Array.isArray(data)) {
      for (var i = 0; i < data.length; i++) {
        appendFormData(data[i], root + "[" + i + "]");
      }
    } else if (typeof data === "object" && data) {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          if (root === "") {
            appendFormData(data[key], key);
          } else {
            appendFormData(data[key], root + "[" + key + "]");
          }
        }
      }
    } else {
      if (data !== null && typeof data !== "undefined") {
        formData.append(root, data);
      }
    }
  };
  appendFormData(obj, rootName);

  return formData;
};

export default useMutate;

// const Sample = () => {

//   const [postUserData,{ response, error, loading }] = useMutate({
//     path:COMMON_URL.CREATE_USER,
//     onCompleted :() => {},
//     onError: () => {}
//     }
//   );

//   return;

//   <button onClick={()=>postUserData({variables:userData})}>Hello</button>;
// };
