import styled from "styled-components";
import Colors from "../../../../styles/Colors";

export const ProfileContainer = styled.div``;

export const PofileBanner = styled.div`
  height: 180px;
  background: -webkit-linear-gradient(
    80deg,
    #004d8cbd,
    #ef7b14b8 46%,
    #004d8cc4 93%
  );
  position: relative;
  margin: 0 0 40px;
`;

export const ProfileInitial = styled.div`
  position: absolute;
  bottom: -22px;
  left: 48%;
  height: 50px;
  width: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px #3f51b5;
  border-radius: 50%;
  font-size: 28px;
  font-weight: bolder;
  color: #0000ff;
`;

export const BottomForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  gap: 10px;
  left: 0;
  padding: 0px 20px;
`;

export const FormFields = styled.div`
  position: relative;
  // padding: 0 20px 25px;
`;

export const Profiletabs = styled.div`
  border: 1px solid ${Colors.grey6};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 10px 0 20px 0;
  padding: 10px 20px;
  height: calc(100% - 20px);
  @media screen and (max-width: 991px) {
    padding: 20px 18px;
  }
`;

export const Changepswd = styled.div`
  padding: 30px 0 8px 0;
  margin: 10px 0 0 0;
`;

export const Profilesection = styled.div`
  margin: 40px 0 0 0;
`;

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const Unitfeild = styled.div`
  padding: 20px;
  border: 1px solid ${Colors.grey6};
  min-height: calc (100vh - 100px);
  margin-bottom:15px;
`;
export const ViewProfile = styled.div`
  border: 1px solid ${Colors.grey6};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 10px 0 0 0;
  padding: 15px 20px;
  height: calc(100% - 20px);
  @media screen and (max-width: 991px) {
    padding: 10px 18px;
  }
`;

export const ClientDocImage = styled.div`
  display: flex;
  width: 100%;
  img{
    height: 300px;
  }
`;
