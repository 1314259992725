import styled from "styled-components";
import colors from "../../styles/Colors";

export const Header = styled.header`
  padding: 20px 40px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${colors.primary1};
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  @media screen and (max-width: 991px) {
    height: 70px;
    padding: 0 20px;
  }
`;
export const PaginationWrapper = styled.div`
  width: 100% !important;
  display: flex;
  justify-content: end;
  align-items: center;
`;
export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavItem = styled.div`
  display: flex;
  column-gap: 40px;
  align-items: center;
`;
export const Logo = styled.div`
  background: #f1f1f1;
  padding: 4px;
  box-shadow: 0 0 10px #00000047;
  @media screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 125px;
    @media screen and (max-width: 991px) {
      width: 100px;
    }
  }
`;

export const NavButtons = styled.div`
  display: flex;
  gap: 10px;
  @media screen and (max-width: 991px) {
    // display: none;
  }
`;

export const NavList = styled.ul`
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  column-gap: 20px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    background: ${colors.offWhite};
    position: absolute;
    top: 70px;
    height: 100vh;

    // left: -205px;
    right: ${(props) => (props.isOpen ? "0" : "-205px")};
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    transition: all.6s;
    &.showNav {
      right: 0;
    }
  }

  // @media screen and (max-width: 575px) {
  //   flex-direction: column;
  //   background: white;
  //   position: absolute;
  //   top: 70px;
  //   left: -100%;
  //   width: 100%;
  //   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  //   transition: all.6s;
  //   &.toggle {
  //     left: 0;
  //   }
  // }
`;

export const NavLink = styled.li`
  margin: 0;
  list-style: none;
  padding: 10px;
  text-transform: capitalize;
  //   margin-right: 10px;
  text-align: center;
  font-size: 15px;
  color: ${colors.white};
  transition: all.8s;
  font-weight: 600;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    color: ${colors.black};
    display: flex;
    width: 200px;
    padding: 18px 20px;
    // border-bottom: 1px solid #e0e0e08c;
  }

  &:hover {
    transition: all.5s;
    color: ${colors.primary};
  }
`;
