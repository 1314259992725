import styled from "styled-components";
import { Row, Col, Image, Form, Switch } from "antd";
import Input from "../elements/input/Input";
import { useState } from "react";
import {
  CloseOutlined,
  CloudUploadOutlined,
  FilePdfOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import colors from "../../styles/Colors";
import SEEKER_URLS from "../../api/urls/SEEKER_URL";
import useMutate from "../../api/hooks/useMutate";
import { genderFormat } from "../../utils/genderFormat";
import { errorMessages } from "../../utils/errorMessages";
import Select from "../elements/select/Select";
import DatePickerB from "../elements/datePicker/DatePicker";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import { regExpPattern } from "../../utils/regExpPattern";
import { regErrorMessages } from "../../utils/regErrorMessages";
import Button from "../elements/button/Button";
import { Container, StyledForm } from "./EditCampaignModal";
import { formatDate, getAgeFromTs, getTs } from "../../utils/dateTime";
import { AddNewMember } from "../pages/seeker/addFamilyDetails/addFamilyDetailsStyle";
import { DocItem } from "../pages/seeker/profile/Profile";
import moment from "moment";
import {
  ImagePlaceholder,
  PlaceHolderWrappper,
  relationOptions,
  Requiredwrapper,
} from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { useRef } from "react";
import toast from "react-hot-toast";

export const FormFields = styled.div`
  position: relative;
  padding: 0 20px 10px;
  @media screen and (max-width: 1199px) {
    max-height: 400px;
    padding: 0 20px 10px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 10px;
  position: fixed;
  bottom: 0;
  gap: 10px;
  border-top: 1px solid ${colors.background};
  width: 100%;
  left: 0;
  padding: 10px 25px;
  background: #fff;
  border-radius: 0 0 10px 10px;
  .SecondaryBtn {
    background-color: ${colors.white};
    border-color: ${colors.primary};
    color: ${colors.primary};
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    left: 0;
    border-radius: 0 0 10px 10px;
    padding: 5px 20px;
  }
`;

export const BottomForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 10px 30px 10px 40px;
  align-items: center;
  background: #fff;
  button {
    margin: initial !important;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const Note = styled.div`
  padding: 10px 10px;
  display: flex;
  gap: 10px;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
  span {
    font-weight: 600;
    color: #909090;
  }
  @media screen and (max-width: 575px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ModalContent = styled.div`
  padding: 20px 10px;
`;

export const NewMemberDetails = styled.div`
  background: ${colors.white};
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  padding: 15px 25px 10px 15px;
  margin-bottom: 30px;
`;

export const Label = styled.div`
  font-weight: 600;
  min-width: 100px;
`;

export const Details = styled.div`
  display: flex;
`;

const GenderOptions = [
  {
    value: "Male",
    key: 1,
  },
  {
    value: "Female",
    key: 2,
  },
  {
    value: "Other",
    key: 3,
  },
];

const dateFormat = "MM/DD/YYYY";

function AddNewSeekerModal({ close, data, setIsSuccess }) {
  const [address, setAddress] = useState({});
  const [addressError, setAddressError] = useState("");
  const [isAllowed, setIsAllowed] = useState([]);
  const [uploadMemberDoucment, setUploadMemberDoucment] = useState({
    preview: data?.documents?.[0]?.file || "",
    type: data?.documents?.[0]?.file.slice(-3),
  });
  const [postSeekerSignUp, { loading }] = useMutate({
    path: SEEKER_URLS.CREATE_SEEKER_ADMIN,
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const [form] = Form.useForm();
  const members = [
    {
      memberId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      birthDate: "",
      relation: "",
      gender: "",
      documents: [
        {
          file: "",
          type: "",
        },
      ],
    },
  ];
  const regForm = {
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    gender: genderFormat(data?.gender) || "",
    dob: data?.birthDate || "",
    address: data?.address || "",
    phoneNumber: data?.phoneNumber?.replace(/\s+/g, "") || "",
    email: data?.email || "",
    password: data?.email || "",
    documents: data?.documents || [],
    member: data?.members || [],
  };
  const BirthDateFormate = (d) => {
    return !d || d.isAfter(new Date()) || d.isSameOrBefore("1960-01-01");
  };

  const [registerationForm, setRegisterationForm] = useState(regForm);

  const onSubmit = (values) => {
    const houseHoldMembers =
      registerationForm?.member?.length > 0
        ? registerationForm?.member?.map((item) => {
            // debugger;
            if (!item?.documents?.[0]?.file.length) {
              item.documents = [];
            }
            return item;
          })
        : [];
    const variables = {
      isHouseHold: 1,
      userName: data?.userName || values?.famId || "",
      firstName: values.MemberfirstName,
      lastName: values.MemberlastName,
      gender: genderFormat(values.Membergender),
      // birthDate: moment(values?.memberDOB).format("YYYY-MM-DD"),
      birthDate: getTs(values.memberDOB),
      phoneNumber: values.MemberContactNumber?.replace(/\s+/g, ""),
      email: values.MemberEmail,

      password: values.MemberPassword,
      documents: [
        {
          file: uploadMemberDoucment.preview,
          type: uploadMemberDoucment.value.type,
        },
      ],

      ...address,

      longitude: address?.lng,
      latitude: address?.lat,

      members: houseHoldMembers,
    };
    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }
    postSeekerSignUp({
      variables,
      onCompleted: () => {
        toast.success("Client added successfully");
        setIsSuccess(true);
        close();
      },
      onError: () => {},
    });
  };
  const [uploadOtherDocuments, setUploadOtherDocuments] = useState({
    value: "",
    preview: "",
  });
  const [antherHouseHold, setAntherHouseHold] = useState();
  const getOtherMember = (checked) => {
    if (checked) {
      setRegisterationForm((val) => ({
        ...val,
        member: [...val.member, ...members],
      }));
      setIsAllowed((old) => [...old, true]);
    } else {
      setRegisterationForm((val) => ({
        ...val,
        member: [],
      }));
      setIsAllowed([]);
    }
    setAntherHouseHold(checked);
  };
  const fileUpload = (e, check) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (check === "housemember") {
        setUploadMemberDoucment({
          value: files,
          preview: reader.result,
        });
      } else {
        setUploadOtherDocuments({
          value: files,
          preview: reader.result,
        });
      }
    };

    reader.readAsDataURL(files);
  };

  const addMember = () => {
    setRegisterationForm((val) => ({
      ...val,
      member: [...val.member, ...members],
    }));
    setIsAllowed((old) => [...old, true]);
  };

  const handleFirstName = (e, id) => {
    const { value } = e.target;
    const updatedData = registerationForm.member.map((member) => {
      if (member.memberId === id) {
        member.firstName = value;
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };
  const handleDob = (e, id) => {
    const updatedData = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.birthDate = getTs(e);
        const date = getTs(e);
        const allowedMembers = isAllowed?.map((memInfo, memIndex) => {
          if (memIndex === id) {
            if (getAgeFromTs(date) <= 18) {
              memInfo = false;
            } else {
              memInfo = true;
            }
          }

          return memInfo;
        });
        setIsAllowed(allowedMembers);
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };
  const handleLastName = (e, id) => {
    const { value } = e.target;
    const updatedData = registerationForm.member.map((member) => {
      if (member.memberId === id) {
        member.lastName = value;
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };
  const handleRelation = (value, id) => {
    const updatedData = registerationForm.member.map((member) => {
      if (member.memberId === id) {
        member.relation = value;
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };

  const handleGenderSelect = (value, id) => {
    const updatedData = registerationForm.member.map((member) => {
      if (member.memberId === id) {
        member.gender = genderFormat(value);
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };

  const handlePhoneNumber = (e, id) => {
    const { value } = e.target;
    const updatedData = registerationForm.member.map((member) => {
      if (member.memberId === id) {
        member.phoneNumber = value;
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };

  const handleImagesUpload = (e, id) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    let updatedData;
    reader.readAsDataURL(files);
    reader.onloadend = () => {
      updatedData = registerationForm.member.map((item, index) => {
        if (index === id) {
          let items = item?.documents?.[0];
          items.file = reader.result;
          items.type = files.type;
        }
        return item;
      });

      setRegisterationForm((val) => ({
        ...val,
        member: [...updatedData],
      }));
    };
  };
  const handleRemvoveMemberImage = (id) => {
    const filteredImages = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.documents = [
          {
            file: "",
            type: "",
          },
        ];
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...filteredImages],
    }));
  };
  const handleRemoveImage = () => {
    setUploadMemberDoucment({
      value: "",
      preview: "",
    });
  };

  const isOrgLoaded = useRef(false);

  return (
    <>
      <Container>
        <Row>
          <Col span={24}>
            <StyledForm
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete="off"
              form={form}
              onFinish={onSubmit}
            >
              <Row>
                <Col span={24} lg={{ span: 12 }}>
                  <Input
                    label="FAM #"
                    name="famId"
                    required={true}
                    initialValue={data?.userName}
                    requiredMessage={errorMessages.FAMID}
                    pattern={regExpPattern.SUBDOMAIN}
                    regExpMessage={regErrorMessages.SUBDOMAIN}
                  />
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                  <Input
                    label="First Name"
                    name="MemberfirstName"
                    required={true}
                    initialValue={registerationForm.firstName}
                    requiredMessage={errorMessages.FIRSTNAME}
                  />
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                  <Input
                    label="Last Name"
                    name="MemberlastName"
                    initialValue={registerationForm.lastName}
                    required={true}
                    requiredMessage={errorMessages.LASTNAME}
                  />
                </Col>

                <Col span={24} lg={{ span: 12 }}>
                  <Select
                    placeholder="Select Gender"
                    name="Membergender"
                    FormFields
                    label={"Gender"}
                    option={GenderOptions}
                    required={true}
                    initialValue={
                      registerationForm.gender ? registerationForm.gender : ""
                    }
                    requiredMessage={errorMessages.SELECTGENDER}
                  />
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                  <DatePickerB
                    name="memberDOB"
                    label="Date of Birth"
                    // picker="HeadofHouseholdDateofBirth"
                    onChange={(e) => {}}
                    disabledDate={BirthDateFormate}
                    required={true}
                    initialValue={
                      registerationForm.dob
                        ? formatDate(registerationForm.dob)
                        : ""
                    }
                    requiredMessage={errorMessages.DATEOFBIRTH}
                  />
                </Col>

                <Col span={24} lg={{ span: 12 }}>
                  <Requiredwrapper>
                    <span className="validdata">*</span>
                  </Requiredwrapper>
                  <AddressSearchDropdown
                    onChange={(address) => {
                      if (Object?.keys(address)?.length) {
                        if (address?.city?.length) {
                          setAddressError("");
                        } else {
                          setAddressError("Please select address with city");
                        }
                      } else {
                        if (isOrgLoaded.current) {
                          setAddressError("Please select address");
                        }
                      }
                      setAddress(address);
                      isOrgLoaded.current = true;
                    }}
                    value={address}
                    requiredMessage={errorMessages.ADDRESS}
                    label="Street Address"
                    addressError={addressError}
                  />
                </Col>

                <Col span={24} lg={{ span: 12 }}>
                  <PhoneNumberInput
                    type="tel"
                    label="Phone Number"
                    name="MemberContactNumber"
                    required={true}
                    initialValue={registerationForm.phoneNumber}
                    requiredMessage={errorMessages?.CONTACTNUMBER}
                    width="100%"
                  />
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                  <Input
                    label="Email Address"
                    name="MemberEmail"
                    pattern={regExpPattern.EMAIL}
                    required={true}
                    initialValue={registerationForm.email}
                    regExpMessage={regErrorMessages.EMAIL}
                    requiredMessage={errorMessages.EMAIL}
                  />
                </Col>

                <Col span={24} lg={{ span: 12 }}>
                  <PlaceHolderWrappper>
                    {!uploadMemberDoucment.preview?.length > 0 ? (
                      <>
                        <Form.Item
                          label=""
                          name="memberImages"
                          rules={[
                            {
                              required: true,
                              message: "Please insert the file !",
                            },
                          ]}
                        >
                          <ImagePlaceholder>
                            <CloudUploadOutlined />
                            Upload File
                          </ImagePlaceholder>
                          <input
                            name="documents"
                            type={"file"}
                            className={"uploadImage"}
                            accept=".png, .jpg, .jpeg,.pdf "
                            onChange={(e) => fileUpload(e, "housemember")}
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        {uploadMemberDoucment.value.type !==
                        ("application/pdf" || "pdf") ? (
                          <Image src={uploadMemberDoucment.preview} />
                        ) : (
                          <DocItem>
                            <FilePdfOutlined />
                          </DocItem>
                        )}

                        <CloseOutlined onClick={handleRemoveImage} />
                      </>
                    )}
                  </PlaceHolderWrappper>

                  <Note>
                    <span>
                      (Please upload one of the following files: Driver's
                      License, Utility Bill, Birth Certificate.)
                    </span>
                  </Note>
                </Col>
                <Col span={24}>
                  <Note>
                    Do you have anyone else in your Household ?
                    <div>
                      <span
                        style={{
                          color: `${!antherHouseHold ? "#004d8c" : ""} `,
                        }}
                      >
                        No
                      </span>
                      <Switch
                        checked={antherHouseHold}
                        onChange={getOtherMember}
                        style={{ margin: "0 10px" }}
                      />
                      <span
                        style={{
                          color: `${antherHouseHold ? "#004d8c" : ""} `,
                        }}
                      >
                        Yes
                      </span>
                    </div>
                  </Note>
                </Col>
              </Row>
              {antherHouseHold && (
                <>
                  <Row>
                    <Col span={24}>
                      <p>&nbsp;</p>
                    </Col>
                  </Row>
                  <NewMemberDetails>
                    {registerationForm?.member?.length > 0 &&
                      registerationForm?.member.map((member, index) => {
                        return (
                          (member.memberId = index),
                          (
                            <Row>
                              <Col span={24} lg={{ span: 12 }}>
                                <Input
                                  name={`firstName + ${index}`}
                                  label="First Name"
                                  initialValue={member?.firstName}
                                  onChange={(e) =>
                                    handleFirstName(e, member.memberId)
                                  }
                                  required={true}
                                  requiredMessage={errorMessages.FIRSTNAME}
                                />
                              </Col>
                              <Col span={24} lg={{ span: 12 }}>
                                <Input
                                  name={`lastName + ${index}`}
                                  label="Last Name"
                                  onChange={(e) =>
                                    handleLastName(e, member.memberId)
                                  }
                                  initialValue={member?.lastName}
                                  required={true}
                                  requiredMessage={errorMessages.LASTNAME}
                                />
                              </Col>
                              <Col span={24} lg={{ span: 12 }}>
                                <DatePickerB
                                  name={`dob + ${index}`}
                                  label="Date of Birth"
                                  disabledDate={BirthDateFormate}
                                  // picker="Member1DateofBirth"
                                  required={true}
                                  onChange={(e) =>
                                    handleDob(e, member.memberId)
                                  }
                                  initialValue={
                                    member.birthDate
                                      ? formatDate(member.birthDate)
                                      : ""
                                  }
                                  requiredMessage={errorMessages.DATEOFBIRTH}
                                  format={dateFormat}
                                />
                              </Col>
                              <Col span={24} lg={{ span: 12 }}>
                                <Select
                                  name={`relation + ${index}`}
                                  placeholder="Select"
                                  label={"Relation"}
                                  initialValue={member.relation}
                                  option={relationOptions}
                                  onChange={(e) =>
                                    handleRelation(e, member.memberId)
                                  }
                                  required={true}
                                  requiredMessage={errorMessages.RELATION}
                                  defaultValue={member.relation}
                                />
                              </Col>
                              <Col span={24} lg={{ span: 12 }}>
                                <Select
                                  placeholder="Select Gender"
                                  name={`gender + ${index}`}
                                  label={"Gender"}
                                  option={GenderOptions}
                                  initialValue={member.gender}
                                  onChange={(e) => {
                                    handleGenderSelect(e, member.memberId);
                                  }}
                                  required={true}
                                  defaultValue="Female"
                                  requiredMessage={errorMessages.SELECTGENDER}
                                />
                              </Col>
                              <Col span={24} lg={{ span: 12 }}>
                                <PhoneNumberInput
                                  type="tel"
                                  label="Phone Number"
                                  name={`contactNumber + ${index}`}
                                  onChange={(e) =>
                                    handlePhoneNumber(e, member.memberId)
                                  }
                                  initialValue={member.phoneNumber}
                                  requiredMessage={errorMessages?.CONTACTNUMBER}
                                  width="100%"
                                />
                              </Col>
                              <Col span={24} lg={{ span: 12 }}>
                                <PlaceHolderWrappper>
                                  {!member.documents?.[0]?.file?.length > 0 ? (
                                    <>
                                      <Form.Item
                                        label=""
                                        name={`memberImages${index}`}
                                        rules={[
                                          {
                                            required:
                                              isAllowed?.[member.memberId],
                                            message: "Please insert the file !",
                                          },
                                        ]}
                                      >
                                        <ImagePlaceholder>
                                          <CloudUploadOutlined />
                                          Upload File
                                        </ImagePlaceholder>
                                        <input
                                          name="documents"
                                          type={"file"}
                                          className={"uploadImage"}
                                          accept=".png, .jpg, .jpeg,.pdf "
                                          onChange={(e) =>
                                            handleImagesUpload(
                                              e,
                                              member.memberId
                                            )
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  ) : (
                                    <>
                                      {member.documents?.[0]?.type !==
                                      ("application/pdf" || "pdf") ? (
                                        <Image
                                          src={member.documents?.[0]?.file}
                                        />
                                      ) : (
                                        <DocItem>
                                          <FilePdfOutlined />
                                        </DocItem>
                                      )}
                                      <CloseOutlined
                                        onClick={() =>
                                          handleRemvoveMemberImage(
                                            member.memberId
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                </PlaceHolderWrappper>

                                <Note>
                                  <span>
                                    (Please upload one of the following files:
                                    Driver's License, Utility Bill, Birth
                                    Certificate.)
                                  </span>
                                </Note>
                              </Col>

                              <Col span={24}>
                                <hr />
                              </Col>
                            </Row>
                          )
                        );
                      })}
                  </NewMemberDetails>
                  <Col xl={{ span: 24 }} sm={{ span: 24 }} span={24}>
                    <AddNewMember onClick={() => addMember()}>
                      <PlusCircleOutlined title="New Member" />
                      Add New Member
                    </AddNewMember>
                  </Col>
                </>
              )}

              <ButtonContainer>
                <Button text={"Cancel"} onClick={close} />
                <Button
                  type="button"
                  text={data ? "Update" : "Create  Account"}
                  loading={loading}
                  onClick={() => {
                    if (!Object?.keys(address)?.length) {
                      setAddressError("Please enter the address !");
                    }
                    form.submit();
                  }}
                />
              </ButtonContainer>
            </StyledForm>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddNewSeekerModal;
