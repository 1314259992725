import {
  ROLES_INITIAL_ROUTES,
} from "../hooks/useSetAppConfigs";
import { useSelector } from "react-redux";
import Loader from "../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AuthRoute = ({ rolesAllowed, children }) => {
  const { loading, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate(ROLES_INITIAL_ROUTES[user.roleName]);
    }
  }, [user]);

  return <>{loading ? <Loader /> : <>{children}</>}</>;
};

export default AuthRoute;
