import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const LayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  body {
    background-color: "#FAFAFA" !important;
  }
`;

export const InnerContent = styled.div`
  width: 70%;
  min-height: 60%;
  max-height: 75%;
  border: 1px solid #ccc;
  background: ${colors.white};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OuterWrapper = styled.div`
  width: 100%;
  height: 60%;
`;
export const Heading = styled.div`
  font-size: 35px;
  font-weight: 700;
  padding: 0 10px;
  background: -webkit-linear-gradient(
    45deg,
    ${colors.primary},
    ${colors.secondary} 60%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
`;

export const RightContainer = styled.div`
  padding: 20px;
`;

export const BottomForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 10px;
  align-items: center;
  button {
    margin: initial !important;
  }

  @media screen and (max-width: 567px) {
    gap: 10px;
  }
`;
