import { Col, Form, Row } from "antd";
import { useState } from "react";
import useMutate from "../../api/hooks/useMutate";
import EMPLOYEE_URLS from "../../api/urls/EMPLOYEE_URL";
import { errorMessages } from "../../utils/errorMessages";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import Button from "../elements/button/Button";
import Input from "../elements/input/Input";
import { Requiredwrapper } from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { Wrapper } from "./basemodal/DeclineRequestApproval";
import { Container, StyledForm } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";

export const DonationModalLocation = ({
  header,
  info,
  close,
  setIsSuccess,
  data,
}) => {
  const [form] = Form.useForm();
  const [sendNote, { loading }] = useMutate({
    path: EMPLOYEE_URLS.ADD_EDIT_NOTE,
    method: "post",
    onCompleted: () => {},
    onError: () => {},
  });

  const onSubmit = (values) => {
    const variables = {
      donationNote: values?.note,
      campaignId: data?.campaignId,
      seekerId: data?.clientId,
      donorId: data?.donorId,
    };
    sendNote({
      variables,
      onCompleted: () => {
        setIsSuccess(true);
        close();
      },
      onError: () => {},
    });
  };
  return (
    <Container>
      <Wrapper>
        <StyledForm
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Input
                label="Note"
                required={true}
                name="note"
                requiredMessage={errorMessages?.note}
                initialValue={data?.donationNote}
              />
            </Col>
          </Row>

          <ButtonContainer noBorder>
            <Button
              text="Cancel"
              className="SecondaryBtn"
              onClick={() => close()}
            />
            <Button
              text={data?.donationNote ? "Update" : "Submit"}
              htmlType="submit"
              loading={loading}
            />
          </ButtonContainer>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};

export default DonationModalLocation;
