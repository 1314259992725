import { useState, useEffect } from "react";
import axiosInstance from "../axios";
import { HTTP_METHODS } from "./useMutate";

const useFetch = ({
  path,
  variables,
  method = "get",
  config = {},
  params = "",
  onCompleted = () => {},
  onError = () => {},
}) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(0);
  const refetch = () => setReload((prev) => prev + 1);

  useEffect(() => {
    //let isMounted = true;
    const controller = new AbortController();

    const fetchData = async () => {
      let res;
      try {
        if (variables) {
          res = await axiosInstance[HTTP_METHODS.POST](
            path,
            { ...variables },
            {
              ...config,
              signal: controller.signal,
            }
          );
        } else {
          res = await axiosInstance[method.toLowerCase()](path, {
            ...config,
            signal: controller.signal,
          });
        }

        if (res.data.code != 200) {
          throw new Error(res.data.message);
        }

        setResponse(res.data);
        if (onCompleted && typeof onCompleted === "function") {
          onCompleted(res.data.data);
        }
      } catch (err) {
        setError(err.message);
        if (onError && typeof onError === "function") {
          onError();
        }
      } finally {
        setLoading(false);
      }
    };

    // call the function
    fetchData();

    // useEffect cleanup function
    return () => controller.abort();

    // eslint-disable-next-line
  }, [reload]);

  return { response, error, loading, refetch };
};

// const Sample = () => {
//   const { response, error, loading, refetch } = useFetch({
// COMMON_URL.GET_USER,
//     onCompleted = () => {},
//     onError = () => {}
//     }
//   );

//   return;

//   <h1>Hello</h1>;
// };

export default useFetch;
