import { useEffect, useState } from "react";
import {
  FamilyDetail,
  MainHeading,
  ButtonContainer,
  Info,
  WishInfo,
} from "../../donor/familyDetails/FamilyDetailsStyle";
import { Wishes, WishesLabel } from "../addFamilyDetails/addFamilyDetailsStyle";
import { Col, Form, Row } from "antd";
import Button from "../../../elements/button/Button";
import {
  MainContent,
  SectionHeading,
  Section,
  CampaignHeading,
  CampaignDescription,
  CampaignImage,
} from "../../donor/familyList/FamilyListStyle";
import { CampaignData } from "../../../elements/cards/CardStyle";
import { Label } from "../../employee/settings/SettingsStyle";
import { CalendarOutlined } from "@ant-design/icons";
import { genderFormat } from "../../../../utils/genderFormat";

const response = {
  id: 656,
  firstName: "Bikram",
  lastName: "Thakur",
  gender: "male",
  age: "29",
  birthDate: "67565786567867",
  wishes: [
    {
      wishId: "1",
      wish: "",
    },
    {
      wishId: "2",
      wish: "",
    },
    {
      wishId: "3",
      wish: "",
    },
  ],
  contact: "87654678987",
  members: [
    {
      id: 7656,
      firstName: "Rahul",
      lastName: "Grover",
      contactNumber: 1234567,
      birthDate: 8753945734,
      relation: "Brother",
      gender: "male",
      wishes: [
        {
          wishId: "1",
          wish: "",
        },
        {
          wishId: "2",
          wish: "",
        },
        {
          wishId: "3",
          wish: "",
        },
      ],
      age: "10",
    },
    {
      id: 711656,
      name: "xyz",
      relation: "daughter",
      gender: "female",
      wishes: [
        {
          wishId: "1",
          wish: "",
        },
        {
          wishId: "2",
          wish: "",
        },
        {
          wishId: "3",
          wish: "",
        },
      ],
      age: "20",
    },
  ],
};

function ViewFamilyDetails() {
  const [selectedMembers, setSelectedMembers] = useState(response);
  // const [openNewFamilyMember, setOpenNewFamilyMember] = useState(false);
  const [newMemberDetails, setNewMemberDetails] = useState([]);

  // const addnewFamilyMember = () => {
  //   setOpenNewFamilyMember(true);
  // };

  const handleWishes = (wishId, memberIndex, e) => {
    const writeWishes = selectedMembers?.members?.map((famMember, index) => {
      famMember.wishes.map((wishInfo) => {
        if (index === memberIndex && wishInfo.wishId === wishId) {
          wishInfo.wish = e.target.value;
        }
        return wishInfo;
      });
      return famMember;
    });
    setSelectedMembers((val) => ({
      ...val,
      members: writeWishes,
    }));
  };
  const [form] = Form.useForm();
  const onFinish = () => {
    let houseHoldWishes = [];
    selectedMembers.wishes.map((wishInfo) => {
      houseHoldWishes.push({ description: wishInfo.wish });
      return wishInfo;
    });
    let otherWishes = [];
    let memberWishes = [];
    selectedMembers.members.map((wishInfo) => {
      wishInfo.wishes.map((info) => {
        memberWishes.push({ description: info.wish });
        return info;
      });
      otherWishes.push({
        id: wishInfo.id,
        wishes: memberWishes,
        details: {
          documents: wishInfo?.documents,
          gender: genderFormat(wishInfo?.gender),
          birthDate: wishInfo?.birthDate,
          firstName: wishInfo?.firstName,
          lastName: wishInfo?.lastName,
          relation: wishInfo?.relation,
          phoneNumber: wishInfo?.contactNumber?.replace(/\s+/g, ""),
        },
      });
      memberWishes = [];
      return wishInfo;
    });
    const formData = {
      campaignId: 1,
      members: [
        { id: selectedMembers.id, wishes: houseHoldWishes },
        ...otherWishes,
      ],
    };
  };
  const handleHouseHoldWishes = (e, id) => {
    const updateWishes = selectedMembers?.wishes.map((wishInfo) => {
      if (wishInfo.wishId === id) {
        wishInfo.wish = e.target.value;
      }
      return wishInfo;
    });
    setSelectedMembers((member) => ({
      ...member,
      wishes: updateWishes,
    }));
  };
  useEffect(() => {
    if (newMemberDetails.length > 0) {
      setSelectedMembers((val) => ({
        ...val,
        members: [...val.members, ...newMemberDetails],
      }));
      setNewMemberDetails([]);
    }
  }, [newMemberDetails]);
  return (
    <>
      <MainContent>
        <Section>
          <Row gutter={[0, 20]}>
            <Col xl={{ span: 24 }} span={24}>
              <CampaignHeading>Thanks Giving</CampaignHeading>
              <CampaignImage>
                {/* <Image src={CampaignImg} preview={false} /> */}
              </CampaignImage>
            </Col>
            <Col xl={{ span: 24 }} span={24}>
              <CampaignDescription>
                Thanks Giving Day is a national holiday in the United States,
                and Thanks giving 2022 occurs on Thursday, November 24. In 1621,
                the Plymouth colonists and the Wampanoag shared an autumn
                harvest feast that is acknowledged today as one of the first
                Thanksgiving celebrations in the colonies
              </CampaignDescription>
            </Col>
            <Col xl={{ span: 12 }} span={24}>
              <CampaignData>
                <span className="bold"> Start Date: </span>
                10/30/2022
              </CampaignData>
            </Col>
            <Col xl={{ span: 12 }} span={24}>
              <CampaignData>
                <span className="bold"> End Date: </span>
                11/20/2022
              </CampaignData>
            </Col>
            <Col xl={{ span: 12 }} span={24}>
              <CampaignData>
                <span className="bold">Enrollment period: </span> 10/15/2022 To
                10/30/2022 <CalendarOutlined title="Add to calender" />
              </CampaignData>
            </Col>
            <Col xl={{ span: 12 }} span={24}>
              <CampaignData>
                <span className="bold"> Client pickup address: </span>
                1607 23rd Street NW, Washington, DC 20008
              </CampaignData>
            </Col>
            <Col xl={{ span: 12 }} span={24}>
              <CampaignData>
                <span className="bold"> Attended Event: </span>
                Yes
              </CampaignData>
            </Col>
          </Row>
          &nbsp;
          <hr />
        </Section>

        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col span={24}>
              <SectionHeading>Client Requests</SectionHeading>
              <Row gutter={20}>
                <Col xl={{ span: 12 }} span={24}>
                  <FamilyDetail key={selectedMembers?.id}>
                    <MainHeading>Household's wishes</MainHeading>
                    {/* <FamilyData> */}
                    <Row gutter={20}>
                      <Col span={24}>
                        <Row gutter={20}>
                          <Col span={24}>
                            <Info>
                              <Label>Gender: </Label>
                              {selectedMembers?.gender}
                            </Info>
                            {/* </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}> */}
                            <Info>
                              <Label>Age: </Label>
                              {selectedMembers?.age}
                            </Info>
                          </Col>
                          <Col span={24}>
                            <Wishes>
                              &nbsp;
                              <WishesLabel>Wishes</WishesLabel>
                              <Row>
                                <Col span={24} md={{ span: 24 }}>
                                  <WishInfo>
                                    <Label>Wish 1:</Label>I need one automatic
                                    battery operated remote controlled
                                    helicopter in any size whichever will be
                                    available
                                  </WishInfo>
                                </Col>
                                <Col span={24} md={{ span: 24 }}>
                                  <WishInfo>
                                    <Label>Wish 2:</Label>I need one automatic
                                    battery operated remote controlled
                                    helicopter in any size whichever will be
                                    available
                                  </WishInfo>
                                </Col>
                                <Col span={24} md={{ span: 24 }}>
                                  <WishInfo>
                                    <Label>Wish 3:</Label>I need one automatic
                                    battery operated remote controlled
                                    helicopter in any size whichever will be
                                    available
                                  </WishInfo>
                                </Col>
                              </Row>
                            </Wishes>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* </FamilyData> */}
                  </FamilyDetail>
                </Col>

                {selectedMembers?.members?.map((famMember, i) => {
                  return (
                    <>
                      <Col xl={{ span: 12 }} span={24}>
                        <FamilyDetail key={i}>
                          <MainHeading>
                            {famMember?.relation}'s wishes
                          </MainHeading>
                          {/* <FamilyData> */}
                          <Row gutter={20}>
                            <Col span={24}>
                              <Row gutter={20}>
                                <Col span={24}>
                                  <Info>
                                    <Label>Gender: </Label>
                                    {famMember.gender}
                                  </Info>
                                  {/* </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}> */}
                                  <Info>
                                    <Label>Age: </Label>
                                    {famMember.age}
                                  </Info>
                                </Col>
                                <Col span={24}>
                                  <Wishes>
                                    &nbsp;
                                    <WishesLabel>Wishes</WishesLabel>
                                    <Row>
                                      <Col span={24} md={{ span: 24 }}>
                                        <WishInfo>
                                          <Label>Wish 1:</Label>I need one
                                          automatic battery operated remote
                                          controlled helicopter in any size
                                          whichever will be available
                                        </WishInfo>
                                      </Col>
                                      <Col span={24} md={{ span: 24 }}>
                                        <WishInfo>
                                          <Label>Wish 2:</Label>I need one
                                          automatic battery operated remote
                                          controlled helicopter in any size
                                          whichever will be available
                                        </WishInfo>
                                      </Col>
                                      <Col span={24} md={{ span: 24 }}>
                                        <WishInfo>
                                          <Label>Wish 3:</Label>I need one
                                          automatic battery operated remote
                                          controlled helicopter in any size
                                          whichever will be available
                                        </WishInfo>
                                      </Col>
                                    </Row>
                                  </Wishes>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          {/* </FamilyData> */}
                        </FamilyDetail>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Col>
            <Col xs={{ span: 24 }}>
              <ButtonContainer>
                <Button text={"Save"} htmlType="submit" />
              </ButtonContainer>
            </Col>
          </Row>
        </Form>
      </MainContent>
    </>
  );
}
export default ViewFamilyDetails;
