import { code } from "./COMMON_URL";
const SEEKER_URLS = {
  SIGNUP: `/seeker/register-seeker`,
  LOGIN: `/Seeker/seeker-login`,
  FORGOTPASSWORD: `/Seeker/seeker-forgot-password`,
  CAMPAIGNS: "/campaign/get-available-campaign-list-for-seeker",
  VERIFYSEEKERLOGIN: `/Seeker/verify-seeker-login`,
  SEEKER_FAMILY_DETAILS: `/seeker/seeker-family-detail`,
  CAMPAIGNDETAIL: "/campaign/get-campaign-detail",
  OPTINCAMPAIGN: "/campaign/opt-campaign",
  ADD_FAMILY_MEMBER: "/seeker/add-family-member",
  DELETEMEMBER: "/seeker/delete-family-member",
  EDITSEEKER: "/seeker/edit-seeker",
  CREATE_SEEKER_ADMIN: "/seeker/register-seeker-by-admin",
  EXPORTSEEKER: "/seeker/export-Campaignseekers-to-excel",
};

export default SEEKER_URLS;
