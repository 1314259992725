import { CloudUploadOutlined } from "@ant-design/icons";
import { Image, Col, Form, Row } from "antd";
import React from "react";
// import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import styled from "styled-components";
import useMutate from "../../api/hooks/useMutate";
import CAMPAIGN_URL from "../../api/urls/CAMPAIGN_URL";
import { errorMessages } from "../../utils/errorMessages";
import Button from "../elements/button/Button";
import DatePickerB from "../elements/datePicker/DatePicker";
import Input from "../elements/input/Input";
import {
  ImagePlaceholder,
  PlaceHolderWrappper,
} from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { useState } from "react";
import { ButtonContainer } from "./NewSeekerModal";
import { regErrorMessages } from "../../utils/regErrorMessages";
import { regExpPattern } from "../../utils/regExpPattern";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import { Container, StyledForm } from "./EditCampaignModal";

export const DateFromTo = styled.div`
  p {
    padding: 0 10px 0;
    margin-bottom: 7px;
  }
  .ant-form-item-label {
    display: none;
  }
`;

export const To = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 15px 12px;
`;

export const NewCampaignModal = ({ header, info, close }) => {
  // const navigate = useNavigate();
  const [form] = Form.useForm();

  const [imageUpload, setImageUpload] = useState({
    logo: "",
  });

  const fileUpload = (e) => {
    const files = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUpload((file) => ({
        ...file,
        logo: reader.result,
      }));
    };
    reader.readAsDataURL(files);
  };

  const [postCampaignSignUp, { response, error, loading }] = useMutate({
    path: CAMPAIGN_URL.CREATECAMPAIGN,
    errorToast: true,
    method: "post",
    onCompleted: () => {},
    onError: () => {},
  });

  const onSubmit = (values) => {
    const variables = {
      OrganisationId: 1,
      CampaignName: values.campaignName,
      CampaignStartDate: values.startDate,
      CampaignEndDate: values.endDate,
      EnrollmentPeriodStartDate: values.enrollmentFrom,
      EnrollmentPeriodEndDate: values.enrollmentto,
      DonorPeriodStartDate: values.donorFrom,
      DonorPeriodEndDate: values.donorto,
      DropOfAddress: values.dropoffAddress,
      DropOfStartDate: values.dropoffFrom,
      DropOfEndDate: values.dropoffto,
      PickupAddress: values.pickupAddress,
      PickupStartDate: values.pickupFrom,
      PickupEndDate: values.pickupto,
      QuestionsCallNumber: values.questionsCallNumber,
      QuestionsEmail: values.questionsEmailAddress,
      QuestionsVisitAddress: values.questionsVisitAddress,
      LogoImage: imageUpload.logo,
      // ActionPerformedBy:"Test"
    };
    postCampaignSignUp({
      variables,
      onCompleted: () => {
        close();
      },
      onError: () => {},
    });
  };

  const dateFormat = "MM/DD/YYYY";
  return (
    <>
      {!loading ? (
        <Container>
          <StyledForm
            form={form}
            onFinish={onSubmit}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Row>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  name="campaignName"
                  label={"Event Name"}
                  required={true}
                  requiredMessage={errorMessages.CAMPAIGNNAME}
                />
              </Col>

              <Col span={24} lg={{ span: 12 }}>
                <Input
                  name="campaignDescription"
                  label={"Event Description"}
                  required={true}
                  requiredMessage={errorMessages.CAMPAIGNNAME}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <DatePickerB
                  name="startDate"
                  label="Start Date"
                  picker="HeadofHouseholdDateofBirth"
                  required={true}
                  requiredMessage={errorMessages.STARTDATE}
                  format={dateFormat}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <DatePickerB
                  name="endDate"
                  label="End Date"
                  picker="HeadofHouseholdDateofBirth"
                  required={true}
                  requiredMessage={errorMessages.ENDDATE}
                  format={dateFormat}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <DateFromTo>
                  <Row>
                    <Col span={24}>
                      <p> Enrollment period </p>
                    </Col>
                    <Col span={11}>
                      <DatePickerB
                        name="enrollmentFrom"
                        picker="enrollmentFrom"
                        format={dateFormat}
                        required={true}
                        requiredMessage={errorMessages.STARTDATE}
                      />
                    </Col>
                    <Col span={2}>
                      <To>To</To>
                    </Col>
                    <Col span={11}>
                      <DatePickerB
                        name="enrollmentto"
                        picker="enrollmentTo"
                        format={dateFormat}
                        required={true}
                        requiredMessage={errorMessages.ENDDATE}
                      />
                    </Col>
                  </Row>
                </DateFromTo>
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <DateFromTo>
                  <Row>
                    <Col span={24}>
                      <p>
                        <span
                          style={{
                            color: "#ff4d4f",
                            fontFamily: "SimSun,sans-serif",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                        <span
                          style={{
                            color: "#ff4d4f",
                            fontFamily: "SimSun,sans-serif",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                        Donations accepted
                      </p>
                    </Col>
                    <Col span={11}>
                      <DatePickerB
                        name="donorFrom"
                        picker="donorFrom"
                        format={dateFormat}
                        required={true}
                        requiredMessage={errorMessages.STARTDATE}
                      />
                    </Col>
                    <Col span={2}>
                      <To>To</To>
                    </Col>
                    <Col span={11}>
                      <DatePickerB
                        name="donorto"
                        picker="donorTo"
                        format={dateFormat}
                        required={true}
                        requiredMessage={errorMessages.ENDDATE}
                      />
                    </Col>
                  </Row>
                </DateFromTo>
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  name="dropoffAddress"
                  label={"Donation drop-off"}
                  required={true}
                  requiredMessage={errorMessages.DROPOFFADDRESS}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <DateFromTo>
                  <Row>
                    <Col span={24}>
                      <p>
                        <span
                          style={{
                            color: "#ff4d4f",
                            fontFamily: "SimSun,sans-serif",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                        Dropoff Dates
                      </p>
                    </Col>
                    <Col span={11}>
                      <DatePickerB
                        name="dropoffFrom"
                        picker="dropoffFrom"
                        format={dateFormat}
                        required={true}
                        requiredMessage={errorMessages.STARTDATE}
                      />
                    </Col>
                    <Col span={2}>
                      <To>To</To>
                    </Col>
                    <Col span={11}>
                      <DatePickerB
                        name="dropoffto"
                        picker="dropoffTo"
                        format={dateFormat}
                        required={true}
                        requiredMessage={errorMessages.ENDDATE}
                      />
                    </Col>
                  </Row>
                </DateFromTo>
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  name="pickupAddress"
                  label={"Client pickup address"}
                  required={true}
                  requiredMessage={errorMessages.PICKUPADDRESS}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <DateFromTo>
                  <Row>
                    <Col span={24}>
                      <p>
                        <span
                          style={{
                            color: "#ff4d4f",
                            fontFamily: "SimSun,sans-serif",
                            marginRight: "4px",
                          }}
                        >
                          *
                        </span>
                        Client pickup dates
                      </p>
                    </Col>
                    <Col span={11}>
                      <DatePickerB
                        name="pickupFrom"
                        picker="pickupFrom"
                        format={dateFormat}
                        required={true}
                        requiredMessage={errorMessages.STARTDATE}
                      />
                    </Col>
                    <Col span={2}>
                      <To>To</To>
                    </Col>
                    <Col span={11}>
                      <DatePickerB
                        name="pickupto"
                        picker="pickupTo"
                        format={dateFormat}
                        required={true}
                        requiredMessage={errorMessages.ENDDATE}
                      />
                    </Col>
                  </Row>
                </DateFromTo>
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <PhoneNumberInput
                  type="tel"
                  label="Phone Number"
                  name="questionsCallNumber"
                  required={true}
                  requiredMessage={errorMessages.QUESTIONSCALLNUMBER}
                  width="100%"
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  name="questionsEmailAddress"
                  label={"Questions Email Address"}
                  required={true}
                  pattern={regExpPattern.EMAIL}
                  regExpMessage={regErrorMessages.EMAIL}
                  requiredMessage={errorMessages.QUESTIONSEMAILADDRESS}
                />
              </Col>
              <Col span={24}>
                <Input
                  name="questionsVisitAddress"
                  label={"Questions Visit"}
                  required={true}
                  requiredMessage={errorMessages.QUESTIONSVISITADDRESS}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  name="maxWishes"
                  label={"Max Wishes/Members :"}
                  required={true}
                  requiredMessage={errorMessages.CAMPAIGNNAME}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  name="maxChracter"
                  label={"Max Character :"}
                  required={true}
                  requiredMessage={errorMessages.CAMPAIGNNAME}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <PlaceHolderWrappper>
                  {!imageUpload.logo.length > 0 ? (
                    <>
                      <Form.Item
                        label=""
                        name="logoImage"
                        rules={[
                          {
                            required: true,
                            message: "Please insert the logo !",
                          },
                        ]}
                      >
                        <ImagePlaceholder>
                          <CloudUploadOutlined />
                          Upload Logo
                        </ImagePlaceholder>
                        <input
                          name="uploadLogo"
                          type={"file"}
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => fileUpload(e, "logo")}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Image src={imageUpload.logo} />
                  )}
                  <div>Recommended size: 200px x 200px</div>
                </PlaceHolderWrappper>
              </Col>
            </Row>
            <ButtonContainer noBorder>
              <Button
                text="Cancel"
                className={"SecondaryBtn"}
                onClick={() => close()}
              />
              <Button text="Save" htmlType="submit" />
            </ButtonContainer>

            {info && (
              <p style={{ fontSize: "1.2rem", textAlign: "center" }}>{info}</p>
            )}
          </StyledForm>
        </Container>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default NewCampaignModal;
