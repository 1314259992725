import { Col, Row, Form } from "antd";
import React, { useState } from "react";
import { errorMessages } from "../../utils/errorMessages";
import Button from "../elements/button/Button";
import Input from "../elements/input/Input";
import { Requiredwrapper } from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { StyledForm } from "../pages/admin/signUp/SingupStlyes";
import { Container } from "./EditCampaignModal";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import { genderFormat } from "../../utils/genderFormat";
import { ButtonContainer } from "./NewSeekerModal";
import useMutate from "../../api/hooks/useMutate";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import { useRef } from "react";
import toast from "react-hot-toast";
import { regExpPattern } from "../../utils/regExpPattern";
import { regErrorMessages } from "../../utils/regErrorMessages";
import DONOR_URLS from "../../api/urls/DONOR_URL";
import EMPLOYEE_URLS from "../../api/urls/EMPLOYEE_URL";

export const EditDonorProfile = ({
  header,
  info,
  close,
  setIsSuccess,
  data,
  id,
  isEmployee = false,
}) => {
  const [address, setAddress] = useState({
    placeName: data?.placeName,
    address1: data?.address1,
    address2: data?.address2,
    lat: data?.latitude,
    lng: data?.longitude,
    placeId: data?.placeId,
    state: data?.state,
    zip: data?.zip,
    city: data?.city,
    country: data?.country,
  });
  const [addressError, setAddressError] = useState("");
  const isOrgLoaded = useRef(false);
  const [editDonorProfile, { loading }] = useMutate({
    path: DONOR_URLS.SIGNUP,
    method: "post",
    errorToast: true,
  });
  const [editEmployeeProfile, { loading: employeeLoading }] = useMutate({
    path: EMPLOYEE_URLS.CREATEEMPLOYEE,
    method: "post",
    errorToast: true,
  });
  const [form] = Form.useForm();

  const GenderOptions = [
    {
      key: "1",
      value: "Male",
    },
    {
      value: "Female",
      key: 2,
    },
    {
      value: "Other",
      key: 3,
    },
  ];

  const onSubmit = (values) => {
    const variables = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.MemberEmail,
      userId: id,
      phoneNo: values?.contactNumber?.replace(/\s+/g, ""),
      ...address,
      longitude: address?.lng,
      latitude: address?.lat,
      gender: genderFormat(values?.gender),
      termAndCondition: true,
    };

    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }
    if (isEmployee) {
      variables.employeeId = data?.userId;
      editEmployeeProfile({
        variables,
        onCompleted: () => {
          toast.success("Updated Successfully");
          setIsSuccess(true);
          close();
        },
        onError: () => {},
      });
    } else {
      editDonorProfile({
        variables,
        onCompleted: (data) => {
          toast.success("Updated Successfully");
          localStorage.setItem("accessToken", data.token);
          setIsSuccess(true);
          close();
        },
        onError: () => {},
      });
    }
  };

  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        form={form}
        onFinish={onSubmit}
      >
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="First Name"
              name="firstName"
              required={true}
              initialValue={data?.firstName}
              requiredMessage={errorMessages.FIRSTNAME}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Last Name"
              name="lastName"
              initialValue={data?.lastName}
              required={true}
              requiredMessage={errorMessages.LASTNAME}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Email Address"
              name="MemberEmail"
              pattern={regExpPattern.EMAIL}
              required={true}
              initialValue={data?.email}
              regExpMessage={regErrorMessages.EMAIL}
              requiredMessage={errorMessages.EMAIL}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <PhoneNumberInput
              type="tel"
              label="Phone Number"
              name="contactNumber"
              initialValue={data?.phoneNo}
              required={false}
              requiredMessage={errorMessages.CONTACTNUMBER}
              width="100%"
            />
          </Col>
          <Col span={24} lg={{ span: 24 }}>
            <Requiredwrapper>
              <span className="validdata">*</span>
            </Requiredwrapper>
            <AddressSearchDropdown
              onChange={(address) => {
                if (Object?.keys(address)?.length) {
                  if (address?.city?.length) {
                    setAddressError("");
                  } else {
                    setAddressError("Please select address with city");
                  }
                } else {
                  if (isOrgLoaded.current) {
                    setAddressError("Please select address");
                  }
                }
                setAddress(address);
                isOrgLoaded.current = true;
              }}
              value={address}
              requiredMessage={errorMessages.ADDRESS}
              label="Street Address"
              addressError={addressError}
            />

            {/* {addressError && (
              <span style={{ marginLeft: "20px", color: "red" }}>
                {addressError}
              </span>
            )} */}
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button
            text="Update"
            type="button"
            onClick={() => {
              if (!Object?.keys(address)?.length) {
                setAddressError("Please enter the address !");
              }
              form.submit();
            }}
            loading={loading || employeeLoading}
          />
        </ButtonContainer>
      </StyledForm>

      {info && (
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>{info}</p>
      )}
    </Container>
  );
};

export default EditDonorProfile;
