export const errorMessages = {
  ORGANIZATIONNAME: "Please enter your organisation name !",
  FIRSTNAME: "Please enter your first name  !",
  LASTNAME: "Please enter your last name !",
  PASSWORD: "Please enter your password !",
  EMAIL: "Please enter your email !",
  SELECTGENDER: "Please select your gender !",
  SELECTBIRTHYEAR: "Please select your birth year !",
  SELECTBIRTHMONTH: "Please select your birth month !",
  RELATION: "Please select your relation !",
  CONTACTNUMBER: "Please enter your Phone Number !",
  NOOFKIDS: "Please enter No. of Adults !",
  CITYOFRESIDENCE: "Please select your city !",
  NAME: "Please enter your name !",
  AGE: "Please enter your age !",
  SUBDOMAIN: "Please enter your Sub Domain !",
  DATEOFBIRTH: "Please select your Date of Birth !",
  ZIPCODE: "Please enter zip code !",
  CITY: "Please select city !",
  Wishs: "Please enter your wish !",
  ADDRESS: "Please enter your street address !",
  IMAGE: "Please insert the images !",
  FAMID: "Please enter your FAM ID !",
  OLDPASSWORD: "Please enter your old password !",
  NEWPASSWORD: "Please enter your new password !",
  STARTDATE: "Please select start date !",
  ENDDATE: "Please select end date !",
  ORGANIZATIONADDRESS: "Please enter your organization address !",
  CAMPAIGNNAME: "Please enter event name !",
  DROPOFFADDRESS: "Please enter dropoff address !",
  PICKUPADDRESS: "Please enter pickup address !",
  QUESTIONSCALLNUMBER: "Please enter questions call number !",
  QUESTIONSEMAILADDRESS: "Please enter questions email address !",
  QUESTIONSVISITADDRESS: "Please enter questions visit address !",
  WISH: "Please enter your wish !",
  DROPOFFDATE: "Please enter your dropoff date",
  DROPOFLOCATION: "Please enter your dropoff location",
  EVENTDESCRIPTION: "Please enter event description !",
  note: "Please enter the donation note !",
};
