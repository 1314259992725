import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import Button from "../elements/button/Button";
import { Container } from "./EditCampaignModal";
import moment from "moment";
import { CampaignImage } from "../pages/donor/familyList/FamilyListStyle";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { CopyOutlined } from "@ant-design/icons";
import colors from "../../styles/Colors";
import { useNavigate } from "react-router-dom";

// import styled from "styled-components";

const PageHeading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  padding: 15px 0 0;
`;

const Label = styled.div`
  font-weight: 600;
  min-width: 140px;
  padding-right: 10px;
`;

const Linkdata = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 20px;
  .copyevent {
    margin: 0;
    padding: 5px 20px;
    // height: max-content;
    color: ${colors.white};
    transition: all 0.9s;
    text-decoration: none;
    border-radius: 8px;
    font-weight: 700;
    white-space: nowwrap;
    cursor: pointer;
    background: ${colors.primary};
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    &:focus {
      background: ${colors.white};
      border: 1px solid ${colors.primary};
      color: ${colors.primary};
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 10px;
  position: fixed;
  bottom: 0;
  gap: 10px;
  border-top: 1px solid ${colors.background};
  width: 100%;
  left: 0;
  padding: 10px 25px;
  background: #fff;
  border-radius: 0 0 10px 10px;
  .SecondaryBtn {
    background-color: ${colors.white};
    border-color: ${colors.primary};
    color: ${colors.primary};
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    left: 0;
    border-radius: 0 0 10px 10px;
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  padding: 7px 30px 7px 0;

  @media screen and (max-width: 450px) {
    padding: 7px 0px 7px 0;
    flex-direction: column;
  }
`;
const NewMemberDetails = styled.div`
  padding: 20px 0;
`;

const dateFormat = "MM/DD/YYYY";

export const LandingViewEventModal = ({ close, data, landingPageType }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Row>
        <Col
          style={{
            display: "flex",
            flex: "0 0 100%",
            flexDirection: "column",
            maxWidth: "100%",
          }}
          span={24}
        >
          <Linkdata>
            <PageHeading>{data?.campaignName}</PageHeading>
            {/* <div className="App">
              <CopyToClipboard
                text={`${window.location.origin}/add-family-details/?id=${data.campaignId}`}
                onCopy={() => toast.success("Copied Successfully")}
              >
                <button className="copyevent">
                  Copy Event Url <CopyOutlined />
                </button>
              </CopyToClipboard>
            </div> */}
          </Linkdata>

          <CampaignImage src={data?.imageFile} />
        </Col>
        <Col span={24}>
          <Info>
            <Label>{data?.description}</Label>
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Event name :</Label> {data?.campaignName}
          </Info>
        </Col>

        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Start date :</Label>
            {moment(data?.campaignStartDate * 1000).format(dateFormat)}
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>End date :</Label>
            {moment(data?.campaignEndDate * 1000).format(dateFormat)}
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Enrollment period :</Label>
            {moment(data?.enrollmentPeriodStartDate * 1000).format(
              dateFormat
            )}{" "}
            &nbsp; To &nbsp;{" "}
            {moment(data?.enrollmentPeriodEndDate * 1000).format(dateFormat)}
          </Info>
        </Col>

        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Donations accepted :</Label>
            {moment(data?.donorPeriodStartDate * 1000).format(dateFormat)}{" "}
            &nbsp; To &nbsp;
            {moment(data?.donorPeriodEndDate * 1000).format(dateFormat)}
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Donation drop-off :</Label> {data?.dropOfAddress}
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Client pickup address :</Label> {data?.pickupAddress}
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Client pickup dates :</Label>
            {moment(data?.pickupStartDate * 1000).format(dateFormat)} &nbsp; To
            &nbsp;
            {moment(data?.pickupEndDate * 1000).format(dateFormat)}
          </Info>
        </Col>
      </Row>
      <NewMemberDetails>
        <Row>
          <Col span={24}>
            <PageHeading>Questions </PageHeading>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Phone Number :</Label> {data?.questionsCallNumber}
              {/* (111)-1111-111 */}
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Email :</Label> {data?.questionsEmail}
              {/* test@gmail */}
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Questions Visit :</Label>
              {data?.questionsVisitAddress}
              {/* 05/03/1998 */}
            </Info>
          </Col>
        </Row>
      </NewMemberDetails>
      <ButtonContainer noBorder>
        {landingPageType === "DONOR" ? (
          <ButtonContainer>
            <Button
              text={"Login as Donor"}
              // className="secondary"
              onClick={() => navigate("/donor-login")}
            />
            <Button
              text="Register as Donor"
              className="secondary"
              onClick={() => navigate("/register-donor")}
            />
          </ButtonContainer>
        ) : (
          <Button
            text={"Login as FAM Client"}
            onClick={() => {
              navigate("/client-login");
              // navigate("/client-login", {
              //   state: {
              //     eventlink: true,
              //     route: `/add-family-details/?id=${data.campaignId}`,
              //   },
              // })
            }}
          />
        )}
        {/* <Button
          text={"Register as FAM Client"}
          onClick={() => navigate("/register-client")}
        /> */}
      </ButtonContainer>
    </Container>
  );
};

export default LandingViewEventModal;
