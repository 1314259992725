import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const ActionIcon = styled.div`
  height: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.background};
  padding: 5px;
  cursor: pointer;
  width: max-content;
  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;

export const ActionIconWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  .ant-checkbox-wrapper {
    align-items: center;
    margin-left: 10px;
  }
  .ant-checkbox {
    margin-bottom: 5px;
  }
`;
