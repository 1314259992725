import {
  MainCard,
  CardImage,
  CardHeading,
  CardContent,
  CardDescription,
  CampaignData,
  CardActions,
} from "./Cards2Style";
import { Image } from "antd";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";

function Card(campaign) {
  const navigate = useNavigate();
  return (
    <>
      <MainCard>
        <CardImage>
          <Image preview={false} src={campaign.imageFile} />
        </CardImage>
        <CardContent>
          <CardHeading>{campaign.name}</CardHeading>
          <CardDescription>
            <p>{campaign.description}</p>
          </CardDescription>
          <CampaignData>
            <span className="bold">Enrollment period: </span>
            {campaign.enrollmentPeriod}
          </CampaignData>
          {campaign?.pickupAddress ? (
            <CampaignData>
              <span className="bold"> Client pickup address: </span>
              {campaign.pickupAddress}
            </CampaignData>
          ) : (
            <CampaignData>
              <span className="bold"> Donation drop-off: </span>
              {campaign.dropAddress}
            </CampaignData>
          )}
        </CardContent>
        <CardActions>
          <Button
            width={"100%"}
            text={"View Details"}
            onClick={() => navigate(campaign.path)}
          />
        </CardActions>
      </MainCard>
    </>
  );
}
export default Card;
