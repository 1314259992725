import { Row, Col, Image, Form } from "antd";
import LogoImg from "../../../../assets/images/logo.png";
import Input from "../../../elements/input/Input";
import Button from "../../../elements/button/Button";
import { regExpPattern } from "../../../../utils/regExpPattern";

import {
  BottomForm,
  LayoutContainer,
  InnerContent,
  OuterWrapper,
  Heading,
  RightContainer,
} from "./ForgotPasswordStyles";
import { useNavigate } from "react-router-dom";
import useMutate from "../../../../api/hooks/useMutate";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import { errorMessages } from "../../../../utils/errorMessages";
import ORG_URL from "../../../../api/urls/ORG_URL";
import Loader from "../../../loader/Loader";
import { LeftContainer, Logo } from "../../admin/signUp/SingupStlyes";

function EmployeeForgotPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [postForgotPassword, { response, error, loading }] = useMutate({
    url: ORG_URL.FORGOTPASSWORD,
    onCompleted: () => {},
    onError: () => {},
  });

  const onSubmit = (values) => {
    //
    postForgotPassword({
      variables: {
        email: values.email,
        password: values.password,
        actionPerformedBy: "admin",
      },
      onCompleted: () => {
        navigate("/login");
      },
      onError: () => {
        alert("Not working ");
      },
    });
  };

  return (
    <>
      {!loading ? (
        <LayoutContainer>
          <InnerContent style={{ width: "730px", minHeight: "360px" }}>
            <OuterWrapper>
              <Row>
                <Col xl={{ span: 10 }} sm={{ span: 24 }}>
                  <LeftContainer>
                    <Logo>
                      <Image width={200} preview={false} src={LogoImg}></Image>
                    </Logo>
                  </LeftContainer>
                </Col>
                <Col xl={{ span: 14 }} sm={{ span: 24 }}>
                  <RightContainer>
                    <Form
                      name="basic"
                      form={form}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      autoComplete="off"
                      onFinish={onSubmit}
                    >
                      <Heading>Forgot Password</Heading>
                      <Row>
                        <Col span={24}>
                          <Input
                            type="text"
                            pattern={regExpPattern.EMAIL}
                            regExpMessage={regErrorMessages.EMAIL}
                            label="Enter Your Email"
                            name="email"
                            required={true}
                            requiredMessage={errorMessages.EMAIL}
                          />
                        </Col>
                        <Col span={24}>
                          <BottomForm>
                            <div>
                              <div>
                                Back to &nbsp;
                                <a onClick={() => navigate("/employee-login")}>
                                  LogIn
                                </a>
                              </div>
                            </div>
                            <Button text={"Submit"} htmlType={"sumbit"} />
                          </BottomForm>
                        </Col>
                      </Row>
                    </Form>
                  </RightContainer>
                </Col>
              </Row>
            </OuterWrapper>
          </InnerContent>
        </LayoutContainer>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default EmployeeForgotPassword;
