// import { CloudUploadOutlined } from "@ant-design/icons";
import { Col, Form, Row, Input as AntInput } from "antd";
// import moment from "moment";
import React from "react";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/Colors";
import { errorMessages } from "../../utils/errorMessages";
import { regErrorMessages } from "../../utils/regErrorMessages";
import { regExpPattern } from "../../utils/regExpPattern";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import Button from "../elements/button/Button";
import useMutate from "../../api/hooks/useMutate";
import EMPLOYEE_URLS from "../../api/urls/EMPLOYEE_URL";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import Input from "../elements/input/Input";
import { Container, StyledForm } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";
import { Requiredwrapper } from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { useState } from "react";
import { useRef } from "react";

export const EditEmployeeModal = ({
  header,
  info,
  close,
  data,
  isEditModal = false,
}) => {
  const [address, setAddress] = useState(
    data
      ? {
          placeName: data?.placeName,
          address1: data?.address1,
          address2: data?.address2,
          lat: data?.latitude,
          lng: data?.longitude,
          placeId: data?.placeId,
          state: data?.state,
          zip: data?.zip,
          city: data?.city,
          country: data?.country,
        }
      : ""
  );
  const isOrgLoaded = useRef(false);
  const [addressError, setAddressError] = useState("");
  const [form] = Form.useForm();
  const [postDonorSignUp, { response, error, loading }] = useMutate({
    path: EMPLOYEE_URLS.CREATEEMPLOYEE,
    method: "post",
    onCompleted: () => {},
    onError: () => {},
  });

  const onSubmit = (values) => {
    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }
  };
  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
      >
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="First Name"
              name="firstName"
              required={true}
              requiredMessage={errorMessages.FIRSTNAME}
              initialValue={data?.firstName}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Last Name"
              name="lastName"
              required={true}
              requiredMessage={errorMessages.LASTNAME}
              initialValue={data?.lastName}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Requiredwrapper>
              <span className="validdata">*</span>
            </Requiredwrapper>
            <AddressSearchDropdown
              onChange={(address) => {
                if (Object?.keys(address)?.length) {
                  if (address?.city?.length) {
                    setAddressError("");
                  } else {
                    setAddressError("Please select address with city");
                  }
                } else {
                  if (isOrgLoaded.current) {
                    setAddressError("Please select address");
                  }
                }
                setAddress(address);
                isOrgLoaded.current = true;
              }}
              value={address}
              requiredMessage={errorMessages.ADDRESS}
              label="Street Address"
              addressError={addressError}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <PhoneNumberInput
              type="tel"
              label="Phone Number"
              name="contactNumber"
              required={true}
              requiredMessage={errorMessages.CONTACTNUMBER}
              width="100%"
              initialValue={data?.phoneNo}
            />
          </Col>
          {!isEditModal && (
            <>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  type="password"
                  label="Password"
                  name="password"
                  required={true}
                  initialValue={data?.password}
                  pattern={regExpPattern.PASSWORD}
                  regExpMessage={regErrorMessages.PASSWORD}
                  requiredMessage={errorMessages.PASSWORD}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Form.Item
                  name="confirm"
                  initialValue={data?.password}
                  label="Confirm Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Password does not match !")
                        );
                      },
                    }),
                  ]}
                >
                  <AntInput.Password />
                </Form.Item>
              </Col>
            </>
          )}

          <Col span={24} lg={{ span: 12 }}>
            <Requiredwrapper>
              <span className="validdata">*</span>
            </Requiredwrapper>
            <AddressSearchDropdown
              onChange={() => {}}
              label="Street Address"
              required
            />
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button text="Update" htmlType="submit" />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

export default EditEmployeeModal;
