import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
// import LogoImg from "../../assets/images/logo.png";

const Logo = styled.div`
  background: #f1f1f1;
  padding: 4px;
  box-shadow: 0 0 10px #00000047;
  @media screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 200px;
    height: 130px;
    @media screen and (max-width: 991px) {
      width: 150px;
    }
  }
`;

const LeftContainer = styled.div`
  // margin: 15px 0 0;
  padding: 20px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// import React from 'react'

const CustomImage = () => {
  const { shortOrg } = useSelector((state) => state.auth);
  return (
    <LeftContainer>
      <Logo>
        <img preview={false} src={shortOrg?.posterImage} />
      </Logo>
    </LeftContainer>
  );
};

export default CustomImage;
