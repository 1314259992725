import { Form } from "antd";
import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const FormFields = styled.div`
  position: relative;
  padding: 0 20px 40px;
  height: 85vh;
  overflow: auto;
  @media screen and (max-width: 1199px) {
    max-height: 400px;
    overflow: auto;
  }

  @media screen and (max-width: 575px) {
    padding: 0 20px 65px;
  }
`;
export const StyledForm = styled(Form)`
  padding: 0 8px 0px 0;
`;

export const InnerContent = styled.div`
  width: 70%;
  border: 1px solid #ccc;
  background: ${colors.white};
  border-radius: 7px;

  @media screen and (max-width: 991px) {
    width: 80%;
  }

  @media screen and (max-width: 576px) {
    width: 90%;
  }
`;
export const BottomForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  left: 0;

  // padding: 10px 30px 10px 40px;
  padding: 10px 30px 0 40px;
  align-items: center;
  background: ${colors.white};
  z-index: 20;
  button {
    margin: initial !important;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 10px;
  }
`;

// export const FormFields = styled.div`
//   position: relative;
//   padding: 0 20px 25px;

//    @media screen and (max-width: 1200px) {
//     height: 460px;
//     overflow: auto;
//     padding: 0 20px 25px;
//   }
// `;

export const LayoutContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: ${colors.background};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  body {
    background-color: "#FAFAFA" !important;
  }
  @media screen and (max-width: 575px) {
    // height: 100%;
  }
`;

export const OuterWrapper = styled.div`
  width: 100%;
  // height: 60%;
`;

export const LeftContainer = styled.div`
  margin: 15px 0 0;
  padding: 20px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RightContainer = styled.div`
  padding: 20px 10px;
  .ant-form-item-explain-error {
    color: #ff4d4f;
    position: relative;
    font-size: 12px;
    line-height: 17px;
    z-index: 1;
    @media screen and (max-width: 991px) {
      position: relative;
    }
  }
  .ant-form-item-label {
    padding: 0;
  }
`;

export const Heading = styled.div`
  font-size: 35px;
  font-weight: 700;
  padding: 0 30px;
  background: -webkit-linear-gradient(
    45deg,
    ${colors.primary},
    ${colors.secondary} 60%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
  @media screen and (max-width: 991px) {
    font-size: 22px;
  }
`;

export const ResponseTab = styled.div`
  margin-top: 24px;
  @media screen and (max-width: 767px) {
    margin-top: 5px;
  }
`;

export const SubDomain = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  button {
    flex-direction: column;
    margin: 0 10px 0px;
  }

  .ant-form-item-explain-error {
    color: #ff4d4f;
    position: absolute;
    font-size: 12px;
    line-height: 17px;
    z-index: 1;
    @media screen and (max-width: 767px) {
      position: relative;
    }
  }

  .errormsg {
    color: #ff4d4f;
    position: absolute;
    top: 66px;
    left: 12px;
    font-size: 12px;
    transition: all 3s;
  }
  .anticon {
    font-size: 24px;
    margin: 10px;
  }

  .avalibilitybtn {
    margin-top: 24px;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin-top: 15px;
    }
    .ant-btn {
    }
    .ant-btn-loading-icon {
    }
    .anticon {
      padding: initial;
      margin: 3px 10px 0 0;
      font-size: 16px;
      line-height: 16px;
    }
  }
`;

export const Logo = styled.div`
  background: #f1f1f1;
  padding: 4px;
  box-shadow: 0 0 10px #00000047;
  @media screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 250px;
    @media screen and (max-width: 991px) {
      width: 150px;
    }
  }
`;
