import { Col, Form, Row } from "antd";
import React from "react";
import styled from "styled-components";
import colors from "../../styles/Colors";
import { errorMessages } from "../../utils/errorMessages";
import Button from "../elements/button/Button";
import Input from "../elements/input/Input";
import { Container, StyledForm } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";

export const NewLabelsModal = ({ close }) => {
  const [form] = Form.useForm();
  const onSubmit = () => {
    close();
  };
  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
        style={{ padding: "0 0 20px" }}
      >
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="First Name"
              name="firstNam"
              required={true}
              requiredMessage={errorMessages.FIRSTNAME}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Last Name"
              name="lastName"
              required={true}
              requiredMessage={errorMessages.LASTNAME}
            />
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <Input
              placeholder="Address"
              name={"address"}
              label={"Street Address:"}
              required={true}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              type="contactNumber"
              label="Phone Number"
              name="contactNumber"
              required={true}
              requiredMessage={errorMessages.CONTACTNUMBER}
            />
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button text="Save" htmlType="submit" />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

export default NewLabelsModal;
