import { Image } from "antd";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import LogoImage from "../../assets/images/logo.png";
import Button from "../elements/button/Button";
import { Header, Logo, Nav, NavButtons, NavItem } from "./PublicHeaderStyles";
import { useSelector } from "react-redux";

export const PublicHeader = () => {
  const { shortOrg } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
  };
  return (
    <>
      <Header>
        <Nav>
          <Logo>
            <Image
              preview={false}
              src={shortOrg?.logoImage}
              // onClick={() => navigate("/")}
            />
          </Logo>
          <NavItem>
            {/* To Do : Add toggle className (showNav) on click of NavList */}
            {/* <NavList className={isActive ? "showNav" : ""}>
            <NavLink onClick={() => navigate("/all-events")}>Events</NavLink>
            <NavLink> news</NavLink>
            <NavLink>contact us </NavLink>
            <NavLink> about us</NavLink>
            </NavList> */}
            <NavButtons>
              {/* <Button
                text={"Login as FAM Client"}
                onClick={() => navigate("/client-login")}
              /> */}
              <Button
                className={"secondary"}
                text={"Switch to Donor"}
                onClick={() => navigate("/landing-donor")}
              />
            </NavButtons>
          </NavItem>
          {/* <Togglebtn onClick={() => handleClick()}>
            <MenuOutlined />
          </Togglebtn> */}
        </Nav>
      </Header>
    </>
  );
};
