import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const Profilecontet = styled.div`
  //   margin-top: 40px;/
`;

export const Profilelayout = styled.div`
  display: flex;
`;
