import styled from "styled-components";
import colors from "../../../styles/Colors";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalWrapper = styled.div`
  width: auto;
  // min-width: 400px;
  height: auto;
  max-height: 90vh;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
  border-radius: 10px;
  @media screen and (max-device-width: 767px) and (orientation: landscape) {
    width: 90vw;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${colors.grey1};
  padding: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
  border-bottom: 1px solid ${colors.grey6};
  z-index: 100;
  background: #fff;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 10px 10px 0 0;
  @media (max-width: 767px) {
    padding: 10px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  border-radius: 4px 4px 0 0;
  padding: 50px 0 60px;
  overflow: auto p {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 567px) {
    padding: 50px 0 55px;
  } 
`;

export const CloseModalButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  svg {
    font-size: 20px;
  }
  @media (max-width: 767px) {
    top: 15px;
    right: 10px;
  }
`;

export default {
  CloseModalButton,
  ModalContent,
  Header,
  ModalWrapper,
  Background,
};
