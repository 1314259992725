import { Col, Row, Form, Input as AntInput } from "antd";
// import moment from "moment";
import React from "react";
import toast from "react-hot-toast";
import useMutate from "../../api/hooks/useMutate";
import ORG_URL from "../../api/urls/ORG_URL";
import { errorMessages } from "../../utils/errorMessages";
import { regErrorMessages } from "../../utils/regErrorMessages";
import { regExpPattern } from "../../utils/regExpPattern";
import Button from "../elements/button/Button";
import Input from "../elements/input/Input";

import { Container, StyledForm } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";

export const AddNewDonorModal = ({ header, info, close, id, setIsSuccess }) => {
  const [form] = Form.useForm();
  const [resetPassword, { loading: changePasswordLoading }] = useMutate({
    path: ORG_URL.RESETPASSWORD,
    method: "put",
    errorToast: true,
    successToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const onSubmit = (values) => {
    const variables = {
      userId: id,
      newPassword: values.NewPassword,
    };
    resetPassword({
      variables,
      onCompleted: (data) => {
        setIsSuccess(true);
        close();
      },
      onError: () => {},
    });
  };

  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        form={form}
        onFinish={onSubmit}
      >
        <Row>
          <Col span={24}>
            <Input
              type="password"
              label="New Password"
              name="NewPassword"
              required={true}
              pattern={regExpPattern.PASSWORD}
              regExpMessage={regErrorMessages.PASSWORD}
              requiredMessage={errorMessages.PASSWORD}
            />
          </Col>
          <Col span={24}>
            <Form.Item
              name="confirm"
              type="password"
              label="Confirm Password"
              dependencies={["NewPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("NewPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password does not match !")
                    );
                  },
                }),
              ]}
            >
              <AntInput.Password />
            </Form.Item>
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button
            text="Reset"
            htmlType="submit"
            loading={changePasswordLoading}
          />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

export default AddNewDonorModal;
