import { Row, Col, Pagination } from "antd";
import { PaginationWrapper } from "../../landing/landingPageStyle";
// import { useNavigate } from "react-router-dom";
import { MainContent, SectionHeading } from "../familyList/FamilyListStyle";
import { useState } from "react";
import Card2 from "../../../elements/cards2/Cards2";
import useMutate from "../../../../api/hooks/useMutate";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import { useEffect } from "react";
import NoDataFound from "../../../noData/NoDataFound";
import { formatDate } from "../../../../utils/dateTime";
import LoaderDashboard from "../../../loader/LoaderDashboard";
const AllCampaignList = () => {
  const [page, setPage] = useState(1);
  const [getCampaignsList, { response, loading }] = useMutate({
    path: SEEKER_URLS.CAMPAIGNS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const param = {
    PageNo: page,
    PageSize: 6,
    SearchValue: "",
    SortColumn: "CreatedDate",
    SortOrder: "desc",
    isCampaign: false,
  };
  useEffect(() => {
    getCampaignsList({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [page]);

  const handleChange = (value) => {
    setPage(value);
  };
  return (
    <>
      {!loading ? (
        <MainContent>
          <Row gutter={20}>
            <Col span={24}>
              <SectionHeading>Events</SectionHeading>
            </Col>
            {!loading && !response?.data?.records?.length && (
              <Col span={24}>
                <NoDataFound heading="No events found" />
              </Col>
            )}

            {response?.data?.records?.length &&
              response?.data?.records?.map((item) => {
                //
                return (
                  <Col span={24} xl={{ span: 8 }} sm={{ span: 12 }}>
                    <Card2
                      name={item?.campaignName}
                      description={item?.description}
                      imageFile={item?.imageFile}
                      enrollmentPeriod={`${formatDate(
                        item?.enrollmentPeriodStartDate,
                        "MM/DD/YYYY"
                      )} to ${formatDate(
                        item?.enrollmentPeriodEndDate,
                        "MM/DD/YYYY"
                      )}`}
                      dropAddress={item?.dropOfAddress}
                      path={`/family-list?id=${item?.campaignId}`}
                    />
                  </Col>
                );
              })}

            {response?.data?.pagination?.totalPages > 1 && (
              <Col span={24}>
                <PaginationWrapper>
                  <Pagination
                    defaultCurrent={1}
                    pageSize={6}
                    current={page}
                    total={response?.data?.pagination?.totalRecordsCount}
                    onChange={handleChange}
                    showSizeChanger={false}
                  />
                </PaginationWrapper>
              </Col>
            )}
          </Row>
        </MainContent>
      ) : (
        <LoaderDashboard />
      )}
    </>
  );
};
export default AllCampaignList;
