import styled from "styled-components";
import { Card } from "antd";
import Colors from "../../../styles/Colors";
import colors from "../../../styles/Colors";

export const MainCard = styled(Card)`
  border-color: ${Colors.background};
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  height: 95%;
  margin-bottom: 20px;
  .ant-card-body {
    border-color: ${Colors.primary};
    border-radius: 8px;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  &.emptyCard {
    height: 100px;
  }
`;

export const CardImage = styled.div`
  .ant-image {
    width: 100%;
    height: 180px;
    img {
      max-width: 100%;
      border-radius: 8px 8px 0px 0px;
      max-height: 100%;
      border-right: 1px solid ${Colors.background};
      height: 100%;
    }
  }
`;
export const CardHeading = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${Colors.primary};
  margin: 0;
`;

export const CardDescription = styled.div`
  font-size: 14px;
  display: block;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 7px;
  @media screen and (max-width: 991px) {
    
  }
`;
export const CardContent = styled.div`
  padding: 15px;
`;

export const CampaignData = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.textGrey};
  display: flex;
  align-items: center;
  gap: 10px;
  .bold {
    color: ${colors.black};
    font-weight: 700;
    font-size: 15px;
  }
  .anticon {
    font-size: 16px;
    padding: 4px;
    border: 1px solid ${colors.secondary};
    background: ${colors.secondary};
    color: ${colors.white};
    border-radius: 4px;
  }
`;

export const CardActions = styled.div`
  width: 100%;
  button {
    display: flex;
    justify-content: center;
    border-radius: 0 0 8px 8px;
    padding: 12px 0;
    width: 100%;
    &:hover {
      background-color: ${Colors.secondary};
      border-color: ${Colors.secondary};
      color: ${Colors.white};
    }
  }
`;

export const ActionIcon = styled.div`
  height: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.background};
  padding: 10px 0;
  &:hover {
    background-color: ${Colors.primary};
    color: ${Colors.white};
  }
`;
