import styled from "styled-components";
import colors from "../../../styles/Colors";
// import LogoImg from "../../../assets/images/logo.png";
import { Image } from "antd";
import Button from "../../elements/button/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLES_INITIAL_ROUTES } from "../../../hooks/useSetAppConfigs";

const PageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: ${colors.primary};
  font-weight: 600;
  background: #004d8c11;
`;

const Heading = styled.div`
  font-size: 55px;
  font-weight: 700;
  padding: 20px 10px 0;
  background: -webkit-linear-gradient(45deg, #004d8c, #ef7b14 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
`;

const Text = styled.p`
  font-size: 16px;
  padding: 10px 20px 5px;
  text-align: center;
`;

const LogoContainer = styled.div`
  text-align: center;
`;

const Logo = styled.div`
  background: #f1f1f1;
  padding: 4px;
  box-shadow: 0 0 10px #00000047;
`;

const NotFound = () => {
  const { loading, user } = useSelector((state) => state.auth);
  const { shortOrg } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const onHomePageClick = () => {
    if (user) {
      navigate(ROLES_INITIAL_ROUTES[user.roleName]);
    } else {
      navigate("landing");
    }
  };

  return (
    <>
      <PageWrapper>
        <LogoContainer>
          <Logo>
            <Image preview={false} src={shortOrg?.logoImage} width={200} />
          </Logo>
        </LogoContainer>
        <Heading>Oops!</Heading>
        <div> Page not found</div>
        <Text>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable
        </Text>
        <Button text="Go To Homepage" onClick={() => onHomePageClick()} />
      </PageWrapper>
    </>
  );
};

export default NotFound;
