import Button from "../../../elements/button/Button";
import { PageHeading, SearchContainer } from "../campaigns/CampaignStyle";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LockOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { ActionIcon } from "../donors/DonorsStyle";
import { Dropdown, Switch, Table, Space, Menu, Input } from "antd";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import { useEffect, useState } from "react";
import useMutate from "../../../../api/hooks/useMutate";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import ORG_URL from "../../../../api/urls/ORG_URL";
import EMPLOYEE_URLS from "../../../../api/urls/EMPLOYEE_URL";
import { Moreicon } from "../seekers/SeekersStyle";
import Link from "../../../elements/links/Links";
import Search from "antd/lib/input/Search";
import { formatPhoneNumber } from "../../../../utils/phoneNumber";
import { debounce } from "../../../../utils/Debounce";
import { COLUMN_WIDTH } from "../seekers/viewclient/Viewclient";

let responseData = "";
let pagination = "";
function Employees() {
  const [openNewEmployeeModal, setOpenNewEmployeeModal] = useState(false);
  const [openEditEmployeeModal, setOpenEditEmployeeModal] = useState(false);
  const [openViewEmployeeModal, setOpenViewEmployeeModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openConfirmDeleteEmployee, setOpenConfirmDeleteEmployee] =
    useState(false);
  const [page, setPage] = useState(1);
  const [isDataToShare, setIsDataToShare] = useState();
  const [isEditModal, setIsEditModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [sortTable, setSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });

  const [deleteEmployees, { loading: deleteEmployeeLoading }] = useMutate({
    path: EMPLOYEE_URLS.DELETEEMPLOYEE,
    method: "delete",
    onCompleted: () => {},
    onError: () => {},
  });

  const [updateStatus, { loading: statusLoading }] = useMutate({
    path: EMPLOYEE_URLS.UPDATE_STATUS,
    method: "put",
    onCompleted: () => {},
    onError: () => {},
  });

  const ConfirmDeleteEmployee = () => {
    setOpenConfirmDeleteEmployee(true);
  };

  const deleteEmployee = () => {
    const params = {
      userId: isDataToShare?.userId,
    };
    deleteEmployees({
      params: params,
      onCompleted: () => {
        if (responseData.length === 1) {
          let currentPage = page;
          setPage(currentPage - 1);
        } else {
          setIsSuccess(true);
        }
        setOpenConfirmDeleteEmployee(false);
      },
      onError: () => {},
    });
  };

  const ViewEmployee = () => {
    setOpenViewEmployeeModal(true);
  };

  const resetPassword = () => {
    setOpenResetPasswordModal(true);
  };
  const addEmployeeModal = () => {
    setIsEditModal(false);
    setOpenNewEmployeeModal(true);
  };

  const editemploye = () => {
    setIsEditModal(true);
    setOpenEditEmployeeModal(true);
  };

  const switchChange = (checked, record) => {
    const variables = {
      userId: record?.userId,
      IsActive: checked,
    };
    updateStatus({
      variables: variables,
      onCompleted: () => {
        setIsSuccess(true);
      },
      onError: () => {},
    });
  };

  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };

  const [getEmployees, { response, loading }] = useMutate({
    path: ORG_URL.EMPLOYEE,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const param = {
    PageNo: page || 1,
    PageSize: 100,
    SearchValue: searchValue,
    SortColumn: sortTable.column,
    SortOrder: sortTable.order,
  };
  useEffect(() => {
    getEmployees({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
    setIsSuccess(false);
  }, [page, sortTable, isSuccess, searchValue]);

  responseData = response?.data?.records;
  pagination = response?.data?.pagination;
  const ActionMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <ActionIcon title="View" onClick={() => ViewEmployee()}>
              <EyeOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "2",
          label: (
            <ActionIcon title="Edit" onClick={() => editemploye()}>
              <EditOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "3",
          label: (
            <ActionIcon title="Delete" onClick={() => ConfirmDeleteEmployee()}>
              <DeleteOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "4",
          label: (
            <ActionIcon title="Reset Password" onClick={() => resetPassword()}>
              <LockOutlined />
            </ActionIcon>
          ),
        },
      ]}
    />
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      render: (text, record) => (
        <Link
          text={`${record?.firstName} ${record.lastName}`}
          onClick={() => {
            setIsDataToShare(record);
            ViewEmployee();
          }}
        />
      ),
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Phone Number ",
      dataIndex: "phoneNo",
      sorter: true,
      render: (phoneNumber) => formatPhoneNumber(phoneNumber),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => a.status - b.status,
      },
      render: (_, record) => (
        <Switch
          checked={record?.isActive}
          onChange={(checked) => switchChange(checked, record)}
          {...record}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            onClick={(e) => {
              e.preventDefault();
              setIsDataToShare(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  // const handleSearch = (value) => {
  //   setSearchValue(value);
  // };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const search = debounce(handleSearch);

  return (
    <>
      <MainContent>
        <PageHeading>
          Employees
          <SearchContainer>
            <Input
              placeholder="Search by Name"
              onChange={search}
              // style={{
              //   width: "auto",
              // }}
            />
            <Button
              text={"Add New Employee"}
              onClick={() => addEmployeeModal()}
            />
          </SearchContainer>
        </PageHeading>
        <Table
          loading={loading || statusLoading || deleteEmployeeLoading}
          dataSource={responseData}
          columns={columns}
          onChange={handleChange}
          showSorterTooltip={false}
          scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
          pagination={{
            pageSize: 100,
            defaultCurrent: page,
            total: pagination?.totalRecordsCount,
            hideOnSinglePage: true,
          }}
        />
      </MainContent>
      <Modal
        title={!isEditModal ? "Add Employee" : "Edit Employee"}
        open={isEditModal ? openEditEmployeeModal : openNewEmployeeModal}
        setOpenModal={
          isEditModal ? setOpenEditEmployeeModal : setOpenNewEmployeeModal
        }
        modalType={ModalList.AddNewEmployeeModal}
        data={isEditModal ? isDataToShare : ""}
        setIsSuccess={setIsSuccess}
        isEditModal={isEditModal}
      />
      <Modal
        title="Delete?"
        open={openConfirmDeleteEmployee}
        setOpenModal={setOpenConfirmDeleteEmployee}
        modalType={ModalList.ConfirmDeleteEmployee}
        onConfirm={deleteEmployee}
        loading={deleteEmployeeLoading}
      />

      <Modal
        title="View Employee"
        open={openViewEmployeeModal}
        setOpenModal={setOpenViewEmployeeModal}
        modalType={ModalList.ViewEmployeeModal}
        data={isDataToShare}
      />
      <Modal
        title="Reset Password"
        open={openResetPasswordModal}
        setOpenModal={setOpenResetPasswordModal}
        modalType={ModalList.ResetPasswordModal}
        id={isDataToShare?.userId}
        setIsSuccess={setIsSuccess}
      />
    </>
  );
}

export default Employees;
