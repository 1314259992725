import { Table } from "antd";
import React from "react";
import { COLUMN_WIDTH } from "../../admin/seekers/viewclient/Viewclient";

const RecievedMembers = ({
  dataSource,
  pagination,
  columns,
  loading,
  page,
  onChange,
}) => {
  return (
    <div>
      <Table
        // dataSource={response?.data?.records || []}
        dataSource={dataSource}
        sortDirections={["ascend", "descend", "ascend"]}
        columns={columns}
        loading={loading}
        scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
        showSorterTooltip={false}
        onChange={onChange}
        pagination={{
          defaultCurrent: 1,
          pageSize: 100,
          total: pagination?.totalRecordsCount,
          current: page,
          showSizeChanger: false,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default RecievedMembers;
