/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Dropdown, Input, Menu, Space, Table } from "antd";
import { Switch } from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { ActionIcon } from "../donors/DonorsStyle";
import { PageHeading, SearchContainer } from "../campaigns/CampaignStyle";
import Button from "../../../elements/button/Button";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import useMutate from "../../../../api/hooks/useMutate";
import { useEffect } from "react";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { Moreicon } from "../seekers/SeekersStyle";
import { formatDate } from "../../../../utils/dateTime";
import Search from "antd/lib/input/Search";
import { debounce } from "../../../../utils/Debounce";
import toast from "react-hot-toast";
import { Navigate, useNavigate } from "react-router-dom";
import { COLUMN_WIDTH } from "../seekers/viewclient/Viewclient";

let responseData;
let pagination = "";

const Campaigns = () => {
  const [isDataToShare, setIsDataToShare] = useState();
  const [isEditModal, setIsEditModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openEditCampaignModal, setOpenEditCampaignModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openConfirmDeleteCampaign, setOpenConfirmDeleteCampaign] =
    useState(false);
  const [openAddNewCampaignModal, setOpenAddNewCampaignModal] = useState(false);
  const [sortTable, setSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [isSuccess, setIsSuccess] = useState(false);

  const [page, setPage] = useState(1);

  const addCampaign = () => {
    setIsEditModal(false);
    setOpenAddNewCampaignModal(true);
  };
  const [openViewCampaignsModal, setOpenViewCampaignsModal] = useState(false);

  const openDeleteModal = (record) => {
    setOpenConfirmDeleteCampaign(true);
  };

  const viewCampaign = () => {
    setOpenViewCampaignsModal(true);
  };

  const editCampaign = () => {
    setIsEditModal(true);
    setOpenEditCampaignModal(true);
  };

  const [getCampaignsList, { response, loading }] = useMutate({
    path: CAMPAIGN_URL.GET_CAMPAIGN_LIST,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const param = {
    pageNo: page || 1,
    PageSize: 100,
    searchValue: searchValue,
    sortColumn: sortTable.column,
    sortOrder: sortTable.order,
    campaignId: null,
  };

  useEffect(() => {
    getCampaignsList({
      params: param,
      loading,
      onCompleted: () => {},
      onError: () => {},
    });
    setIsSuccess(false);
  }, [page, sortTable, isSuccess, searchValue]);

  // const data = response?.data?.records.map((item) => {
  //   item.campaignStartDate = formatDate(item.campaignStartDate, "MM/DD/YYYY");
  //   item.campaignEndDate = formatDate(item.campaignEndDate, "MM/DD/YYYY");
  //   return item;
  // });
  responseData = response?.data?.records;
  pagination = response?.data?.pagination;
  const navigate = useNavigate();
  const [deleteCampaigns] = useMutate({
    path: CAMPAIGN_URL.DELETECAMPAIGN,
    method: "delete",
    onCompleted: () => {},
    onError: () => {},
  });

  const deleteCampaign = () => {
    const params = {
      CampaignId: isDataToShare.campaignId,
    };

    deleteCampaigns({
      params: params,
      onCompleted: () => {
        if (responseData.length === 1) {
          let currentPage = page;
          setPage(currentPage - 1);
        } else {
          setIsSuccess(true);
        }
        setOpenConfirmDeleteCampaign(false);
      },
      onError: () => {},
    });
  };

  const [updateCampaignStatus, { loading: updateLoading }] = useMutate({
    path: CAMPAIGN_URL.UPDATECAMPAIGNSTATUS,
    method: "put",
    onCompleted: () => {},
    onError: () => {},
  });

  const onClickStatus = (checked, record) => {
    const params = {
      CampaignId: record.campaignId,
      IsActive: checked,
      ActionPerformedBy: record.campaignName,
    };
    updateCampaignStatus({
      params: params,
      onCompleted: () => {
        setIsSuccess(true);
      },
      onError: () => {},
    });
  };

  const columns = [
    {
      title: " Event  Name",
      dataIndex: "campaignName",
      sorter: true,
    },
    {
      title: "Start Date",
      dataIndex: "campaignStartDate",
      sorter: true,
      //  render:(_,date)=>getFullDate(date.id)
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
    },

    {
      title: "End Date",
      dataIndex: "campaignEndDate",
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
      sorter: true,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <Switch
          defaultChecked
          checked={record?.isActive}
          onChange={(checked) => {
            onClickStatus(checked, record);
          }}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            onClick={(e) => {
              e.preventDefault();
              setIsDataToShare(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const ActionMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <ActionIcon
              title="View"
              onClick={() => {
                // viewCampaign();
                navigate(`/view-event?id=${isDataToShare?.campaignId}`);
              }}
            >
              <EyeOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "2",
          label: (
            <ActionIcon
              title="Edit"
              onClick={() => {
                editCampaign();
              }}
            >
              <EditOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "3",
          label: (
            <ActionIcon title="Delete" onClick={() => openDeleteModal()}>
              <DeleteOutlined />
            </ActionIcon>
          ),
        },
      ]}
    />
  );

  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const search = debounce(handleSearch);

  return (
    <>
      <MainContent>
        <PageHeading>
          Events
          <SearchContainer>
            <Input
              placeholder="Search by Event Name"
              // style={{
              //   width: "auto",
              // }}
              onChange={search}
            />
            <Button text={"Add New Event"} onClick={() => addCampaign()} />
          </SearchContainer>
        </PageHeading>
        <div style={{ position: "relative" }}>
          {/* <Loader /> */}
          <Table
            loading={loading || updateLoading}
            dataSource={responseData}
            columns={columns}
            onChange={handleChange}
            sortDirections={["ascend", "descend", "ascend"]}
            scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
            showSorterTooltip={false}
            pagination={{
              pageSize: 100,
              defaultCurrent: page,
              total: pagination?.totalRecordsCount,
              hideOnSinglePage: true,
            }}
          />
        </div>
      </MainContent>

      <Modal
        title={isEditModal ? "Edit Event" : "Add New Event"}
        open={isEditModal ? openEditCampaignModal : openAddNewCampaignModal}
        setOpenModal={
          isEditModal ? setOpenEditCampaignModal : setOpenAddNewCampaignModal
        }
        modalType={ModalList.EditcampaignModal}
        data={isEditModal ? isDataToShare : ""}
        setIsSuccess={setIsSuccess}
      />
      <Modal
        title="Reset Password"
        open={openResetPasswordModal}
        setOpenModal={setOpenResetPasswordModal}
        modalType={ModalList.ResetPasswordModal}
      />

      <Modal
        title="Delete?"
        open={openConfirmDeleteCampaign}
        setOpenModal={setOpenConfirmDeleteCampaign}
        onConfirm={deleteCampaign}
        modalType={ModalList.ConfirmDeleteSeeker}
        setIsSuccess={setIsSuccess}
      />

      <Modal
        title="View Event"
        open={openViewCampaignsModal}
        setOpenModal={setOpenViewCampaignsModal}
        modalType={ModalList.ViewCampaignsModal}
        data={isDataToShare}
      />
    </>
  );
};

export default Campaigns;
