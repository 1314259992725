import styled from "styled-components";
import colors from "../../styles/Colors";

export const FooterContainer = styled.footer`
  text-align: center;
  background: ${colors.primary};
  color: ${colors.white};
  padding: 10px 0;
  width: 100%;
  bottom: 0;
}
`;
function Footer() {
  return <FooterContainer>All Rights Reserved 2022</FooterContainer>
}
export default Footer;
