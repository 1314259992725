import React, { useState } from "react";
import {
  SideBarContainer,
  SideNav,
  NavList,
  NavLink,
  RoleHeading,
  BottomNav,
} from "./SideBarStyles";
import { ADMIN, EMPLOYEE, DONOR, SEEKER, SUPERADMIN } from "./SIDEBAR_OPTIONS";
import { useNavigate } from "react-router-dom";
import { logoutUser, ROLES_ENUM } from "../../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Modal, { ModalList } from "../modal/basemodal/BaseModal";
import { closeSideNav, toggleMobileSideNav } from "../../redux/slices/appSlice";
import { isPortableDevice } from "../../utils/getDeviceOs";
import { ROLES_LOGOUT_ROUTES } from "../../hooks/useSetAppConfigs";
import { getShortOrg } from "../../auth/authAsyncActions";

const SIDEBAR_OPTIONS = {
  Admin: ADMIN,
  Employee: EMPLOYEE,
  Donor: DONOR,
  Seeker: SEEKER,
};

function SideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const { mobileSideNav } = useSelector((state) => state.app);

  const { user } = useSelector((state) => state.auth);
  if (!user?.roleName) {
    return;
  }

  const roleName = user.roleName;

  const getSidebarOptions = () => {
    if (roleName === ROLES_ENUM.SUPERADMIN) {
      return SIDEBAR_OPTIONS[ROLES_ENUM.ADMIN];
    }
    return SIDEBAR_OPTIONS[roleName];
  };

  const sidebarOptions = getSidebarOptions();
  const logout = () => {
    const subDomain = "app";
    // const subDomain = window.location.host?.split(".")[0];
    localStorage.removeItem("accessToken");
    localStorage.removeItem("roleName");
    localStorage.removeItem("subDomainData");
    dispatch(getShortOrg(subDomain));
    dispatch(logoutUser());
    return navigate(ROLES_LOGOUT_ROUTES[user?.roleName]);
  };

  const onSideMenuClick = (path) => {
    if (isPortableDevice()) {
      dispatch(toggleMobileSideNav());
    }

    navigate(path);
  };

  const pathname = window.location.pathname;

  return (
    <>
      <SideBarContainer isActive={mobileSideNav}>
        <SideNav>
          {roleName === ROLES_ENUM.SUPERADMIN && (
            <>
              <RoleHeading>Admin</RoleHeading>
            </>
          )}

          <NavList>
            {sidebarOptions.map((sideNavItem) => {
              return (
                <NavLink
                  key={sideNavItem.name}
                  onClick={() => onSideMenuClick(sideNavItem.path)}
                  active={sideNavItem.active.includes(pathname) ? 1 : 0}
                >
                  {sideNavItem.name}
                </NavLink>
              );
            })}
          </NavList>

          {roleName === ROLES_ENUM.SUPERADMIN && (
            <>
              <RoleHeading>Super Admin</RoleHeading>
              <NavList>
                {SUPERADMIN.map((sideNavItem) => {
                  return (
                    <NavLink
                      key={sideNavItem.name}
                      onClick={() => onSideMenuClick(sideNavItem.path)}
                      active={sideNavItem.active.includes(pathname) ? 1 : 0}
                    >
                      {sideNavItem.name}
                    </NavLink>
                  );
                })}
              </NavList>
            </>
          )}
        </SideNav>
        <BottomNav>
          <NavLink
            onClick={() => {
              dispatch(closeSideNav());
              setOpenLogOutModal(true);
            }}
          >
            Logout
          </NavLink>
        </BottomNav>
      </SideBarContainer>

      <Modal
        title="Logout?"
        open={openLogOutModal}
        setOpenModal={setOpenLogOutModal}
        modalType={ModalList.AdminLogOutModal}
        onConfirm={logout}
      />
    </>
  );
}

export default SideBar;
