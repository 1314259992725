import { useEffect, useState } from "react";
import {
  FamilyDetail,
  MainHeading,
  ButtonContainer,
  Info,
} from "../../donor/familyDetails/FamilyDetailsStyle";
import {
  capitalFirstLetter,
  formatDate,
  getAgeFromTs,
} from "../../../../utils/dateTime";
import { genderAPIFormat } from "../../../../utils/genderFormat";
import {
  AddNewMember,
  Bold,
  KidWish,
  NotEligible,
  Wishes,
  WishesLabel,
} from "./addFamilyDetailsStyle";
import { Col, Form, Row } from "antd";
import { errorMessages } from "../../../../utils/errorMessages";
import Button from "../../../elements/button/Button";
import {
  MainContent,
  SectionHeading,
  Section,
  CampaignHeading,
  CampaignDescription,
  CampaignImage,
  Addmemeberheading,
} from "../../donor/familyList/FamilyListStyle";
import { CampaignData } from "../../../elements/cards/CardStyle";
import TextAreaB from "../../../elements/textArea/TextArea";
import { Label } from "../../employee/settings/SettingsStyle";
import { PlusCircleOutlined } from "@ant-design/icons";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import useMutate from "../../../../api/hooks/useMutate";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoaderDashboard from "../../../loader/LoaderDashboard";
import moment from "moment";
import toast from "react-hot-toast";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";

function AddFamilyDetails() {
  const [selectedMembers, setSelectedMembers] = useState();
  const [openNewFamilyMember, setOpenNewFamilyMember] = useState(false);
  const [newMemberDetails, setNewMemberDetails] = useState([]);
  const navigate = useNavigate();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");
  const seekerId = query.get("seekerId");
  const edit = query.get("edit");
  const admin = query.get("admin");
  const addnewFamilyMember = () => {
    setOpenNewFamilyMember(true);
  };
  const { user } = useSelector((state) => state.auth);

  const handleWishes = (wishId, memberIndex, e) => {
    const writeWishes = selectedMembers?.members?.map((famMember, index) => {
      famMember.wishes.map((wishInfo) => {
        if (index === memberIndex && wishInfo.wishId === wishId) {
          wishInfo.wish = e.target.value;
          wishInfo.error = !e.target.value ? true : false;
        }
        return wishInfo;
      });
      return famMember;
    });
    setSelectedMembers((val) => ({
      ...val,
      members: writeWishes,
    }));
  };
  const [getFamilyDetails, { response: familySeekerResponse, loading }] =
    useMutate({
      path: SEEKER_URLS.SEEKER_FAMILY_DETAILS,
      method: "get",
      onCompleted: () => {},
      onError: () => {},
    });
  const [
    getClientFamilyWishes,
    { response: editFamilydetails, loading: editFamilyDetailLoading },
  ] = useMutate({
    path: CAMPAIGN_URL.GET_DONATION_DETAILS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });

  const [getCampaignDetail, { response, loading: campaignLoading }] = useMutate(
    {
      path: SEEKER_URLS.CAMPAIGNDETAIL,
      method: "get",
      onCompleted: () => {},
      onError: () => {},
    }
  );
  const [optInCampaign, { loading: optInCampaignLoading }] = useMutate({
    path: SEEKER_URLS.OPTINCAMPAIGN,
    errorToast: true,
    method: "post",
    onCompleted: () => {
      navigate("/received-donation");
    },
    onError: () => {},
  });

  const [form] = Form.useForm();

  useEffect(() => {
    getCampaignDetail({
      params: {
        campaignId: id,
      },
      onCompleted: (data) => {
        const ts = moment(new Date()).unix();

        if (data?.enrollmentPeriodEndDate < ts) {
          toast.error("Sorry the event you're looking for expired.");

          if (admin) {
            return navigate(-1);
          }

          return navigate("/client-events");
        }
      },
      onError: () => {},
    });
  }, []);

  useEffect(() => {
    if (!user?.id) return;

    getFamilyDetails({
      params: {
        userId: seekerId || user?.id,
      },
      onCompleted: (responseData) => {
        const data = [responseData]?.map((item) => {
          item.wishes = [
            {
              wishId: "1",
              wish: "",
            },
            {
              wishId: "2",
              wish: "",
            },
            {
              wishId: "3",
              wish: "",
            },
          ];
          item?.members?.map((itemInfo) => {
            itemInfo.wishes = [
              {
                wishId: "1",
                wish: "",
              },
              {
                wishId: "2",
                wish: "",
              },
              {
                wishId: "3",
                wish: "",
              },
            ];
            return itemInfo;
          });
          return item;
        });

        if (!edit) {
          setSelectedMembers(...data);
        }
      },
      onError: () => {},
    });
  }, []);

  const onSubmit = () => {
    let houseHoldWishes = [];
    const filledHouseHoldWishes = selectedMembers.wishes.filter(
      (wishInfo) => wishInfo.wish.length > 0
    );
    filledHouseHoldWishes?.map((wishInfo) => {
      houseHoldWishes.push({ description: wishInfo.wish });
      return wishInfo;
    });
    let otherWishes = [];
    let memberWishes = [];

    selectedMembers?.members?.map((wishInfo) => {
      const filledMemberWishes = wishInfo.wishes.filter(
        (memberWishes) => memberWishes.wish.length > 0
      );
      filledMemberWishes?.map((info) => {
        memberWishes?.push({ description: info.wish });
        return info;
      });
      if (wishInfo.userId !== 0) {
        otherWishes.push({
          id: wishInfo.seekerMemberId,
          wishes: memberWishes,
          memberDetails: null,
        });
      } else {
        otherWishes.push({
          id: wishInfo.userId,
          wishes: memberWishes,
          memberDetails: {
            documents: wishInfo?.documents,
            gender: wishInfo?.gender,
            DOB: wishInfo?.dob,
            // DOB: "10-12-1999",
            firstName: wishInfo?.firstName,
            lastName: wishInfo?.lastName,
            relation: wishInfo?.relation,
            phoneNumber: wishInfo?.contactNumber?.replace(/\s+/g, ""),
          },
        });
      }

      memberWishes = [];
      return wishInfo;
    });

    const variables = {
      campaignId: id,
      userId: selectedMembers.userId,
      wishes: houseHoldWishes,
      members: [...otherWishes],
    };

    console.log("variables", variables);
    optInCampaign({
      variables,
      onCompleted: () => {
        if (admin) {
          return navigate(`/view-client-detail?id=${seekerId}`);
        }
        navigate("/received-donation");
      },
      onError: () => {},
    });
  };
  const handleHouseHoldWishes = (e, userId) => {
    const updateWishes = selectedMembers?.wishes.map((wishInfo) => {
      if (wishInfo.wishId === userId) {
        wishInfo.wish = e.target.value;
        wishInfo.error = !e.target.value ? true : false;
      }
      return wishInfo;
    });
    setSelectedMembers((member) => ({
      ...member,
      wishes: updateWishes,
    }));
  };
  const handleSubmit = () => {
    // let filledAllHouseHoldWish = [];
    // let filledAllMembersWish = [];
    const houseHoldWishes = selectedMembers?.wishes.map((info) => {
      if (getAgeFromTs(selectedMembers?.dob) <= 18) {
        if (info.wish.length === 0) {
          info.error = true;
        } else {
          info.wish = info.wish.trim();
          if (info?.wish.length === 0) {
            info.error = true;
          }
        }
      } else {
        if (info.wish.length === 0) {
          info.error = false;
        } else {
          info.wish = info.wish.trim();
          if (info?.wish.length === 0) {
            info.error = false;
          }
        }
      }
      return info;
    });
    const memberWishes = selectedMembers?.members?.map((info) => {
      if (getAgeFromTs(info?.dob) <= 18) {
        info.wishes.map((memberInfo) => {
          if (memberInfo.wish.length === 0) {
            memberInfo.error = true;
          } else {
            memberInfo.wish = memberInfo.wish.trim();
            if (memberInfo?.wish.length === 0) {
              memberInfo.error = true;
            }
          }
          return memberInfo;
        });
      } else {
        info.wishes.map((memberInfo) => {
          if (memberInfo.wish.length === 0) {
            memberInfo.error = false;
          } else {
            memberInfo.wish = memberInfo.wish.trim();
            if (memberInfo?.wish.length === 0) {
              memberInfo.error = false;
            }
          }
          return memberInfo;
        });
      }
      return info;
    });
    setSelectedMembers((memberInfo) => ({
      ...memberInfo,
      wishes: houseHoldWishes,
      members: memberWishes,
    }));
    console.log("household", houseHoldWishes);
    console.log("member", memberWishes);

    let finalError = false;

    for (const wish of houseHoldWishes) {
      if (wish.error) {
        finalError = true;
      }
    }

    if (memberWishes?.length) {
      for (const member of memberWishes) {
        for (const wish of member.wishes) {
          if (wish.error) {
            finalError = true;
          }
        }
      }
    }

    let isEligible = false;

    if (getAgeFromTs(selectedMembers.dob) <= 18) {
      isEligible = true;
    }

    if (memberWishes?.length) {
      for (const member of memberWishes) {
        if (getAgeFromTs(member.dob) <= 18) {
          isEligible = true;
        }
      }
    }

    if (!isEligible) {
      return toast.error("None of the members are eligible to enter wishes");
    }

    if (finalError) {
      toast.error("Please fill all wishes input boxes");
    } else {
      onSubmit();
    }
  };
  useEffect(() => {
    if (newMemberDetails.length > 0) {
      let allMembers = [];
      allMembers.push(...newMemberDetails);
      setSelectedMembers((val) => ({
        ...val,
        members: [...(val?.members || []), ...allMembers],
      }));
      setNewMemberDetails([]);
    }
  }, [newMemberDetails]);
  useEffect(() => {
    let params = {
      CampaignId: id,
    };

    if (seekerId) {
      params = {
        CampaignId: id,
        seekerId: seekerId,
      };
    }

    getClientFamilyWishes({
      params,
      onCompleted: () => {},
      onError: () => {},
    });
  }, []);
  useEffect(() => {
    if (editFamilydetails?.data?.members?.length) {
      const houseHold = editFamilydetails?.data?.members?.filter(
        (member) => member.isHouseHold == true
      );
      const familyMembers = editFamilydetails?.data?.members?.filter(
        (member) => member.isHouseHold != true
      );
      console.log(houseHold, familyMembers);
      const householdEditData = houseHold?.map((member) => {
        member?.wishes?.map((wishInfo) => {
          wishInfo.wish = wishInfo.description;
          return wishInfo;
        });
        return member;
      });
      const memberEditData = familyMembers?.map((memberInfo) => {
        memberInfo?.wishes?.map((wishInfo) => {
          wishInfo.wish = wishInfo.description;
          return wishInfo;
        });
        return memberInfo;
      });
      const editData = {
        ...householdEditData[0],
        members: memberEditData,
      };
      if (edit) {
        setSelectedMembers(editData);
      }
    }
  }, [editFamilydetails]);

  return (
    <>
      {!(loading || campaignLoading || editFamilyDetailLoading) ? (
        <>
          <MainContent>
            <Section>
              <Row gutter={[0, 10]}>
                <Col xl={{ span: 24 }} span={24}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CampaignHeading>
                      {response?.data?.campaignName}
                      <Button
                        text={"Back"}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </CampaignHeading>
                  </div>

                  <CampaignImage src={response?.data?.imageFile} />
                </Col>
                <Col xl={{ span: 24 }} span={24}>
                  <CampaignDescription>
                    {response?.data?.description}
                  </CampaignDescription>
                </Col>
                <Col xl={{ span: 12 }} span={24}>
                  <CampaignData>
                    <span className="bold"> Start date: </span>
                    {response?.data?.campaignStartDate
                      ? formatDate(
                          response?.data?.campaignStartDate,
                          "MM/DD/YYYY"
                        )
                      : ""}
                  </CampaignData>
                </Col>
                <Col xl={{ span: 12 }} span={24}>
                  <CampaignData>
                    <span className="bold"> End date: </span>
                    {response?.data?.campaignEndDate
                      ? formatDate(
                          response?.data?.campaignEndDate,
                          "MM/DD/YYYY"
                        )
                      : ""}
                  </CampaignData>
                </Col>
                <Col xl={{ span: 12 }} span={24}>
                  <CampaignData>
                    <span className="bold">Enrollment period: </span>
                    {response?.data?.enrollmentPeriodStartDate &&
                      formatDate(
                        response?.data?.enrollmentPeriodStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                    To{" "}
                    {response?.data?.enrollmentPeriodEndDate &&
                      formatDate(
                        response?.data?.enrollmentPeriodEndDate,
                        "MM/DD/YYYY"
                      )}
                    {/* <CalendarOutlined title="Add to calender" /> */}
                  </CampaignData>
                </Col>
                <Col xl={{ span: 12 }} span={24}>
                  <CampaignData>
                    <span className="bold"> Client pickup address :</span>
                    {response?.data?.pickupAddress}
                  </CampaignData>
                </Col>
              </Row>
              &nbsp;
              <hr />
            </Section>

            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete="off"
              form={form}
            >
              {/* "TO Do : If client has already participated in the event commented section will Display." */}
              {/* <Row gutter={20}>
                <Col span={24}>
                  <NoDataFound
                    heading="You have already participated in this Event."
                    description="You can participate only once in an Event."
                  />
                </Col>
              </Row> */}

              <Row gutter={20}>
                <Col span={24}>
                  <SectionHeading>
                    <Addmemeberheading>
                      Client Requests
                      <AddNewMember onClick={addnewFamilyMember}>
                        <PlusCircleOutlined title="New Member" />
                        Add New Member
                      </AddNewMember>
                    </Addmemeberheading>
                  </SectionHeading>

                  <Info>
                    <Bold>Note:</Bold>
                    Wishes for only 18 years and below are accepted.
                  </Info>
                  <p> </p>

                  <Row gutter={20}>
                    <Col xl={{ span: 12 }} span={24}>
                      <FamilyDetail key={selectedMembers?.userId}>
                        <MainHeading>
                          {capitalFirstLetter(selectedMembers?.firstName) ||
                            `Household`}
                          's wishes
                        </MainHeading>
                        {/* <FamilyData> */}
                        <Row gutter={20}>
                          <Col span={24}>
                            <Row gutter={20}>
                              <Col span={24}>
                                <Info>
                                  <Label>Gender: </Label>
                                  {genderAPIFormat(selectedMembers?.gender)}
                                </Info>
                                {/* </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}> */}
                                <Info>
                                  <Label>Age: </Label>
                                  {getAgeFromTs(selectedMembers?.dob)}
                                </Info>
                              </Col>
                              <Col span={24}>
                                <Wishes>
                                  &nbsp;
                                  <WishesLabel>Wishes</WishesLabel>
                                  <Row>
                                    {getAgeFromTs(selectedMembers?.dob) <=
                                    18 ? (
                                      selectedMembers?.wishes?.map(
                                        (memberInfo, memberInfoIndex) => {
                                          return (
                                            (memberInfo.wishId =
                                              memberInfo.wishId ||
                                              memberInfoIndex),
                                            (memberInfo.label =
                                              "Wish " + (memberInfoIndex + 1)),
                                            (memberInfo.name =
                                              "wish" + (memberInfoIndex + 1)),
                                            (
                                              <Col span={24}>
                                                <KidWish>
                                                  <TextAreaB
                                                    label={memberInfo.label}
                                                    name={`houseHold${memberInfoIndex}`}
                                                    initialValue={
                                                      memberInfo?.description ||
                                                      ""
                                                    }
                                                    required={false}
                                                    showCount={true}
                                                    disabled={
                                                      getAgeFromTs(
                                                        selectedMembers?.dob
                                                      ) > 18
                                                        ? true
                                                        : false
                                                    }
                                                    maxLength={100}
                                                    onChange={(e) =>
                                                      handleHouseHoldWishes(
                                                        e,
                                                        memberInfo.wishId
                                                      )
                                                    }
                                                    requiredMessage={
                                                      errorMessages?.WISH
                                                    }
                                                    placeholder="Enter Wish"
                                                  />
                                                </KidWish>
                                                {getAgeFromTs(
                                                  selectedMembers?.dob
                                                ) > 18
                                                  ? false
                                                  : true &&
                                                    memberInfo?.error && (
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        {errorMessages?.WISH}
                                                      </div>
                                                    )}
                                              </Col>
                                            )
                                          );
                                        }
                                      )
                                    ) : (
                                      <NotEligible>
                                        This member is not eligible to enter the
                                        wishes.
                                      </NotEligible>
                                    )}
                                  </Row>
                                </Wishes>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* </FamilyData> */}
                      </FamilyDetail>
                    </Col>

                    {selectedMembers?.members?.map((famMember, i) => {
                      return (
                        <>
                          <Col xl={{ span: 12 }} span={24}>
                            <FamilyDetail key={i}>
                              <MainHeading>
                                {capitalFirstLetter(famMember?.firstName) ||
                                  `Member ${i + 1}`}
                                's wishes
                              </MainHeading>

                              <Row gutter={20}>
                                <Col span={24}>
                                  <Row gutter={20}>
                                    <Col span={24}>
                                      <Info>
                                        <Label>Gender: </Label>
                                        {genderAPIFormat(famMember.gender)}
                                      </Info>

                                      <Info>
                                        <Label>Age: </Label>
                                        {getAgeFromTs(famMember?.dob)}
                                      </Info>
                                    </Col>

                                    <Col span={24}>
                                      <Wishes>
                                        &nbsp;
                                        <WishesLabel>Wishes</WishesLabel>
                                        <Row>
                                          {getAgeFromTs(famMember?.dob) <=
                                          18 ? (
                                            famMember?.wishes?.map(
                                              (memberInfo, memberInfoIndex) => {
                                                return (
                                                  (memberInfo.wishId =
                                                    memberInfoIndex),
                                                  (memberInfo.label =
                                                    "Wish " +
                                                    (memberInfoIndex + 1)),
                                                  (memberInfo.name =
                                                    "wish" +
                                                    (memberInfoIndex + 1)),
                                                  (
                                                    <Col span={24}>
                                                      <KidWish>
                                                        <TextAreaB
                                                          label={
                                                            memberInfo.label
                                                          }
                                                          name={`${
                                                            famMember?.relation ||
                                                            famMember?.seekerMemberId
                                                          }${memberInfoIndex}${i}`}
                                                          initialValue={
                                                            memberInfo?.description ||
                                                            ""
                                                          }
                                                          maxLength={100}
                                                          required={false}
                                                          showCount={true}
                                                          disabled={
                                                            getAgeFromTs(
                                                              famMember?.dob
                                                            ) > 18
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e) =>
                                                            handleWishes(
                                                              memberInfo.wishId,
                                                              i,
                                                              e
                                                            )
                                                          }
                                                          requiredMessage={
                                                            errorMessages?.WISH
                                                          }
                                                          placeholder="Enter Wish"
                                                        />
                                                      </KidWish>
                                                      {getAgeFromTs(
                                                        famMember?.dob
                                                      ) > 18
                                                        ? false
                                                        : true &&
                                                          memberInfo?.error && (
                                                            <div
                                                              style={{
                                                                color: "red",
                                                              }}
                                                            >
                                                              {
                                                                errorMessages?.WISH
                                                              }
                                                            </div>
                                                          )}
                                                    </Col>
                                                  )
                                                );
                                              }
                                            )
                                          ) : (
                                            <NotEligible>
                                              This member is not eligible to
                                              enter the wishes.
                                            </NotEligible>
                                          )}

                                          {/* TODO: NotEligible section will show when Age is greater than 18*/}

                                          {/* <NotEligible>
                                              This memeber is not eligible to enter the
                                              wishes.
                                            </NotEligible> */}
                                        </Row>
                                      </Wishes>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </FamilyDetail>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                </Col>
                <Col xs={{ span: 24 }}>
                  <ButtonContainer>
                    <Button
                      text={"Save"}
                      htmlType="button"
                      onClick={handleSubmit}
                      loading={optInCampaignLoading}
                    />
                  </ButtonContainer>
                </Col>
              </Row>
            </Form>
          </MainContent>
          <Modal
            title="Add New Family Member"
            open={openNewFamilyMember}
            setOpenModal={setOpenNewFamilyMember}
            modalType={ModalList.AddNewFamilyMemberModal}
            setNewMemberDetails={setNewMemberDetails}
            isAddMember={true}
          />
        </>
      ) : (
        <LoaderDashboard />
      )}
    </>
  );
}
export default AddFamilyDetails;
