import React from "react";
import { DatePicker, Form } from "antd";
import styled from "styled-components";
import colors from "../../../styles/Colors";

const DatePickerContainer = styled.div`
  margin: ${(props) => props.margin || "auto"};
  padding: ${(props) => props.padding || "auto"};
  .ant-picker {
    width: 100%;
  }

  .ant-form-item,
  .ant-select-in-form-item {
    margin: 0 0 8px 10px !important;

    .ant-form-item-label {
      padding: 0 !important;
    }

    .ant-form-item-control-input {
      input {
        border-radius: 4px;
        width: 100%;

        &:hover,
        &:focus {
          border-color: ${colors.primary};
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
`;

const DatePickerB = ({
  label,
  name,
  onChange,
  type,
  margin,
  picker,
  padding,
  size,
  width,
  className,
  value,
  disabled,
  required = false,
  requiredMessage,
  defaultValue,
  initialValue,
  format = "MM/DD/YYYY",
  restProps,
  fieldKey,
  disabledDate,
}) => {
  return (
    <>
      <DatePickerContainer
        margin={margin}
        padding={padding}
        // className={className}
        // initialValue={initialValue}
        // defaultValue={defaultValue}
        // disabledDate={disabledDate}
      >
        <Form.Item
          disabledDate={disabledDate}
          disabled={disabled}
          format={format}
          label={<>{label}</>}
          {...restProps}
          name={name}
          fieldKey={fieldKey}
          initialValue={initialValue}
          rules={[
            {
              required: required,
              message: required && requiredMessage,
            },
          ]}
        >
          <DatePicker
            // picker={picker}
            name={name}
            disabled={disabled}
            value={value}
            size={size}
            onChange={(e) => onChange(e)}
            type={type}
            width={width}
            defaultValue={defaultValue}
            format={format}
            disabledDate={disabledDate}
          />
        </Form.Item>
      </DatePickerContainer>
    </>
  );
};

export default DatePickerB;
