import SideBar from "../components/sidebar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header/Header";
import { getUser } from "../auth/authAsyncActions";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROLES_ENUM } from "../auth/authSlice";
import NotFound from "../components/pages/notFound/NotFound";
import { closeSideNav } from "../redux/slices/appSlice";
import useMutate from "../api/hooks/useMutate";
import ORG_URL from "../api/urls/ORG_URL";

const ProtectedRoute = ({ rolesAllowed, children }) => {
  const { loading, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [getGlobalCodes] = useMutate({
    method: "get",
    path: ORG_URL.GET_GLOBAL_CATEGORIES,
  });
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const eventlink = query.get("eventlink");
  const id = query.get("id");

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      if (eventlink) {
        return navigate("/client-login", {
          state: {
            eventlink: true,
            route: `${window.location.pathname}?id=${id}`,
          },
        });
      }
      return navigate("/landing");
    } else {
      if (!user) {
        dispatch(getUser());
      }
    }

    getGlobalCodes({
      onCompleted: (data) => {
        localStorage.setItem("globalCodeCategories", JSON.stringify(data));
      },
    });
  }, []);

  if (
    user &&
    user?.roleName !== ROLES_ENUM.SUPERADMIN &&
    !rolesAllowed.includes(user?.roleName)
  ) {
    return <NotFound />;
  }

  return (
    <>
      {loading ? (
        <h1>Protected Loading</h1>
      ) : (
        <>
          <Header />
          <SideBar />

          <div onClick={() => dispatch(closeSideNav())}> {children} </div>
        </>
      )}
    </>
  );
};

export default ProtectedRoute;
