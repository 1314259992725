import { code } from "./COMMON_URL";

const DONOR_URLS = {
  SIGNUP: `/donor/add-update-donor`,
  LOGIN: `/Login`,
  ADD_SELECTED_FAMILY: "/campaign/add-campaign-selected-seekers",
  DONOR_LIST: "/donor/get-opted-in-seekers-list-by-campaign-list",
  GET_ALL_CAMPAIGNS: "/campaign/get-events-list",
  GET_DONOR_DETAILS: "/donor/get-donor-detail",
  SELECTED_FAMILY_LIST: "/donor/get-selected-seeker-by-donor",
  GETCITYFROMSEEKERS: "/donor/get-city",
  DELETE: "/donor/delete-donor",
  DONATIONSTATUS: "/organisation/update-donation-status",
  EXPORTDONORS: "/export-Campaigndonors-to-excel",
};

export default DONOR_URLS;
