import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const FamilyDetail = styled.div`
  border: 1px solid ${colors.background};
  padding: 20px 20px 20px;
  margin-top: 20px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  height: calc(100% - 25px);
  @media screen and (max-width: 767px) {
    margin-top: 25px;
  }
`;

export const MainHeading = styled.h3`
  position: absolute;
  top: -16px;
  background-color: ${colors.white};
  padding: 0 6px;
  left: 9px;
  font-size: 18px;
  color: ${colors.secondary};
  font-weight: 600;
  @media screen and (max-width: 567px) {
    font-size: 15px;
    top: -14px;
  }
`;

export const Info = styled.div`
  display: flex;
  padding: 7px 30px 0px 0;
  // align-items: center;
  @media screen and (max-width: 567px) {
    font-size: 13px;
    padding: 7px 0px 0px 0;
    flex-wrap: wrap;
  }
`;

export const Wish = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .ant-checkbox-wrapper {
    margin: 0;
  }
`;

export const Wishes = styled.li`
  // border: 1px solid ${colors.background};
  display: flex;
  align-items: center;

  .ant-checkbox-wrapper {
    padding: 10px;
    &:hover {
      border: none;
    }
  }
`;

export const WishContent = styled.ul`
  padding: 4px 0;
  width: 100%;
`;

export const Label = styled.div`
  font-weight: 600;
  min-width: 150px;
  padding-right: 10px;
  @media screen and (max-width: 767px) {
    min-width: 120px;
  }
  @media screen and (max-width: 500px) {
    min-width: 47%;
    white-space: nowwrap;
  }
`;
export const FamilyData = styled.div``;

export const WishInfo = styled.ul`
  display: flex;
  padding: 7px 10px 0px 0;
  margin: 0;
  @media screen and (max-width: 767px) {
    // flex-direction: column;
    // padding: 6px 0;
  }
`;

export const FamilyMemberDetail = styled.div`
  border: 1px solid ${colors.background};
  padding: 20px 20px 20px;
  margin-top: 30px;
  height: calc(100% - 30px);
  border-radius: 8px;
  position: relative;
  width: 100% !important;
  @media screen and (max-width: 1100px) {
    width: 100% !important;
  }
  @media screen and (max-width: 767px) {
    margin-top: 40px;
    height: calc(100% - 40px);
  }
  @media screen and (max-width: 567px) {
    margin-top: 20px;
    height: calc(100% - 20px);
  }
  h3 {
    color: ${colors.black};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  @media screen and (max-width: 767px) {
    // padding: 20px 0 0 0;
    margin-top: 10px;
  }
`;

export const DetailsWrapper = styled.div`
  // display: flex;
  // width: 100%;
  // flex-wrap: wrap;
  // gap: 20px;
`;
