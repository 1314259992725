import { Row, Col, Image, Form, Checkbox } from "antd";
import LogoImg from "../../../../assets/images/logo.png";
import Input from "../../../elements/input/Input";
import Button from "../../../elements/button/Button";
import {
  BottomForm,
  RightContainer,
  LayoutContainer,
  InnerContent,
  OuterWrapper,
  Heading,
  LeftContainer,
} from "../../admin/logIn/LoginStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { Customcheckbox } from "../../admin/logIn/LoginStyles";
import useMutate from "../../../../api/hooks/useMutate";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import { errorMessages } from "../../../../utils/errorMessages";
import Link from "../../../elements/links/Links";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { ROLES_INITIAL_ROUTES } from "../../../../hooks/useSetAppConfigs";
import { Logo } from "../../admin/signUp/SingupStlyes";
import CustomImage from "../../../image/LeftContainer";

function LogIn() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { shortOrg, user } = useSelector((state) => state.auth);
  const location = useLocation();
  const historyState = location.state;
  const [postSeekerLogin, { response, error, loading }] = useMutate({
    errorToast: true,
    path: SEEKER_URLS.LOGIN,
    onCompleted: () => {},
    onError: () => {},
  });

  if (user) {
    return navigate(ROLES_INITIAL_ROUTES[user.roleName]);
  }

  const onFinish = (data) => {
    const variables = {
      userName: data.famId,
      // password: data.password,
    };
    postSeekerLogin({
      variables,
      onCompleted: (response) => {
        localStorage.setItem("seekerToken", response.token);
        localStorage.removeItem("accessToken");

        if (historyState?.eventlink) {
          return navigate(`/verify-client?id=${data.famId}`, {
            state: { eventlink: true, ...historyState },
          });
        }

        navigate(`/verify-client?id=${data.famId}`);
      },
      onError: () => {},
    });
  };
  return (
    <>
      <LayoutContainer>
        <InnerContent style={{ maxWidth: "740px", minHeight: "310px" }}>
          <OuterWrapper>
            <Row>
              <Col lg={{ span: 8 }} span={24}>
                <CustomImage />
              </Col>
              <Col xl={{ span: 16 }} lg={{ span: 16 }} sm={{ span: 24 }}>
                <RightContainer>
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    form={form}
                    onFinish={onFinish}
                  >
                    <Heading>Client login</Heading>
                    <Row gutter={[8, 8]}>
                      <Col span={24}>
                        <Input
                          name="famId"
                          label="FAM #"
                          required={true}
                          requiredMessage={errorMessages.FAMID}
                        />
                      </Col>
                      <Col span={24}>
                        <Customcheckbox>
                          {/* <Checkbox>Remember me</Checkbox> */}
                        </Customcheckbox>
                      </Col>

                      <Col span={24}>
                        <BottomForm style={{ padding: "10px 0 0" }}>
                          <div>
                            {/* Don't have an account ? &nbsp;
                            <Link text="Register" to={"/register-client"} /> */}
                          </div>

                          <Button
                            text={"Verify Details"}
                            htmlType="submit"
                            loading={loading}
                          />
                        </BottomForm>
                      </Col>
                    </Row>
                  </Form>
                </RightContainer>
              </Col>
            </Row>
          </OuterWrapper>
        </InnerContent>
      </LayoutContainer>
    </>
  );
}

export default LogIn;
