export const AZURE_CODE = `${process.env.REACT_APP_API_AZURE_CODE}`;

const COMMON_URL = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  GET_USER: `/organisation/get-user-details`,
  CREATE_USER: `/users`,
  REGISTER: `/register`,
  FORGOTPASSWORD: `auth/forgot-password`,
  USER_LIST: `/unknown`,
  GET_GLOBAL_CODES: "/organisation/get-globalCodes",
};

export default COMMON_URL;
