import { code } from "./COMMON_URL";

const ORG_URL = {
  SIGNUP: `/organisation/create-organisation`,
  GET_SHORT_ORG: `/organisation/get-short-org`,
  GET_ORG: `/organisation/get-short-org `,
  DASHBOARD: `/organisation/organisation-dashboard`,
  LOGIN: `/Login`,
  ORG_LIST: `/organisation/get-all-organisations`,
  CHECKFORAVAILABLITYSUBDOMAIN: `/organisation/check-subdomain-availability`,
  DONORS: `/organisation/get-organisation-donor`,
  SEEKER: `/organisation/get-organisation-seeker`,
  EMPLOYEE: `/organisation/get-organisation-employee`,
  SUPERADMINDASHBOARD: `/organisation/SuperAdmin-dashboard`,
  CHANGEPASSWORD: `/auth/change-password`,
  RESETPASSWORD: `/auth/reset-password`,
  DELETEEMPLOYEE: `/organisation/delete-organisations`,
  UPDATEORGANIZATION: `/organisation/edit-organisations`,
  UPDATEPROFILE: `/user/update-user-admin`,
  UPDATE_SEEKER_APPROVAL: `/seeker/update-seeker-approval`,
  APPROVEMEMBERS: "/member/update-member-approval",
  ADMINDETAILS: "/organisation/get-admin-details",
  DONATION_LIST: "/organisation/get-donations-list",
  SENDMAILTODONOR: "/donor-campaign-join-reminder",
  EXPORTDONORS: "/export-donors-to-excel",
  EXPORTSEEKER: "/seeker/export-seekers-to-excel",
  SENDMAILTOSEEKER: "seeker-campaign-join-reminder",
  DONATIONSTATUS: "/organisation/update-donation-status",
  GET_GLOBAL_CATEGORIES: "/organisation/get-globalCode-categories",
};

export default ORG_URL;
