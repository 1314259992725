import { Col, Row } from "antd";
import useFetch from "../../../../api/hooks/useFetch";
import ORG_URL from "../../../../api/urls/ORG_URL";

import {
  Count,
  DashboardTile,
  DashboardTiles,
  Label,
} from "./SuperAdminDashboardStyle";
import { PageHeading } from "../../admin/campaigns/CampaignStyle";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import LoaderDashboard from "../../../loader/LoaderDashboard";

const SuperAdminDashboard = () => {
  const { response, loading } = useFetch({
    path: ORG_URL.SUPERADMINDASHBOARD,
    onCompleted: () => {},
    onError: () => {},
  });
  return (
    <>
      {!loading ? (
        <MainContent>
          <PageHeading> Dashboard</PageHeading>
          <DashboardTiles>
            <Row gutter={[16, 16]}>
              <Col span={24} sm={{ span: 12 }} lg={{ span: 6 }}>
                <DashboardTile>
                  <Label>Total Organisations</Label>
                  <Count>{response?.data?.organisationCount}</Count>
                </DashboardTile>
              </Col>
              <Col span={24} sm={{ span: 12 }} lg={{ span: 6 }}>
                <DashboardTile>
                  <Label>Total Events </Label>
                  <Count>{response?.data?.campaignCount}</Count>
                </DashboardTile>
              </Col>
              <Col span={24} sm={{ span: 12 }} lg={{ span: 6 }}>
                <DashboardTile>
                  <Label>Total Donors </Label>
                  <Count>{response?.data?.donorCount}</Count>
                </DashboardTile>
              </Col>
              <Col span={24} sm={{ span: 12 }} lg={{ span: 6 }}>
                <DashboardTile>
                  <Label>Total Clients </Label>
                  <Count>{response?.data?.seekerCount}</Count>
                </DashboardTile>
              </Col>
            </Row>
          </DashboardTiles>
        </MainContent>
      ) : (
        <LoaderDashboard />
      )}
    </>
  );
};
export default SuperAdminDashboard;
