import React, { useEffect, useState } from "react";
import Modal, { ModalList } from "../../modal/basemodal/BaseModal";
import {
  Banner,
  BannerContent,
  LeftContainer,
  Description,
  PageHeading,
  BottomButtons,
  Campaigns,
  SectionHeading,
  PaginationWrapper,
} from "./landingPageStyle";
import { Pagination } from "antd";
import Button from "../../elements/button/Button";
import { Row, Col } from "antd";
import Tiles from "../../elements/tiles/Tiles";
import { useNavigate } from "react-router-dom";
import Footer from "../../footer/Footer";
import CAMPAIGN_URL from "../../../api/urls/CAMPAIGN_URL";
import useMutate from "../../../api/hooks/useMutate";
import { formatDate } from "../../../utils/dateTime";
import Loader from "../../loader/Loader";
import { PublicHeader } from "../../publicHeader/PublicHeader";
import moment from "moment";

let pagination = "";

const LandingPageDonor = () => {
  const navigate = useNavigate();
  const [openViewCampaignsModal, setOpenViewCampaignsModal] = useState(false);
  const [isDataToShare, setIsDataToShare] = useState();
  const handleChange = (value) => {
    setPage(value);
  };
  // const [toggle, setToggle] = useState(false);

  const [page, setPage] = useState(1);
  const [getCampaignsList, { response, loading }] = useMutate({
    path: CAMPAIGN_URL.GET_CAMPAIGN_LIST_LANDING,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const param = {
    pageNo: page,
    PageSize: 6,
    searchValue: "",
    sortColumn: "",
    sortOrder: "",
  };
  useEffect(() => {
    getCampaignsList({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [page]);

  pagination = response?.data?.pagination;

  return (
    <>
      {/* {!loading ? ( */}
      <>
        <PublicHeader />
        <Banner>
          <BannerContent>
            <LeftContainer>
              <PageHeading>Welcome</PageHeading>
              <Description>
                This holiday season FAM is offering clients the opportunity to
                request Christmas gifts for children under 18 years old. To
                register please Login
              </Description>
              <BottomButtons>
                <Button
                  // className={"secondary"}
                  text={"Login as FAM Client"}
                  onClick={() => navigate("/client-login")}
                />
                {/* <Button
                  text={"Login as Donor"}
                  className="secondary"
                  onClick={() => navigate("/donor-login")}
                /> */}
              </BottomButtons>
            </LeftContainer>
          </BannerContent>
        </Banner>
        {response?.data?.records?.filter(
          (record) =>
            record?.enrollmentPeriodEndDate < moment(new Date()).unix()
        ) && (
          <Campaigns>
            <SectionHeading>Running Events</SectionHeading>
            <Row gutter={20}>
              <Col span={24}>
                <div style={{ minHeight: "440px" }}>
                  <Row gutter={20}>
                    <>
                      {!loading ? (
                        response?.data?.records?.map((item) => {
                          return (
                            <Col
                              xxl={{ span: 6 }}
                              xl={{ span: 8 }}
                              sm={{ span: 12 }}
                              span={24}
                            >
                              <Tiles
                                title={item?.campaignName}
                                imageFile={item?.imageFile}
                                cardDescription={item?.description}
                                address={item?.pickupAddress}
                                loading={loading}
                                enrollmentPeriod={`${formatDate(
                                  item?.enrollmentPeriodStartDate,
                                  "MM/DD/YYYY"
                                )} to ${formatDate(
                                  item?.enrollmentPeriodEndDate,
                                  "MM/DD/YYYY"
                                )}`}
                                campaignId={item?.campaignId}
                                onClick={() => {
                                  setOpenViewCampaignsModal(true);
                                  setIsDataToShare(item);
                                }}
                              />
                            </Col>
                          );
                        })
                      ) : (
                        <Loader />
                      )}
                    </>
                  </Row>
                </div>
              </Col>
              <Col span={24}>
                <PaginationWrapper>
                  <Pagination
                    defaultCurrent={1}
                    pageSize={6}
                    total={pagination?.totalRecordsCount}
                    onChange={handleChange}
                    current={page}
                    showSizeChanger={false}
                    hideOnSinglePage={true}
                  />
                </PaginationWrapper>
              </Col>
            </Row>
          </Campaigns>
        )}
        {/* <Contact>
          <ContactHeading>contact us</ContactHeading>
          <ConatctForm>
            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete="off"
            >
              <FormFields>
                <Row>
                  <Col span={24}>
                    <Input label="Name" name="Name" />
                  </Col>
                  <Col span={24}>
                    <Input label="Email" name="email" />
                  </Col>
                  <Col span={24}>
                    <Input label="Phone number" name="PhoneNumber" />
                  </Col>
                  <Col span={24}>
                    <TextAreaB label="Your Message" name="YourMessage" />
                  </Col>
                  <Col span={24}>
                    <BottomForm>
                      <Button text={" Send Message"} />
                    </BottomForm>
                  </Col>
                </Row>
              </FormFields>
            </Form>
          </ConatctForm>
        </Contact> */}
        {/* <Newslettercontainer>
          <Newsletterwrapper>
            <Newsheading>Sign up for newsletter</Newsheading>
            <Newstext>We'll give you just the right amount of love!</Newstext>
            <Newstext>
              Sign up for our weekly update and be the first to know about our
              specials and promotions.
            </Newstext>
            <Newspara>No spam, We promise</Newspara>
            <Newspara>Email Address</Newspara>
            <Suscriberwrapper>
              <Input placeholder="Email address" />
              <Button text={" Subscribe"} />
            </Suscriberwrapper>
          </Newsletterwrapper>
        </Newslettercontainer> */}
        <Footer />
        <Modal
          title="View Event"
          open={openViewCampaignsModal}
          setOpenModal={setOpenViewCampaignsModal}
          modalType={ModalList.LandingViewEventModal}
          data={isDataToShare}
        />
      </>
      {/* ) : (
        <Loader />
      )} */}
    </>
  );
};

export default LandingPageDonor;
