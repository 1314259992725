import React, { useState } from "react";
import { Table } from "antd";
import { ActionIcon } from "./DonorsStyle";
import { EyeOutlined } from "@ant-design/icons";
import { PageHeading } from "../../admin/campaigns/CampaignStyle";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import { ActionIconWrapper } from "../../admin/donors/DonorsStyle";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { COLUMN_WIDTH } from "../../admin/seekers/viewclient/Viewclient";

const DonorsList = () => {
  // const navigate = useNavigate();
  const [openViewDonorModal, setOpenViewDonorModal] = useState(false);

  const dataSource = [
    {
      id: "JSAC8873R",
      name: "William",
      lastName: "Johnson",
      email: "test@gmail.com",
      lastLogin: "01/24/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "Henry",
      lastName: "Jones",
      email: "test@gmail.com",
      lastLogin: "03/20/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "Olivia",
      lastName: "Miller",
      email: "test@gmail.com",
      lastLogin: "05/30/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "James",
      lastName: "Jones",
      email: "test@gmail.com",
      lastLogin: "02/04/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "Sophia",
      lastName: "Wilson",
      email: "test@gmail.com",
      lastLogin: "10/11/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "Lucas",
      lastName: "Davis",
      email: "test@gmail.com",
      lastLogin: "06/05/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "William",
      lastName: "Brown",
      email: "test@gmail.com",
      lastLogin: "11/24/2022",
      caontactNo: "(111)-111-1111",
    },

    {
      id: "JSAC8873R",
      name: "Henry",
      lastName: "Martin",
      email: "test@gmail.com",
      lastLogin: "12/20/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "Olivia",
      lastName: "Wilson",
      email: "test@gmail.com",
      lastLogin: "09/02/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "James",
      lastName: "Brown",
      email: "test@gmail.com",
      lastLogin: "03/29/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "Sophia",
      lastName: "Martin",
      email: "test@gmail.com",
      lastLogin: "10/21/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "Lucas",
      lastName: "Thomas",
      email: "test@gmail.com",
      lastLogin: "11/24/2022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "William",
      lastName: "Anderson",
      email: "test@gmail.com",
      lastLogin: "11/242022",
      caontactNo: "(111)-111-1111",
    },
    {
      id: "JSAC8873R",
      name: "Henry",
      lastName: "Martin",
      email: "test@gmail.com",
      lastLogin: "11/24/2022",
      caontactNo: "(111)-111-1111",
    },
  ];
  const columns = [
    {
      title: "Id#",
      dataIndex: "id",
      sorter: {
        compare: (a, b) => {
          if (a.id < b.id) return -1;
          if (b.id < a.id) return 1;

          return 0;
        },
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Name",
      dataIndex: "name",

      sorter: {
        compare: (a, b) => {
          if (a.name < b.name) return -1;
          if (b.name < a.name) return 1;

          return 0;
        },
      },
      defaultSortOrder: "ascend",
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email - b.email,
      },
    },
    {
      title: "Phone Number ",
      dataIndex: "caontactNo",
      sorter: {
        compare: (a, b) => a.email - b.email,
      },
    },
    // {
    //   title: "Last Login",
    //   dataIndex: "lastLogin",
    //   sorter: {
    //     compare: (a, b) => a.lastLogin - b.lastLogin,
    //   },
    // },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   sorter: {
    //     compare: (a, b) => a.status - b.status,
    //   },
    //   render: (_, text) => <Switch {...text} />,
    // },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIconWrapper>
          <ActionIcon
            title="View"
            onClick={() => {
              setOpenViewDonorModal(true);
            }}
          >
            <EyeOutlined />
          </ActionIcon>
        </ActionIconWrapper>
      ),
    },
  ];
  return (
    <>
      <MainContent>
        <PageHeading>Donors</PageHeading>
        <Table
          dataSource={dataSource}
          columns={columns}
          sortDirections={["ascend", "descend", "ascend"]}
          showSorterTooltip={false}
          scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
          pagination={{
            pageSize: 100,
            hideOnSinglePage: true,
          }}
        />
      </MainContent>
      <Modal
        title="View Donor"
        open={openViewDonorModal}
        setOpenModal={setOpenViewDonorModal}
        modalType={ModalList.ViewDonorModal}
      />
    </>
  );
};

export default DonorsList;
