import "./App.css";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Routes from "./routing/Routes";
import GlobalStyle from "./styles/GlobalStyles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getShortOrg, getUser } from "./auth/authAsyncActions";
import Loader from "./components/loader/Loader";
import { ROLES_INITIAL_ROUTES } from "./hooks/useSetAppConfigs";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    const pathname = window.location.pathname;
    const subDomain = "app";

    // const subDomain = window.location.host?.split(".")[0];
    // if (subDomain) {
    //   dispatch(getShortOrg(subDomain));
    // }

    const existingSubDomain = localStorage.getItem("subDomainData");
    if (existingSubDomain) {
      dispatch(getShortOrg(existingSubDomain));
    } else if (subDomain) {
      dispatch(getShortOrg(subDomain));
    }
    if (!token) {
      if (pathname === "/") {
        return navigate("/landing");
      }
    } else {
      dispatch(getUser()).then((data) => {
        if (pathname === "/") {
          navigate(ROLES_INITIAL_ROUTES[data.payload.data.roleName]);
        }
      });
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      {loading ? <Loader /> : <Routes />}
      <Toaster
        toastOptions={{
          duration: 3000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "14px",
          },
        }}
        position="top-right"
      />
    </>
  );
}

export default App;
