import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
// import styled from "styled-components";
import { ButtonContainer } from "./NewSeekerModal";
import Button from "../elements/button/Button";
import { Container } from "./EditCampaignModal";
import { formatPhoneNumber } from "../../utils/phoneNumber";

const Label = styled.div`
  font-weight: 600;
  min-width: 140px;
  padding-right: 10px;
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  padding: 7px 30px 7px 0;
`;

export const ViewDonorModal = ({ close, data }) => {
  return (
    <Container>
      <Row>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Name :</Label> {data?.firstName}
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Phone Number :</Label>
            {formatPhoneNumber(data?.phoneNo)}
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Email Address :</Label>
            {data?.email}
          </Info>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Info>
            <Label>Street Address :</Label> {data?.address1}
          </Info>
        </Col>
      </Row>

      <ButtonContainer noBorder>
        <Button
          text="Cancel"
          className={"SecondaryBtn"}
          onClick={() => close()}
        />
      </ButtonContainer>
    </Container>
  );
};

export default ViewDonorModal;
