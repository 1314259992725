import React, { useEffect, useState } from "react";
import { Dropdown, Input, Menu, Space, Table } from "antd";
import { ActionIcon } from "./DonorsStyle";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  LockOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { PageHeading, SearchContainer } from "../campaigns/CampaignStyle";
import { useNavigate } from "react-router-dom";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import ORG_URL from "../../../../api/urls/ORG_URL";
import useMutate from "../../../../api/hooks/useMutate";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { Moreicon } from "../seekers/SeekersStyle";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import { Checkbox } from "antd";
import { ButtonContainer } from "../../donor/familyDetails/FamilyDetailsStyle";
import Button from "../../../elements/button/Button";
import Search from "antd/lib/input/Search";
import { capitalFirstLetter } from "../../../../utils/dateTime";
import { formatPhoneNumber } from "../../../../utils/phoneNumber";
import { debounce } from "../../../../utils/Debounce";
import { COLUMN_WIDTH } from "../seekers/viewclient/Viewclient";
import downloadFile from "../../../../utils/downloadFile";

let responseData = "";
let pagination = "";
const Donors = () => {
  const navigate = useNavigate();
  const [actionData, setActionData] = useState();
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [openConfirmDeleteDonor, setOpenConfirmDeleteDonor] = useState(false);
  const [getDonors, { response, loading }] = useMutate({
    path: ORG_URL.DONORS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [exportDonors, { loading: exportDonorLoader }] = useMutate({
    path: ORG_URL.EXPORTDONORS,
    // successToast: true,
    errorToast: true,
    method: "post",
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const [page, setPage] = useState(1);
  const [sortTable, setSortTable] = useState({
    column: "userId",
    order: "desc",
  });
  const [openEditDonorModal, setOpenEditDonorModal] = useState(false);
  const [openViewDonorModal, setOpenViewDonorModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const ConfirmDeleteDonor = (record) => {
    setOpenConfirmDeleteDonor(true);
  };

  const [deleteDonors] = useMutate({
    path: DONOR_URLS.DELETE,
    successToast: true,
    method: "delete",
    onCompleted: () => {},
    onError: () => {},
  });

  const deleteDonor = () => {
    const params = {
      userId: actionData.userId,
    };

    deleteDonors({
      params: params,
      onCompleted: () => {
        if (responseData.length === 1) {
          let currentPage = page;
          setPage(currentPage - 1);
        } else {
          setIsSuccess(true);
        }
        setOpenConfirmDeleteDonor(false);
      },
      onError: () => {},
    });
  };

  const EditDonor = () => {
    setOpenEditDonorModal(true);
  };
  const resetPassword = () => {
    setOpenResetPasswordModal(true);
  };
  const param = {
    PageNo: page || 1,
    PageSize: 100,
    SearchValue: searchValue,
    SortColumn: sortTable.column,
    SortOrder: sortTable.order,
  };
  useEffect(() => {
    getDonors({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
    setIsSuccess(false);
  }, [page, sortTable, isSuccess, searchValue]);

  responseData = response?.data?.records;
  pagination = response?.data?.pagination;

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      render: (text, record) => (
        <a onClick={() => navigate(`/view-donor-details?id=${record?.userId}`)}>
          {`${capitalFirstLetter(
            record?.firstName
          )} ${" "} ${capitalFirstLetter(record.lastName)}`}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Phone Number ",
      dataIndex: "phoneNo",
      sorter: true,
      render: (phoneNumber) => formatPhoneNumber(phoneNumber),
    },

    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            onClick={(e) => {
              e.preventDefault();
              setActionData(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  const ActionMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <ActionIcon
              title="View"
              onClick={() =>
                navigate(`/view-donor-details?id=${actionData?.userId}`)
              }
            >
              <EyeOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "2",
          label: (
            <ActionIcon title="Edit" onClick={() => EditDonor()}>
              <EditOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "3",
          label: (
            <ActionIcon title="Delete" onClick={() => ConfirmDeleteDonor()}>
              <DeleteOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "4",
          label: (
            <ActionIcon title="Reset Password" onClick={() => resetPassword()}>
              <LockOutlined />
            </ActionIcon>
          ),
        },
      ]}
    />
  );

  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const search = debounce(handleSearch);
  const exportAllDonors = () => {
    exportDonors({
      onCompleted: (response) => {
        downloadFile(response);
      },
      onError: () => {},
    });
  };
  return (
    <>
      <MainContent>
        <PageHeading>
          Donors
          <SearchContainer>
            <Input
              placeholder="Search by Name"
              onChange={search}
              disabled={loading}
              // style={{
              //   width: "auto",
              // }}
            />
            <Button
              text="Export Donors List"
              onClick={exportAllDonors}
              loading={exportDonorLoader}
            />
          </SearchContainer>
        </PageHeading>
        <Table
          dataSource={responseData}
          loading={loading}
          sortDirections={["ascend", "descend", "ascend"]}
          columns={columns}
          onChange={handleChange}
          showSorterTooltip={false}
          scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
          pagination={{
            current: page,
            pageSize: 100,
            defaultCurrent: page,
            total: pagination?.totalRecordsCount,
            hideOnSinglePage: true,
          }}
        />
      </MainContent>

      <Modal
        title="Reset Password"
        open={openResetPasswordModal}
        setOpenModal={setOpenResetPasswordModal}
        modalType={ModalList.ResetPasswordModal}
        id={actionData?.userId}
        setIsSuccess={setIsSuccess}
      />

      <Modal
        title="Delete?"
        open={openConfirmDeleteDonor}
        setOpenModal={setOpenConfirmDeleteDonor}
        modalType={ModalList.ConfirmDeleteDonor}
        onConfirm={deleteDonor}
        setIsSuccess={setIsSuccess}
      />
      <Modal
        title="View Donor"
        open={openViewDonorModal}
        data={actionData}
        setOpenModal={setOpenViewDonorModal}
        modalType={ModalList.ViewDonorModal}
      />

      <Modal
        title="Edit Donor"
        open={openEditDonorModal}
        data={actionData}
        setOpenModal={setOpenEditDonorModal}
        modalType={ModalList.EditDonorModal}
        setIsSuccess={setIsSuccess}
      />
    </>
  );
};

export default Donors;
