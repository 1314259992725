export const SUPERADMIN = [
  {
    name: "Dashboard",
    path: "/superadmin-dashboard",
    active: ["/superadmin-dashboard"],
  },
  {
    name: "Organisations",
    path: "/organisations",
    active: ["/organisations"],
  },
];

export const ADMIN = [
  {
    name: "Dashboard",
    path: "/admin-dashboard",
    active: ["/admin-dashboard"],
  },
  {
    name: "Events",
    path: "/events",
    active: ["/events", "/view-event"],
  },
  {
    name: "Clients",
    path: "/clients",
    active: [
      "/clients",
      "/client-profile",
      "/view-client-detail",
      "/add-family-details",
      "/client-donation-details",
      "/client-donation-details-admin",
    ],
  },
  {
    name: "Donors",
    path: "/donors",
    active: [
      "/donors",
      "/view-donor-details",
      "/family-wishesinfo",
      "/family-list",
      "/donor-profile",
      "/family-details",
    ],
  },
  {
    name: "Employees",
    path: "/employees",
    active: ["/employees"],
  },
  {
    name: "Donations",
    path: "/donations",
    active: ["/donations", "/donation-details"],
  },
  {
    name: "Settings",
    path: "/admin-profile",
    active: ["/admin-profile"],
  },
];

export const EMPLOYEE = [
  {
    name: "Events",
    path: "/events",
    active: ["/events", "/view-event"],
  },
  {
    name: "Clients",
    path: "/clients",
    active: [
      "/clients",
      "/client-profile",
      "/view-client-detail",
      "/add-family-details",
      "/client-donation-details",
      "/client-donation-details-admin",
    ],
  },
  {
    name: "Donors",
    path: "/donors",
    active: [
      "/donors",
      "/view-donor-details",
      "/family-wishesinfo",
      "/donor-profile",
      "/family-details",
    ],
  },
  {
    name: "Donations",
    path: "/donations",
    active: ["/donations", "/donation-details"],
  },
  {
    name: "Profile",
    path: "/emp-profile",
    active: ["/emp-profile"],
  },
];

export const SEEKER = [
  {
    name: "Family Details",
    path: "/client-profile",
    active: ["/client-profile"],
  },
  {
    name: "Events",
    path: "/client-events",
    active: ["/client-events", "/add-family-details"],
  },
  {
    name: "Activity History",
    path: "/received-donation",
    active: [
      "/received-donation",
      "/view-family-details",
      "/client-donation-details",
    ],
  },
];

export const DONOR = [
  {
    name: "Events",
    path: "/all-events",
    active: ["/all-events", "/family-list", "/family-details"],
  },
  {
    name: "Selected Families",
    path: "/selected-families",
    active: ["/selected-families", "/family-wishesinfo"],
  },
  {
    name: "Profile",
    path: "/donor-profile",
    active: ["/donor-profile"],
  },
];
