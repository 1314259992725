import { Row, Col, Image, Form, Input as AntInput } from "antd";
import Button from "../../../elements/button/Button";
import Input from "../../../elements/input/Input";
// import LogoImg from "../../../../assets/images/logo.png";
import {
  // LeftContainer,
  LayoutContainer,
  InnerContent,
  OuterWrapper,
  Heading,
  RightContainer,
} from "../ForgotPasswordStyles";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import { errorMessages } from "../../../../utils/errorMessages";
import { LeftContainer } from "../../admin/logIn/LoginStyles";
import { Logo } from "../../admin/signUp/SingupStlyes";
import { useSelector } from "react-redux";

const BottomForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 0 10px;
  align-items: center;
  justify-content: end;
  button {
    margin: initial !important;
  }
`;

function Resetpassword() {
  // const navigate = useNavigate();
  const { shortOrg } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("Token");
  const onSubmit = (values) => {
    const variables = {
      password: values.newPassword,
      token: token,
    };
  };

  return (
    <>
      <LayoutContainer>
        <InnerContent style={{ width: "730px", minHeight: "360px" }}>
          <OuterWrapper>
            <Row>
              <Col xl={{ span: 8 }} sm={{ span: 24 }}>
                <LeftContainer>
                  <Logo>
                    <Image
                      width={200}
                      preview={false}
                      src={shortOrg?.posterImage}
                    ></Image>
                  </Logo>
                </LeftContainer>
              </Col>
              <Col xl={{ span: 16 }} sm={{ span: 24 }}>
                <RightContainer>
                  <Form
                    name="basic"
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    onFinish={onSubmit}
                  >
                    <Heading>Reset Password</Heading>
                    <Row>
                      <Col span={24}>
                        <Input
                          type="password"
                          label="Password"
                          name="newPassword"
                          required={true}
                          pattern={regExpPattern.PASSWORD}
                          regExpMessage={regErrorMessages.PASSWORD}
                          requiredMessage={errorMessages.PASSWORD}
                        />
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="confirmPassword"
                          label="Confirm Password"
                          dependencies={["newPassword"]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("newPassword") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Password does not match !")
                                );
                              },
                            }),
                          ]}
                        >
                          <AntInput.Password />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <BottomForm>
                          {/* <div>
                            <div>
                              Back to &nbsp;
                              <a onClick={() => navigate("/login")}>LogIn</a>
                            </div>
                          </div> */}
                          <Button text={"Submit"} />
                        </BottomForm>
                      </Col>
                    </Row>
                  </Form>
                </RightContainer>
              </Col>
            </Row>
          </OuterWrapper>
        </InnerContent>
      </LayoutContainer>
    </>
  );
}

export default Resetpassword;
