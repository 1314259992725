import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const LabelCard = styled.div`
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  width: 320px;
`;
export const LabelCard1 = styled.div`
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  width: 650px;
  height: max-content;
`;
export const LabelCardHeader = styled.div`
  font-weight: 600;
  padding: 10px 20px;
  border-bottom: 1px solid ${colors.background};
`;

export const Label = styled.div`
  font-weight: 600;
  min-width: 110px;
  padding-right: 10px;
  @media screen and (max-width: 567px) {
    font-weight: 600;
    min-width: 90px;
    padding-right: 10px;
  }
`;

export const Info = styled.div`
  display: flex;
  padding: 7px 0;
  @media screen and (max-width: 567px) {
    font-size: 13px;
  }
`;

export const Lableinfo = styled.div`
  padding: 10px 20px;
`;

export const LabelCardSamples = styled.div`
  display: flex;
  gap: 20px;
`;

export const LabelCardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
