import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled from "styled-components";
import colors from "../../styles/Colors";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const LoaderContainer = styled.div`
  .ant-spin {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #00000011;
    box-shadow: 0 0 10px #00000011;
    svg {
      font-size: 40px;
    }
  }
`;
const LoaderWrapper = styled.div``;

const Loader = () => {
  return (
    <>
      <LoaderContainer>
        <LoaderWrapper>
          <Spin indicator={antIcon} />
        </LoaderWrapper>
      </LoaderContainer>
    </>
  );
};

export default Loader;
