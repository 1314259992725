/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Dropdown, Space, Menu, Input } from "antd";
import { Tabs } from "antd";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { ActionIcon } from "../donors/DonorsStyle";
import { PageHeading, SearchContainer } from "../campaigns/CampaignStyle";
import Button from "../../../elements/button/Button";
import { useNavigate } from "react-router-dom";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import ORG_URL from "../../../../api/urls/ORG_URL";
import useMutate from "../../../../api/hooks/useMutate";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { Moreicon } from "./SeekersStyle";
import AllMemberDetails from "./AllMemberDetails";
import ApprovedMembersList from "./ApprovedMembersList";
import InActiveMembers from "./InActiveMembers";
import Link from "../../../elements/links/Links";
import AddNewMemberList from "./AddNewMemberList";
import NewDependentMembers from "./NewDependentMembers";
import { debounce } from "../../../../utils/Debounce";
import downloadFile from "../../../../utils/downloadFile";

let pagination = "";
const Seekers = () => {
  const navigate = useNavigate();
  const [isClientEdit, setIsClientEdit] = useState(false);
  const [ActionData, setActionData] = useState();
  const [openNewSeekerModal, setOpenNewSeekerModal] = useState(false);
  const [openEditSeekerModal, setOpenEditSeekerModal] = useState(false);
  const [data, setData] = useState();
  const [filter, setFilter] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openViewSeekerModal, setOpenViewSeekerModal] = useState(false);
  const [page, setPage] = useState(1);

  const [sortTable, setSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [exportClients, { loading: exportClientLoader }] = useMutate({
    path: ORG_URL.EXPORTSEEKER,
    // successToast: true,
    errorToast: true,
    method: "post",
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const [getSeekerlist, { response, loading }] = useMutate({
    path: ORG_URL.SEEKER,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  pagination = response?.data?.pagination;

  const editSeeker = () => {
    setIsClientEdit(true);
    setOpenEditSeekerModal(true);
  };

  const addNewMember = () => {
    setIsClientEdit(false);
    setOpenNewSeekerModal(true);
  };

  const getStatusText = (str) => {
    const Status = {
      Active: "Active",
      Pending: "Pending",
      inActive: "Inactive",
      newMemberRequest: "New Member Request",
      newDependent: "New Dependent",
    };

    return Status[str];
  };

  // const ConfirmDeleteSeeker = () => {
  //   setOpenConfirmDeleteSeeker(true);
  // };

  // const resetPassword = () => {
  //   setOpenResetPasswordModal(true);
  // };

  useEffect(() => {
    const param = {
      PageNo: page || 1,
      PageSize: 100,
      SearchValue: searchValue,
      SortColumn: sortTable?.column,
      SortOrder: sortTable?.order,
      filter: filter,
    };
    getSeekerlist({
      params: param,
      onCompleted: (resposiveData) => {
        setData(resposiveData?.records);
      },
      onError: () => {},
    });
    setIsSuccess(false);
  }, [page, sortTable, isSuccess, filter, searchValue]);
  const handleChange = (pagination, filters, sorter) => {
    if (loading) {
      return;
    }
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter?.field,
        order: sorter?.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };

  const columns = [
    {
      title: "FAM #",
      dataIndex: "userName",
      sorter: true,
      columnWidth: "auto",
      render: (text, record) => (
        <>
          {text ? (
            <Link text={text} to={`/view-client-detail?id=${record?.userId}`} />
          ) : (
            "Not Assigned"
          )}
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      columnWidth: "auto",
      render: (_, record) => `${record?.firstName} ${record.lastName}`,
    },
    {
      title: "Request Status",
      dataIndex: "requestStatus",
      columnWidth: "auto",
      render: (_, record) => `${getStatusText(record?.requestStatus)}`,
      sorter: false,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
      columnWidth: "auto",
    },
    {
      title: "Actions",
      dataIndex: "action",
      columnWidth: "auto",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            onClick={(e) => {
              e.preventDefault();
              setActionData(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  const columns1 = [
    {
      title: "FAM #",
      dataIndex: "userName",
      sorter: true,
      render: (text, record) => (
        <>
          {text ? (
            <Link text={text} to={`/view-client-detail?id=${record?.userId}`} />
          ) : (
            "Not Assigned"
          )}
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      render: (_, record) => `${record?.firstName} ${record.lastName}`,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setActionData(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      render: (_, record) => `${record?.firstName} ${record.lastName}`,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setActionData(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  const ActionMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <ActionIcon
              title="View"
              onClick={() => {
                navigate(`/view-client-detail?id=${ActionData.userId}`);
              }}
            >
              <EyeOutlined />
            </ActionIcon>
          ),
        },
        // {
        //   key: "2",
        //   label: (
        //     <ActionIcon title="Edit" onClick={() => editSeeker()}>
        //       <EditOutlined />
        //     </ActionIcon>
        //   ),
        // },
        // {
        //   key: "3",
        //   label: (
        //     <ActionIcon title="Delete" onClick={() => ConfirmDeleteSeeker()}>
        //       <DeleteOutlined />
        //     </ActionIcon>
        //   ),
        //   // disabled: true,
        // },
      ]}
    />
  );

  const handleTabChange = (value) => {
    if (value === "1") {
      setFilter("All");
    } else if (value === "2") {
      setFilter("Active");
    } else if (value === "3") {
      setFilter("Pending");
    } else if (value === "4") {
      setFilter("inActive");
    } else if (value === "5") {
      setFilter("newMemberRequest");
    } else if (value === "6") {
      setFilter("newDependent");
    }
    setPage(1);
    setSortTable({
      column: "CreatedDate",
      order: "desc",
    });
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const search = debounce(handleSearch);
  const exportAllClients = () => {
    exportClients({
      onCompleted: (response) => {
        downloadFile(response);
      },
      onError: () => {},
    });
  };
  return (
    <>
      <MainContent>
        <PageHeading>
          Clients
          <SearchContainer>
            <Input
              placeholder="Search by Name or Id"
              onChange={search}
              // style={{
              //   width: "auto",
              // }}
            />
            <Button
              text="Export Clients List"
              onClick={exportAllClients}
              loading={exportClientLoader}
            />
            <Button text={"Add New Client"} onClick={addNewMember} />
          </SearchContainer>
        </PageHeading>
        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
          <Tabs.TabPane tab="All" key="1">
            <AllMemberDetails
              dataSource={data || []}
              loading={loading}
              page={page}
              columns={columns}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <>
                {"Active"}
                {/* <div className="badge">5</div> */}
              </>
            }
            key="2"
          >
            <ApprovedMembersList
              dataSource={data || []}
              loading={loading}
              page={page}
              columns={columns1}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <>
                {"Pending"}
                {/* <div className="badge">3</div> */}
              </>
            }
            key="3"
          >
            <ApprovedMembersList
              dataSource={data || []}
              loading={loading}
              page={page}
              columns={columns1}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <>
                {"Inactive"}
                {/* <div className="badge">2</div> */}
              </>
            }
            key="4"
          >
            <InActiveMembers
              dataSource={data || []}
              loading={loading}
              page={page}
              columns={columns1}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <>
                {"New Member Request"}
                {/* <div className="badge">2</div> */}
              </>
            }
            key="5"
          >
            <AddNewMemberList
              dataSource={data || []}
              loading={loading}
              page={page}
              columns={columns2}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <>
                {"New Dependent"}
                {/* <div className="badge">2</div> */}
              </>
            }
            key="6"
          >
            <NewDependentMembers
              dataSource={data || []}
              loading={loading}
              page={page}
              columns={columns1}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
      <Modal
        title={!isClientEdit ? "Add New Client" : "Edit Clients"}
        open={isClientEdit ? openEditSeekerModal : openNewSeekerModal}
        setOpenModal={
          isClientEdit ? setOpenEditSeekerModal : setOpenNewSeekerModal
        }
        modalType={ModalList.AddNewSeekerModal}
        data={isClientEdit ? ActionData : ""}
        setIsSuccess={setIsSuccess}
      />
      <Modal
        title="View Clients"
        open={openViewSeekerModal}
        setOpenModal={setOpenViewSeekerModal}
        modalType={ModalList.ViewSeekerModal}
      />

      {/* <Modal
        title="Reset Password"
        open={openResetPasswordModal}
        setOpenModal={setOpenResetPasswordModal}
        modalType={ModalList.ResetPasswordModal}
      />

      <Modal
        title="Delete?"
        open={openConfirmDeleteSeeker}
        setOpenModal={setOpenConfirmDeleteSeeker}
        modalType={ModalList.ConfirmDeleteSeeker}
      /> */}
    </>
  );
};

export default Seekers;
