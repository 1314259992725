import React, { useState } from "react";
import {
  CampaignImage,
  MainContent,
} from "../../donor/familyList/FamilyListStyle";
import { PageHeading } from "../../admin/campaigns/CampaignStyle";
import {
  CampaignsDescription,
  Heading,
  Label,
} from "../../employee/donationDetails/DonationDetailsStyles";
import { Col, Row } from "antd";
// import CampaignImage from "../../../../assets/images/Donate-gifts.jpeg";
import Button from "../../../elements/button/Button";
import styled from "styled-components";
import colors from "../../../../styles/Colors";
import {
  DetailsWrapper,
  FamilyDetail,
  FamilyMemberDetail,
  Info,
  MainHeading,
  WishInfo,
} from "../../donor/familyDetails/FamilyDetailsStyle";
import { useLocation, useNavigate } from "react-router-dom";
import useMutate from "../../../../api/hooks/useMutate";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";
import { useEffect } from "react";
import Loader from "../../../loader/Loader";
import {
  capitalFirstLetter,
  formatDate,
  getAgeFromTs,
} from "../../../../utils/dateTime";
import { ButtonContainer } from "../addFamilyDetails/addFamilyDetailsStyle";
import { ApprovedHeading, PendingHeading } from "../profile/Profile";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Spacer } from "../../admin/donors/ViewDonordetails";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";

export const Campaignsdetails = styled.div`
  padding: 20px;
  border: 1px solid ${colors.grey5};
  border-radius: 10px;
  margin-top: 20px;
`;
export const Detailslist = styled.div`
  margin: 40px 0 0 0;
  @media screen and (max-width: 475px) {
    .ant-form-item {
      margin: 0 0 8px 10px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
`;
export const MainHeadingcol = styled.h3`
  position: absolute;
  top: -16px;
  background-color: ${colors.white};
  padding: 0 6px;
  left: 9px;
  font-size: 18px;
  color: ${colors.primary};
  font-weight: 600;
  @media screen and (max-width: 575px) {
    font-size: 15px;
    top: -14px;
  }
`;

export const FlexSort = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  // align-items: center;
  margin: -5px 0 15px 0;
  flex-wrap: wrap;
  @media screen and (max-width: 1300px) {
    flex-direction: column;
    // align-items: end;
  }
  .dWMuty {
    text-align: left;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;
export const CustomStatus = styled.div`
  display: flex;
  // align-items: center;
  gap: 10px;
  .bjltJs {
    text-align: left;
  }
`;

export const ButtonRight = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: calc(100% + 40px);
  margin: 10px 0 -10px;
  border-top: 1px solid #a9a9a9;
  padding: 10px 20px 0;
  position: relative;
  left: -20px;
  @media screen and (max-width: 475px) {
    flex-direction: column;
    align-items: end;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AdminWishesApproval = () => {
  const query = useQuery();
  const id = query.get("id");
  const seekerId = query.get("seekerId");
  const admin = query.get("admin");
  const navigate = useNavigate();
  const [declineStatus, setdeclineStatus] = useState({
    memberId: "",
    isHouseHold: "",
  });
  const [editDetails, setEditDetails] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [getClientFamilyWishes, { response, loading }] = useMutate({
    path: CAMPAIGN_URL.GET_DONATION_DETAILS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [postAllMembersForApproval, { loading: approvedMembersLoading }] =
    useMutate({
      path: CAMPAIGN_URL.APPROVEDMEMBERS,
      errorToast: true,
      successToast: true,
      method: "post",
      onCompleted: () => {},
      onError: () => {},
    });
  useEffect(() => {
    let params = {
      CampaignId: id,
    };

    if (seekerId) {
      params = {
        CampaignId: id,
        seekerId: seekerId,
      };
    }

    getClientFamilyWishes({
      params,
      onCompleted: () => {
        setisSuccess(false);
      },
      onError: () => {},
    });
  }, [isSuccess]);

  const campaignDetails = response?.data?.campaignDetail;
  const houseHold = response?.data?.members?.find(
    (member) => member.isHouseHold == true
  );

  const members = response?.data?.members?.filter(
    (member) => member.isHouseHold != true
  );
  const approvedAllMembers = () => {
    const approvedMembers = [];
    members?.map((member) => {
      if (member?.wishes?.length && member?.isWishApproved === false) {
        approvedMembers.push({ MemberId: member.id, IsHouseHold: 0 });
      }
      return member;
    });
    let approvedHouseHold = {
      IsHouseHold: 1,
      MemberId: "",
    };
    if (houseHold?.wishes?.length && houseHold?.isWishApproved === false) {
      approvedHouseHold.MemberId = houseHold?.id;
    }
    const variables = {
      CampaignId: id,
      Members: JSON.stringify([approvedHouseHold, ...approvedMembers]),
      isApproved: true,
      Reason: "",
    };
    postAllMembersForApproval({
      variables,
      onCompleted: () => {
        navigate(-1);
        setisSuccess(true);
      },
      onError: () => {},
    });
  };
  const approvedMember = (memberId, isHouseHold) => {
    // debugger;
    const variables = {
      CampaignId: id,
      Members: JSON.stringify([
        {
          IsHouseHold: isHouseHold,
          MemberId: memberId,
        },
      ]),
      isApproved: true,
      Reason: "",
    };
    postAllMembersForApproval({
      variables,
      onCompleted: () => {
        setisSuccess(true);
      },
      onError: () => {},
    });
  };

  return (
    <>
      {!loading ? (
        <MainContent>
          <PageHeading>
            Donation Details
            <Button
              text={"Back"}
              onClick={() => {
                navigate(-1);
              }}
            />
          </PageHeading>

          <Row>
            <Col span={24}>
              <HeadingWrapper>
                <Heading>{campaignDetails?.campaignName}</Heading>
                <Info>
                  <Label>Donation Status:</Label>{" "}
                  {response?.data?.donationStatus}
                </Info>
              </HeadingWrapper>
            </Col>
            <Col span={24}>
              <CampaignImage src={campaignDetails?.imageFile} />
            </Col>

            <Col xs={{ span: 24 }}>
              <CampaignsDescription>
                {campaignDetails?.description}
              </CampaignsDescription>
            </Col>
            <Col span={24}>
              <Campaignsdetails>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Start date:</Label>
                      {formatDate(
                        campaignDetails?.campaignStartDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>End date:</Label>
                      {formatDate(
                        campaignDetails?.campaignStartDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Enrollment period:</Label>{" "}
                      {formatDate(
                        campaignDetails?.campaignStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(
                        campaignDetails?.campaignEndDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Donations accepted:</Label>{" "}
                      {formatDate(
                        campaignDetails?.donorPeriodStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(
                        campaignDetails?.donorPeriodEndDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Dropoff dates:</Label>{" "}
                      {formatDate(
                        campaignDetails?.dropOfStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(campaignDetails?.dropOfEndDate, "MM/DD/YYYY")}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Client pickup dates</Label>{" "}
                      {formatDate(
                        campaignDetails?.pickupStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(campaignDetails?.pickupEndDate, "MM/DD/YYYY")}
                    </Info>
                  </Col>
                </Row>
              </Campaignsdetails>
              {/* &nbsp;
            <hr /> */}
            </Col>

            <Col xs={{ span: 24 }}>
              <Detailslist>
                <FamilyDetail>
                  <MainHeading>Wishes </MainHeading>
                  {!response?.data?.members?.some(
                    (member) => member.isWishApproved
                  ) && (
                    <ButtonRight>
                      <Button
                        text="Approve All"
                        onClick={approvedAllMembers}
                        loading={approvedMembersLoading}
                      />
                    </ButtonRight>
                  )}
                  <DetailsWrapper>
                    <Row gutter={[30, 20]} style={{ width: "100%" }}>
                      <Col span={24} md={{ span: 12 }}>
                        <FamilyMemberDetail>
                          <MainHeading>Household Wishes</MainHeading>
                          <Row>
                            <Col span={24} md={{ span: 24 }}>
                              {houseHold?.wishes?.length ? (
                                <>
                                  <FlexSort>
                                    <CustomStatus>
                                      <WishInfo>
                                        <Label>Approval Status:</Label>
                                        {houseHold?.isApproved ? (
                                          <ApprovedHeading>
                                            <CheckCircleOutlined /> Approved
                                          </ApprovedHeading>
                                        ) : (
                                          <PendingHeading>
                                            <ClockCircleOutlined /> Yet to be
                                            approved
                                          </PendingHeading>
                                        )}
                                      </WishInfo>
                                    </CustomStatus>
                                    <CustomStatus>
                                      <WishInfo>
                                        <Label>Wishes Approval Status:</Label>
                                        {houseHold?.isWishApproved ? (
                                          <ApprovedHeading>
                                            <CheckCircleOutlined /> Approved
                                          </ApprovedHeading>
                                        ) : (
                                          <PendingHeading>
                                            <ClockCircleOutlined /> Yet to be
                                            approved
                                          </PendingHeading>
                                        )}
                                      </WishInfo>
                                    </CustomStatus>
                                  </FlexSort>
                                </>
                              ) : (
                                ""
                              )}
                              <Info>
                                <Label>Name:</Label>{" "}
                                {`${houseHold?.firstName} ${houseHold?.lastName}`}
                              </Info>
                            </Col>
                            {houseHold?.wishes?.length > 0 ? (
                              houseHold?.wishes?.map((wishInfo, wishIndex) => {
                                return (
                                  <Col span={24} md={{ span: 24 }}>
                                    <WishInfo>
                                      <Label>Wish {wishIndex + 1}:</Label>
                                      {wishInfo?.description}
                                    </WishInfo>
                                  </Col>
                                );
                              })
                            ) : (
                              <Col span={24} md={{ span: 24 }}>
                                <WishInfo>
                                  <Label>Wishes:</Label>
                                  Household is not eligible.
                                </WishInfo>
                              </Col>
                            )}
                            {houseHold?.wishes?.length
                              ? admin &&
                                !houseHold?.isWishApproved && (
                                  <Col span={24} md={{ span: 24 }}>
                                    <ButtonRight>
                                      <Button
                                        text="Decline"
                                        className="secondary"
                                        onClick={() => {
                                          setOpenDeclineModal(true);
                                          setdeclineStatus({
                                            memberId: houseHold?.id,
                                            isHouseHold: houseHold?.isHouseHold,
                                          });
                                        }}
                                      />
                                      <Button
                                        text="Approve"
                                        loading={
                                          memberId === houseHold?.id
                                            ? approvedMembersLoading
                                            : false
                                        }
                                        onClick={() => {
                                          approvedMember(
                                            houseHold.id,
                                            houseHold.isHouseHold
                                          );
                                          setMemberId(houseHold.id);
                                        }}
                                      />
                                      <Button
                                        text={"Edit"}
                                        htmlType="button"
                                        onClick={() => {
                                          setEditDetails({
                                            campaignId:
                                              response?.data?.campaignDetail
                                                ?.campaignId,
                                            memberId: houseHold.id,
                                            wishes: houseHold?.wishes,
                                          });
                                          setOpenEditModal(true);
                                        }}
                                      />
                                    </ButtonRight>
                                  </Col>
                                )
                              : ""}
                          </Row>
                        </FamilyMemberDetail>
                      </Col>
                      {members?.map((memberInfo, memberIndex) => {
                        return (
                          <Col span={24} md={{ span: 12 }}>
                            <FamilyMemberDetail>
                              <MainHeading>
                                Member {memberIndex + 1}
                              </MainHeading>

                              <Row>
                                <Col span={24} md={{ span: 24 }}>
                                  {memberInfo?.wishes?.length ? (
                                    <>
                                      <FlexSort>
                                        <CustomStatus>
                                          <WishInfo>
                                            <Label>Approval Status:</Label>
                                            {memberInfo?.isApproved ? (
                                              <ApprovedHeading>
                                                <CheckCircleOutlined /> Approved
                                              </ApprovedHeading>
                                            ) : (
                                              <PendingHeading>
                                                <ClockCircleOutlined /> Yet to
                                                be approved
                                              </PendingHeading>
                                            )}
                                          </WishInfo>
                                        </CustomStatus>
                                        <CustomStatus>
                                          <WishInfo>
                                            <Label>
                                              Wishes Approval Status:
                                            </Label>
                                            {memberInfo?.isWishApproved ? (
                                              <ApprovedHeading>
                                                <CheckCircleOutlined /> Approved
                                              </ApprovedHeading>
                                            ) : (
                                              <PendingHeading>
                                                <ClockCircleOutlined /> Yet to
                                                be approved
                                              </PendingHeading>
                                            )}
                                          </WishInfo>
                                        </CustomStatus>
                                      </FlexSort>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <Info>
                                    <Label> Relation:</Label>{" "}
                                    {memberInfo?.relation}
                                  </Info>
                                </Col>
                                <Col span={24} md={{ span: 24 }}>
                                  <Info>
                                    <Label>Name:</Label>{" "}
                                    {`${capitalFirstLetter(
                                      memberInfo?.firstName
                                    )} ${capitalFirstLetter(
                                      memberInfo.lastName
                                    )}`}
                                  </Info>
                                </Col>
                                {memberInfo?.wishes?.length > 0 ? (
                                  memberInfo?.wishes?.map(
                                    (wishInfo, wishIndex) => {
                                      return (
                                        <Col span={24} md={{ span: 24 }}>
                                          <Col span={24} md={{ span: 24 }}>
                                            <WishInfo>
                                              <Label>
                                                Wish {wishIndex + 1}:
                                              </Label>
                                              {wishInfo?.description}
                                            </WishInfo>
                                          </Col>
                                        </Col>
                                      );
                                    }
                                  )
                                ) : (
                                  <Col span={24} md={{ span: 24 }}>
                                    <WishInfo>
                                      <Label>Wishes:</Label>
                                      {`${capitalFirstLetter(
                                        memberInfo?.firstName
                                      )} ${capitalFirstLetter(
                                        memberInfo?.lastName
                                      )}`}{" "}
                                      is not eligible.
                                    </WishInfo>
                                  </Col>
                                )}
                                {memberInfo?.wishes?.length > 0
                                  ? admin && (
                                      <Col span={24} md={{ span: 24 }}>
                                        <ButtonRight>
                                          {!memberInfo?.isWishApproved && (
                                            <>
                                              <Button
                                                text="Decline"
                                                className="secondary"
                                                onClick={() => {
                                                  setOpenDeclineModal(true);
                                                  setdeclineStatus({
                                                    memberId: memberInfo?.id,
                                                    isHouseHold:
                                                      memberInfo?.isHouseHold,
                                                  });
                                                }}
                                              />
                                              <Button
                                                text="Approve"
                                                loading={
                                                  memberId === memberInfo?.id
                                                    ? approvedMembersLoading
                                                    : false
                                                }
                                                onClick={() => {
                                                  approvedMember(
                                                    memberInfo.id,
                                                    memberInfo.isHouseHold
                                                  );
                                                  setMemberId(memberInfo?.id);
                                                }}
                                              />
                                            </>
                                          )}

                                          <Button
                                            text={"Edit"}
                                            htmlType="button"
                                            onClick={() => {
                                              setEditDetails({
                                                campaignId:
                                                  response?.data?.campaignDetail
                                                    ?.campaignId,
                                                memberId: memberInfo.id,
                                                wishes: memberInfo?.wishes,
                                              });
                                              setOpenEditModal(true);
                                            }}
                                          />
                                        </ButtonRight>
                                      </Col>
                                    )
                                  : ""}
                              </Row>
                            </FamilyMemberDetail>
                          </Col>
                        );
                      })}
                    </Row>
                  </DetailsWrapper>
                </FamilyDetail>
              </Detailslist>
            </Col>
            <Col xs={{ span: 24 }}>
              <Spacer />
            </Col>
          </Row>
          <Modal
            title="Decline Request"
            open={openDeclineModal}
            setOpenModal={setOpenDeclineModal}
            modalType={ModalList.DeclineRequestApproval}
            id={id}
            setisSuccess={setisSuccess}
            declineStatus={declineStatus}
            loader={approvedMembersLoading}
          />
          <Modal
            title="Edit Wish"
            open={openEditModal}
            setIsSuccess={setisSuccess}
            setOpenModal={setOpenEditModal}
            modalType={ModalList.EditWishesmodal}
            clientDetails={editDetails}
          />
        </MainContent>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AdminWishesApproval;
