import React, { useEffect, useState } from "react";
import Modal, { ModalList } from "../../modal/basemodal/BaseModal";
import {
  Banner,
  BannerContent,
  LeftContainer,
  Description,
  PageHeading,
  BottomButtons,
  Campaigns,
  SectionHeading,
  PaginationWrapper,
} from "./landingPageStyle";
import { Image, Pagination } from "antd";
import Button from "../../elements/button/Button";
import { Row, Col } from "antd";
import Tiles from "../../elements/tiles/Tiles";
import { useNavigate } from "react-router-dom";
import Footer from "../../footer/Footer";
import CAMPAIGN_URL from "../../../api/urls/CAMPAIGN_URL";
import useMutate from "../../../api/hooks/useMutate";
import { formatDate } from "../../../utils/dateTime";
// import Loader from "../../loader/Loader";
import { PublicHeader } from "../../publicHeader/PublicHeader";
import LogoImage from "../../../assets/images/logo.png";
import moment from "moment";
import {
  Header,
  Logo,
  Nav,
  NavButtons,
  NavItem,
} from "../../publicHeader/PublicHeaderStyles";
import {
  CampaignData,
  CardActions,
  CardContent,
  CardDescription,
  CardHeading,
  CardImage,
  MainCard,
} from "../../elements/cards2/Cards2Style";
import { dateAPIFormat } from "../../../utils/genderFormat";
import { useSelector } from "react-redux";
import Loader from "../../loader/Loader";

let pagination = "";

const LandingPageDonor = () => {
  const { shortOrg } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [openViewCampaignsModal, setOpenViewCampaignsModal] = useState(false);
  const [isDataToShare, setIsDataToShare] = useState();
  const handleChange = (value) => {
    setPage(value);
  };
  // const [toggle, setToggle] = useState(false);

  const [page, setPage] = useState(1);
  const [getCampaignsList, { response, loading }] = useMutate({
    path: CAMPAIGN_URL.GET_CAMPAIGN_LIST_LANDING,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const param = {
    pageNo: page,
    PageSize: 6,
    searchValue: "",
    sortColumn: "",
    sortOrder: "",
  };
  useEffect(() => {
    getCampaignsList({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [page]);

  pagination = response?.data?.pagination;

  return (
    <>
      {/* {!loading ? ( */}
      <>
        <Header>
          <Nav>
            <Logo>
              <img
                preview={false}
                src={shortOrg?.logoImage}
                // onClick={() => navigate("/")}
              />
            </Logo>
            <NavItem>
              {/* To Do : Add toggle className (showNav) on click of NavList */}
              {/* <NavList className={isActive ? "showNav" : ""}>
            <NavLink onClick={() => navigate("/all-events")}>Events</NavLink>
            <NavLink> news</NavLink>
            <NavLink>contact us </NavLink>
            <NavLink> about us</NavLink>
            </NavList> */}
              <NavButtons>
                {/* <Button
                text={"Login as FAM Client"}
                onClick={() => navigate("/client-login")}
              /> */}
                <Button
                  className={"secondary"}
                  text={"Switch to Client"}
                  onClick={() => navigate("/landing")}
                />
              </NavButtons>
            </NavItem>
            {/* <Togglebtn onClick={() => handleClick()}>
            <MenuOutlined />
          </Togglebtn> */}
          </Nav>
        </Header>
        <Banner>
          <BannerContent>
            <LeftContainer>
              <PageHeading>Welcome</PageHeading>
              <Description>
                Thank you for helping FAM to provide for families in need. You
                will be providing gifts for children under 18 years old. We
                recommend a maximum of $50 per child.
              </Description>
              <BottomButtons>
                <Button
                  // className={"secondary"}
                  text={"Login as Donor"}
                  onClick={() => navigate("/donor-login")}
                />
                <Button
                  text={"Register as Donor"}
                  className="secondary"
                  onClick={() => navigate("/register-donor")}
                />
              </BottomButtons>
            </LeftContainer>
          </BannerContent>
        </Banner>
        {response?.data?.records?.filter(
          (record) =>
            record?.enrollmentPeriodEndDate < moment(new Date()).unix()
        ) && (
          <Campaigns>
            <SectionHeading>Running Events</SectionHeading>
            <Row gutter={20}>
              <Col span={24}>
                <div style={{ minHeight: "440px" }}>
                  <Row gutter={20}>
                    <>
                      {!loading ? (
                        response?.data?.records?.map((item) => {
                          return (
                            <Col
                              xxl={{ span: 6 }}
                              xl={{ span: 8 }}
                              sm={{ span: 12 }}
                              span={24}
                            >
                              {/* <Tiles
                                title={item?.campaignName}
                                imageFile={item?.imageFile}
                                cardDescription={item?.description}
                                address={item?.pickupAddress}
                                loading={loading}
                                enrollmentPeriod={`${formatDate(
                                  item?.enrollmentPeriodStartDate,
                                  "MM/DD/YYYY"
                                )} to ${formatDate(
                                  item?.enrollmentPeriodEndDate,
                                  "MM/DD/YYYY"
                                )}`}
                                campaignId={item?.campaignId}
                                onClick={() => {
                                  setOpenViewCampaignsModal(true);
                                  setIsDataToShare(item);
                                }}
                              /> */}

                              <MainCard>
                                <CardImage>
                                  <Image
                                    preview={false}
                                    src={item?.imageFile}
                                  />
                                </CardImage>

                                <CardContent>
                                  <Row>
                                    <Col span={24}>
                                      <CardHeading>
                                        {item?.campaignName}
                                      </CardHeading>
                                    </Col>
                                    <Col span={24}>
                                      <CardDescription>
                                        <p>{item?.description}</p>
                                      </CardDescription>
                                    </Col>
                                    <Col lg={{ span: 12 }} span={24}>
                                      <CampaignData>
                                        Start Date:&nbsp;
                                        <span className="bold">{`${formatDate(
                                          item?.campaignStartDate,
                                          "MM/DD/YYYY"
                                        )}`}</span>
                                      </CampaignData>
                                    </Col>
                                    <Col lg={{ span: 12 }} span={24}>
                                      <CampaignData>
                                        End Date:&nbsp;
                                        <span className="bold">{`${formatDate(
                                          item?.campaignEndDate,
                                          "MM/DD/YYYY"
                                        )}`}</span>
                                      </CampaignData>
                                    </Col>
                                    <Col span={24}>
                                      <CampaignData>
                                        Donation drop-off:&nbsp;
                                        <span className="bold">
                                          {item?.dropOfAddress}
                                        </span>
                                      </CampaignData>
                                    </Col>

                                    <Col span={24}>
                                      <CampaignData>
                                        Donations accepted:&nbsp;
                                        <span className="bold">
                                          {`${formatDate(
                                            item?.enrollmentPeriodStartDate,
                                            "MM/DD/YYYY"
                                          )} To ${formatDate(
                                            item?.enrollmentPeriodEndDate,
                                            "MM/DD/YYYY"
                                          )}`}
                                        </span>
                                      </CampaignData>
                                    </Col>
                                  </Row>
                                </CardContent>
                                <CardActions>
                                  <Button
                                    width={"100%"}
                                    text={"View Details"}
                                    onClick={() => {
                                      setOpenViewCampaignsModal(true);
                                      setIsDataToShare(item);
                                    }}
                                  />
                                </CardActions>
                              </MainCard>
                            </Col>
                          );
                        })
                      ) : (
                        <Loader />
                      )}
                    </>
                  </Row>
                </div>
              </Col>
              <Col span={24}>
                <PaginationWrapper>
                  <Pagination
                    defaultCurrent={1}
                    pageSize={6}
                    total={pagination?.totalRecordsCount}
                    onChange={handleChange}
                    current={page}
                    showSizeChanger={false}
                    hideOnSinglePage={true}
                  />
                </PaginationWrapper>
              </Col>
            </Row>
          </Campaigns>
        )}
        {/* <Contact>
          <ContactHeading>contact us</ContactHeading>
          <ConatctForm>
            <Form
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              autoComplete="off"
            >
              <FormFields>
                <Row>
                  <Col span={24}>
                    <Input label="Name" name="Name" />
                  </Col>
                  <Col span={24}>
                    <Input label="Email" name="email" />
                  </Col>
                  <Col span={24}>
                    <Input label="Phone number" name="PhoneNumber" />
                  </Col>
                  <Col span={24}>
                    <TextAreaB label="Your Message" name="YourMessage" />
                  </Col>
                  <Col span={24}>
                    <BottomForm>
                      <Button text={" Send Message"} />
                    </BottomForm>
                  </Col>
                </Row>
              </FormFields>
            </Form>
          </ConatctForm>
        </Contact> */}
        {/* <Newslettercontainer>
          <Newsletterwrapper>
            <Newsheading>Sign up for newsletter</Newsheading>
            <Newstext>We'll give you just the right amount of love!</Newstext>
            <Newstext>
              Sign up for our weekly update and be the first to know about our
              specials and promotions.
            </Newstext>
            <Newspara>No spam, We promise</Newspara>
            <Newspara>Email Address</Newspara>
            <Suscriberwrapper>
              <Input placeholder="Email address" />
              <Button text={" Subscribe"} />
            </Suscriberwrapper>
          </Newsletterwrapper>
        </Newslettercontainer> */}
        <Footer />
        <Modal
          title="View Event"
          open={openViewCampaignsModal}
          setOpenModal={setOpenViewCampaignsModal}
          modalType={ModalList.LandingViewEventModal}
          data={isDataToShare}
          landingPageType="DONOR"
        />
      </>
      {/* ) : (
        <Loader />
      )} */}
    </>
  );
};

export default LandingPageDonor;
