import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../elements/button/Button";
import { ButtonContainer } from "./NewSeekerModal";
const Container = styled.div`
  max-width: 65vw;
  padding: 0 17px 0 10px;
  overflow: auto;
  max-height: 70vh;
  min-width: 550px;

  @media screen and (max-width: 567px) {
    max-width: 95vw;
    min-width: 95vw;
  }
  @media screen and (max-device-width: 767px) and (orientation: landscape) {
    max-width: 100%;
    max-height: 70vh;
  }
`;
export const SeekerRegistrationModal = ({ header, info }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <p
        style={{
          padding: "15px",
          fontSize: "15px",
        }}
      >
        {header ||
          "Thank you registering with FAM.  A FAM representative will contact you shortly."}
      </p>
      {info && (
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>{info}</p>
      )}
      <ButtonContainer noBorder>
        <Button
          background="grey"
          text="Go to Home"
          onClick={() => navigate("/landing")}
          type="button"
        />
      </ButtonContainer>
    </Container>
  );
};

export default SeekerRegistrationModal;
