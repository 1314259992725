import { Row, Col, Image, Form } from "antd";
import LogoImg from "../../../../assets/images/logo.png";
import {
  BottomForm,
  RightContainer,
  LayoutContainer,
  InnerContent,
  OuterWrapper,
  Heading,
} from "./LoginStyles";
import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../../../utils/errorMessages";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import useMutate from "../../../../api/hooks/useMutate";
import Button from "../../../elements/button/Button";
import Input from "../../../elements/input/Input";
import { Customcheckbox } from "./LoginStyles";
import { Checkbox } from "antd";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import Loader from "../../../loader/Loader";
import Link from "../../../elements/links/Links";
import { ROLES_INITIAL_ROUTES } from "../../../../hooks/useSetAppConfigs";
import { Logo } from "../../admin/signUp/SingupStlyes";
import { LeftContainer } from "../../admin/logIn/LoginStyles";
import CustomImage from "../../../image/LeftContainer";

function LogIn() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [postDonorLoginAPI, { response, error, loading }] = useMutate({
    path: DONOR_URLS.LOGIN,
    errorToast: true,
  });
  const onFinish = (data) => {
    const variables = {
      email: data.email,
      password: data.password,
    };

    postDonorLoginAPI({
      variables,
      onCompleted: (data) => {
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("roleName", data.roleName);
        navigate(ROLES_INITIAL_ROUTES[data.roleName]);
      },
      onError: () => {},
    });
  };
  return (
    <>
      {!loading ? (
        <LayoutContainer>
          <InnerContent style={{ maxWidth: "820px", minHeight: "360px" }}>
            <OuterWrapper>
              <Row>
                <Col lg={{ span: 8 }} span={24}>
                  {/* <LeftContainer>
                    <Logo>
                      <Image preview={false} src={LogoImg} />
                    </Logo>
                  </LeftContainer> */}
                  <CustomImage />
                </Col>
                <Col xl={{ span: 16 }} lg={{ span: 16 }} sm={{ span: 24 }}>
                  <RightContainer>
                    <Form
                      name="basic"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      autoComplete="off"
                      form={form}
                      onFinish={onFinish}
                    >
                      <Heading>Donor Login</Heading>
                      <Row gutter={[0, 8]}>
                        <Col span={24}>
                          <Input
                            name="email"
                            pattern={regExpPattern.EMAIL}
                            required={true}
                            regExpMessage={regErrorMessages.EMAIL}
                            label="Email"
                            requiredMessage={errorMessages.EMAIL}
                          />
                        </Col>
                        <Col span={24}>
                          <Input
                            name="password"
                            type="password"
                            required={true}
                            label="Password"
                            requiredMessage={errorMessages.PASSWORD}
                          />
                        </Col>
                        {/* <Col span={24}> */}
                        {/* <Customcheckbox> */}
                        {/* <Checkbox>Remember me</Checkbox> */}
                        <div>
                          &nbsp;&nbsp;&nbsp; Forgot password ? &nbsp;
                          <Link text="Reset" to={"/forgot-password"} />
                        </div>
                        {/* </Customcheckbox> */}
                        {/* </Col> */}
                        <Col span={24}>
                          <BottomForm
                            style={{
                              alignItems: "center",
                              // justifyContent: "flex-end",
                            }}
                          >
                            <div>
                              Don't have an account ? &nbsp;
                              <Link text="Register" to={"/register-donor"} />
                            </div>
                            {/* <div>
                              Forgot password ? &nbsp;
                              <Link text="Reset" to={"/forgot-password"} />
                            </div> */}
                            <Button
                              text={"Login"}
                              htmlType="submit"
                              loading={loading}
                            />
                          </BottomForm>
                        </Col>
                      </Row>
                    </Form>
                  </RightContainer>
                </Col>
              </Row>
            </OuterWrapper>
          </InnerContent>
        </LayoutContainer>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default LogIn;
