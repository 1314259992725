import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { CloseOutlined } from "@ant-design/icons";
import {
  Background,
  ModalWrapper,
  Header,
  Title,
  CloseModalButton,
  ModalContent,
} from "./BaseModalStyles";
import SeekerRegistrationModal from "../SeekerRegistrationModal";
import EditDonorProfile from "../EditDonorProfile";
import NewFamilyMemberModal from "../NewFamilyMemberModal";
import NewEmployeeModal from "../NewEmployeeModal";
import AddNewSeekerModal from "../NewSeekerModal";
import EditCampaignModal from "../EditCampaignModal";
import ResetPasswordModal from "../ResetPasswordModal";
import ConfirmModal from "../ConfirmPasswordModal";
import ViewSeekerModal from "../ViewSeekerModal";
import NewCampaignModal from "../NewCampaignModal";
import ViewCampaignsModal from "../ViewCampaignsModal";
import NewLabelsModal from "../NewLabelsModal";
import EditDonorModal from "../EditDonorModal";
import ViewDonorModal from "../ViewDonorModal";
import ViewEmployeeModal from "../ViewEmployeeModal";
import ViewClientsModal from "../ViewClientsModal";
import EditClientModal from "../EditClientModal";
import FamlyMemberWishesModal from "../FamlyMemberWishesModal";
import AdminLogOutModal from "../AdminLogout";
import EditEmployeeModal from "../EditEmployeeModal";
import HouseDetailModalExclamation from "../HouseDetailModalExclamation";
import HouseDetailCheckModal from "../HouseDetailCheckModal";
import EditHouseHoldDetailsModal from "../EditFamilyDetails";
import LandingViewEventModal from "../LandingViewEventModal";
import DeclineRequestApproval from "./DeclineRequestApproval";
import EditWishesmodal from "../EditWishesmodal";
import ConfirmSeekerSelection from "../ConfirmSeekerSelection";
import ConfirmSingleSeekerSelection from "../ConfirmSingleSeekerSelection";
import DonationModalLocation from "../DonationLocationModal";

export const ModalList = Object.freeze({
  DeleteUserModal: "DeleteUserModal",
  ConfirmModal: "ConfirmModal",
  DonationModalLocation: "DonationModalLocation",
  SeekerRegisterModal: "SeekerRegisterModal",
  AddNewFamilyMemberModal: "AddNewFamilyMemberModal",
  EditDonorProfile: "EditDonorProfile",
  EditHouseHoldModal: "EditHouseHoldModal",
  AddNewEmployeeModal: "AddNewEmployeeModal",
  AddNewSeekerModal: "AddNewSeekerModal",
  EditcampaignModal: "EditcampaignModal",
  ResetPasswordModal: "ResetPasswordModal",
  ConfirmDeleteSeeker: "ConfirmDeleteSeeker",
  ConfirmDeleteDonor: "ConfirmDeleteDonor",
  ConfirmDeleteEmployee: "ConfirmDeleteEmployee",
  EditEmployeeModal: "EditEmployeeModal",
  ViewSeekerModal: "ViewSeekerModal",
  NewCampaignModal: "NewCampaignModal",
  NewLabelsModal: "NewLabelsModal",
  EditDonorModal: "EditDonorModal",
  ViewDonorModal: "ViewDonorModal",
  ViewCampaignsModal: "ViewCampaignsModal",
  ViewEmployeeModal: "ViewEmployeeModal",
  ViewClientsModal: "ViewClientsModal",
  EditClientModal: "EditClientModal",
  FamlyMemberWishesModal: "FamlyMemberWishesModal",
  // SuperAdminLogOutModal: "SuperAdminLogOutModal",
  AdminLogOutModal: "AdminLogOutModal",
  // DonorLogout: "DonorLogout",
  // SeekerLogOutModal: "SeekerLogOutModal",
  // EmployeeLogoutModal: "EmployeeLogoutModal",
  HouseDetailModalExclamation: "HouseDetailModalExclamation",
  DeclineRequestApproval: "DeclineRequestApproval",
  HouseDetailCheckModal: "HouseDetailCheckModal",
  LandingViewEventModal: "LandingViewEventModal",
  EditWishesmodal: "EditWishesmodal",
  ConfirmSeekerSelection: "ConfirmSeekerSelection",
  ConfirmSingleSeekerSelection: "ConfirmSingleSeekerSelection",
});

const RenderModal = ({ modalType, title, close, ...rest }) => {
  return (
    <>
      {(() => {
        switch (modalType) {
          // SAMPLE
          // case ModalList.DeleteUserModal:
          //   return <ConfirmModal {...rest} close={close} />;

          case ModalList.SeekerRegisterModal:
            return <SeekerRegistrationModal {...rest} close={close} />;
          case ModalList.AddNewFamilyMemberModal:
            return <NewFamilyMemberModal {...rest} close={close} />;
          case ModalList.EditHouseHoldModal:
            return <EditHouseHoldDetailsModal {...rest} close={close} />;
          case ModalList.EditDonorProfile:
            return <EditDonorProfile {...rest} close={close} />;
          case ModalList.AddNewEmployeeModal:
            return <NewEmployeeModal {...rest} close={close} />;
          case ModalList.EditEmployeeModal:
            return <EditEmployeeModal {...rest} close={close} />;
          case ModalList.AddNewSeekerModal:
            return <AddNewSeekerModal {...rest} close={close} />;
          case ModalList.EditcampaignModal:
            return <EditCampaignModal {...rest} close={close} />;
          case ModalList.ResetPasswordModal:
            return <ResetPasswordModal {...rest} close={close} />;
          case ModalList.ConfirmDeleteSeeker:
            return <ConfirmModal {...rest} close={close} />;
          case ModalList.ConfirmDeleteDonor:
            return <ConfirmModal {...rest} close={close} />;
          case ModalList.ConfirmDeleteEmployee:
            return <ConfirmModal {...rest} close={close} />;
          case ModalList.ViewSeekerModal:
            return <ViewSeekerModal {...rest} close={close} />;
          case ModalList.NewCampaignModal:
            return <NewCampaignModal {...rest} close={close} />;
          case ModalList.ViewCampaignsModal:
            return <ViewCampaignsModal {...rest} close={close} />;
          case ModalList.LandingViewEventModal:
            return <LandingViewEventModal {...rest} close={close} />;
          case ModalList.NewLabelsModal:
            return <NewLabelsModal {...rest} close={close} />;
          case ModalList.ViewDonorModal:
            return <ViewDonorModal {...rest} close={close} />;
          case ModalList.EditDonorModal:
            return <EditDonorModal {...rest} close={close} />;
          case ModalList.ViewEmployeeModal:
            return <ViewEmployeeModal {...rest} close={close} />;
          case ModalList.ViewClientsModal:
            return <ViewClientsModal {...rest} close={close} />;
          case ModalList.EditClientModal:
            return <EditClientModal {...rest} close={close} />;
          case ModalList.FamlyMemberWishesModal:
            return <FamlyMemberWishesModal {...rest} close={close} />;
          // case ModalList.SuperAdminLogOutModal:
          //   return <SuperAdminLogOutModal {...rest} close={close} />;
          case ModalList.AdminLogOutModal:
            return <AdminLogOutModal {...rest} close={close} />;
          // case ModalList.DonorLogout:
          //   return <DonorLogout {...rest} close={close} />;
          // case ModalList.SeekerLogOutModal:
          //   return <SeekerLogOutModal {...rest} close={close} />;
          // case ModalList.EmployeeLogoutModal:
          //   return <EmployeeLogoutModal {...rest} close={close} />;
          case ModalList.HouseDetailModalExclamation:
            return <HouseDetailModalExclamation {...rest} close={close} />;
          case ModalList.HouseDetailCheckModal:
            return <HouseDetailCheckModal {...rest} close={close} />;
          case ModalList.DeclineRequestApproval:
            return <DeclineRequestApproval {...rest} close={close} />;
          case ModalList.EditWishesmodal:
            return <EditWishesmodal {...rest} close={close} />;
          case ModalList.ConfirmSeekerSelection:
            return <ConfirmSeekerSelection {...rest} close={close} />;
          case ModalList.ConfirmSingleSeekerSelection:
            return <ConfirmSingleSeekerSelection {...rest} close={close} />;
          case ModalList.DonationModalLocation:
            return <DonationModalLocation {...rest} close={close} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

const Modal = ({
  open,
  modalType,
  isClosable = true,
  setOpenModal,
  title,
  ...rest
}) => {
  const modalRef = useRef();

  useEffect(() => {
    if (open) {
      document.body.classList.add("hideScroll");
    }

    return () => {
      document.body.classList.remove("hideScroll");
    };
  }, [open]);
  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: open ? 1 : 0,
    transform: open ? `translateY(0%)` : `translateY(-100%)`,
  });

  const closeModal = (e) => {
    if (modalRef.current === e?.target) {
      setOpenModal(false);
    }
    setOpenModal(false);
  };

  const close = () => {
    setOpenModal(false);
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && open) {
        setOpenModal(false);
      }
    },
    [setOpenModal, open]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {open && (
        <Background ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper showModal={open}>
              <Header>
                <Title>{title}</Title>
                {isClosable && (
                  <CloseModalButton
                    aria-label="Close modal"
                    onClick={() => closeModal()}
                  >
                    <CloseOutlined />
                  </CloseModalButton>
                )}
              </Header>
              <ModalContent>
                <RenderModal close={close} modalType={modalType} {...rest} />
              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      )}
    </>
  );
};

export default Modal;
