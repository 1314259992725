// import { DatePicker } from 'antd';
import { Row, Col, Image, Form, Checkbox, Input as AntInput } from "antd";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import LogoImg from "../../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons";
import {
  InnerContent,
  LayoutContainer,
  OuterWrapper,
  RightContainer,
  Heading,
  FormFields,
  BottomForm,
  SubDomain,
} from "./SingupStlyes";

import { useState } from "react";
import ORG_URL from "../../../../api/urls/ORG_URL";
import useMutate from "../../../../api/hooks/useMutate";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import { errorMessages } from "../../../../utils/errorMessages";
import Input from "../../../elements/input/Input";
import Button from "../../../elements/button/Button";
import { ResponseTab } from "./SingupStlyes";
import AddressSearchDropdown from "../../../elements/address/GoogleAddressInput";
import Link from "../../../elements/links/Links";
import { PhoneNumberInput } from "../../../elements/input/phoneNumberInput";
import {
  ImagePlaceholder,
  PlaceHolderWrappper,
  Requiredwrapper,
} from "../../seeker/RegisterSeeker/RegisterSeeker";
import { useRef } from "react";
import CustomImage from "../../../image/LeftContainer";

function SignUp() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [checked, setChecked] = useState();
  const [isAvailables, setIsAvailables] = useState(false);
  const [isAvailabale, setIsAvailable] = useState("Not Checked");
  const [address, setAddress] = useState({});
  const [addressError, setAddressError] = useState("");
  const isOrgLoaded = useRef(false);
  const [subDomainValue, setSubDomainValue] = useState({
    value: "",
    error: "",
  });
  const [imageUpload, setImageUpload] = useState({
    logo: "",
    poster: "",
  });
  const [fileList, setfileList] = useState({
    logo: "",
    poster: "",
  });
  const fileUpload = (e, check) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (check === "logo") {
        setImageUpload((file) => ({
          ...file,
          logo: files,
        }));
        setfileList((data) => ({
          ...data,
          logo: reader.result,
        }));
      } else {
        setImageUpload((file) => ({
          ...file,
          poster: files,
        }));
        setfileList((data) => ({
          ...data,
          poster: reader.result,
        }));
      }
    };
    reader.readAsDataURL(files);
  };
  const onCheckboxChange = (e) => {
    if (e.target.checked) {
      setChecked(e.target.checked);
    } else {
      setChecked(false);
    }
  };
  const [postOrganisationSignUp, { loading: signupLoading }] = useMutate({
    path: ORG_URL.SIGNUP,
    isFormData: true,
    successToast: true,
    errorToast: true,
    onCompleted: () => {
      //
      navigate("/dashboard");
    },
    onError: () => {},
  });
  const [
    postCheckAvailablity,
    {
      response: subDomainResponse,
      loading: availablityLoading,
      error: subDomainError,
    },
  ] = useMutate({
    isFormData: false,
    path: ORG_URL.CHECKFORAVAILABLITYSUBDOMAIN,
    onCompleted: () => {},
    onError: () => {},
  });
  const onFinish = (data) => {
    const variables = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      organisationName: data.organizationName,
      logoImage: imageUpload.logo,
      posterImage: imageUpload.poster,
      password: data.password,
      subDomain: data.subDomain,
      phoneNo: data.contactNumber?.replace(/\s+/g, ""),
      ...address,
      longitude: address?.lng,
      latitude: address?.lat,
      // gender:1,
      termAndCondition: 1,
    };
    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }

    postOrganisationSignUp({
      variables,
      onCompleted: () => {
        navigate("/login");
      },
      onError: () => {},
    });
  };

  const checkAvailablity = () => {
    const variables = {
      subdomain: subDomainValue.value.toLowerCase(),
    };
    postCheckAvailablity({
      variables,
      onCompleted: () => {
        setIsAvailable("Available");
      },
      onError: () => {
        setIsAvailable("Not Available");
      },
    });
  };
  const getSubDomainValue = (e) => {
    setIsAvailable("Not Checked");
    setSubDomainValue({
      value: e.target.value.trim(),
      error: !e.target.value ? "Please enter your Sub Domain !" : "",
    });
  };

  const signUp = () => {
    if (subDomainValue.value.length === 0) {
      setSubDomainValue((data) => ({
        ...data,
        error: "Please enter your Sub Domain !",
      }));
    }

    if (!Object?.keys(address)?.length) {
      setAddressError("Please enter the address !");
    }

    form.submit();
  };
  const regex = /^[a-zA-Z0-9]{1,20}$/;
  const handleRemoveImage = (imageType) => {
    if (imageType === "logo") {
      setfileList((data) => ({
        ...data,
        logo: "",
      }));
    } else {
      setfileList((data) => ({
        ...data,
        poster: "",
      }));
    }
  };

  return (
    <>
      <LayoutContainer>
        <InnerContent>
          <OuterWrapper>
            <Row>
              <Col xl={{ span: 7 }} span={24}>
                {/* <LeftContainer>
                  <Logo>
                    <Image preview={false} src={LogoImg} />
                  </Logo>
                </LeftContainer> */}
                <CustomImage />
              </Col>
              <Col xl={{ span: 17 }} span={24}>
                <RightContainer>
                  <Heading>Register new account </Heading>
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    form={form}
                    onFinish={onFinish}
                  >
                    <FormFields>
                      <Row gutter={[0, 8]}>
                        <Col md={{ span: 12 }} span={24}>
                          <Input
                            label="Organisation name"
                            name="organizationName"
                            required={true}
                            requiredMessage={errorMessages.ORGANIZATIONNAME}
                          />
                        </Col>

                        <Col md={{ span: 12 }} span={24}>
                          <Requiredwrapper>
                            <span className="validdata">*</span>
                          </Requiredwrapper>
                          <AddressSearchDropdown
                            onChange={(address) => {
                              if (Object?.keys(address)?.length) {
                                if (address?.city?.length) {
                                  setAddressError("");
                                } else {
                                  setAddressError(
                                    "Please select address with city"
                                  );
                                }
                              } else {
                                if (isOrgLoaded.current) {
                                  setAddressError("Please select address");
                                }
                              }
                              setAddress(address);
                              isOrgLoaded.current = true;
                            }}
                            value={address}
                            required={false}
                            requiredMessage={errorMessages.ADDRESS}
                            label=" Organisation Address"
                            addressError={addressError}
                          />
                        </Col>

                        <Col span={24}>
                          <SubDomain>
                            <Input
                              label="Sub Domain"
                              name="subDomain"
                              error={subDomainValue.error}
                              pattern={regExpPattern.SUBDOMAIN}
                              regExpMessage={regErrorMessages.SUBDOMAIN}
                              onChange={(e) => getSubDomainValue(e)}
                            />
                            {subDomainValue?.error && (
                              <div className="errormsg">
                                {subDomainValue.error}
                              </div>
                            )}
                            {isAvailabale == "Not Available" && (
                              <div className="errormsg">{"Not Available"}</div>
                            )}
                            <>
                              {isAvailabale == "Not Available" && (
                                <ResponseTab>
                                  <CloseCircleOutlined
                                    style={{ color: "red" }}
                                  />
                                </ResponseTab>
                              )}

                              {isAvailabale == "Available" && (
                                <ResponseTab>
                                  <CheckCircleOutlined
                                    style={{ color: "green" }}
                                  />
                                </ResponseTab>
                              )}
                            </>

                            <Button
                              className="avalibilitybtn"
                              onClick={() => checkAvailablity()}
                              loading={availablityLoading}
                              text="Check for Availability"
                              disabled={
                                subDomainValue.value.length === 0
                                  ? true
                                  : subDomainValue.value.length > 0
                                  ? !regex.test(subDomainValue.value)
                                  : ""
                              }
                              // style={{ marginBottom: "10px" }}
                            />
                          </SubDomain>
                        </Col>
                        <Col md={{ span: 12 }} span={24}>
                          <Input
                            label="First name"
                            name="firstName"
                            required={true}
                            requiredMessage={errorMessages.FIRSTNAME}
                          />
                        </Col>
                        <Col md={{ span: 12 }} span={24}>
                          <Input
                            label="Last name"
                            name="lastName"
                            required={true}
                            requiredMessage={errorMessages.LASTNAME}
                          />
                        </Col>
                        <Col md={{ span: 12 }} span={24}>
                          <PhoneNumberInput
                            label="Phone Number"
                            name="contactNumber"
                            required={true}
                            requiredMessage={errorMessages?.CONTACTNUMBER}
                            width="100%"
                          />
                        </Col>

                        <Col md={{ span: 12 }} span={24}>
                          <Input
                            label="Email"
                            name="email"
                            pattern={regExpPattern.EMAIL}
                            required={true}
                            regExpMessage={regErrorMessages.EMAIL}
                            requiredMessage={errorMessages.EMAIL}
                          />
                        </Col>

                        <Col md={{ span: 12 }} span={24}>
                          <Input
                            label="Password"
                            name="password"
                            type={"password"}
                            required={true}
                            pattern={regExpPattern.PASSWORD}
                            regExpMessage={regErrorMessages.PASSWORD}
                            requiredMessage={errorMessages.PASSWORD}
                          />
                        </Col>
                        <Col md={{ span: 12 }} span={24}>
                          <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={["password"]}
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "The two passwords that you entered do not match!"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <AntInput.Password />
                          </Form.Item>
                        </Col>
                        <Col md={{ span: 12 }} span={24}>
                          <PlaceHolderWrappper>
                            {!fileList.logo.length > 0 ? (
                              <>
                                <Form.Item
                                  label=""
                                  name="logoImage"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please insert the logo !",
                                    },
                                  ]}
                                >
                                  <ImagePlaceholder>
                                    <CloudUploadOutlined />
                                    Upload Logo
                                  </ImagePlaceholder>
                                  <input
                                    name="uploadLogo"
                                    type={"file"}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => fileUpload(e, "logo")}
                                  />
                                </Form.Item>
                              </>
                            ) : (
                              <>
                                <Image src={fileList.logo} />
                                <CloseOutlined
                                  onClick={() => handleRemoveImage("logo")}
                                />
                              </>
                            )}
                            <div>Recommended size: 200px x 200px</div>
                          </PlaceHolderWrappper>
                        </Col>
                        <Col md={{ span: 12 }} span={24}>
                          <PlaceHolderWrappper>
                            {!fileList.poster.length > 0 ? (
                              <>
                                <Form.Item
                                  label=""
                                  name="posterImage"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please insert the poster !",
                                    },
                                  ]}
                                >
                                  <ImagePlaceholder>
                                    <CloudUploadOutlined />
                                    Upload Poster Image
                                  </ImagePlaceholder>
                                  <input
                                    name="uploadPosterImage"
                                    type={"file"}
                                    className={"uploadImage"}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => fileUpload(e, "poster")}
                                  />
                                </Form.Item>
                              </>
                            ) : (
                              <>
                                <Image src={fileList.poster} />
                                <CloseOutlined
                                  onClick={() => handleRemoveImage("poster")}
                                />
                              </>
                            )}
                            <div>Recommended size: Max 1080px wide</div>
                          </PlaceHolderWrappper>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="checkBox"
                            rules={[
                              {
                                required: !checked,
                                message: "Please accept privacy policy !",
                              },
                            ]}
                          >
                            <Checkbox
                              style={{ padding: "0 10px" }}
                              checked={checked}
                              onChange={onCheckboxChange}
                            >
                              Yes, I understand and agree with the &nbsp;
                              {/* <Link
                                to="/terms-and-conditions"
                                text=" Terms & Conditions"
                                target="_blank"
                              />
                              &nbsp; and &nbsp; */}
                              <Link
                                to="//www.family-assistance.org/privacy-policy"
                                text="Privacy Policy"
                                target="_blank"
                              />
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </FormFields>

                    <BottomForm>
                      <p style={{ margin: "0" }}>
                        Already have an account? &nbsp;
                        <Link to="/login" text="Login" />
                      </p>
                      <Button
                        text={"Register"}
                        htmlType="button"
                        loading={signupLoading}
                        onClick={signUp}
                      />
                    </BottomForm>
                  </Form>
                </RightContainer>
              </Col>
            </Row>
          </OuterWrapper>
        </InnerContent>
      </LayoutContainer>
    </>
  );
}

export default SignUp;
