import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Input, Menu, Space, Table, Tabs } from "antd";

import { MainContent } from "../../donor/familyList/FamilyListStyle";
import {
  PageHeading,
  SearchContainer,
} from "../../admin/campaigns/CampaignStyle";
import Link from "../../../elements/links/Links";
import Search from "antd/lib/input/Search";
import { ButtonContainer } from "../../donor/familyDetails/FamilyDetailsStyle";
import Button from "../../../elements/button/Button";
import { debounce } from "../../../../utils/Debounce";
import ORG_URL from "../../../../api/urls/ORG_URL";
import AllMembers from "./AllMembers";
import RecievedMembers from "./RecievedMembers";
import UnRecievedMembers from "./UnRecievedMembers";
import DelieveredMembers from "./DelieveredMembers";
import useMutate from "../../../../api/hooks/useMutate";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../utils/dateTime";
import { ActionIcon } from "../../admin/donors/DonorsStyle";
import { EditOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { Moreicon } from "../../admin/seekers/SeekersStyle";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";

export const getClientStatus = (status) => {
  switch (status) {
    case "Pending": {
      return "Pending";
    }

    case "Unreceived": {
      return "Pending";
    }

    case "Received": {
      return "Dropped at Location";
    }

    case "Delivered": {
      return "Completed";
    }

    default: {
      return "";
    }
  }
};

export const getDonorStatus = (status) => {
  switch (status) {
    case "Unreceived": {
      return "Pending";
    }
    case "Pending": {
      return "Pending";
    }
    case "Received": {
      return "Dropped at Location";
    }
    case "Delivered": {
      return "Completed";
    }
    default: {
      return "";
    }
  }
};

let pagination = "";
const Donations = () => {
  const navigate = useNavigate();
  const [isClientEdit, setIsClientEdit] = useState(false);
  const [actionData, setActionData] = useState();
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openNewSeekerModal, setOpenNewSeekerModal] = useState(false);
  const [openEditSeekerModal, setOpenEditSeekerModal] = useState(false);
  const [data, setData] = useState();
  const [filter, setFilter] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openViewSeekerModal, setOpenViewSeekerModal] = useState(false);
  const [page, setPage] = useState(1);

  const [sortTable, setSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [getDonationList, { response, loading }] = useMutate({
    path: ORG_URL.DONATION_LIST,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });

  const param = {
    pageNo: page || 1,
    pageSize: 100,
    searchValue: searchValue,
    sortColumn: sortTable.column,
    sortOrder: sortTable.order,
    filter: filter,
  };
  useEffect(() => {
    getDonationList({
      params: param,
      onCompleted: (resposiveData) => {
        setData(resposiveData?.records);
      },
      onError: () => {},
    });
    setIsSuccess(false);
  }, [page, sortTable, isSuccess, filter, searchValue]);
  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSortTable({
      column: sorter.field,
      order: sorter.order === "ascend" ? "ASC" : "DESC",
    });
  };

  const ActionMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <ActionIcon
              title="View"
              onClick={() =>
                navigate(
                  `/donation-details?campaignId=${actionData?.campaignId}&donorId=${actionData?.donorId}&clientId=${actionData?.clientId}&donationId=${actionData?.campaignSelectedSeekerId}`
                )
              }
            >
              <EyeOutlined />
            </ActionIcon>
          ),
        },
        {
          key: "2",
          label: (
            <ActionIcon
              title={actionData?.donationNote ? "Edit-Note" : "Add-Note"}
              onClick={() => setOpenLocationModal(true)}
            >
              <EditOutlined />
            </ActionIcon>
          ),
        },
      ]}
    />
  );

  pagination = response?.data?.pagination;

  const columns = [
    {
      title: "FAM #",
      dataIndex: "userName",
      sorter: true,
      defaultSortOrder: "ascend",
      render: (_, record) => (
        <Link
          text={record?.userName}
          to={`/view-client-detail?id=${record?.clientId}`}
        />
      ),
    },
    {
      title: "Unique Id",
      dataIndex: "uniqueCode",
    },
    {
      title: "Event Name",
      dataIndex: "eventName",
      sorter: true,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: true,
    },
    {
      title: "PickUp Date",
      dataIndex: "pickupDate",
      sorter: true,
      render: (pickupDate) => formatDate(pickupDate, "MM/DD/YYYY"),
    },
    {
      title: "Donor Name",
      dataIndex: "donorName",
      sorter: true,
    },
    {
      title: "DropOff Date",
      dataIndex: "dropOffDate",
      sorter: true,
      render: (dropOffDate) => formatDate(dropOffDate, "MM/DD/YYYY"),
    },

    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
    },

    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            onClick={(e) => {
              e.preventDefault();
              setActionData(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const filterColumns = [
    {
      title: "FAM #",
      dataIndex: "userName",
      sorter: true,
      defaultSortOrder: "ascend",
      render: (_, record) => (
        <Link
          text={record?.userName}
          to={`/view-client-detail?id=${record?.clientId}`}
        />
      ),
    },
    {
      title: "Unique Id",
      dataIndex: "uniqueCode",
    },
    {
      title: "Event Name",
      dataIndex: "eventName",
      sorter: true,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: true,
    },
    {
      title: "PickUp Date",
      dataIndex: "pickupDate",
      sorter: true,
      render: (pickupDate) => formatDate(pickupDate, "MM/DD/YYYY"),
    },
    {
      title: "Donor Name",
      dataIndex: "donorName",
      sorter: true,
    },
    {
      title: "DropOff Date",
      dataIndex: "dropOffDate",
      sorter: true,
      render: (dropOffDate) => formatDate(dropOffDate, "MM/DD/YYYY"),
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <Dropdown overlay={ActionMenu} trigger={["click"]}>
          <a
            onClick={(e) => {
              e.preventDefault();
              setActionData(record);
            }}
          >
            <Space>
              <Moreicon>
                <MoreOutlined />
              </Moreicon>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const handleTabChange = (value) => {
    if (value === "1") {
      setFilter("All");
    } else if (value === "2") {
      setFilter("Received");
    } else if (value === "3") {
      setFilter("Unreceived");
    } else if (value === "4") {
      setFilter("Delivered");
    }
    setPage(1);
    setSortTable({
      column: "CreatedDate",
      order: "desc",
    });
  };

  const handleSearch = (event) => {
    // debugger;
    setSearchValue(event.target.value);
    setPage(1);
  };

  const search = debounce(handleSearch);
  return (
    <>
      <MainContent>
        <PageHeading>
          Donations
          <SearchContainer>
            <Input
              placeholder="Search by Name,Fam-Id or Unique-Id"
              onChange={search}
              // style={{
              //   width: "auto",
              // }}
            />
          </SearchContainer>
        </PageHeading>
        <Tabs defaultActiveKey="1" onChange={handleTabChange}>
          <Tabs.TabPane tab="All" key="1">
            <AllMembers
              dataSource={data}
              loading={loading}
              page={page}
              columns={columns}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Received" key="2">
            <RecievedMembers
              dataSource={data}
              loading={loading}
              page={page}
              columns={filterColumns}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Unreceived" key="3">
            <UnRecievedMembers
              dataSource={data}
              loading={loading}
              page={page}
              columns={filterColumns}
              onChange={handleChange}
              pagination={pagination}
            />
            {/* <ButtonContainer>
              <Button text="Send Mail To Donor" />
            </ButtonContainer> */}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Delivered" key="4">
            <DelieveredMembers
              dataSource={data}
              loading={loading}
              page={page}
              columns={filterColumns}
              onChange={handleChange}
              pagination={pagination}
            />
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
      <Modal
        title={data?.donationNote ? "Edit Note" : "Add Note"}
        open={openLocationModal}
        setOpenModal={setOpenLocationModal}
        modalType={ModalList.DonationModalLocation}
        setIsSuccess={setIsSuccess}
        data={actionData}
      />
    </>
  );
};

export default Donations;
