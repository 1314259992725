import Button from "../../../elements/button/Button";
import {
  PageHeading,
  SearchContainer,
} from "../../admin/campaigns/CampaignStyle";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import { useEffect, useState } from "react";
import ORG_URL from "../../../../api/urls/ORG_URL";
import useMutate from "../../../../api/hooks/useMutate";
import { ActionIcon, ActionIconWrapper } from "../../admin/donors/DonorsStyle";
import Link from "../../../elements/links/Links";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { formatPhoneNumber } from "../../../../utils/phoneNumber";
import { debounce } from "../../../../utils/Debounce";
import toast from "react-hot-toast";
import { COLUMN_WIDTH } from "../../admin/seekers/viewclient/Viewclient";
import { getShortOrg } from "../../../../auth/authAsyncActions";
import { useDispatch } from "react-redux";

let data = "";
let pagination = "";
function SuperAdminClients() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [viewData, setViewData] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [openViewClientsModal, setOpenViewClientsModal] = useState(false);
  const [openEditClientsModal, setOpenEditClientsModal] = useState(false);

  const [page, setPage] = useState(1);
  const [sortTable, setSortTable] = useState({
    column: "name",
    order: "desc",
  });
  const [getOrgList, { response, loading }] = useMutate({
    path: ORG_URL.ORG_LIST,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [deleteEmployees, { loading: deleteEmployeeLoading }] = useMutate({
    path: ORG_URL.DELETEEMPLOYEE,
    method: "delete",
    errorToast: true,
  });
  const [openConfirmDeleteEmployee, setOpenConfirmDeleteEmployee] =
    useState(false);

  const ViewClientsModal = () => {
    setOpenViewClientsModal(true);
  };
  const EditlientsModal = () => {
    setOpenEditClientsModal(true);
  };
  const ConfirmDeleteEmployee = () => {
    setOpenConfirmDeleteEmployee(true);
  };

  useEffect(() => {
    getOrgList({
      params: {
        PageNo: page || 1,
        PageSize: 100,
        SearchValue: searchValue,
        SortColumn: sortTable.column,
        SortOrder: sortTable.order,
      },
      onCompleted: () => {},
      onError: () => {},
    });
    setIsSuccess(false);
  }, [page, sortTable, isSuccess, searchValue]);
  data = response?.data?.records;
  pagination = response?.data?.pagination;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      defaultSortOrder: "descend",
    },

    {
      title: "Sub Domain",
      dataIndex: "subDomain",
      sorter: true,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Phone Number ",
      dataIndex: "phoneNumber",
      sorter: true,
      render: (phoneNumber) => formatPhoneNumber(phoneNumber),
    },

    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIconWrapper>
          <ActionIcon
            title="View"
            onClick={() => {
              setViewData(record);
              ViewClientsModal();
            }}
          >
            <EyeOutlined />
          </ActionIcon>
          <ActionIcon
            title="Delete?"
            onClick={() => {
              setViewData(record);
              ConfirmDeleteEmployee();
            }}
          >
            <DeleteOutlined />
          </ActionIcon>
          <Link
            to="/admin-dashboard"
            onClick={() => {
              localStorage.setItem("organisationId", record.id);
              // window.location?.replace("/admin-dashboard");
              navigate("/admin-dashboard");
              dispatch(getShortOrg(record?.subDomain));
              localStorage.setItem("subDomainData", record?.subDomain);
            }}
            text="Impersonate"
          />
        </ActionIconWrapper>
      ),
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const deleteEmployee = () => {
    const params = {
      OrganisationId: viewData?.id,
    };

    deleteEmployees({
      params,
      onCompleted: () => {
        toast.success("Deleted Successfully");
        if (data.length === 1) {
          let currentPage = page;
          setPage(currentPage - 1);
        } else {
          setIsSuccess(true);
        }
        setOpenConfirmDeleteEmployee(false);
      },
      onError: () => {},
    });
  };
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const search = debounce(handleSearch);
  return (
    <>
      <MainContent>
        <PageHeading>
          Organisations
          <SearchContainer>
            <Input
              placeholder="Search by Name"
              onChange={search}
              // style={{
              //   width: "auto",
              // }}
            />
            <Button
              text={"Add New Organisation"}
              onClick={() => EditlientsModal()}
            />
          </SearchContainer>
        </PageHeading>
        <Table
          dataSource={data}
          loading={loading || deleteEmployeeLoading}
          columns={columns}
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          onChange={handleChange}
          scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
          pagination={{
            pageSize: 100,
            defaultCurrent: page,
            total: pagination?.totalRecordsCount,
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
        />
      </MainContent>

      <Modal
        title="Delete?"
        open={openConfirmDeleteEmployee}
        setOpenModal={setOpenConfirmDeleteEmployee}
        modalType={ModalList.ConfirmDeleteEmployee}
        onConfirm={deleteEmployee}
      />
      <Modal
        title="View Organisation"
        open={openViewClientsModal}
        data={viewData}
        setOpenModal={setOpenViewClientsModal}
        modalType={ModalList.ViewClientsModal}
      />

      <Modal
        title="Add Organisation"
        open={openEditClientsModal}
        setOpenModal={setOpenEditClientsModal}
        setIsSuccess={setIsSuccess}
        modalType={ModalList.EditClientModal}
      />
    </>
  );
}

export default SuperAdminClients;
