import React from "react";
import { Routes as BaseRoutes, Route } from "react-router-dom";
import { ROLES_ENUM } from "../auth/authSlice";
import ProtectedRoute from "./ProtectedRoute";
import SignUp from "../components/pages/admin/signUp/SignUp";
import LogIn from "../components/pages/admin/logIn/LogIn";
import ForgotPassword from "../components/pages/forgotPassword/ForgotPassword";
import Landing from "../components/pages/landing/LandingPage";
import RegisteDonor from "../components/pages/donor/donorRegistration/DonorRegistration";
import AllCampaignList from "../components/pages/donor/allCampaigns/AllCampaignList";
import RegisterSeeker from "../components/pages/seeker/RegisterSeeker/RegisterSeeker";
import FamilyList from "../components/pages/donor/familyList/FamilyList";
import FamilyDetails from "../components/pages/donor/familyDetails/FamilyDetails";
import AddFamilyDetails from "../components/pages/seeker/addFamilyDetails/AddFamilyDetails";
import Profile from "../components/pages/donor/profile/Profile";
import SeekerLogin from "../components/pages/seeker/login/login";
import SelectedFamilies from "../components/pages/donor/selectedFamilies/SelectedFamilies";
import SeekerVerify from "../components/pages/seeker/login/varification";
import SeekerCampaigns from "../components/pages/seeker/allCampaigns/AllCampaignList";
import ReceivedDination from "../components/pages/seeker/receivedDonation/ReceivedDonation";
import SeekerProfile from "../components/pages/seeker/profile/Profile";
import FamilyWishesInfo from "../components/pages/donor/familyWishesInfo/FamilyWishesInfo";
import Seekers from "../components/pages/admin/seekers/Seekers";
import AdminDashboard from "../components/pages/admin/dashboard/Dashboard";
import Campaigns from "../components/pages/admin/campaigns/Campaigns";
import Donors from "../components/pages/admin/donors/Donors";
import Employees from "../components/pages/admin/employees/Employees";
import Adminprofile from "../components/pages/admin/profile/profile";
import DonorLogin from "../components/pages/donor/logIn/LogIn";
import EmployeeForgotPassword from "../components/pages/employee/forgotPassword/ForgotPassword";
import SeekersList from "../components/pages/employee/seekers/Seekers";
import DonorsList from "../components/pages/employee/donors/Donors";
// import SeekerprofileForAdmin from "../components/pages/employee/profile/profile";
import EmployeeSetting from "../components/pages/employee/settings/Settings";
import SuperAdminLogIn from "../components/pages/superAdmin/logIn/LogIn";
import SuperAdminDashboard from "../components/pages/superAdmin/dashboard/SuperAdminDashboard";
import SuperAdminClients from "../components/pages/superAdmin/clients/Clients";
import NotFound from "../components/pages/notFound/NotFound";
// import AdminSettings from "../components/pages/admin/settings/settings";
import Donations from "../components/pages/employee/donations/Donations";
import DonationDetails from "../components/pages/employee/donationDetails/DonationDetails";
import SuperAdminprofile from "../components/pages/superAdmin/profile/profile";
import LandingPageDonor from "../components/pages/landing/LandingPageDonor";
import ViewclientDetails from "../components/pages/admin/seekers/viewclient/Viewclient";
import Loader from "../components/loader/Loader";
import AuthRoute from "./AuthRoute";
import Resetpassword from "../components/pages/forgotPassword/Resetpassword/Resetpassword";
import ViewFamilyDetails from "../components/pages/seeker/viewFamilyDetails/viewFamilyDetails";
import ViewDonorDetails from "../components/pages/admin/donors/ViewDonordetails";
import ClientFamilyWishesInfo from "../components/pages/seeker/familyWishesInfo/ClientFamilyWishesInfo";
import VerifyEmail from "../components/pages/VerifyEmail/VerifyEmail";
import ViewEvent from "../components/pages/admin/viewEvent/ViewEvent.jsx";
import EmpProfile from "../components/pages/employee/profile/profile";
import AdminWishesApproval from "../components/pages/seeker/familyWishesInfo/AdminWishesApproval";
import DonorWishesInfo from "../components/pages/donor/familyWishesInfo/DonorWishesInfo";
const Routes = () => {
  return (
    <BaseRoutes>
      <Route index element={<Loader />} />
      <Route path="/landing" element={<Landing />} />
      {/* DONOR ROUTES START */}
      <Route
        path="/all-events"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.DONOR,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <AllCampaignList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/selected-families"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.DONOR,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <SelectedFamilies />
          </ProtectedRoute>
        }
      />
      <Route
        path="/donor-profile"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.DONOR,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <Profile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/emp-profile"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.DONOR,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <EmpProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/family-list"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.DONOR,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <FamilyList />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/family-details"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.DONOR,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <FamilyDetails />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/family-wishesinfo"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.DONOR,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <FamilyWishesInfo />
          </ProtectedRoute>
        }
      />

      {/* <Route
        path="/family-wishesinfo-admin"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.DONOR,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <DonorWishesInfo />
          </ProtectedRoute>
        }
      /> */}
      {/* DONOR ROUTES END */}

      {/* ADMIN ROUTES START */}

      <Route
        path="/admin-dashboard"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <AdminDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/view-client-detail"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <ViewclientDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/clients"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <Seekers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/events"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <Campaigns />
          </ProtectedRoute>
        }
      />
      <Route
        path="/donors"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <Donors />
          </ProtectedRoute>
        }
      />
      <Route
        path="/donations"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <Donations />
          </ProtectedRoute>
        }
      />
      <Route
        path="/employees"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <Employees />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin-profile"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <Adminprofile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/view-donor-details"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <ViewDonorDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/donation-details"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <DonationDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/view-event"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <ViewEvent />
          </ProtectedRoute>
        }
      />

      {/* ADMIN ROUTES END */}

      {/* SUPERADMIN ROUTES START */}
      <Route
        path="/organisations"
        element={
          <ProtectedRoute rolesAllowed={[ROLES_ENUM.SUPERADMIN]}>
            <SuperAdminClients />
          </ProtectedRoute>
        }
      />
      <Route
        path="/superadmin-dashboard"
        element={
          <ProtectedRoute rolesAllowed={[ROLES_ENUM.SUPERADMIN]}>
            <SuperAdminDashboard />
          </ProtectedRoute>
        }
      />
      {/* SUPERADMIN ROUTES END */}

      {/* SEEKER ROUTES START */}
      <Route
        path="/client-profile"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.SEEKER,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <SeekerProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/client-events"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.SEEKER,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <SeekerCampaigns />
          </ProtectedRoute>
        }
      />
      <Route
        path="/received-donation"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.SEEKER,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <ReceivedDination />
          </ProtectedRoute>
        }
      />
      <Route
        path="/view-family-details"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.SEEKER,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <ViewFamilyDetails />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/add-family-details"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.SEEKER,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <AddFamilyDetails />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/client-donation-details"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.SEEKER,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <ClientFamilyWishesInfo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/client-donation-details-admin"
        element={
          <ProtectedRoute
            rolesAllowed={[
              ROLES_ENUM.SEEKER,
              ROLES_ENUM.ADMIN,
              ROLES_ENUM.EMPLOYEE,
            ]}
          >
            <AdminWishesApproval />
          </ProtectedRoute>
        }
      />
      {/* SEEKER ROUTES END */}

      {/* PUBLIC ROUTES START */}
      <Route
        path="/clients-list"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <SeekersList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/donors-list"
        element={
          <ProtectedRoute
            rolesAllowed={[ROLES_ENUM.ADMIN, ROLES_ENUM.EMPLOYEE]}
          >
            <DonorsList />
          </ProtectedRoute>
        }
      />

      <Route
        path="/login"
        element={
          <AuthRoute>
            <LogIn />
          </AuthRoute>
        }
      />
      <Route path="/verify-email" element={<VerifyEmail />} />

      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/selected-families" element={<SelectedFamilies />} />
      <Route path="/superadmin-profile" element={<SuperAdminprofile />} />
      <Route path="/clients-list" element={<SeekersList />} />
      <Route path="/donors-list" element={<DonorsList />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/register-donor" element={<RegisteDonor />} />
      {/* <Route path="/register-client" element={<RegisterSeeker />} /> */}
      <Route path="/client-login" element={<SeekerLogin />} />
      <Route path="/donor-login" element={<DonorLogin />} />
      <Route path="/verify-client" element={<SeekerVerify />} />
      {/* <Route
        path="/employee-forgot-password"
        element={<EmployeeForgotPassword />}
      /> */}

      <Route path="/employee-settings" element={<EmployeeSetting />} />
      {/* <Route path="/superAdmin-login" element={<SuperAdminLogIn />} /> */}
      <Route path="/donations" element={<Donations />} />

      <Route path="/landing-donor" element={<LandingPageDonor />} />

      <Route path="/reset-password" element={<Resetpassword />} />
      <Route path="*" element={<NotFound />} />
      {/* PUBLIC ROUTES END */}
    </BaseRoutes>
  );
};

export default Routes;
