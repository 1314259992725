import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const PageHeading = styled.h2`
  font-size: 25px;
  font-weight: 600;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  color: ${colors.primary};
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  line-height: 25px;

  @media screen and (max-width: 567px) {
    font-size: 20px;
    align-items: flex-start;
  }
  @media screen and (max-width: 367px) {
    flex-wrap: wrap;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: end;
  }
  @media screen and (max-width: 367px) {
    width: 100%;
  }
`;
