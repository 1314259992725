import React from "react";
import {
  HeaderContainer,
  NavButtons,
  Notifications,
  UserName,
  Dropdown,
  LogoContainer,
} from "./HeaderStyle";
import { DownOutlined } from "@ant-design/icons";
import Link from "../elements/links/Links";
import { BarsOutlined } from "@ant-design/icons";
// import LogoImage from "../../assets/images/logo.png";
import { Logo, Togglebtn } from "../pages/landing/landingPageStyle";
import { Image } from "antd";
import { useNavigate } from "react-router-dom";
import { Space, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleMobileSideNav } from "../../redux/slices/appSlice";
import { Languagechange } from "./HeaderStyle";
import { ROLES_INITIAL_ROUTES } from "../../hooks/useSetAppConfigs";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, shortOrg } = useSelector((state) => state.auth);

  const menu = (
    <Menu
      items={[
        {
          label: <Link text={"English"} />,
          key: "0",
        },
      ]}
    />
  );

  const menu1 = (
    <Menu
      items={[
        {
          label: <Link text={"notification 1"} />,
          key: "0",
        },
        {
          label: <Link text={"notification 2"} />,
          key: "1",
        },
        {
          label: <Link text={"notification 3"} />,
          key: "2",
        },
        {
          label: <Link text={"notification 4"} />,
          key: "3",
        },
        {
          label: <Link text={"notification 5"} />,
          key: "4",
        },
      ]}
    />
  );

  return (
    <>
      <HeaderContainer src={shortOrg?.posterImage}>
        {/* <HeaderContainer> */}
        <LogoContainer>
          <Togglebtn onClick={() => dispatch(toggleMobileSideNav())}>
            <BarsOutlined />
          </Togglebtn>
          <Logo>
            <Image
              preview={false}
              alt={shortOrg?.organisationName}
              src={shortOrg?.logoImage}
              onClick={() => navigate(ROLES_INITIAL_ROUTES[user.roleName])}
            />
          </Logo>
        </LogoContainer>

        <NavButtons>
          <Languagechange>
            <Notifications>
              <Dropdown
                style={{ margin: 0, border: "none" }}
                overlay={menu}
                trigger={["click"]}
              >
                <a
                  style={{ border: "none" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Space>
                    English
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Notifications>
          </Languagechange>
          {/* <Notifications>
            <Dropdown
              style={{ margin: 0, border: "none" }}
              overlay={menu1}
              trigger={["click"]}
            >
              <a style={{ border: "none" }} onClick={(e) => e.preventDefault()}>
                <Space>
                  <Notificaionicon>
                    <Badge>8</Badge>
                    <BellOutlined />
                  </Notificaionicon>
                </Space>
              </a>
            </Dropdown>
          </Notifications> */}
          <UserName>
            Welcome, <span> {user?.firstName}</span>
          </UserName>
        </NavButtons>
      </HeaderContainer>
    </>
  );
}

export default Header;
