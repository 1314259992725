import { Row, Col, Image, Form } from "antd";
import LogoImage from "../../../../assets/images/logo.png";
import {
  BottomForm,
  RightContainer,
  LayoutContainer,
  InnerContent,
  OuterWrapper,
  Heading,
  LeftContainer,
} from "./LoginStyles";
import { useNavigate } from "react-router-dom";
import ORG_URL from "../../../../api/urls/ORG_URL";
import { errorMessages } from "../../../../utils/errorMessages";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import useMutate from "../../../../api/hooks/useMutate";
import Button from "../../../elements/button/Button";
import Input from "../../../elements/input/Input";
import { Customcheckbox } from "./LoginStyles";
import { ROLES_INITIAL_ROUTES } from "../../../../hooks/useSetAppConfigs";
import { useSelector } from "react-redux";
import { Logo } from "../signUp/SingupStlyes";
import CustomImage from "../../../image/LeftContainer";

function LogIn() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { shortOrg, user } = useSelector((state) => state.auth);

  const [postLoginAPI, { response, error, loading }] = useMutate({
    path: ORG_URL.LOGIN,
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });

  if (user) {
    return navigate(ROLES_INITIAL_ROUTES[user.roleName]);
  }

  const onFinish = (data) => {
    const variables = {
      email: data.email,
      password: data.password,
    };

    postLoginAPI({
      variables,
      onCompleted: (data) => {
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("roleName", data.roleName);
        navigate(ROLES_INITIAL_ROUTES[data.roleName]);
      },

      onError: () => {},
    });
  };

  return (
    <>
      <LayoutContainer>
        <InnerContent style={{ maxWidth: "820px", minHeight: "360px" }}>
          <OuterWrapper>
            <Row>
              <Col lg={{ span: 8 }} span={24}>
                {/* <LeftContainer>
                  <Logo>
                    <Image
                      preview={false}
                      // src={shortOrg?.logoImage || Logo}
                      src={LogoImage}
                    />
                  </Logo>
                </LeftContainer> */}

                <CustomImage />

                {/* <span>{shortOrg?.organisationName}</span> */}
              </Col>
              <Col xl={{ span: 16 }} lg={{ span: 16 }} span={24}>
                <RightContainer>
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    form={form}
                    onFinish={onFinish}
                  >
                    <Heading>Login</Heading>
                    <Row gutter={[0, 8]}>
                      <Col span={24}>
                        <Input
                          name="email"
                          pattern={regExpPattern.EMAIL}
                          required={true}
                          regExpMessage={regErrorMessages.EMAIL}
                          label="Email"
                          requiredMessage={errorMessages.EMAIL}
                        />
                      </Col>
                      <Col span={24}>
                        <Input
                          name="password"
                          type="password"
                          required={true}
                          label="Password"
                          requiredMessage={errorMessages.PASSWORD}
                        />
                      </Col>
                      <Col span={24}>
                        <Customcheckbox>
                          {/* <Checkbox>Remember me</Checkbox> */}
                          <div>
                            Forgot password ? &nbsp;
                            <a onClick={() => navigate("/forgot-password")}>
                              Reset
                            </a>
                          </div>
                        </Customcheckbox>
                      </Col>
                      <Col span={24}>
                        <BottomForm>
                          <div>
                            <div>
                              Don't have an account ? &nbsp;
                              <a onClick={() => navigate("/sign-up")}>
                                Register here
                              </a>
                            </div>
                          </div>
                          <Button
                            text={"Login"}
                            htmlType="submit"
                            loading={loading}
                          />
                        </BottomForm>
                      </Col>
                    </Row>
                  </Form>
                </RightContainer>
              </Col>
            </Row>
          </OuterWrapper>
        </InnerContent>
      </LayoutContainer>
    </>
  );
}

export default LogIn;
