import React, { useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Input from "../elements/input/Input";
import { SubDomain, ResponseTab } from "../pages/admin/signUp/SingupStlyes";
import ORG_URL from "../../api/urls/ORG_URL";
import { Row, Col, Image, Form, Input as AntInput } from "antd";
import Button from "../elements/button/Button";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import { errorMessages } from "../../utils/errorMessages";
import { regExpPattern } from "../../utils/regExpPattern";
import { regErrorMessages } from "../../utils/regErrorMessages";
import { Container, StyledForm } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";
import { useNavigate } from "react-router-dom";
import useMutate from "../../api/hooks/useMutate";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import {
  ImagePlaceholder,
  PlaceHolderWrappper,
  Requiredwrapper,
} from "../pages/seeker/RegisterSeeker/RegisterSeeker";

export const DateFromTo = styled.div`
  p {
    padding: 0 10px 0;
    margin-bottom: 7px;
  }
  .ant-form-item-label {
    display: none;
  }
`;
export const To = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding: 0px 0px 15px 12px;
`;
export const EditClientModal = ({ header, info, close, setIsSuccess }) => {
  const [isAvailabale, setIsAvailable] = useState("Not Checked");
  const [address, setAddress] = useState({});
  const [addressError, setAddressError] = useState("");
  const isOrgLoaded = useRef(false);

  const [subDomainValue, setSubDomainValue] = useState({
    value: "",
    error: "",
  });
  const regex = /^[a-zA-Z0-9]{1,20}$/;
  const [imageUpload, setImageUpload] = useState({
    logo: "",
    poster: "",
  });
  const [fileList, setfileList] = useState({
    logo: "",
    poster: "",
  });
  const fileUpload = (e, check) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (check === "logo") {
        setImageUpload((file) => ({
          ...file,
          logo: files,
        }));
        setfileList((data) => ({
          ...data,
          logo: reader.result,
        }));
      } else {
        setImageUpload((file) => ({
          ...file,
          poster: files,
        }));
        setfileList((data) => ({
          ...data,
          poster: reader.result,
        }));
      }
    };
    reader.readAsDataURL(files);
  };

  const navigate = useNavigate();

  const [postOrganisationSignUp, { loading }] = useMutate({
    path: ORG_URL.SIGNUP,
    isFormData: true,
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const [
    postCheckAvailablity,
    {
      response: subDomainResponse,
      loading: availablityLoading,
      error: subDomainError,
    },
  ] = useMutate({
    isFormData: false,
    path: ORG_URL.CHECKFORAVAILABLITYSUBDOMAIN,
  });
  const onFinish = (data) => {
    const variables = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      organisationName: data.organisationName,
      logoImage: imageUpload.logo,
      posterImage: imageUpload.poster,
      password: data.password,
      subDomain: data.subDomain,
      phoneNo: data.contactNumber,
      ...address,
      longitude: address?.lng,
      latitude: address?.lat,
      // gender:1,
      termAndCondition: 1,
    };
    console.log(variables);

    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }

    postOrganisationSignUp({
      variables,
      onCompleted: () => {
        setIsSuccess(true);
        close();
      },
      onError: () => {},
    });
  };

  const checkAvailablity = () => {
    const variables = {
      subdomain: subDomainValue.value.toLowerCase(),
    };
    postCheckAvailablity({
      variables,
      onCompleted: () => {
        setIsAvailable("Available");
      },
      onError: () => {
        setIsAvailable("Not Available");
      },
    });
  };
  const getSubDomainValue = (e) => {
    setIsAvailable("Not Checked");
    setSubDomainValue({
      value: e.target.value.trim(),
      error: !e.target.value ? "Please enter your Sub Domain !" : "",
    });
  };
  const signUp = () => {
    if (subDomainValue.value.length === 0) {
      setSubDomainValue((data) => ({
        ...data,
        error: "Please enter your Sub Domain !",
      }));
    }

    if (!Object?.keys(address)?.length) {
      setAddressError("Please enter the address !");
    }

    form.submit();
  };
  const handleRemoveImage = (imageType) => {
    if (imageType === "logo") {
      setfileList((data) => ({
        ...data,
        logo: "",
      }));
    } else {
      setfileList((data) => ({
        ...data,
        poster: "",
      }));
    }
  };
  // const navigate = useNavigate();
  const [form] = Form.useForm();
  return (
    <Container>
      <Row>
        <Col span={24}>
          <StyledForm
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            form={form}
            onFinish={onFinish}
          >
            <Row gutter={[0, 8]}>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  label="Organisation name"
                  name="organisationName"
                  required={true}
                  requiredMessage={errorMessages.ORGANIZATIONNAME}
                />
              </Col>

              <Col span={24} lg={{ span: 12 }}>
                <Requiredwrapper>
                  <span className="validdata">*</span>
                </Requiredwrapper>
                <AddressSearchDropdown
                  onChange={(address) => {
                    if (Object?.keys(address)?.length) {
                      if (address?.city?.length) {
                        setAddressError("");
                      } else {
                        setAddressError("Please select address with city");
                      }
                    } else {
                      if (isOrgLoaded.current) {
                        setAddressError("Please select address");
                      }
                    }
                    setAddress(address);
                    isOrgLoaded.current = true;
                  }}
                  value={address}
                  required={false}
                  requiredMessage={errorMessages.ADDRESS}
                  label=" Organisation Address"
                  addressError={addressError}
                />
              </Col>

              <Col span={24}>
                <SubDomain>
                  <Input
                    label="Sub Domain"
                    name="subDomain"
                    error={subDomainValue.error}
                    pattern={regExpPattern.SUBDOMAIN}
                    regExpMessage={regErrorMessages.SUBDOMAIN}
                    onChange={(e) => getSubDomainValue(e)}
                  />
                  {subDomainValue?.error && (
                    <div className="errormsg">{subDomainValue.error}</div>
                  )}
                  {isAvailabale == "Not Available" && (
                    <div className="errormsg">{"Not Available"}</div>
                  )}
                  <>
                    {isAvailabale == "Not Available" && (
                      <ResponseTab>
                        <CloseCircleOutlined style={{ color: "red" }} />
                      </ResponseTab>
                    )}

                    {isAvailabale == "Available" && (
                      <ResponseTab>
                        <CheckCircleOutlined style={{ color: "green" }} />
                      </ResponseTab>
                    )}
                  </>

                  <Button
                    className="avalibilitybtn"
                    onClick={() => checkAvailablity()}
                    // hideTextWhileLoading
                    // loading={availablityLoading}

                    text="Check for Availability"
                    disabled={
                      subDomainValue.value.length === 0
                        ? true
                        : subDomainValue.value.length > 0
                        ? !regex.test(subDomainValue.value)
                        : ""
                    }
                    // style={{ marginBottom: "10px" }}
                  />
                </SubDomain>
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  label="First name"
                  name="firstName"
                  required={true}
                  requiredMessage={errorMessages.FIRSTNAME}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  label="Last name"
                  name="lastName"
                  required={true}
                  requiredMessage={errorMessages.LASTNAME}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <PhoneNumberInput
                  type="tel"
                  label="Phone Number"
                  name="contactNumber"
                  required={true}
                  requiredMessage={errorMessages?.CONTACTNUMBER}
                  width="100%"
                />
              </Col>

              <Col span={24} lg={{ span: 12 }}>
                <Input
                  label="Email"
                  name="email"
                  pattern={regExpPattern.EMAIL}
                  required={true}
                  regExpMessage={regErrorMessages.EMAIL}
                  requiredMessage={errorMessages.EMAIL}
                />
              </Col>

              <Col span={24} lg={{ span: 12 }}>
                <Input
                  label="Password"
                  name="password"
                  type={"password"}
                  required={true}
                  pattern={regExpPattern.PASSWORD}
                  regExpMessage={regErrorMessages.PASSWORD}
                  requiredMessage={errorMessages.PASSWORD}
                />
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <AntInput.Password />
                </Form.Item>
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <PlaceHolderWrappper>
                  {!fileList.logo.length > 0 ? (
                    <>
                      <Form.Item
                        label=""
                        name="logoImage"
                        rules={[
                          {
                            required: true,
                            message: "Please insert the logo !",
                          },
                        ]}
                      >
                        <ImagePlaceholder>
                          <CloudUploadOutlined />
                          Upload Logo
                        </ImagePlaceholder>
                        <input
                          name="uploadLogo"
                          type={"file"}
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => fileUpload(e, "logo")}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Image src={fileList.logo} />
                      <CloseOutlined
                        onClick={() => handleRemoveImage("logo")}
                      />
                    </>
                  )}
                  <div>Recommended size: 200px x 200px</div>
                </PlaceHolderWrappper>
              </Col>
              <Col span={24} lg={{ span: 12 }}>
                <PlaceHolderWrappper>
                  {!fileList.poster.length > 0 ? (
                    <>
                      <Form.Item
                        label=""
                        name="posterImage"
                        rules={[
                          {
                            required: true,
                            message: "Please insert the poster !",
                          },
                        ]}
                      >
                        <ImagePlaceholder>
                          <CloudUploadOutlined />
                          Upload Poster Image
                        </ImagePlaceholder>
                        <input
                          name="uploadPosterImage"
                          type={"file"}
                          className={"uploadImage"}
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => fileUpload(e, "poster")}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      <Image src={fileList.poster} />
                      <CloseOutlined
                        onClick={() => handleRemoveImage("poster")}
                      />
                    </>
                  )}
                  <div>Recommended size: Max 1080px wide</div>
                </PlaceHolderWrappper>
              </Col>
            </Row>

            <ButtonContainer noBorder>
              <Button
                text="Cancel"
                className={"SecondaryBtn"}
                htmlType="button"
                onClick={() => close()}
              />
              <Button
                text="Save"
                loading={loading}
                onClick={signUp}
                htmlType="button"
              />
            </ButtonContainer>
          </StyledForm>
        </Col>
      </Row>
    </Container>
  );
};

export default EditClientModal;
