import { Form, Button as AntButton } from "antd";
import styled from "styled-components";
import Colors from "../../../styles/Colors";

const FormItem = styled(Form.Item)`
  margin: 0px !important;
`;

const ButtonContainer = styled(AntButton)`
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "5px 20px"};
  height: ${(props) => props.height || "max-content"};
  color: #fff;
  border-radius: 8px;
  transition: all 0.9s;
  text-decoration: none;
  font-weight: 700;
  white-space: nowwrap;
  cursor: pointer;
  background: ${(props) => props.background || Colors.primary};
  border: 1px solid ${(props) => props.background || Colors.primary};
  display: flex;
  justify-content: center;

  &:hover {
    background: ${Colors.white};
    border: 1px solid ${Colors.primary};
    color: ${Colors.primary};
  }
  &.secondary {
    background: ${Colors.secondary};
    border: 1px solid ${Colors.secondary};
    &:hover {
      background: ${Colors.white};
      border: 1px solid ${Colors.secondary};
      color: ${Colors.secondary};
    }
  }
`;

const Button = ({
  type,
  margin,
  text,
  width,
  className,
  href,
  onClick,
  htmlType,
  background,
  disabled,
  loading = false,
}) => {
  return (
    <FormItem>
      <ButtonContainer
        margin={margin}
        className={className}
        type={type}
        width={width}
        href={href}
        disabled={disabled}
        htmlType={htmlType}
        onClick={onClick}
        background={background}
        loading={loading}
      >
        {text}
      </ButtonContainer>
    </FormItem>
  );
};

export default Button;
