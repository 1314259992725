import React from "react";
import styled from "styled-components";
import colors from "../../styles/Colors";
import { Profiletabs } from "../pages/seeker/profile/ProfileStyle";

export const Nomemebersdata = styled.div`
  text-align: center;
  height: 55vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  h1 {
    color: ${colors.primary};
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
`;

function NoDataFound({ heading = "No Data Found", description = "" }) {
  return (
    <>
      <Profiletabs>
        <Nomemebersdata>
          <h1>{heading}</h1>
          <p>{description}</p>
        </Nomemebersdata>
      </Profiletabs>
    </>
  );
}
export default NoDataFound;
