import { Row, Col, Image, Form } from "antd";
import Logo from "../../../../assets/images/logo.png";
import {
  BottomForm,
  RightContainer,
  LayoutContainer,
  InnerContent,
  OuterWrapper,
  Heading,
} from "./LoginStyles";
import { useNavigate } from "react-router-dom";
import ORG_URL from "../../../../api/urls/ORG_URL";
import { errorMessages } from "../../../../utils/errorMessages";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import useMutate from "../../../../api/hooks/useMutate";
import Button from "../../../elements/button/Button";
import Input from "../../../elements/input/Input";
import { Customcheckbox } from "./LoginStyles";
import { Checkbox } from "antd";
import Loader from "../../../loader/Loader";
import { LeftContainer } from "../../admin/logIn/LoginStyles";
import CustomImage from "../../../image/LeftContainer";

function SuperAdminLogIn() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [postLoginAPI, { response, error, loading }] = useMutate({
    url: ORG_URL.LOGIN,
    onCompleted: () => {},
    onError: () => {},
  });
  const onFinish = (data) => {
    navigate("/superAdmin-dashboard");
  };
  return (
    <>
      {!loading ? (
        <LayoutContainer>
          <InnerContent style={{ maxWidth: "820px", minHeight: "360px" }}>
            <OuterWrapper>
              <Row>
                <Col lg={{ span: 8 }} span={24}>
                  {/* <LeftContainer>
                    <Image preview={false} src={Logo} width={200}></Image>
                  </LeftContainer> */}
                  <CustomImage />
                </Col>
                <Col xl={{ span: 16 }} lg={{ span: 16 }} sm={{ span: 24 }}>
                  <RightContainer>
                    <Form
                      name="basic"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      autoComplete="off"
                      form={form}
                      onFinish={onFinish}
                    >
                      <Heading>Superadmin Login</Heading>
                      <Row gutter={[0, 8]}>
                        <Col span={24}>
                          <Input
                            name="email"
                            pattern={regExpPattern.EMAIL}
                            required={true}
                            regExpMessage={regErrorMessages.EMAIL}
                            label="Email"
                            requiredMessage={errorMessages.EMAIL}
                          />
                        </Col>
                        <Col span={24}>
                          <Input
                            name="password"
                            type="password"
                            required={true}
                            pattern={regExpPattern.PASSWORD}
                            regExpMessage={regErrorMessages.PASSWORD}
                            label="Password"
                            requiredMessage={errorMessages.PASSWORD}
                          />
                        </Col>
                        <Col span={24}>
                          <Customcheckbox>
                            <Checkbox>Remember me</Checkbox>
                          </Customcheckbox>
                        </Col>
                        <Col span={24}>
                          <BottomForm>
                            <Button
                              text={"Login"}
                              htmlType="submit"
                              loading={loading}
                            />
                          </BottomForm>
                        </Col>
                      </Row>
                    </Form>
                  </RightContainer>
                </Col>
              </Row>
            </OuterWrapper>
          </InnerContent>
        </LayoutContainer>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default SuperAdminLogIn;
