import styled from "styled-components";
import { Dropdown as AntDropdown } from "antd";
import colors from "../../../../styles/Colors";
import noimg from "./../../../../assets/images/noimg.png";
// import CampaignImg from "../../../../assets/images/hands.jpg";

export const MainContent = styled.div`
  width: calc(100% - 200px);
  height: 100%;
  margin-left: auto;
  margin-bottom: 10px;
  position: relative;
  top: 100px;
  padding: 20px 20px;
  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 0 20px 20px;
  }
  @media screen and (max-width: 767px) {
    top: 80px;
  }
`;
export const Section = styled.div`
  padding: 10px 0 20px;
`;

export const Addmemeberheading = styled.div`
  font-size: 23px;
  font-weight: 600;
  color: ${colors.primary};
  line-height: 25px;
  // margin-bottom: 30px;
  text-transform: capitalize;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 575px) {
    flex-direction: column;
    gap: 30px;
    align-items: normal;
    margin-bottom: -20px;
  }
`;

export const SectionHeading = styled.h2`
  font-size: 23px;
  font-weight: 600;
  color: ${colors.primary};
  line-height: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
`;

export const CampaignImage = styled.img`
  border: 1px solid ${colors.background};
  padding: 4px;
  width: 100%;
  max-width: 2000px;
  // min-height: 300px;
  max-height: 350px;
  height: auto;
`;

export const CampaignHeading = styled.div`
  font-size: 23px;
  font-weight: 600;
  color: #004d8c;
  line-height: 25px;
  margin-bottom: 18px;
  width: 100%;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    font-size: 23px;
  }
`;

export const CampaignDescription = styled.div`
  font-size: 14px;
  padding: 5px 0;
  font-weight: 600;
`;

export const Dropdown = styled(AntDropdown)`
  // margin-right: 30px;
  padding: 5px;
  // width: 100%;
  border: 1px solid ${colors.grey5};
  border-radius: 5px;
  .ant-space-item {
    color: ${colors.primary};
  }
`;

export const Filters = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 10px;
  @media screen and (max-width: 567px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ActionIcon = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.background};
  padding: 10px 0;
  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;
export const Dropddownfilters = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 5px 10px;
  gap: 5px;
  width: 250px;
  @media only screen and (max-width: 767px) {
    margin: 0 5px;
    padding: 0;
    border: none;
    gap: 2px;
  }
  @media only screen and (max-width: 500px) {
    margin: 0 5px;
    padding: 0;
    border: none;
    gap: 2px;
    // font-size: 12px !important;
  }
  @media screen and (max-width: 567px) {
    width: 100%;
  }
`;

export const Bottominputfilter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: 100%;
  label {
    padding: 0 10px 0 0;
    font-weight: 500;
  }

  .eHCKaZ {
    margin: 0 auto;
  }

  .ant-form-item {
    margin: 0 !important;
  }
  .ant-form-item-control {
    // margin-top: 4px;
  }
`;

export const Status = styled.div`
  padding: 0 0 0 12px;
`;
