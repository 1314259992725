import { Col, Form, Row } from "antd";
import { useState } from "react";
import styled from "styled-components";
import useMutate from "../../../api/hooks/useMutate";
import CAMPAIGN_URL from "../../../api/urls/CAMPAIGN_URL";
import { errorMessages } from "../../../utils/errorMessages";
import Button from "../../elements/button/Button";
import TextAreaB from "../../elements/textArea/TextArea";
import { StyledForm } from "../../pages/admin/signUp/SingupStlyes";
import { Container } from "../EditCampaignModal";
import { ButtonContainer } from "../NewSeekerModal";

export const Wrapper = styled.div`
  padding: 10px;
  @media screen and (max-width: 767px) {
    margin: 0 0 10px 0;
  }
`;

export const HouseDetailModalExclamation = ({
  header,
  info,
  close,
  data,
  id,
  setisSuccess,
  declineStatus,
}) => {
  const [form] = Form.useForm();
  const [postAllMembersForApproval, { loading: approvedMembersLoading }] =
    useMutate({
      path: CAMPAIGN_URL.APPROVEDMEMBERS,
      errorToast: true,
      successToast: true,
      method: "post",
      onCompleted: () => {},
      onError: () => {},
    });
  const [isAllowed, setIsAllowed] = useState(true);
  const handleChange = (e) => {
    const value = e.target.value;
    const reason = value.trim();
    setIsAllowed(!(reason.length > 0));
  };
  const onSubmit = (values) => {
    const variables = {
      CampaignId: id,
      Members: JSON.stringify([
        {
          IsHouseHold: declineStatus?.isHouseHold,
          MemberId: declineStatus?.memberId,
        },
      ]),
      isApproved: false,
      Reason: values?.reason,
    };
    postAllMembersForApproval({
      variables,
      onCompleted: () => {
        setisSuccess(true);
        close();
      },
      onError: () => {},
    });
  };
  return (
    <Container>
      <Wrapper>
        <StyledForm
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
        >
          <Row>
            <Col span={24}>
              <TextAreaB
                label="Reason"
                name="reason"
                required={true}
                onChange={handleChange}
                requiredMessage={errorMessages.REASON}
                initialValue={data?.reason || ""}
              />
            </Col>
          </Row>
          <ButtonContainer noBorder>
            <Button
              text="Cancel"
              className="SecondaryBtn"
              onClick={() => close()}
            />
            <Button
              text="Submit"
              htmlType="submit"
              loading={approvedMembersLoading}
              disabled={isAllowed}
            />
          </ButtonContainer>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};

export default HouseDetailModalExclamation;
