import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const Aproveicon = styled.div`
  background: ${colors.green};
  height: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  border: 1px solid ${colors.green};
  padding: 8px;
  cursor: pointer;

  transition: all 0.5s;
  &:hover {
    background-color: ${colors.white};
    color: ${colors.green};
    border: 1px solid ${colors.green};
  }
`;
export const Declineicon = styled.div`
  background: ${colors.red};
  height: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.red};
  padding: 8px;
  cursor: pointer;
  color: ${colors.white};

  transition: all.5s;
  &:hover {
    background-color: ${colors.white};
    color: ${colors.red};
    border: 1px solid ${colors.red};
  }
`;

export const SeekersTabs = styled.div`
  margin: 20px 0;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  gap: 25px;
`;

export const SeekersTabsItems = styled.div``;
export const Moreicon = styled.div`
  height: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.background};
  padding: 4px;
  cursor: pointer;
  width: max-content;
  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
  svg {
    font-size: 18px;
  }
`;
