import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  mobileSideNav: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleMobileSideNav: (state) => {
      state.mobileSideNav = !state.mobileSideNav;
    },
    closeSideNav: (state) => {
      state.mobileSideNav = false;
    },
  },
});

export const { toggleMobileSideNav } = appSlice.actions;
export const { closeSideNav } = appSlice.actions;

export default appSlice.reducer;
