import React from "react";
import { Profilelayout } from "./profileStyle";
import { Row, Col, Form, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import ORG_URL from "../../../../api/urls/ORG_URL";
import { errorMessages } from "../../../../utils/errorMessages";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import { StyledForm } from "../../admin/signUp/SingupStlyes";
import Button from "../../../elements/button/Button";
import {
  BottomForm,
  FormFields,
  Profiletabs,
} from "../../seeker/profile/ProfileStyle";
import useMutate from "../../../../api/hooks/useMutate";
import Input from "../../../elements/input/Input";
import { MainContent } from "../../donor/familyList/FamilyListStyle";

function SuperAdminprofile() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [postLoginAPI, { response, error, loading }] = useMutate({
    url: ORG_URL.LOGIN,
  });
  const onFinish = (data) => {
    navigate("");
  };
  return (
    <>
      <MainContent>
        <Profilelayout>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Profile" key="1">
              <Row gutter={[0, 12]}>
                <Col span={24} xl={{ span: 24 }}>
                  <Profiletabs
                    style={{ width: "100%", padding: "20px 10px 0" }}
                  >
                    <StyledForm
                      name="basic"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      autoComplete="off"
                      form={form}
                      // onFinish={onSubmit}
                    >
                      <FormFields>
                        <Row gutter={[0, 12]}>
                          <Col span={24} md={{ span: 12 }}>
                            <Input
                              name="firstname"
                              pattern={regExpPattern.EMAIL}
                              required={true}
                              regExpMessage={regErrorMessages.EMAIL}
                              label="First Name"
                              requiredMessage={errorMessages.EMAIL}
                              initialValue="john"
                            />
                          </Col>
                          <Col span={24} md={{ span: 12 }}>
                            <Input
                              name="lastname"
                              pattern={regExpPattern.EMAIL}
                              required={true}
                              regExpMessage={regErrorMessages.EMAIL}
                              label="Last Name"
                              requiredMessage={errorMessages.EMAIL}
                              initialValue="doe"
                            />
                          </Col>

                          <Col span={24} md={{ span: 12 }}>
                            <Input
                              name="email"
                              pattern={regExpPattern.EMAIL}
                              required={true}
                              regExpMessage={regErrorMessages.EMAIL}
                              label="Email"
                              requiredMessage={errorMessages.EMAIL}
                              initialValue="demo@gmail.com "
                            />
                          </Col>
                          <Col span={24} md={{ span: 12 }}>
                            <Input
                              name="contact"
                              pattern={regExpPattern.EMAIL}
                              required={true}
                              regExpMessage={regErrorMessages.EMAIL}
                              label="Phone"
                              requiredMessage={errorMessages.EMAIL}
                              initialValue="(123) 133 1234"
                            />
                          </Col>
                          <Col span={24} md={{ span: 12 }}>
                            <Input
                              name="address"
                              pattern={regExpPattern.EMAIL}
                              required={true}
                              regExpMessage={regErrorMessages.EMAIL}
                              label="Street Address"
                              requiredMessage={errorMessages.EMAIL}
                              initialValue="4774 Bruce Street "
                            />
                          </Col>
                        </Row>
                      </FormFields>
                      <BottomForm>
                        <Button text={"Save"} htmlType="submit" />
                      </BottomForm>
                    </StyledForm>
                  </Profiletabs>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Change Password" key="2">
              <Profiletabs style={{ width: "100%", padding: "20px 10px 0" }}>
                <StyledForm
                  name="basic"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  autoComplete="off"
                  form={form}
                >
                  <Row>
                    <Col span={24} md={{ span: 8 }}>
                      <Input
                        label="Old Password"
                        name="OldPassword"
                        required={true}
                        requiredMessage={errorMessages.OldPassword}
                      />
                    </Col>
                    <Col span={24} md={{ span: 8 }}>
                      <Input
                        label="New Password"
                        name="NewPassword"
                        required={true}
                        requiredMessage={errorMessages.NewPassword}
                      />
                    </Col>
                    <Col spspan={24} md={{ span: 8 }}>
                      <Input
                        label="Confirm New Password"
                        name="ConfirmNewPassword"
                        required={true}
                        requiredMessage={errorMessages.ConfirmNewPassword}
                      />
                    </Col>
                  </Row>
                  <BottomForm style={{ padding: "0 10px" }}>
                    <Button text={"Submit"} htmlType="submit" />
                  </BottomForm>
                </StyledForm>
              </Profiletabs>
            </Tabs.TabPane>
          </Tabs>
        </Profilelayout>
      </MainContent>
    </>
  );
}

export default SuperAdminprofile;
