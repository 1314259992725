import React, { useEffect, useState } from "react";
import {
  CampaignImage,
  MainContent,
} from "../../donor/familyList/FamilyListStyle";
import {
  PageHeading,
  SearchContainer,
} from "../../admin/campaigns/CampaignStyle";
import { CampaignsDescription, Heading, Label } from "./DonationDetailsStyles";
import { Col, Row, Space } from "antd";
import Button from "../../../elements/button/Button";
import styled from "styled-components";
import colors from "../../../../styles/Colors";
import {
  DetailsWrapper,
  FamilyDetail,
  FamilyMemberDetail,
  Info,
  MainHeading,
  WishInfo,
} from "../../donor/familyDetails/FamilyDetailsStyle";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "../../../elements/select/Select";
import { CompassOutlined, DownOutlined } from "@ant-design/icons";
import { Bold } from "../../seeker/addFamilyDetails/addFamilyDetailsStyle";
import {
  capitalFirstLetter,
  formatDate,
  getAgeFromTs,
} from "../../../../utils/dateTime";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import useMutate from "../../../../api/hooks/useMutate";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import { genderAPIFormat } from "../../../../utils/genderFormat";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";
import Loader from "../../../loader/Loader";
import COMMON_URL from "../../../../api/urls/COMMON_URL";

export const Campaignsdetails = styled.div`
  padding: 20px;
  border: 1px solid ${colors.grey5};
  border-radius: 10px;
  margin-top: 20px;
`;
export const Detailslist = styled.div`
  // margin: 40px 0 0 0;
`;
export const MainHeadingcol = styled.h3`
  position: absolute;
  top: -16px;
  background-color: ${colors.white};
  padding: 0 6px;
  left: 9px;
  font-size: 18px;
  color: ${colors.primary};
  font-weight: 600;
  @media screen and (max-width: 567px) {
    font-size: 15px;
    top: -14px;
  }
`;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DonationDetails = () => {
  const query = useQuery();
  const campaignId = query.get("campaignId");
  const donorId = query.get("donorId");
  const donationId = query.get("donationId");
  const clientId = query.get("clientId");
  const globalCodeCategoryId = localStorage.getItem("globalCodeCategories");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const [getDonorDetails, { response: donorsDetails, loading: donorloading }] =
    useMutate({
      path: DONOR_URLS.GET_DONOR_DETAILS,
      method: "get",
      onCompleted: () => {},
      onError: () => {},
    });
  const [
    getGlobalCodes,
    { response: globalCodes, loading: getGlobalCodesLoading },
  ] = useMutate({
    path: COMMON_URL.GET_GLOBAL_CODES,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [changeDonationStatus, { loading: donationStatusLoading }] = useMutate({
    errorToast: true,
    successToast: true,
    path: DONOR_URLS.DONATIONSTATUS,
    method: "post",
    onCompleted: () => {},
    onError: () => {},
  });
  const [getCampaignDetail, { response, loading: campaignLoading }] = useMutate(
    {
      path: SEEKER_URLS.CAMPAIGNDETAIL,
      method: "get",
      onCompleted: () => {},
      onError: () => {},
    }
  );
  const [
    getClientFamilyWishes,
    { response: familyWishes, loading: wishesLoading },
  ] = useMutate({
    path: CAMPAIGN_URL.GET_DONATION_DETAILS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  useEffect(() => {
    getCampaignDetail({
      params: {
        campaignId: campaignId,
      },
      onCompleted: () => {},
      onError: () => {},
    });
    getDonorDetails({
      params: {
        donorId,
      },
      onCompleted: () => {},
      onError: () => {},
    });

    const globalCodeCategories = JSON.parse(globalCodeCategoryId);
    getGlobalCodes({
      params: {
        globalCodeCategoryId: globalCodeCategories[3]?.globalCodeCategoryId,
      },
      onCompleted: () => {},
      onError: () => {},
    });
  }, []);
  useEffect(() => {
    getClientFamilyWishes({
      params: {
        CampaignId: campaignId,
        seekerId: clientId,
      },
      onCompleted: () => {
        setIsSuccess(false);
      },
      onError: () => {},
    });
  }, [isSuccess]);

  const houseHold = familyWishes?.data?.members?.find(
    (member) => member?.isHouseHold === true
  );
  const HouseHoldMembers = familyWishes?.data?.members?.filter(
    (member) => member?.isHouseHold !== true
  );
  const handleDonationStatus = (value) => {
    let status;
    if (value === "Received") {
      status = 11;
    } else if (value === "Unreceived") {
      status = 12;
    } else if (value === "Delivered") {
      status = 13;
    }
    const variables = {
      campaignSelectedSeekerId: Number(donationId),
      status: status,
    };

    changeDonationStatus({
      variables,
      onCompleted: () => {
        setIsSuccess(true);
      },
      onError: () => {},
    });
  };
  const donationStatusOptions = globalCodes?.data?.map((item, index) => {
    item.value = item.codeName;
    item.key = index + 1;
    return item;
  });
  const donationStatus = familyWishes?.data?.donationStatus;
  return (
    <>
      {!(
        donorloading ||
        campaignLoading ||
        donationStatusLoading ||
        wishesLoading
      ) ? (
        <MainContent>
          <PageHeading>
            Donation Details
            <Button text={"Back"} onClick={() => navigate(-1)} />
          </PageHeading>

          <Row>
            <Col span={24} md={{ span: 12 }}>
              <Heading style={{ paddingTop: "10px" }}>
                {response?.data?.campaignName}
              </Heading>
            </Col>

            <Col span={24} md={{ span: 5 }}>
              <Info>
                <Label
                  style={{
                    minWidth: "auto",
                  }}
                >
                  Unique Id:
                </Label>
                {familyWishes?.data?.uniqueCode}
              </Info>
            </Col>
            <Col span={24} md={{ span: 7 }}>
              <Info
                style={{ padding: 0, maxWidth: "400px", marginLeft: "auto" }}
              >
                <Label
                  style={{
                    minWidth: "auto",
                    whiteSpace: "nowrap",
                    lineHeight: "33px",
                  }}
                >
                  Donation Status:
                </Label>
                <Select
                  option={donationStatusOptions}
                  trigger={["click"]}
                  defaultValue={donationStatus}
                  onChange={(value) => handleDonationStatus(value)}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <DownOutlined />
                    </Space>
                  </a>
                </Select>
              </Info>
              {/* </div> */}
            </Col>

            <Col span={24}>
              <CampaignImage src={response?.data?.imageFile} />
            </Col>

            <Col xs={{ span: 24 }}>
              <CampaignsDescription>
                {response?.data?.description}
              </CampaignsDescription>
            </Col>
            <Col span={24}>
              <Campaignsdetails style={{ margin: 0 }}>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Start Date:</Label>
                      {response?.data?.campaignStartDate
                        ? formatDate(
                            response?.data?.campaignStartDate,
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>End Date:</Label>{" "}
                      {response?.data?.campaignEndDate
                        ? formatDate(
                            response?.data?.campaignEndDate,
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Enrollment Period:</Label>{" "}
                      {response?.data?.enrollmentPeriodStartDate &&
                        formatDate(
                          response?.data?.enrollmentPeriodStartDate,
                          "MM/DD/YYYY"
                        )}{" "}
                      To{" "}
                      {response?.data?.enrollmentPeriodEndDate &&
                        formatDate(
                          response?.data?.enrollmentPeriodEndDate,
                          "MM/DD/YYYY"
                        )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Donor Period:</Label>{" "}
                      {formatDate(
                        response?.data?.donorPeriodStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(
                        response?.data?.donorPeriodEndDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Dropoff Dates:</Label>{" "}
                      {formatDate(
                        response?.data?.dropOfStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(response?.data?.dropOfEndDate, "MM/DD/YYYY")}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Client pickup dates:</Label>{" "}
                      {formatDate(
                        response?.data?.pickupStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(response?.data?.pickupEndDate, "MM/DD/YYYY")}
                    </Info>
                  </Col>
                </Row>
              </Campaignsdetails>
              &nbsp;
              <hr />
            </Col>

            <Col xs={{ span: 24 }}>
              <Detailslist>
                <FamilyDetail>
                  <MainHeadingcol>Donor's Details </MainHeadingcol>

                  <Row>
                    <Col span={24}>
                      <Info>
                        <Label>Name:</Label>
                        {` ${capitalFirstLetter(
                          donorsDetails?.data?.firstName
                        )} ${capitalFirstLetter(
                          donorsDetails?.data?.lastName
                        )}`}
                      </Info>
                    </Col>
                    {/* <Col span={24}>
                      <Info>
                        <Label>Gender:</Label>{" "}
                        {genderAPIFormat(donorsDetails?.data?.gender)}
                      </Info>
                    </Col> */}
                    <Col span={24}>
                      <Info>
                        <Label>Street Address:</Label>{" "}
                        {donorsDetails?.data?.placeName}
                      </Info>
                    </Col>
                  </Row>
                </FamilyDetail>
              </Detailslist>
              &nbsp;
              <hr />
            </Col>

            <Col xs={{ span: 24 }}>
              <Detailslist>
                <FamilyDetail>
                  <MainHeadingcol>Donation Note </MainHeadingcol>
                  <Row>
                    <Col span={24}>
                      <Info>
                        <Label>{familyWishes?.data?.donationNote}</Label>
                      </Info>
                    </Col>
                  </Row>
                </FamilyDetail>
              </Detailslist>
              &nbsp;
              <hr />
            </Col>

            <Col xs={{ span: 24 }}>
              <Detailslist>
                <FamilyDetail>
                  <MainHeading>Client's Details </MainHeading>

                  <Row>
                    <Col span={24}>
                      <Info>
                        <Label> Name:</Label>{" "}
                        {` ${capitalFirstLetter(
                          houseHold?.firstName
                        )} ${capitalFirstLetter(houseHold?.lastName)}`}
                      </Info>
                    </Col>
                    <Col span={24}>
                      <Info>
                        <Label>Street Address:</Label>{" "}
                        {familyWishes?.data?.address?.placeName}
                      </Info>
                    </Col>
                    <Col span={24}>
                      <Info>
                        <Label>Gender:</Label>{" "}
                        {genderAPIFormat(houseHold?.gender)}
                      </Info>
                    </Col>
                    <Col span={24}>
                      <Info>
                        <Label>Pickup Dates:</Label>{" "}
                        {formatDate(
                          familyWishes?.data?.campaignDetail?.pickupStartDate,
                          "MM/DD/YYYY"
                        )}{" "}
                        To{" "}
                        {formatDate(
                          familyWishes?.data?.campaignDetail?.pickupEndDate,
                          "MM/DD/YYYY"
                        )}
                      </Info>
                    </Col>
                  </Row>

                  <DetailsWrapper>
                    <Row gutter={[30, 20]}>
                      <Col span={12}>
                        <FamilyMemberDetail>
                          <MainHeading>Household Wishes</MainHeading>
                          <Row>
                            <Col span={24}>
                              {houseHold?.wishes?.length > 0 ? (
                                houseHold?.wishes?.map(
                                  (wishInfo, wishIndex) => {
                                    return (
                                      <WishInfo>
                                        <Label>Wish {wishIndex + 1}:</Label>
                                        {wishInfo.description}
                                      </WishInfo>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                    padding: "20px",
                                  }}
                                >
                                  {`${capitalFirstLetter(
                                    houseHold?.firstName
                                  )} ${capitalFirstLetter(
                                    houseHold?.lastName
                                  )}`}{" "}
                                  is not eligible.
                                </div>
                              )}
                            </Col>
                          </Row>
                        </FamilyMemberDetail>
                      </Col>

                      {HouseHoldMembers?.map((memberInfo, memberIndex) => {
                        return (
                          <Col span={12}>
                            <FamilyMemberDetail>
                              <MainHeading>
                                {`Member ${memberIndex + 1}`} Info
                              </MainHeading>
                              {/* <FamilyData> */}
                              <Info style={{ width: "100%" }}>
                                <Label>Relation:</Label> {memberInfo?.relation}
                              </Info>
                              <Info>
                                <Label> Name:</Label>{" "}
                                {` ${capitalFirstLetter(
                                  memberInfo?.firstName
                                )} ${capitalFirstLetter(memberInfo?.lastName)}`}
                              </Info>

                              <Info>
                                <ul
                                  style={{
                                    width: "100%",
                                    padding: "0",
                                  }}
                                >
                                  {memberInfo?.wishes?.length > 0 ? (
                                    memberInfo?.wishes?.map(
                                      (wishInfo, wishIndex) => {
                                        return (
                                          <WishInfo>
                                            <Label>Wish {wishIndex + 1}:</Label>
                                            {wishInfo.description}
                                          </WishInfo>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div>
                                      {`${capitalFirstLetter(
                                        memberInfo?.firstName
                                      )} ${capitalFirstLetter(
                                        memberInfo?.lastName
                                      )}`}{" "}
                                      is not eligible.
                                    </div>
                                  )}
                                </ul>
                              </Info>
                              {/* </FamilyData> */}
                            </FamilyMemberDetail>
                          </Col>
                        );
                      })}
                    </Row>
                  </DetailsWrapper>
                </FamilyDetail>
              </Detailslist>
            </Col>
          </Row>
        </MainContent>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DonationDetails;
