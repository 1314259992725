import styled from "styled-components";
import Colors from "../../../styles/Colors";
import BannerImage from "../../../assets/images/Gift1.jpg";
import Formbg from "../../../assets/images/form-bg.png";
import colors from "../../../styles/Colors";

export const Header = styled.header`
  padding: 20px 40px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${Colors.primary1};
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  @media screen and (max-width: 991px) {
    height: 70px;
    padding: 0 20px;
  }
`;
export const PaginationWrapper = styled.div`
  width: 100% !important;
  display: flex;
  justify-content: end;
  align-items: center;
`;
export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavItem = styled.div`
  display: flex;
  column-gap: 40px;
  align-items: center;
`;

export const Logo = styled.div`
  background: #f1f1f1;
  padding: 4px;
  box-shadow: 0 0 10px #00000047;
  @media screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 130px;
    @media screen and (max-width: 991px) {
      width: 80px;
    }
  }
`;

export const NavButtons = styled.div`
  display: flex;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

export const NavList = styled.ul`
  padding: 0;
  margin: 0;
  align-items: center;
  display: flex;
  column-gap: 20px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    background: ${colors.offWhite};
    position: absolute;
    top: 70px;
    height: 100vh;

    // left: -205px;
    right: ${(props) => (props.isOpen ? "0" : "-205px")};
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    transition: all.6s;
    &.showNav {
      right: 0;
    }
  }

  // @media screen and (max-width: 575px) {
  //   flex-direction: column;
  //   background: white;
  //   position: absolute;
  //   top: 70px;
  //   left: -100%;
  //   width: 100%;
  //   box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  //   transition: all.6s;
  //   &.toggle {
  //     left: 0;
  //   }
  // }
`;

export const NavLink = styled.li`
  margin: 0;
  list-style: none;
  padding: 10px;
  text-transform: capitalize;
  //   margin-right: 10px;
  text-align: center;
  font-size: 15px;
  color: ${Colors.white};
  transition: all.8s;
  font-weight: 600;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    color: ${Colors.black};
    display: flex;
    width: 200px;
    padding: 18px 20px;
    // border-bottom: 1px solid #e0e0e08c;
  }

  &:hover {
    transition: all.5s;
    color: ${Colors.primary};
  }
`;

export const Banner = styled.div`
  height: 100vh;
  // background-color: ${Colors.background},linear-gradient(273deg, #4493e7ad, rgb(247 118 0 / 60%)),;
  // background-color:linear-gradient(45deg, #497f00, rgba(73, 127, 0, 0.2901960784)),
  background-image: url(${BannerImage});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 60px 0;

  @media screen and (max-width: 767px) {
    padding: 70px 30px 0;
    height: 65vh;
  }

  @media screen and (max-width: 575px) {
    padding: 70px 20px 0;
  }
`;
export const BannerContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
export const LeftContainer = styled.div`
  width: 40%;
  padding: 0 30px;
  @media screen and (max-width: 1199px) {
    width: 60%;
  }
  @media screen and (max-width: 991px) {
    width: 80%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
  }
`;
export const Description = styled.div`
  font-size: 16px;
`;
export const PageHeading = styled.h1`
  font-size: 42px;
  font-weight: 600;
  color: ${Colors.primary};
  line-height: 42px;
  margin-bottom: 13px;
  text-transform: capitalize;
  @media screen and (max-width: 767px) {
    font-size: 38px;
  }
`;

export const BottomButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  @media screen and (max-width: 424px) {
    flex-direction: column;
    text-align: center;
    margin-top: 16px;
    gap: 15px;
  }
`;

export const Contact = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px;
  background-image: url(${Formbg});
  // background-position: center;

  @media screen and (max-width: 767px) {
    padding: 60px 30px;
  }
  @media screen and (max-width: 575px) {
    padding: 60px 20px;
  }
  h2 {
    font-size: 40px;
    font-weight: 600;
    color: ${Colors.white};
    text-align: center;
    text-transform: capitalize;
  }
`;

export const ContactHeading = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: ${Colors.white};
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 20px;
`;

export const ConatctForm = styled.form``;

export const FormFields = styled.div`
  padding: 30px 50px;
  width: 40%;
  margin: 0 auto;
  background: ${Colors.white};
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media screen and (max-width: 1199px) {
    width: 60%;
  }
  @media screen and (max-width: 991px) {
    width: 80%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 30px;
  }

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
`;

export const ConatctLabel = styled.label``;

export const ConatctFeild = styled.div``;

export const BottomForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 0 10px;
  justify-content: end;
  a {
    width: 100%;
    text-align: center;
    border: 1px solid ${Colors.primary};
    padding: 8px;
  }
`;
export const Campaigns = styled.section`
  padding: 60px 40px;
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
  @media screen and (max-width: 575px) {
    padding: 20px;
  }
`;
export const SectionHeading = styled.h2`
  font-size: 25px;
  font-weight: 600;
  color: ${Colors.primary};
  line-height: 25px;
  margin-bottom: 45px;
  text-transform: capitalize;
`;

export const CampaignList = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const Togglebtn = styled.div`
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
    font-size: 20px;
    color: ${Colors.white};
    margin-right: 20px;
    border: 1px solid ${Colors.white};
    padding: 0 5px;
    border-radius: 5px;
  }
  @media screen and (max-width: 767px) {
    // font-size: 15px;
    margin-right: 10px;
  }
`;

export const NavButtonsMobile = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
  }
`;

export const Newslettercontainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 50px;
  // background: ${Colors.secondary};
  background: -webkit-linear-gradient(45deg, #004d8c, #ef7b14 60%);
  @media screen and (max-width: 767px) {
    padding: 50px 20px;
  }
  @media screen and (max-width: 575px) {
    padding: 50px 10px;
  }
`;
export const Newsletterwrapper = styled.div`
  // width: 50%;
  margin: 0 auto;
  border: 1px solid ${Colors.white};
  border-radius: 10px;
  padding: 40px;
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
`;
export const Newsheading = styled.h2`
  font-size: 40px;
  font-weight: 600;
  color: ${Colors.white};

  // background: -webkit-linear-gradient(45deg, #004d8c, #ef7b14 60%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  text-align: center;
  text-transform: capitalize;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    font-size: 35px;
  }
`;
export const Suscriberwrapper = styled.div`
  // display: flex;
  // gap: 25px;
  // padding-top: 10px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const Newspara = styled.p`
  font-size: 17px;
  margin: 10px 0 6px 0;
  text-align: center;
  color: ${Colors.white};
`;

export const Newstext = styled.h4`
  font-size: 20px;
  margin-bottom: 4px;
  text-align: center;
  color: ${Colors.white};
`;
