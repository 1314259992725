import { Checkbox, Col, Row } from "antd";
import {
  ActionIcon,
  MainContent,
  Section,
  SectionHeading,
} from "../../donor/familyList/FamilyListStyle";

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import styled from "styled-components";
import { formatPhoneNumber } from "../../../../utils/phoneNumber";
import {
  ButtonContainer,
  FamilyDetail,
  Info,
  MainHeading,
} from "../../donor/familyDetails/FamilyDetailsStyle";
import Button from "../../../elements/button/Button";
import { Link, useNavigate } from "react-router-dom";
import {
  COLUMN_WIDTH,
  EventsDetailstab,
  InputFullWidthMobile,
} from "../seekers/viewclient/Viewclient";
import { useQuery } from "../../../../utils/useQuery";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import useMutate from "../../../../api/hooks/useMutate";
import Loader from "../../../loader/Loader";
import { genderAPIFormat } from "../../../../utils/genderFormat";
import { debounce } from "../../../../utils/Debounce";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import { EyeOutlined } from "@ant-design/icons";
import { formatDate, getAgeFromTs } from "../../../../utils/dateTime";
import Search from "antd/lib/transfer/search";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";

const ClientTable = styled.div`
  margin-top: 10px;
  @media (max-width: 567px) {
    margin-top: 0px;
  }
`;

const Label = styled.div`
  font-weight: 600;
  min-width: 140px;
  padding-right: 10px;
`;

export const Spacer = styled.div`
  height: 15px;
`;
let pagination = "";
let participatedEventPagination = "";
export const ViewDonorDetails = () => {
  const [availableEventPage, setavailableEventPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [availableSortTable, setavailableSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [page, setPage] = useState(1);
  const [openConfirmModal, setOpenConfirmMiodal] = useState(false);
  const [availableEventSearch, setAvailableEventSearch] = useState("");
  const [sortTable, setSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [sendUnselectedFamilies, { loading: unselectedFamilyLoader }] =
    useMutate({
      path: CAMPAIGN_URL.UNSELECTEDFAMILIES,
      method: "post",
      successToast: true,
      errorToast: true,
      onCompleted: () => {},
      onError: () => {},
    });
  const [
    getDonorDetails,
    { response: donorResponse, loading: donorDetailLoading },
  ] = useMutate({
    path: DONOR_URLS.GET_DONOR_DETAILS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const query = useQuery();
  const id = query.get("id");
  const navigate = useNavigate();
  useEffect(() => {
    getDonorDetails({
      params: {
        DonorId: id,
      },
      onCompleted: () => {},
      onError: () => {},
    });
  }, []);
  const [
    getParticipatedFamlies,
    { response: participatedResponse, loading: participatedResponseLoading },
  ] = useMutate({
    path: DONOR_URLS.SELECTED_FAMILY_LIST,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [
    getFamilies,
    { response: familyDataResponse, loading: familyDataResponseLoading },
  ] = useMutate({
    path: SEEKER_URLS.CAMPAIGNS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const param = {
    pageNo: page || 1,
    PageSize: 100,
    searchValue: searchValue,
    sortColumn: sortTable.column,
    sortOrder: sortTable.order,
    donorId: id,
    isCampaign: false,
  };
  useEffect(() => {
    getFamilies({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [page, sortTable, searchValue]);
  pagination = familyDataResponse?.data?.pagination;

  const pariticipatedParams = {
    pageNo: availableEventPage || 1,
    PageSize: 100,
    searchValue: availableEventSearch,
    sortColumn: availableSortTable.column,
    sortOrder: availableSortTable.order,
    donorId: id,
  };
  useEffect(() => {
    getParticipatedFamlies({
      params: pariticipatedParams,
      onCompleted: () => {
        setIsSuccess(false);
      },
      onError: () => {},
    });
  }, [availableEventPage, availableSortTable, availableEventSearch, isSuccess]);
  participatedEventPagination = participatedResponse?.data?.pagination;

  const columns1 = [
    {
      title: "Event Name",
      dataIndex: "campaignName",
      sorter: true,
      // render: (text, record) => (
      //   <Link
      //     text={text}
      //     to={`/family-wishesinfo/?id=${record?.seekerId}&campaignId=${record?.campaignId}`}
      //   />
      // ),
    },
    {
      title: "# of Children",
      dataIndex: "noOfChildrens",
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: "Unselect Family",
      render: (text, record) => (
        <Checkbox
          checked={selectedClients?.find((check) => {
            return (
              check.seekerId === record.seekerId &&
              check.campaignId === record?.campaignId
            );
          })}
          onChange={(e) => selectClients(e, record)}
        >
          Unselect family
        </Checkbox>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIcon
          onClick={() =>
            navigate(
              `/family-wishesinfo?id=${record?.campaignId}&seekerId=${record?.seekerId}&donorId=${id}`
            )
          }
        >
          <EyeOutlined />
        </ActionIcon>
      ),
    },
  ];

  const columns = [
    {
      title: "Id#",
      dataIndex: "campaignId",
      sorter: true,
    },

    {
      title: " Event  Name",
      dataIndex: "campaignName",
      sorter: true,
    },
    {
      title: "Start Date",
      dataIndex: "campaignStartDate",
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
      sorter: true,
    },

    {
      title: "End Date",
      dataIndex: "campaignEndDate",
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIcon
          title="View"
          onClick={() =>
            navigate(
              `/family-list?&id=${record?.campaignId}&admin=true&donorId=${id}`
            )
          }
        >
          <EyeOutlined />
        </ActionIcon>
      ),
    },
  ];
  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };

  const handleparticipatedEventsChange = (pagination, filters, sorter) => {
    setavailableEventPage(pagination.current);
    if (Object.keys(sorter).length) {
      setavailableSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const handleSearch = (event) => {
    setAvailableEventSearch(event.target.value);
    setavailableEventPage(1);
  };

  const availableSearch = debounce(handleSearch);
  const handleparticipatedSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const search = debounce(handleparticipatedSearch);
  const selectClients = (e, record) => {
    const checked = e.target.checked;
    if (checked) {
      const data = {
        campaignId: record?.campaignId,
        seekerId: record?.seekerId,
      };

      setSelectedClients((val) => [...val, data]);
    } else {
      const filterClients = selectedClients.filter((family) => {
        if (family?.campaignId === record?.campaignId) {
          return family.seekerId !== record.seekerId;
        }
        return family;
      });

      setSelectedClients(filterClients);
    }
  };
  const sendSelectedFamiliesForNotToAdopt = () => {
    const variables = {
      DonorId: id,
      CampaignSeekers: JSON.stringify(selectedClients),
    };
    sendUnselectedFamilies({
      variables,
      onCompleted: () => {
        setOpenConfirmMiodal(false);
        setIsSuccess(true);
      },
      onError: () => {
        setOpenConfirmMiodal(false);
      },
    });
  };
  return (
    <>
      {!donorDetailLoading ? (
        <MainContent>
          <Section>
            <SectionHeading>
              Donor Details
              <Button text={"Back"} onClick={() => navigate(-1)} />
            </SectionHeading>
            <FamilyDetail>
              <MainHeading>Personal details</MainHeading>
              <Row>
                <Col span={24} md={{ span: 24 }}>
                  <div style={{ textAlign: "right", width: "100%" }}>
                    <Button
                      className="secondary"
                      text={"Impersonate"}
                      onClick={() =>
                        navigate(`/donor-profile?id=${id}&admin=true`)
                      }
                      margin="-15px -10px 5px auto"
                    />
                  </div>
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                  <Info>
                    <Label>Name :</Label>{" "}
                    {`${donorResponse?.data?.firstName} ${donorResponse?.data?.lastName}`}
                  </Info>
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                  <Info>
                    <Label>Phone Number :</Label>
                    {formatPhoneNumber(donorResponse?.data?.phoneNo)}
                  </Info>
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                  <Info>
                    <Label>City :</Label> {donorResponse?.data?.city}
                  </Info>
                </Col>
                <Col span={24} lg={{ span: 12 }}>
                  <Info>
                    <Label>Email Address :</Label>
                    {donorResponse?.data?.email}
                  </Info>
                </Col>
                <Col span={24} lg={{ span: 24 }}>
                  <Info>
                    <Label>Street Address :</Label>{" "}
                    {donorResponse?.data?.placeName}
                  </Info>
                </Col>
              </Row>
            </FamilyDetail>
          </Section>
          <Spacer />

          <EventsDetailstab>
            <SectionHeading> Selected Families</SectionHeading>
            <InputFullWidthMobile>
              <Search
                placeholder="Search by Event Name"
                // onSearch={onSearch}
                onChange={availableSearch}
                enterButton
                style={{
                  width: "auto",
                }}
              />
            </InputFullWidthMobile>
          </EventsDetailstab>
          <ClientTable>
            <InputFullWidthMobile>
              <Table
                dataSource={participatedResponse?.data?.records}
                loading={participatedResponseLoading}
                sortDirections={["ascend", "descend", "ascend"]}
                columns={columns1}
                scroll={{ y: 400, x: columns1.length * COLUMN_WIDTH }}
                onChange={handleparticipatedEventsChange}
                showSorterTooltip={false}
                pagination={{
                  pageSize: 100,
                  defaultCurrent: page,
                  total: pagination?.totalRecordsCount,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
              />
            </InputFullWidthMobile>
          </ClientTable>
          <Spacer />
          <InputFullWidthMobile>
            <ButtonContainer style={{ margin: 0 }}>
              <Button
                text="Unselect Families"
                disabled={!selectedClients?.length ? true : false}
                onClick={() => setOpenConfirmMiodal(true)}
              />
            </ButtonContainer>
          </InputFullWidthMobile>
          <Spacer />
          <Spacer />
          <EventsDetailstab>
            <SectionHeading>Available Events</SectionHeading>
            <InputFullWidthMobile>
              <Search
                placeholder="Search by Event Name"
                onChange={search}
                enterButton
                style={{
                  width: "auto",
                }}
              />
            </InputFullWidthMobile>
          </EventsDetailstab>
          <ClientTable>
            <div style={{ position: "relative" }}>
              <Table
                dataSource={familyDataResponse?.data?.records}
                loading={familyDataResponseLoading}
                sortDirections={["ascend", "descend", "ascend"]}
                columns={columns}
                scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
                onChange={handleChange}
                showSorterTooltip={false}
                pagination={{
                  pageSize: 100,
                  defaultCurrent: availableEventPage,
                  total: participatedEventPagination?.totalRecordsCount,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
              />
            </div>
          </ClientTable>
          <Modal
            title="Unselect Families"
            open={openConfirmModal}
            header={"Are you sure, you want to unselect families"}
            setOpenModal={setOpenConfirmMiodal}
            loading={unselectedFamilyLoader}
            modalType={ModalList.ConfirmSeekerSelection}
            onConfirm={sendSelectedFamiliesForNotToAdopt}
            successIcon={true}
            noDelete
          />
        </MainContent>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default ViewDonorDetails;
