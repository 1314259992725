import moment from "moment";

const DATE_FORMAT = "MM-DD-YYYY";
const DATE_TIME_FORMAT = "yyyy-MM-dd hh:mm A";

export const formatDate = (date, dateFormat = DATE_FORMAT) => {
  if (date instanceof Date) {
    return moment(date).format(dateFormat);
  }
  return moment(date * 1000).format(dateFormat);
};

export const formatDateTime = (date) => {
  if (date instanceof Date) {
    return moment(date).format(DATE_TIME_FORMAT);
  }

  return moment(date * 1000).format(DATE_TIME_FORMAT);
};
export const getTs = (date) => {
  return moment(date).unix();
};
export const getAgeFromTs = (dob) => {
  var date = new Date(Number(dob) * 1000);
  //calculate month difference from current date in time
  var month_diff = Date.now() - date.getTime();

  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);

  //extract year from date
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  var age = Math.abs(year - 1970);

  return age;
};

export const capitalFirstLetter = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export const removeSpaces = (str) => {
  return str?.replace(/\s+/g, "");
};
export const initialDateTime = (date) => {
  return moment(moment(date * 1000));
};
