import { Image } from "antd";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";
import {
  CardActions,
  MainCard,
  CardImage,
  CardHeading,
  CardContent,
  CardDescription,
  CampaignData,
} from "../cards2/Cards2Style";

function Tiles({
  title,
  cardDescription,
  enrollmentPeriod,
  address,
  imageFile,
  campaignId,
  onClick,
}) {
  const navigate = useNavigate();
  return (
    <>
      <MainCard>
        <CardImage>
          <Image preview={false} src={imageFile} />
        </CardImage>
        <CardContent>
          <CardHeading>{title}</CardHeading>
          <CardDescription>
            <p>{cardDescription}</p>
          </CardDescription>
          <CampaignData>
            Enrollment period:&nbsp;
            <span className="bold">{enrollmentPeriod}</span>
          </CampaignData>
          <CampaignData>
            Client pickup address:
            <span className="bold"> {address} </span>
          </CampaignData>
        </CardContent>
        <CardActions>
          <Button
            width={"100%"}
            text={"View Details"}
            onClick={() => onClick()}
          />
        </CardActions>
      </MainCard>
    </>
  );
}
export default Tiles;
