import { code } from "./COMMON_URL";

const CAMPAIGN_URL = {
  GET_CAMPAIGN_LIST: `/campaign/get-campaign-list`,
  GET_CAMPAIGN_LIST_LANDING: `/get-current-running-campaigns`,
  CREATECAMPAIGN: `/campaign/add-update-campaign`,
  DELETECAMPAIGN: `/campaign/delete-campaign`,
  UPDATECAMPAIGNSTATUS: `/campaign/update-campaign-status`,
  CAMPAIGNBYSEEKER: "/campaign/campaign-by-seeker",
  GET_DONATION_DETAILS: "/campaign/get-donation-details",
  GET_DONOR_DONATION_DETAILS: "/campaign/get-donation-details-list-for-donors",
  UPDATE_SEEKER_WISHES: "/campaign/update-seeker-wishes",
  APPROVEDMEMBERS: "/seeker/wishes-approval",
  UNSELECTEDFAMILIES: "/campaign/unselect-campaign-selected-seekers",
};

export default CAMPAIGN_URL;
