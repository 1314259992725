import { code } from "./COMMON_URL";
const EMPLOYEE_URLS = {
  CREATEEMPLOYEE: `/employee/create-employee`,
  GETORGANISATIONEMPLOYEE: `/organisation/get-organisation-employee`,
  DELETEEMPLOYEE: `/employee/delete-employee`,
  GETEMPLOYEELIST: `/employee/detail-employee`,
  UPDATE_STATUS: `/employee/update-employee-status`,
  ADD_EDIT_NOTE: "/add-update-comments",
};

export default EMPLOYEE_URLS;
