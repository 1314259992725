import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { ButtonContainer } from "./NewSeekerModal";
import Button from "../elements/button/Button";
import { Container } from "./EditCampaignModal";
import { formatPhoneNumber } from "../../utils/phoneNumber";


const Label = styled.div`
  font-weight: 600;
  min-width: 170px;
  padding-right: 10px;
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  padding: 7px 30px 7px 0;
`;


const Gap = styled.div`
  padding: 0 0 20px 0;
`;

export const ViewClientsModal = ({ close, data }) => {
  return (
    <Container>
      <Gap>
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Organisation name :</Label> {data?.name}
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Sub Domain :</Label> {data?.subDomain}
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Phone Number :</Label>
              {formatPhoneNumber(data?.phoneNumber)}
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Email Address :</Label> {data?.email}
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Organisation Address :</Label>
              {data?.placeName}
            </Info>
          </Col>
        </Row>
      </Gap>
      <ButtonContainer noBorder>
        <Button
          text="Cancel"
          className={"SecondaryBtn"}
          onClick={() => close()}
        />
      </ButtonContainer>
    </Container>
  );
};

export default ViewClientsModal;
