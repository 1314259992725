import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../elements/button/Button";
import { MainContent, SectionHeading } from "../familyList/FamilyListStyle";
import {
  FamilyDetail,
  MainHeading,
  Info,
  Label,
  FamilyMemberDetail,
  ButtonContainer,
  DetailsWrapper,
} from "./FamilyDetailsStyle";
import { WishInfo } from "./FamilyDetailsStyle";
import { familyDetails } from "../../../../utils/fakeJson";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import { Col, Row } from "antd";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";
import useMutate from "../../../../api/hooks/useMutate";
import Loader from "../../../loader/Loader";
import { capitalFirstLetter, getAgeFromTs } from "../../../../utils/dateTime";
import { Detailslist } from "../../employee/donationDetails/DonationDetails";

import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import { genderAPIFormat } from "../../../../utils/genderFormat";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function FamilyDetails() {
  const navigate = useNavigate();
  const query = useQuery();
  const clientId = query.get("clientId");
  const campaignId = query.get("campaignId");
  const donorId = query.get("donorId");
  const [openFamlyMemberWishesModal, setOpenFamlyMemberWishesModal] =
    useState(false);
  const FamlyMemberWishes = () => {
    setOpenFamlyMemberWishesModal(true);
  };
  const [FamilyMember, setFamilyMember] = useState(familyDetails);
  const handleChange = (e, memberIndex, wishInfoIndex) => {
    const checked = e.target.checked;
    const updatesWishes = FamilyMember.map((famItem, famIndex) => {
      famItem.wishes.map((wishItem, wishIndex) => {
        if (famIndex === memberIndex && wishIndex === wishInfoIndex) {
          wishItem.checked = checked;
        }
        return wishItem;
      });
      return famItem;
    });
    setFamilyMember(updatesWishes);
  };
  const [addFamilyToDonate, { loading: addFamilyToDonateLoading }] = useMutate({
    path: DONOR_URLS.ADD_SELECTED_FAMILY,
    method: "post",
    onCompleted: () => {},
    onError: () => {},
  });

  const [getClientFamilyWishes, { response, loading }] = useMutate({
    path: CAMPAIGN_URL.GET_DONOR_DONATION_DETAILS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  useEffect(() => {
    const params = {
      CampaignId: campaignId,
      seekerId: clientId,
    };

    getClientFamilyWishes({
      params,
      onCompleted: () => {},
      onError: () => {},
    });
  }, []);
  const campaignDetails = response?.data?.campaignDetail;
  const houseHold =
    response?.data?.members?.find((member) => member.isHouseHold == true) || {};

  const members =
    response?.data?.members?.filter((member) => member.isHouseHold != true) ||
    [];
  console.log(houseHold, members);
  const addSelectedFamilies = () => {
    const variables = {
      campaignId: campaignId,
      seekers: JSON.stringify([clientId]),
    };

    if (donorId) {
      variables.donorId = donorId;
    }
    addFamilyToDonate({
      variables,
      onCompleted: () => {
        setOpenFamlyMemberWishesModal(false);
        navigate(-1);
      },
      onError: () => {},
    });
  };
  return (
    <>
      {!loading ? (
        <MainContent>
          <Row>
            <Col xs={{ span: 24 }}>
              <SectionHeading>
                Client Requests
                <Button
                  text={"Back"}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </SectionHeading>
            </Col>

            <Col xs={{ span: 24 }}>
              <Detailslist>
                <FamilyDetail>
                  <MainHeading>Wishes </MainHeading>
                  <DetailsWrapper>
                    <Row gutter={{ xs: 5, sm: 16, md: 24}} style={{ width: "100%" }}>
                      {Object.keys(houseHold)?.length > 0 ? (
                        <Col span={24} md={{ span: 12 }}>
                          <FamilyMemberDetail>
                            <MainHeading>Household Wishes</MainHeading>
                            <Row>
                              <Col span={24} md={{ span: 24 }}>
                                <Info>
                                  <Label>Age:</Label>{" "}
                                  {getAgeFromTs(houseHold?.dob)}
                                </Info>
                              </Col>
                              <Col span={24} md={{ span: 24 }}>
                                <Info>
                                  <Label> Gender:</Label>{" "}
                                  {genderAPIFormat(houseHold?.gender)}
                                </Info>
                              </Col>
                              {houseHold?.wishes?.length > 0 ? (
                                houseHold?.wishes?.map(
                                  (wishInfo, wishIndex) => {
                                    console.log(houseHold, "houseHold");
                                    return (
                                      <Col span={24} md={{ span: 24 }}>
                                        <WishInfo>
                                          <Label>Wish {wishIndex + 1}:</Label>
                                          {wishInfo?.description}
                                        </WishInfo>
                                      </Col>
                                    );
                                  }
                                )
                              ) : (
                                <Col span={24} md={{ span: 24 }}>
                                  <WishInfo>
                                    <Label style={{ marginLeft: "15px" }}>
                                      Wishes:
                                    </Label>
                                    Household is not eligible.
                                  </WishInfo>
                                </Col>
                              )}
                            </Row>
                          </FamilyMemberDetail>
                        </Col>
                      ) : (
                        ""
                      )}
                      {members?.map((memberInfo, memberIndex) => {
                        return (
                          <Col span={24} md={{ span: 12 }}>
                            <FamilyMemberDetail>
                              <MainHeading>
                                Member {memberIndex + 1}
                              </MainHeading>

                              <Row>
                                <Col span={24} md={{ span: 24 }}>
                                  <Info>
                                    <Label>Age:</Label>{" "}
                                    {getAgeFromTs(memberInfo?.dob)}
                                  </Info>
                                </Col>
                                <Col span={24} md={{ span: 24 }}>
                                  <Info>
                                    <Label> Gender:</Label>{" "}
                                    {genderAPIFormat(memberInfo?.gender)}
                                  </Info>
                                </Col>
                                <Col span={24} md={{ span: 24 }}>
                                  <Info>
                                    <Label> Relation:</Label>{" "}
                                    {memberInfo?.relation}
                                  </Info>
                                </Col>
                                {/* <Col span={24} md={{ span: 24 }}>
                                  <Info>
                                    <Label>Name:</Label>{" "}
                                    {`${capitalFirstLetter(
                                      memberInfo?.firstName
                                    )} ${capitalFirstLetter(
                                      memberInfo.lastName
                                    )}`}
                                  </Info>
                                </Col> */}
                                {memberInfo?.wishes?.length > 0 ? (
                                  memberInfo?.wishes?.map(
                                    (wishInfo, wishIndex) => {
                                      return (
                                        <Col span={24} md={{ span: 24 }}>
                                          <WishInfo>
                                            <Label>Wish {wishIndex + 1}:</Label>
                                            {wishInfo?.description}
                                          </WishInfo>
                                        </Col>
                                      );
                                    }
                                  )
                                ) : (
                                  <Col span={24} md={{ span: 24 }}>
                                    <WishInfo>
                                      <Label>Wishes:</Label>
                                      {`${capitalFirstLetter(
                                        memberInfo?.firstName
                                      )} ${capitalFirstLetter(
                                        memberInfo?.lastName
                                      )}`}{" "}
                                      is not eligible.
                                    </WishInfo>
                                  </Col>
                                )}
                              </Row>
                            </FamilyMemberDetail>
                          </Col>
                        );
                      })}
                    </Row>
                  </DetailsWrapper>
                </FamilyDetail>
              </Detailslist>
            </Col>
          </Row>
          <ButtonContainer>
            {/* <Button
              text={"Back"}
              onClick={() => navigate(-1)}
              margin="0 10px 0 0"
            /> */}
            <Button
              text={"Select Family"}
              className="secondary"
              onClick={() => FamlyMemberWishes()}
            />
          </ButtonContainer>
        </MainContent>
      ) : (
        <Loader />
      )}
      <Modal
        title="Select Family"
        open={openFamlyMemberWishesModal}
        loading={addFamilyToDonateLoading}
        setOpenModal={setOpenFamlyMemberWishesModal}
        modalType={ModalList.ConfirmSingleSeekerSelection}
        onConfirm={addSelectedFamilies}
        noDelete
      />
    </>
  );
}
export default FamilyDetails;
