import React, { useState } from "react";
import {
  CampaignImage,
  MainContent,
} from "../../donor/familyList/FamilyListStyle";
import { PageHeading } from "../../admin/campaigns/CampaignStyle";
import {
  CampaignsDescription,
  Heading,
  Label,
} from "../../employee/donationDetails/DonationDetailsStyles";
import { Col, Row } from "antd";
// import CampaignImage from "../../../../assets/images/Donate-gifts.jpeg";
import Button from "../../../elements/button/Button";
import styled from "styled-components";
import colors from "../../../../styles/Colors";
import {
  DetailsWrapper,
  FamilyDetail,
  FamilyMemberDetail,
  Info,
  MainHeading,
  WishInfo,
} from "../../donor/familyDetails/FamilyDetailsStyle";
import { useLocation, useNavigate } from "react-router-dom";
import useMutate from "../../../../api/hooks/useMutate";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";
import { useEffect } from "react";
import Loader from "../../../loader/Loader";
import { capitalFirstLetter, formatDate } from "../../../../utils/dateTime";
import { ButtonContainer } from "../addFamilyDetails/addFamilyDetailsStyle";
import { ApprovedHeading, PendingHeading } from "../profile/Profile";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Spacer } from "../../admin/donors/ViewDonordetails";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import { CustomStatus, FlexSort } from "./AdminWishesApproval";
import { getClientStatus } from "../../employee/donations/Donations";
import LoaderDashboard from "../../../loader/LoaderDashboard";

export const Campaignsdetails = styled.div`
  padding: 20px;
  border: 1px solid ${colors.grey5};
  border-radius: 10px;
  margin-top: 20px;
`;
export const Detailslist = styled.div`
  margin: 40px 0 0 0;
`;
export const MainHeadingcol = styled.h3`
  position: absolute;
  top: -16px;
  background-color: ${colors.white};
  padding: 0 6px;
  left: 9px;
  font-size: 18px;
  color: ${colors.primary};
  font-weight: 600;
  @media screen and (max-width: 575px) {
    font-size: 15px;
    top: -14px;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

export const ButtonRight = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ClientFamilyWishesInfo = () => {
  const query = useQuery();
  const id = query.get("id");
  const seekerId = query.get("seekerId");
  const navigate = useNavigate();
  const [editDetails, setEditDetails] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [getClientFamilyWishes, { response, loading }] = useMutate({
    path: CAMPAIGN_URL.GET_DONATION_DETAILS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  useEffect(() => {
    let params = {
      CampaignId: id,
    };

    if (seekerId) {
      params = {
        CampaignId: id,
        seekerId: seekerId,
      };
    }

    getClientFamilyWishes({
      params,
      onCompleted: () => {},
      onError: () => {},
    });
  }, []);

  const campaignDetails = response?.data?.campaignDetail;
  const houseHold = response?.data?.members?.find(
    (member) => member.isHouseHold == true
  );

  const members = response?.data?.members?.filter(
    (member) => member.isHouseHold != true
  );

  return (
    <>
      {!loading ? (
        <MainContent>
          <PageHeading>
            Donation Details
            <Button
              text={"Back"}
              onClick={() => {
                navigate(-1);
              }}
            />
          </PageHeading>

          <Row>
            <Col span={24}>
              <HeadingWrapper>
                <Heading>{campaignDetails?.campaignName}</Heading>
                <Info>
                  <Label>Donation Status:</Label>{" "}
                  {getClientStatus(response?.data?.donationStatus)}
                </Info>
              </HeadingWrapper>
            </Col>
            <Col span={24}>
              <CampaignImage src={campaignDetails?.imageFile} />
            </Col>

            <Col xs={{ span: 24 }}>
              <CampaignsDescription>
                {campaignDetails?.description}
              </CampaignsDescription>
            </Col>
            <Col span={24}>
              <Campaignsdetails>
                <Row>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Start date:</Label>
                      {formatDate(
                        campaignDetails?.campaignStartDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>End date:</Label>
                      {formatDate(
                        campaignDetails?.campaignStartDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Enrollment period:</Label>{" "}
                      {formatDate(
                        campaignDetails?.campaignStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(
                        campaignDetails?.campaignEndDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Donations accepted:</Label>{" "}
                      {formatDate(
                        campaignDetails?.donorPeriodStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(
                        campaignDetails?.donorPeriodEndDate,
                        "MM/DD/YYYY"
                      )}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Dropoff dates:</Label>{" "}
                      {formatDate(
                        campaignDetails?.dropOfStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(campaignDetails?.dropOfEndDate, "MM/DD/YYYY")}
                    </Info>
                  </Col>
                  <Col span={24} md={{ span: 12 }}>
                    <Info>
                      <Label>Client pickup dates</Label>{" "}
                      {formatDate(
                        campaignDetails?.pickupStartDate,
                        "MM/DD/YYYY"
                      )}{" "}
                      To{" "}
                      {formatDate(campaignDetails?.pickupEndDate, "MM/DD/YYYY")}
                    </Info>
                  </Col>
                </Row>
              </Campaignsdetails>
              {/* &nbsp;
            <hr /> */}
            </Col>

            <Col xs={{ span: 24 }}>
              <Detailslist>
                <FamilyDetail>
                  <MainHeading>Wishes </MainHeading>
                  <DetailsWrapper>
                    <Row gutter={[30, 20]} style={{ width: "100%" }}>
                      <Col span={24} md={{ span: 12 }}>
                        <FamilyMemberDetail>
                          <MainHeading>Household Wishes</MainHeading>
                          <Row>
                            <Col span={24} md={{ span: 24 }}>
                              {houseHold?.wishes?.length ? (
                                <>
                                  <FlexSort>
                                    <CustomStatus>
                                      Approval Status:
                                      {houseHold?.isApproved ? (
                                        <ApprovedHeading>
                                          <CheckCircleOutlined /> Approved
                                        </ApprovedHeading>
                                      ) : (
                                        <PendingHeading>
                                          <ClockCircleOutlined /> Yet to be
                                          approved
                                        </PendingHeading>
                                      )}
                                    </CustomStatus>

                                    <CustomStatus>
                                      Wishes Approval Status:
                                      {houseHold?.isWishApproved ? (
                                        <ApprovedHeading>
                                          <CheckCircleOutlined /> Approved
                                        </ApprovedHeading>
                                      ) : (
                                        <PendingHeading>
                                          <ClockCircleOutlined /> Yet to be
                                          approved
                                        </PendingHeading>
                                      )}
                                    </CustomStatus>
                                  </FlexSort>
                                </>
                              ) : (
                                ""
                              )}
                              <Info>
                                <Label>Name:</Label>{" "}
                                {`${houseHold?.firstName} ${houseHold?.lastName}`}
                              </Info>
                            </Col>
                            {houseHold?.wishes?.length > 0 ? (
                              houseHold?.wishes?.map((wishInfo, wishIndex) => {
                                return (
                                  <Col span={24} md={{ span: 24 }}>
                                    <WishInfo>
                                      <Label>Wish {wishIndex + 1}:</Label>
                                      {wishInfo?.description}
                                    </WishInfo>
                                  </Col>
                                );
                              })
                            ) : (
                              <Col span={24} md={{ span: 24 }}>
                                <WishInfo>
                                  <Label>Wishes:</Label>
                                  Household is not eligible.
                                </WishInfo>
                              </Col>
                            )}
                            {!houseHold?.isWishApproved &&
                              houseHold?.wishes?.length && (
                                <ButtonRight>
                                  <Button
                                    text={"Edit"}
                                    htmlType="button"
                                    onClick={() => {
                                      setEditDetails({
                                        campaignId:
                                          response?.data?.campaignDetail
                                            ?.campaignId,
                                        memberId: houseHold.id,
                                        wishes: houseHold?.wishes,
                                      });
                                      setOpenEditModal(true);
                                    }}
                                  />
                                </ButtonRight>
                              )}
                          </Row>
                        </FamilyMemberDetail>
                      </Col>
                      {members?.map((memberInfo, memberIndex) => {
                        return (
                          <Col span={24} md={{ span: 12 }}>
                            <FamilyMemberDetail>
                              <MainHeading>
                                Member {memberIndex + 1}
                              </MainHeading>

                              <Row>
                                <Col span={24} md={{ span: 24 }}>
                                  {memberInfo?.wishes?.length ? (
                                    <>
                                      <FlexSort>
                                        <CustomStatus>
                                          Approval Status:
                                          {memberInfo?.isApproved ? (
                                            <ApprovedHeading>
                                              <CheckCircleOutlined /> Approved
                                            </ApprovedHeading>
                                          ) : (
                                            <PendingHeading>
                                              <ClockCircleOutlined /> Yet to be
                                              approved
                                            </PendingHeading>
                                          )}
                                        </CustomStatus>
                                        <CustomStatus>
                                          Wishes Approval Status:
                                          {memberInfo?.isWishApproved ? (
                                            <ApprovedHeading>
                                              <CheckCircleOutlined /> Approved
                                            </ApprovedHeading>
                                          ) : (
                                            <PendingHeading>
                                              <ClockCircleOutlined /> Yet to be
                                              approved
                                            </PendingHeading>
                                          )}
                                        </CustomStatus>
                                      </FlexSort>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <Info>
                                    <Label> Relation:</Label>{" "}
                                    {memberInfo?.relation}
                                  </Info>
                                </Col>
                                <Col span={24} md={{ span: 24 }}>
                                  <Info>
                                    <Label>Name:</Label>{" "}
                                    {`${capitalFirstLetter(
                                      memberInfo?.firstName
                                    )} ${capitalFirstLetter(
                                      memberInfo.lastName
                                    )}`}
                                  </Info>
                                </Col>
                                {memberInfo?.wishes?.length > 0 ? (
                                  memberInfo?.wishes?.map(
                                    (wishInfo, wishIndex) => {
                                      return (
                                        <Col span={24} md={{ span: 24 }}>
                                          <Col span={24} md={{ span: 24 }}>
                                            <WishInfo>
                                              <Label>
                                                Wish {wishIndex + 1}:
                                              </Label>
                                              {wishInfo?.description}
                                            </WishInfo>
                                          </Col>
                                        </Col>
                                      );
                                    }
                                  )
                                ) : (
                                  <Col span={24} md={{ span: 24 }}>
                                    <WishInfo>
                                      <Label>Wishes:</Label>
                                      {`${capitalFirstLetter(
                                        memberInfo?.firstName
                                      )} ${capitalFirstLetter(
                                        memberInfo?.lastName
                                      )}`}{" "}
                                      is not eligible.
                                    </WishInfo>
                                  </Col>
                                )}
                                {!memberInfo?.isWishApproved &&
                                  memberInfo?.wishes?.length && (
                                    <ButtonRight>
                                      <Button
                                        text={"Edit"}
                                        htmlType="button"
                                        onClick={() => {
                                          setEditDetails({
                                            campaignId:
                                              response?.data?.campaignDetail
                                                ?.campaignId,
                                            memberId: memberInfo.id,
                                            wishes: memberInfo?.wishes,
                                          });
                                          setOpenEditModal(true);
                                        }}
                                      />
                                    </ButtonRight>
                                  )}
                              </Row>
                            </FamilyMemberDetail>
                          </Col>
                        );
                      })}
                    </Row>
                  </DetailsWrapper>
                </FamilyDetail>
              </Detailslist>
            </Col>
            <Col xs={{ span: 24 }}>
              <Spacer />
            </Col>
          </Row>
          <Modal
            title="Edit Wish"
            open={openEditModal}
            setIsSuccess={setIsSuccess}
            setOpenModal={setOpenEditModal}
            modalType={ModalList.EditWishesmodal}
            clientDetails={editDetails}
          />
        </MainContent>
      ) : (
        <LoaderDashboard />
      )}
    </>
  );
};

export default ClientFamilyWishesInfo;
