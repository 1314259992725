import { Row, Col, Image, Form, Radio } from "antd";
import Logo from "../../../../assets/images/logo.png";
import Input from "../../../elements/input/Input";
import Button from "../../../elements/button/Button";
import {
  BottomForm,
  RightContainer,
  LayoutContainer,
  InnerContent,
  OuterWrapper,
  Heading,
} from "../../admin/logIn/LoginStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import { errorMessages } from "../../../../utils/errorMessages";
import { PhoneNumberInput } from "../../../elements/input/phoneNumberInput";
import useMutate from "../../../../api/hooks/useMutate";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import { useSelector } from "react-redux";
import { ROLES_INITIAL_ROUTES } from "../../../../hooks/useSetAppConfigs";
import { LeftContainer } from "../../admin/signUp/SingupStlyes";
import CustomImage from "../../../image/LeftContainer";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SeekerVerify() {
  const { shortOrg, user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [value, setValue] = useState("Email");
  const verifyToken = localStorage.getItem("seekerToken");
  const [postVerifySeeker, { response, error, loading }] = useMutate({
    path: SEEKER_URLS.VERIFYSEEKERLOGIN,
    errorToast: true,
    config: {
      headers: {
        Authorization: "Bearer " + verifyToken,
        organisationId: shortOrg?.organisationId,
      },
    },
    onCompleted: () => {},
    onError: () => {},
  });

  const query = useQuery();
  const code = query.get("id");

  const location = useLocation();

  if (user) {
    return navigate(ROLES_INITIAL_ROUTES[user.roleName]);
  }

  const historyState = location.state;

  console.log(historyState, "historyState");

  const onChange = (e) => {
    form.resetFields();
    setValue(e.target.value);
  };
  const onFinish = (data) => {
    const variables = {
      UserName: code,
      Email: "",
      Phone: "",
      LastName: "",
      [value]:
        value === "Phone" ? data[value]?.replace(/\s+/g, "") : data[value],
    };

    postVerifySeeker({
      variables,
      onCompleted: (data) => {
        localStorage.setItem("accessToken", data.token);
        localStorage.removeItem("seekerToken");

        if (historyState?.eventlink) {
          return navigate(`${historyState.route}`);
        }

        navigate("/client-profile");
      },
      onError: () => {},
    });
  };
  const onFocus = (e) => {
    const fields = ["Email", "LastName", "Phone"];
    const fieldsToRest = fields.filter((field) => field !== e.target.name);
    form.resetFields(fieldsToRest);
    setValue(e.target.name);
  };
  return (
    <>
      <LayoutContainer>
        <InnerContent style={{ maxWidth: "840px", minHeight: "360px" }}>
          <OuterWrapper>
            <Row>
              <Col lg={{ span: 8 }} span={24}>
              <CustomImage />
              </Col>
              <Col xl={{ span: 16 }} lg={{ span: 16 }} sm={{ span: 24 }}>
                <RightContainer>
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    form={form}
                    onFinish={onFinish}
                  >
                    <Heading>Please enter one of the following</Heading>
                    <Radio.Group onChange={onChange} value={value}>
                      <Row gutter={[0, 8]}>
                        <Col span={1}>
                          <Radio value="Email" />
                        </Col>
                        {/* // disabled={value !== "email"} */}
                        <Col span={23}>
                          <Input
                            name="Email"
                            pattern={regExpPattern.EMAIL}
                            required={value === "Email" ? true : false}
                            regExpMessage={regErrorMessages.EMAIL}
                            label="Email"
                            onFocus={onFocus}
                            requiredMessage={errorMessages.EMAIL}
                          />
                        </Col>
                        <Col span={1}>
                          <Radio value="LastName" />
                        </Col>
                        <Col span={23}>
                          <Input
                            name="LastName"
                            pattern={false}
                            onFocus={onFocus}
                            required={value === "LastName" ? true : false}
                            label="Last Name"
                            // disabled={value !== "lastName"}
                            requiredMessage={errorMessages.LASTNAME}
                          />
                        </Col>
                        <Col span={1}>
                          <Radio value="Phone" />
                        </Col>
                        <Col span={23}>
                          <PhoneNumberInput
                            type="tel"
                            label="Phone Number"
                            onFocus={(e) => onFocus(e)}
                            name="Phone"
                            // disabled={value !== "contactNumber"}
                            required={value === "Phone" ? true : false}
                            width="100%"
                            requiredMessage={errorMessages.CONTACTNUMBER}
                          />
                        </Col>
                        <Col span={24}>
                          <BottomForm>
                            <div></div>
                            <Button
                              text={"Login"}
                              htmlType="submit"
                              loading={loading}
                            />
                          </BottomForm>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form>
                </RightContainer>
              </Col>
            </Row>
          </OuterWrapper>
        </InnerContent>
      </LayoutContainer>
    </>
  );
}

export default SeekerVerify;
