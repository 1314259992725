import moment from "moment";

export const genderFormat = (item) => {
  let gender = "";
  if (item === "Male") {
    gender = 1;
  } else if (item === "Female") {
    gender = 2;
  } else if (item === "Other") {
    gender = 3;
  }
  return gender;
};

export const genderAPIFormat = (item) => {
  let gender = "";
  if (Number(item) === 1) {
    gender = "Male";
  } else if (Number(item) === 2) {
    gender = "Female";
  }
  else if (Number(item) === 3) {
    gender = "Other";
  }
  return gender;
};
export const dateAPIFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
