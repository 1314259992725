export const fakeLandingCardJson = [{
        title: "Christmas Eve",
        cardDescription: " The festival of Christmas celebrates the birth of Jesus Christ and conveys his message of love, tolerance and brotherhood. It is a celebration of humanity and mankind. Though Christmas is a primary festival of the Christian calendar, it still has a special significance in everyone's life",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Happy New Year",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        title: "Event Name / title",
        cardDescription: " Event Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed voluptates ipsa voluptatum quod nam dolorum aspernatur laboriosam repellat...",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
];
export const fakeCampaignJson = [{
        name: "Christmas Eve",
        description: "The festival of Christmas celebrates the birth of Jesus Christ and conveys his message of love, tolerance and brotherhood. It is a celebration of humanity and mankind. Though Christmas is a primary festival of the Christian calendar, it still has a special significance in everyone's life",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
        Buttontext: "hjkl",
    },
    {
        name: "Happy New Year",
        description: "'New Year' is an event that takes place when the world celebrates the end of one year, and the beginning of the next. In the Gregorian calendar, the most widely used calendar system today, the first day of January is often considered as a national holiday across the globe.",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        name: "Event 3",
        description: "'New Year' is an event that takes place when the world celebrates the end of one year, and the beginning of the next. In the Gregorian calendar, the most widely used calendar system today, the first day of January is often considered as a national holiday across the globe.",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        name: "Event 4",
        description: "'New Year' is an event that takes place when the world celebrates the end of one year, and the beginning of the next. In the Gregorian calendar, the most widely used calendar system today, the first day of January is often considered as a national holiday across the globe.",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        name: "Event 5",
        description: "'New Year' is an event that takes place when the world celebrates the end of one year, and the beginning of the next. In the Gregorian calendar, the most widely used calendar system today, the first day of January is often considered as a national holiday across the globe.",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        name: "Event 6",
        description: "'New Year' is an event that takes place when the world celebrates the end of one year, and the beginning of the next. In the Gregorian calendar, the most widely used calendar system today, the first day of January is often considered as a national holiday across the globe.",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
    {
        name: "Event 7",
        description: "'New Year' is an event that takes place when the world celebrates the end of one year, and the beginning of the next. In the Gregorian calendar, the most widely used calendar system today, the first day of January is often considered as a national holiday across the globe.",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
];

export const fakeOptedCampaigns = [{
        name: "Christmas Eve",
        description: "The festival of Christmas celebrates the birth of Jesus Christ and conveys his message of love, tolerance and brotherhood. It is a celebration of humanity and mankind. Though Christmas is a primary festival of the Christian calendar, it still has a special significance in everyone's life",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
        ButtonText: "Selected Families",
    },
    {
        name: "Happy New Year",
        description: "'New Year' is an event that takes place when the world celebrates the end of one year, and the beginning of the next. In the Gregorian calendar, the most widely used calendar system today, the first day of January is often considered as a national holiday across the globe.",
        enrollmentPeriod: "Oct/15/2022 to Oct/30/2022",
        address: "Fam Organisation location",
    },
];

export const familyDetails = [{
        info: "Family Member Info",
        relation: "Household",
        age: "12",
        gender: "male",
        wishes: [{
                wish: "I need one automatic battery operated remote controlled helicopter in any size whichever will be available",
                checked: true,
            },
            {
                wish: "I need one automatic battery size whichever will be available",
                checked: false,
            },
            {
                wish: "Controlled helicopter in any size whichever will be available",
                checked: true,
            },
        ],
    },
    {
        info: "Family Member Info",
        relation: "Daughter",
        age: "22",
        gender: "female",
        wishes: [{
                wish: "I need one automatic battery operated remote controlled helicopter in any size whichever will be available",
                checked: false,
            },
            {
                wish: "Controlled helicopter in any size whichever will be available",
                checked: true,
            },
        ],
    },
];