import React, { useEffect, useRef, useState } from "react";
import { Profilecontet } from "./profileStyle";
import { Row, Col, Image, Form, Input as AntInput, Tabs } from "antd";
import { PhoneNumberInput } from "../../../elements/input/phoneNumberInput";
import ORG_URL from "../../../../api/urls/ORG_URL";
import { errorMessages } from "../../../../utils/errorMessages";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import { StyledForm } from "../../admin/signUp/SingupStlyes";
import Button from "../../../elements/button/Button";
import { BottomForm, FormFields } from "../../donor/profile/ProfileStyle";
import useMutate from "../../../../api/hooks/useMutate";
import Input from "../../../elements/input/Input";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { Profiletabs } from "../../seeker/profile/ProfileStyle";
import { PageHeading } from "../campaigns/CampaignStyle";
import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import AddressSearchDropdown from "../../../elements/address/GoogleAddressInput";
import {
  ImagePlaceholder,
  PlaceHolderWrappper,
  Requiredwrapper,
} from "../../seeker/RegisterSeeker/RegisterSeeker";
import Loader from "../../../loader/Loader";
import toast from "react-hot-toast";
import {
  ROLES_INITIAL_ROUTES,
  ROLES_LOGOUT_ROUTES,
} from "../../../../hooks/useSetAppConfigs";
import { useNavigate } from "react-router-dom";
import { getShortOrg } from "../../../../auth/authAsyncActions";
import { useDispatch } from "react-redux";

function Adminprofile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const subDomain = "app";
  // const subDomain = window.location.host?.split(".")[0];
  const [isSuccess, setIsSuccess] = useState(false);
  const [getAdminDetails, { response, error, loading: orgLoading }] = useMutate(
    {
      method: "get",
      path: ORG_URL.ADMINDETAILS,
      onCompleted: () => {},
      onError: () => {},
    }
  );
  const [address, setAddress] = useState({
    placeName: response?.data?.placeName,
    address1: response?.data?.address1,
    address2: response?.data?.address2,
    lat: response?.data?.latitude,
    lng: response?.data?.longitude,
    placeId: response?.data?.placeId,
    state: response?.data?.state,
    zip: response?.data?.zip,
    city: response?.data?.city,
    country: response?.data?.country,
  });
  const [addressError, setAddressError] = useState("");
  const token = localStorage.getItem("accessToken");
  const [tabValue, setTabValue] = useState(1);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [imageUpload, setImageUpload] = useState({
    logo: "",
    poster: "",
  });
  const [fileList, setfileList] = useState({
    logo: response?.data?.organisation?.logoUrl || "",
    poster: response?.data?.organisation?.posterImageUrl || "",
  });

  const [updateProfile, { loading: UpdateProfileLoading }] = useMutate({
    method: "post",
    successToast: true,
    errorToast: true,
    path: ORG_URL.UPDATEPROFILE,
    onCompleted: () => {},
    onError: () => {},
  });

  const [changePassword, { loading: changePasswordLoading }] = useMutate({
    method: "put",
    successToast: true,
    errorToast: true,
    path: ORG_URL.CHANGEPASSWORD,
    onCompleted: () => {},
    onError: () => {},
  });

  useEffect(() => {
    if (!fileList?.logo?.length) {
      setfileList({
        logo: response?.data?.organisation?.logoUrl,
        poster: response?.data?.organisation?.posterImageUrl,
      });
    }
  }, [response]);

  useEffect(() => {
    getAdminDetails({
      onCompleted: (data) => {
        setAddress(data?.organisation?.addressDetails);
        setIsSuccess(false);
      },
      onError: () => {},
    });
  }, [isSuccess]);

  const [updateOrganization, { loading: organizationLoading }] = useMutate({
    isFormData: true,
    method: "put",
    path: ORG_URL.UPDATEORGANIZATION,
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const onFinish = (values) => {
    const variables = {
      organisationName: values.organizationName,
      organizationAddress: values.OrganizationAddress,
      logoImage: imageUpload.logo,
      posterImage: imageUpload.poster,
      ...address,
      longitude: address?.lng,
      latitude: address?.lat,
    };
    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }
    updateOrganization({
      variables,
      onCompleted: () => {
        toast.success("Updated Successfully");
        setIsSuccess(true);
        // dispatch(getShortOrg(values.subDomain));
        const existingSubDomain = localStorage.getItem("subDomainData");
        if (existingSubDomain) {
          dispatch(getShortOrg(existingSubDomain));
        } else if (subDomain) {
          dispatch(getShortOrg(subDomain));
        }
      },
      onError: () => {},
    });
  };
  const fileUpload = (e, check) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (file) => {
      if (check === "logo") {
        setImageUpload((file) => ({
          ...file,
          logo: files,
        }));
        setfileList((data) => ({
          ...data,
          logo: reader.result,
        }));
      } else {
        setImageUpload((file) => ({
          ...file,
          poster: files,
        }));
        setfileList((data) => ({
          ...data,
          poster: reader.result,
        }));
      }
    };
    reader.readAsDataURL(files);
  };
  const handleChangePassword = (values) => {
    const variables = {
      oldPassword: values.OldPassword,
      newPassword: values.NewPassword,
    };
    changePassword({
      variables,
      onCompleted: (data) => {
        // toast.success("Updated Successfully");
        localStorage.removeItem("accessToken");
        navigate(ROLES_LOGOUT_ROUTES[user.roleName]);
      },
      onError: () => {},
    });
  };

  const updateUserProfile = (values) => {
    const variables = {
      Email: values.email,
      PhoneNo: values.contactNumber?.replace(/\s+/g, ""),
      FirstName: values.firstname,
      LastName: values.lastname,
      Address: values.address,
    };
    updateProfile({
      variables,
      onCompleted: () => {
        setIsSuccess(true);
        setTabValue("2");
      },
      onError: () => {},
    });
  };
  const handleRemoveImage = (check) => {
    if (check === "logo") {
      setfileList((file) => ({
        ...file,
        logo: "",
      }));
    } else {
      setfileList((file) => ({
        ...file,
        poster: "",
      }));
    }
  };
  const isOrgLoaded = useRef(false);
  return (
    <>
      {!(orgLoading && isSuccess) ? (
        <MainContent>
          <PageHeading>Settings</PageHeading>
          <Tabs
            defaultActiveKey={tabValue}
            onChange={(value) => setTabValue(value)}
          >
            <Tabs.TabPane tab="Organisation Profile" key="1">
              {!orgLoading ? (
                <Profiletabs>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Profilecontet>
                        <StyledForm
                          name="basic"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          autoComplete="off"
                          // form={form}
                          onFinish={onFinish}
                        >
                          <FormFields>
                            <Row gutter={[0, 12]}>
                              <Col span={24} md={{ span: 8 }}>
                                <Input
                                  label="Organisation name"
                                  name="organizationName"
                                  required={true}
                                  initialValue={
                                    response?.data?.organisation?.name
                                  }
                                  requiredMessage={
                                    errorMessages.ORGANIZATIONNAME
                                  }
                                />
                              </Col>
                              <Col span={24} md={{ span: 8 }}>
                                <Requiredwrapper>
                                  <span className="validdata">*</span>
                                </Requiredwrapper>
                                <AddressSearchDropdown
                                  onChange={(address) => {
                                    if (Object?.keys(address)?.length) {
                                      if (address?.city?.length) {
                                        setAddressError("");
                                      } else {
                                        setAddressError(
                                          "Please select address with city"
                                        );
                                      }
                                    } else {
                                      if (isOrgLoaded.current) {
                                        setAddressError(
                                          "Please select address"
                                        );
                                      }
                                    }
                                    setAddress(address);
                                    isOrgLoaded.current = true;
                                  }}
                                  value={address}
                                  required={false}
                                  requiredMessage={errorMessages.ADDRESS}
                                  label=" Organisation Address"
                                  addressError={addressError}
                                />
                              </Col>

                              <Col span={24} md={{ span: 8 }}>
                                <Input
                                  label="Sub Domain"
                                  name="subDomain"
                                  pattern={regExpPattern.SUBDOMAIN}
                                  required={true}
                                  regExpMessage={regErrorMessages.SUBDOMAIN}
                                  disabled={true}
                                  initialValue={
                                    response?.data?.organisation?.subDomain
                                  }
                                />
                              </Col>
                              <Col span={24} md={{ span: 12 }}>
                                <PlaceHolderWrappper>
                                  {!fileList?.logo?.length > 0 ? (
                                    <>
                                      <Form.Item
                                        label=""
                                        name="logoImage"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please insert the logo !",
                                          },
                                        ]}
                                      >
                                        <ImagePlaceholder>
                                          <CloudUploadOutlined />
                                          Upload Logo
                                        </ImagePlaceholder>
                                        <input
                                          name="uploadLogo"
                                          type={"file"}
                                          accept=".png, .jpg, .jpeg"
                                          onChange={(e) =>
                                            fileUpload(e, "logo")
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  ) : (
                                    <>
                                      <Image src={fileList?.logo} />
                                      <CloseOutlined
                                        onClick={() =>
                                          handleRemoveImage("logo")
                                        }
                                      />
                                    </>
                                  )}
                                  <div>Recommended size: 200px x 200px</div>
                                </PlaceHolderWrappper>
                              </Col>
                              <Col span={24} md={{ span: 12 }}>
                                <PlaceHolderWrappper>
                                  {!fileList?.poster?.length > 0 ? (
                                    <>
                                      <Form.Item
                                        label=""
                                        name="posterImage"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Please insert the poster !",
                                          },
                                        ]}
                                      >
                                        <ImagePlaceholder>
                                          <CloudUploadOutlined />
                                          Upload Poster Image
                                        </ImagePlaceholder>
                                        <input
                                          name="uploadPosterImage"
                                          type={"file"}
                                          className={"uploadImage"}
                                          accept=".png, .jpg, .jpeg"
                                          onChange={(e) =>
                                            fileUpload(e, "poster")
                                          }
                                        />
                                      </Form.Item>
                                    </>
                                  ) : (
                                    <>
                                      <Image src={fileList?.poster} />
                                      <CloseOutlined
                                        onClick={() =>
                                          handleRemoveImage("poster")
                                        }
                                      />
                                    </>
                                  )}
                                  <div>Recommended size: Max 1080px wide</div>
                                </PlaceHolderWrappper>
                              </Col>
                            </Row>
                          </FormFields>
                          <BottomForm>
                            <Button
                              text={"Update"}
                              loading={organizationLoading}
                              htmlType="submit"
                              onClick={() => form.submit()}
                            />
                          </BottomForm>
                        </StyledForm>
                      </Profilecontet>
                    </Col>
                  </Row>
                </Profiletabs>
              ) : (
                <Loader />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="User Profile" key="2">
              {!orgLoading ? (
                <Profiletabs>
                  <Row gutter={[0, 12]}>
                    <Profilecontet>
                      <StyledForm
                        name="basic"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                        // form={form}
                        onFinish={updateUserProfile}
                      >
                        <FormFields>
                          <Row gutter={[0, 12]}>
                            <Col span={24} md={{ span: 12 }}>
                              <Input
                                name="firstname"
                                required={true}
                                label="First Name"
                                requiredMessage={errorMessages.FIRSTNAME}
                                initialValue={response?.data?.firstName}
                              />
                            </Col>
                            <Col span={24} md={{ span: 12 }}>
                              <Input
                                name="lastname"
                                required={true}
                                label="Last Name"
                                requiredMessage={errorMessages.LASTNAME}
                                initialValue={response?.data?.lastName}
                              />
                            </Col>

                            <Col span={24} md={{ span: 12 }}>
                              <PhoneNumberInput
                                type="tel"
                                label="Phone Number"
                                name="contactNumber"
                                required={true}
                                requiredMessage={errorMessages.CONTACTNUMBER}
                                width="100%"
                                initialValue={response?.data?.phoneNumber}
                              />
                            </Col>
                            <Col span={24} md={{ span: 12 }}>
                              <Input
                                name="email"
                                pattern={regExpPattern.EMAIL}
                                required={true}
                                regExpMessage={regErrorMessages.EMAIL}
                                label="Email"
                                requiredMessage={errorMessages.EMAIL}
                                initialValue={response?.data?.email}
                              />
                            </Col>
                          </Row>
                        </FormFields>
                        <BottomForm>
                          <Button
                            text={"Update"}
                            htmlType="submit"
                            loading={UpdateProfileLoading}
                            onClick={() => form.submit()}
                          />
                        </BottomForm>
                      </StyledForm>
                    </Profilecontet>
                  </Row>
                </Profiletabs>
              ) : (
                <Loader />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Change Password" key="3">
              {!orgLoading ? (
                <Profiletabs>
                  <Row gutter={20}>
                    <Col span={24}>
                      <StyledForm
                        name="basic"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        autoComplete="off"
                        onFinish={handleChangePassword}
                        form={form}
                      >
                        <FormFields>
                          <Row>
                            <Col span={24} md={{ span: 8 }}>
                              <Input
                                label="Old Password"
                                name="OldPassword"
                                type="password"
                                required={true}
                                requiredMessage={errorMessages.OLDPASSWORD}
                              />
                            </Col>
                            <Col span={24} md={{ span: 8 }}>
                              <Input
                                label="New Password"
                                name="NewPassword"
                                type="password"
                                required={true}
                                pattern={regExpPattern.PASSWORD}
                                regExpMessage={regErrorMessages.PASSWORD}
                                requiredMessage={errorMessages.PASSWORD}
                              />
                            </Col>
                            <Col span={24} md={{ span: 8 }}>
                              <Form.Item
                                name="confirmPassword"
                                label="Confirm Password"
                                dependencies={["NewPassword"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please confirm your password!",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value ||
                                        getFieldValue("NewPassword") === value
                                      ) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error("Password does not match !")
                                      );
                                    },
                                  }),
                                ]}
                              >
                                <AntInput.Password />
                              </Form.Item>
                            </Col>
                          </Row>
                        </FormFields>
                        <BottomForm style={{ padding: "0 10px" }}>
                          <Button
                            text={"Submit"}
                            htmlType="submit"
                            loading={changePasswordLoading}
                            onClick={() => form.submit()}
                          />
                        </BottomForm>
                      </StyledForm>
                    </Col>
                  </Row>
                </Profiletabs>
              ) : (
                <Loader />
              )}
            </Tabs.TabPane>
          </Tabs>
        </MainContent>
      ) : (
        <Loader />
      )}{" "}
    </>
  );
}

export default Adminprofile;
