import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Image, Col, Form, Row } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { errorMessages } from "../../utils/errorMessages";
import Button from "../elements/button/Button";
import DatePickerB from "../elements/datePicker/DatePicker";
import Input from "../elements/input/Input";
import {
  ImagePlaceholder,
  PlaceHolderWrappper,
} from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { useState } from "react";
import { ButtonContainer } from "./NewSeekerModal";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import { regErrorMessages } from "../../utils/regErrorMessages";
import useMutate from "../../api/hooks/useMutate";
import CAMPAIGN_URL from "../../api/urls/CAMPAIGN_URL";
import { regExpPattern } from "../../utils/regExpPattern";
import TextAreaB from "../elements/textArea/TextArea";
import { formatDate } from "../../utils/dateTime";
import toast from "react-hot-toast";

export const Container = styled.div`
  max-width: 65vw;
  padding: 0 15px 0 10px;
  overflow: auto;
  max-height: 70vh;
  min-width: 60vw;
  @media screen and (max-width: 991px) {
    max-width: 85vw;
    max-height: 70vh;
  }
  @media screen and (max-width: 567px) {
    max-width: 95vw;
  }
  @media screen and (max-device-width: 767px) and (orientation: landscape) {
    max-width: 100%;
    max-height: 70vh;
  }
`;

export const StyledForm = styled(Form)`
  @media screen and (max-device-width: 991px) {
    max-height: 70vh;
  }
  @media screen and (max-device-width: 767px) and (orientation: landscape) {
    max-height: 70vh;
  }
`;
export const DateFromTo = styled.div`
  margin-top: 3px;
  p {
    padding: 0 10px 0;
    margin-bottom: 7px;
    font-weight: 500;
  }
  .ant-form-item-label {
    display: none;
  }
`;

export const To = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 15px 12px;
`;

export const EditCampaignModal = ({
  header,
  info,
  close,
  data,
  setIsSuccess,
}) => {
  // const navigate = useNavigate();
  const [form] = Form.useForm();
  const dateFormat = "MM/DD/YYYY";

  const [addCampaignAPI, { response, error, loading }] = useMutate({
    isFormData: true,
    path: CAMPAIGN_URL.CREATECAMPAIGN,
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const [imageUpload, setImageUpload] = useState({
    logo: data?.imageFile || "",
    value: "",
  });

  const fileUpload = (e) => {
    const files = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUpload({
        value: files,
        logo: reader.result,
      });
    };
    reader.readAsDataURL(files);
  };
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });

  const today = moment();
  const disablePastDate = (current) => {
    return current.isBefore(today);
  };
  const disbaleDateFromStart = (current, date = "") => {
    const today = moment(date);
    return current.isBefore(today);
  };
  const editStartDate = (date, endDate) => {
    const startDate = moment(formatDate(date));
    const lastDate = moment(formatDate(endDate));
    const currentDate = moment();
    const x = startDate.diff(currentDate, "days");
    const y = lastDate.diff(currentDate, "days");
    if (Math.sign(x) === 1) {
      return moment(date * 1000);
    } else {
      return new Date();
    }
  };

  const [campginStartDate, setCampginStartDate] = useState(
    data?.campaignStartDate
      ? editStartDate(data?.campaignStartDate, data?.campaignEndDate)
      : today
  );
  const [enrollmentStartDate, setEnrollmentStartDate] = useState(
    data?.enrollmentPeriodStartDate
      ? editStartDate(data?.enrollmentPeriodStartDate)
      : today
  );
  const [donorStartDate, setDonorStartDate] = useState(
    data?.donorPeriodStartDate
      ? editStartDate(data?.donorPeriodStartDate)
      : today
  );
  const [dropOffStartDate, setDropOffStartDate] = useState(
    data?.dropOfStartDate ? editStartDate(data?.dropOfStartDate) : today
  );
  const [pickUpStartDate, setPickUpStartDate] = useState(
    data?.pickupStartDate ? editStartDate(data?.pickupStartDate) : today
  );

  const onSubmit = (values) => {
    let variables = {
      CampaignId: data?.campaignId || "null",
      OrganisationId: 1,
      CampaignName: values.campaignName,
      CampaignStartDate: moment(values.startDate).startOf("day").unix(), // timeStanmp format
      CampaignEndDate: moment(values.endDate).endOf("day").unix(),
      EnrollmentPeriodStartDate: moment(values.enrollmentFrom)
        .startOf("day")
        .unix(),
      EnrollmentPeriodEndDate: moment(values.enrollmentto).endOf("day").unix(),
      DonorPeriodStartDate: moment(values.donorFrom).startOf("day").unix(),
      DonorPeriodEndDate: moment(values.donorto).endOf("day").unix(),
      DropOfAddress: values.dropoffAddress,
      DropOfStartDate: moment(values.dropoffFrom).startOf("day").unix(),
      DropOfEndDate: moment(values.dropoffto).endOf("day").unix(),
      PickupAddress: values.pickupAddress,
      PickupStartDate: moment(values.pickupFrom).startOf("day").unix(),
      PickupEndDate: moment(values.pickupto).endOf("day").unix(),
      QuestionsCallNumber: values.questionsCallNumber,
      QuestionsEmail: values.questionsEmailAddress,
      QuestionsVisitAddress: values.questionsVisitAddress,
      Description: values?.campaignDescription,
      MaxWishes: "3",
      MacCharater: "20",
      ActionPerformedBy: data?.campaignName,
    };
    if (imageUpload?.value) {
      variables.LogoImage = imageUpload?.value;
    }

    addCampaignAPI({
      variables: variables,
      onCompleted: () => {
        toast.success(data ? "Updated Successfuly" : "Created Successfully");
        setIsSuccess(true);
        close();
      },
      onError: () => {},
    });
  };
  const handleRemoveImage = () => {
    setImageUpload({
      value: "",
      logo: "",
    });
  };
  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        form={form}
        onFinish={onSubmit}
      >
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              name="campaignName"
              label={"Event Name"}
              required={true}
              initialValue={data?.campaignName}
              requiredMessage={errorMessages.CAMPAIGNNAME}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <DateFromTo>
              <Row>
                <Col span={24}>
                  <p>
                    <span
                      style={{
                        color: "#ff4d4f",
                        fontFamily: "SimSun,sans-serif",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                    Enrollment period
                  </p>
                </Col>
                <Col span={11}>
                  <DatePickerB
                    initialValue={
                      data?.enrollmentPeriodStartDate
                        ? moment.unix(data?.enrollmentPeriodStartDate)
                        : ""
                    }
                    format={dateFormat}
                    onChange={(value) => setEnrollmentStartDate(value)}
                    name="enrollmentFrom"
                    required={true}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                    requiredMessage={errorMessages.STARTDATE}
                  />
                </Col>
                <Col span={2}>
                  <To>To</To>
                </Col>
                <Col span={11}>
                  <DatePickerB
                    initialValue={
                      data?.enrollmentPeriodEndDate
                        ? moment.unix(data?.enrollmentPeriodEndDate)
                        : ""
                    }
                    format={dateFormat}
                    disabledDate={(current) =>
                      disbaleDateFromStart(current, enrollmentStartDate)
                    }
                    onChange={() => {}}
                    disabledTime={disabledDateTime}
                    name="enrollmentto"
                    required={true}
                    requiredMessage={errorMessages.ENDDATE}
                  />
                </Col>
              </Row>
            </DateFromTo>
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <DatePickerB
              name="startDate"
              label="Start Date"
              // picker="HeadofHouseholdDateofBirth"
              required={true}
              disabledDate={disablePastDate}
              onChange={(value) => setCampginStartDate(value)}
              disabledTime={disabledDateTime}
              initialValue={
                data?.campaignStartDate
                  ? moment.unix(data?.campaignStartDate)
                  : ""
              }
              requiredMessage={errorMessages.STARTDATE}
              format={dateFormat}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <DatePickerB
              name="endDate"
              label="End Date"
              // picker="HeadofHouseholdDateofBirth"
              required={true}
              onChange={() => {}}
              disabledDate={(current) =>
                disbaleDateFromStart(current, campginStartDate)
              }
              disabledTime={disabledDateTime}
              initialValue={
                data?.campaignEndDate ? moment.unix(data?.campaignEndDate) : ""
              }
              requiredMessage={errorMessages.ENDDATE}
              format={dateFormat}
            />
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <DateFromTo>
              <Row>
                <Col span={24}>
                  <p>
                    <span
                      style={{
                        color: "#ff4d4f",
                        fontFamily: "SimSun,sans-serif",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                    Donations accepted
                  </p>
                </Col>
                <Col span={11}>
                  <DatePickerB
                    initialValue={
                      data?.donorPeriodStartDate
                        ? moment.unix(data?.donorPeriodStartDate)
                        : ""
                    }
                    format={dateFormat}
                    onChange={(value) => setDonorStartDate(value)}
                    name="donorFrom"
                    required={true}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                    requiredMessage={errorMessages.STARTDATE}
                  />
                </Col>
                <Col span={2}>
                  <To>To</To>
                </Col>
                <Col span={11}>
                  <DatePickerB
                    initialValue={
                      data?.donorPeriodEndDate
                        ? moment.unix(data?.donorPeriodEndDate)
                        : ""
                    }
                    format={dateFormat}
                    onChange={() => {}}
                    name="donorto"
                    required={true}
                    disabledDate={(current) =>
                      disbaleDateFromStart(current, donorStartDate)
                    }
                    disabledTime={disabledDateTime}
                    requiredMessage={errorMessages.ENDDATE}
                  />
                </Col>
              </Row>
            </DateFromTo>
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <Input
              name="dropoffAddress"
              initialValue={data?.dropOfAddress}
              label={"Donation drop-off"}
              required={true}
              requiredMessage={errorMessages.DROPOFFADDRESS}
            />
          </Col>
          <Col span={24} lg={{ span: 24 }}>
            <TextAreaB
              name="campaignDescription"
              label={"Event Description"}
              required={true}
              requiredMessage={errorMessages.EVENTDESCRIPTION}
              initialValue={data?.description || ""}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <DateFromTo>
              <Row>
                <Col span={24}>
                  <p>
                    <span
                      style={{
                        color: "#ff4d4f",
                        fontFamily: "SimSun,sans-serif",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                    Dropoff Dates
                  </p>
                </Col>
                <Col span={11}>
                  <DatePickerB
                    name="dropoffFrom"
                    initialValue={
                      data?.dropOfStartDate
                        ? moment.unix(data?.dropOfStartDate)
                        : ""
                    }
                    onChange={(value) => setDropOffStartDate(value)}
                    format={dateFormat}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                    required={true}
                    requiredMessage={errorMessages.STARTDATE}
                  />
                </Col>
                <Col span={2}>
                  <To>To</To>
                </Col>
                <Col span={11}>
                  <DatePickerB
                    name="dropoffto"
                    initialValue={
                      data?.dropOfEndDate
                        ? moment.unix(data?.dropOfEndDate)
                        : ""
                    }
                    onChange={() => {}}
                    disabledDate={(current) =>
                      disbaleDateFromStart(current, dropOffStartDate)
                    }
                    disabledTime={disabledDateTime}
                    format={dateFormat}
                    required={true}
                    requiredMessage={errorMessages.ENDDATE}
                  />
                </Col>
              </Row>
            </DateFromTo>
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <Input
              name="pickupAddress"
              initialValue={data?.pickupAddress}
              label={"Client pickup address"}
              required={true}
              requiredMessage={errorMessages.PICKUPADDRESS}
            />
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <DateFromTo>
              <Row>
                <Col span={24}>
                  <p>
                    <span
                      style={{
                        color: "#ff4d4f",
                        fontFamily: "SimSun,sans-serif",
                        marginRight: "4px",
                      }}
                    >
                      *
                    </span>
                    Client pickup dates
                  </p>
                </Col>
                <Col span={11}>
                  <DatePickerB
                    name="pickupFrom"
                    initialValue={
                      data?.pickupStartDate
                        ? moment.unix(data?.pickupStartDate)
                        : ""
                    }
                    onChange={(value) => setPickUpStartDate(value)}
                    format={dateFormat}
                    required={true}
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                    requiredMessage={errorMessages.STARTDATE}
                  />
                </Col>
                <Col span={2}>
                  <To>To</To>
                </Col>
                <Col span={11}>
                  <DatePickerB
                    name="pickupto"
                    initialValue={
                      data?.pickupEndDate
                        ? moment.unix(data?.pickupEndDate)
                        : ""
                    }
                    disabledDate={(current) =>
                      disbaleDateFromStart(current, pickUpStartDate)
                    }
                    onChange={() => {}}
                    disabledTime={disabledDateTime}
                    format={dateFormat}
                    required={true}
                    requiredMessage={errorMessages.ENDDATE}
                  />
                </Col>
              </Row>
            </DateFromTo>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <PhoneNumberInput
              name="questionsCallNumber"
              initialValue={data?.questionsCallNumber}
              label={"Phone Number"}
              required={true}
              requiredMessage={errorMessages.QUESTIONSCALLNUMBER}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              name="questionsEmailAddress"
              initialValue={data?.questionsEmail}
              label={"Email"}
              pattern={regExpPattern.EMAIL}
              regExpMessage={regErrorMessages?.EMAIL}
              required={true}
              requiredMessage={errorMessages.QUESTIONSEMAILADDRESS}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              name="questionsVisitAddress"
              initialValue={data?.questionsVisitAddress}
              label={"Questions Visit"}
              required={true}
              requiredMessage={errorMessages.QUESTIONSVISITADDRESS}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <PlaceHolderWrappper>
              {!imageUpload.logo.length > 0 ? (
                <>
                  <Form.Item
                    label=""
                    name="logoImage"
                    rules={[
                      {
                        required: true,
                        message: "Please insert the Poster Image !",
                      },
                    ]}
                  >
                    <ImagePlaceholder>
                      <CloudUploadOutlined />
                      Upload Poster Image
                    </ImagePlaceholder>
                    <input
                      name="uploadLogo"
                      type={"file"}
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => fileUpload(e)}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Image src={imageUpload.logo} />
                  <CloseOutlined onClick={handleRemoveImage} />
                </>
              )}
              <div>Recommended size: Max 1080px wide</div>
            </PlaceHolderWrappper>
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button
            text={data ? "Update" : "Save"}
            htmlType="submit"
            loading={loading}
          />
        </ButtonContainer>
      </StyledForm>

      {info && (
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>{info}</p>
      )}
    </Container>
  );
};

export default EditCampaignModal;
