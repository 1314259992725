import { Row, Col, Table, Checkbox } from "antd";
// import { useNavigate } from "react-router-dom";
import {
  ActionIcon,
  MainContent,
  SectionHeading,
} from "../familyList/FamilyListStyle";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import useMutate from "../../../../api/hooks/useMutate";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import { useEffect } from "react";
import { useQuery } from "../../../../utils/useQuery";
import Button from "../../../elements/button/Button";
import { getAgeFromTs } from "../../../../utils/dateTime";
import { genderAPIFormat } from "../../../../utils/genderFormat";
import { SearchContainer } from "../../admin/campaigns/CampaignStyle";
import Input from "../../../elements/input/Input";
import { debounce } from "../../../../utils/Debounce";
import { COLUMN_WIDTH } from "../../admin/seekers/viewclient/Viewclient";
import { ButtonContainer } from "../familyDetails/FamilyDetailsStyle";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";
import { Spacer } from "../../admin/donors/ViewDonordetails";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";

let pagination = "";
const AllCampaignList = () => {
  const [page, setPage] = useState(1);
  const [openConfirmModal, setOpenConfirmMiodal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortTable, setSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const query = useQuery();
  const admin = query.get("admin");
  const id = query.get("id");
  const [getFamilies, { response, loading }] = useMutate({
    path: DONOR_URLS.SELECTED_FAMILY_LIST,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
    SearchValue: searchValue,
  });
  const [sendUnselectedFamilies, { loading: unselectedFamilyLoader }] =
    useMutate({
      path: CAMPAIGN_URL.UNSELECTEDFAMILIES,
      method: "post",
      successToast: true,
      errorToast: true,
      onCompleted: () => {},
      onError: () => {},
    });
  const navigate = useNavigate();
  //   const [form] = Form.useForm();

  const columns = [
    {
      title: "Event Name",
      dataIndex: "campaignName",
      sorter: true,
      render: (text, record) => text,
    },
    {
      title: "# of Children",
      dataIndex: "noOfChildrens",
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: "Unselect Family",
      render: (text, record) => (
        <Checkbox
          checked={selectedClients?.find((check) => {
            return (
              check.seekerId === record.seekerId &&
              check.campaignId === record?.campaignId
            );
          })}
          onChange={(e) => selectClients(e, record)}
        >
          Unselect family
        </Checkbox>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIcon
          onClick={() =>
            navigate(
              `/family-wishesinfo?id=${record?.campaignId}&seekerId=${record?.seekerId}`
            )
          }
        >
          {console.log(record, "record")}
          <EyeOutlined />
        </ActionIcon>
      ),
    },
  ];
  const param = {
    pageNo: page || 1,
    PageSize: 100,
    searchValue: searchValue,
    sortColumn: sortTable.column,
    sortOrder: sortTable.order,
  };

  if (admin) {
    param.donorId = id;
  }
  useEffect(() => {
    getFamilies({
      params: param,
      onCompleted: () => {
        setIsSuccess(false);
      },
      onError: () => {},
      SearchValue: searchValue,
    });
  }, [page, sortTable, searchValue, isSuccess]);
  pagination = response?.data?.pagination;
  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };
  const search = debounce(handleSearch);
  const selectClients = (e, record) => {
    const checked = e.target.checked;
    if (checked) {
      const data = {
        campaignId: record?.campaignId,
        seekerId: record?.seekerId,
      };

      setSelectedClients((val) => [...val, data]);
    } else {
      const filterClients = selectedClients.filter((family) => {
        if (family?.campaignId === record?.campaignId) {
          return family.seekerId !== record.seekerId;
        }
        return family;
      });

      setSelectedClients(filterClients);
    }
  };
  const sendSelectedFamiliesForNotToAdopt = () => {
    const variables = {
      CampaignSeekers: JSON.stringify(selectedClients),
    };
    sendUnselectedFamilies({
      variables,
      onCompleted: () => {
        setOpenConfirmMiodal(false);
        setIsSuccess(true);
      },
      onError: () => {
        setOpenConfirmMiodal(false);
      },
    });
  };
  return (
    <>
      <MainContent>
        <Row gutter={20}>
          <Col span={24}>
            <SectionHeading>
              Selected Families
              <SearchContainer>
                <Input
                  placeholder="Search by Event Name"
                  onChange={search}
                  // style={{
                  //   width: "auto",
                  // }}
                />
              </SearchContainer>
            </SectionHeading>
            {admin && <Button text={"Back"} onClick={() => navigate(-1)} />}
          </Col>
          <Col span={24}>
            <Table
              dataSource={response?.data?.records}
              columns={columns}
              loading={loading}
              scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
              pagination={{
                pageSize: 100,
                defaultCurrent: page,
                total: pagination?.totalRecordsCount,
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
              onChange={handleChange}
              showSorterTooltip={false}
              sortDirections={["ascend", "descend", "ascend"]}
            />
            <Spacer />
            <ButtonContainer style={{ margin: 0 }}>
              <Button
                text="Unselect Families"
                onClick={() => setOpenConfirmMiodal(true)}
                disabled={!selectedClients?.length ? true : false}
              />
            </ButtonContainer>
          </Col>
        </Row>
        <Modal
          title="Unselect Families"
          open={openConfirmModal}
          header={"Are you sure, you want to unselect families"}
          setOpenModal={setOpenConfirmMiodal}
          loading={unselectedFamilyLoader}
          modalType={ModalList.ConfirmSeekerSelection}
          onConfirm={sendSelectedFamiliesForNotToAdopt}
          successIcon={true}
          noDelete
        />
      </MainContent>
    </>
  );
};
export default AllCampaignList;
