import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import colors from "../../styles/Colors";
import Button from "../elements/button/Button";

const Container = styled.div`
  padding: 0px 20px;
  width: clamp(50px, 30vw, 700px);
  @media screen and (max-width: 991px) {
    width: clamp(50px, 50vw, 700px);
  }
  @media screen and (max-width: 767px) {
    width: clamp(50px, 70vw, 700px);
  }
  @media screen and (max-width: 567px) {
    width: clamp(50px, 90vw, 700px);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0 10px;
  gap: 10px;
  position: fixed;
  width: 100%;
  left: 0;
  .SecondaryBtn {
    background: ${colors.white};
    border-color: ${colors.primary};
    color: ${colors.primary};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-size: 65px;
  padding: 10px 0 20px;
  svg {
    color: ${colors.red};
  }
`;

const ConfirmSingleSeekerSelection = ({
  header,
  noDelete,
  info,
  close,
  onConfirm,
  loading,
}) => {
  return (
    <Container>
      <p style={{ marginTop: "10px", fontSize: "1rem", textAlign: "" }}>
        Thank you for you support. The family below thank you as well for your
        kindness and generosity. Once you click confirm, you will get an email
        with the details for each of your selections.
      </p>

      <ButtonContainer noBorder>
        <Button
          text="Decline"
          onClick={() => close()}
          type="button"
          className="SecondaryBtn"
          margin="0px 20px"
        />
        <Button
          text="Confirm"
          background={noDelete ? colors.themeColor : "red"}
          onClick={() => onConfirm()}
          loading={loading}
          type="button"
          margin="0px 20px"
        />
      </ButtonContainer>
    </Container>
  );
};

export default ConfirmSingleSeekerSelection;
