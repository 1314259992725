import { Link as reactLink } from "react-router-dom";
import styled from "styled-components";
import Colors from "../../../styles/Colors";

const LinkContainer = styled(reactLink)`
  color: ${Colors.primary};
  transition: all 0.9s;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    color: ${Colors.secondary};
  }
}
`;

const Link = ({ margin, text, className, onClick, to, target }) => {
  return (
    <LinkContainer
      margin={margin}
      className={className}
      onClick={onClick}
      to={to}
      target={target}
    >
      {text}
    </LinkContainer>
  );
};

export default Link;
