import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUser } from "../auth/authAsyncActions";
import { ROLES_ENUM } from "../auth/authSlice";

export const ROLES_INITIAL_ROUTES = Object.freeze({
  [ROLES_ENUM.SUPERADMIN]: "/admin-dashboard",
  [ROLES_ENUM.ADMIN]: "/admin-dashboard",
  [ROLES_ENUM.DONOR]: "/all-events",
  [ROLES_ENUM.SEEKER]: "/client-profile",
  [ROLES_ENUM.EMPLOYEE]: "/clients",
});

export const ROLES_LOGOUT_ROUTES = Object.freeze({
  [ROLES_ENUM.SUPERADMIN]: "/login",
  [ROLES_ENUM.ADMIN]: "/login",
  [ROLES_ENUM.DONOR]: "/donor-login",
  [ROLES_ENUM.SEEKER]: "/client-login",
  [ROLES_ENUM.EMPLOYEE]: "/login",
});

const SUPERADMIN_ROUTES = ["/superadmin-dashboard", "/organisations"];

const useSetAppConfigs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isOrgLoaded = useRef(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const redirectToLogin = () => {
      const previousRole = localStorage.getItem("roleName");
      if (previousRole === ROLES_ENUM.SEEKER) {
        navigate("/client-login");
      } else {
        navigate("/login");
      }
    };

    const getUserApi = async () => {
      try {
        const {
          payload: {
            data: { roleName },
          },
        } = await dispatch(getUser());
        const { pathname } = location;

        if (
          roleName !== ROLES_ENUM.SUPERADMIN &&
          SUPERADMIN_ROUTES.includes(pathname)
        ) {
          navigate("/login");
        }

        isOrgLoaded.current = true;
        localStorage.getItem("roleName", roleName);
      } catch (error) {
        redirectToLogin();
      }
    };

    if (accessToken) {
      if (!isOrgLoaded.current) {
        getUserApi();
      }
    } else {
      redirectToLogin();
    }
  }, []);
};

export default useSetAppConfigs;
