import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
// import colors from "../../styles/Colors";
import { ButtonContainer } from "./NewSeekerModal";
import Button from "../elements/button/Button";
import { Container, StyledForm } from "./EditCampaignModal";

const PageHeading = styled.h2`
  font-size: 20px;
  font-weight: 600;
  padding: 15px 0 0;
`;

const Label = styled.div`
  font-weight: 600;
  min-width: 140px;
  padding-right: 10px;
`;

const Info = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  padding: 7px 30px 7px 0;
`;

export const ViewSeekerModal = (close) => {
  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
      >
        <Row gutter={10}>
          <Col span={24}>
            <PageHeading>Household details</PageHeading>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Name :</Label> Olivia Miller
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Gender :</Label> Female
            </Info>
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Date of birth :</Label> 09/08/1978
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>No. of Members :</Label> 3
            </Info>
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Phone Number :</Label> 111 331 4445
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Email Address :</Label> demo2222@gmail
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Street Address :</Label> 3630 Ashwood Drive
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Status :</Label> Approved
            </Info>
          </Col>

          <Col span={24}>
            <PageHeading>Member 1 Details</PageHeading>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Name :</Label> Mille Miller
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Gender :</Label> Female
            </Info>
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Date of birth :</Label> 05/03/1998
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Phone Number :</Label> 111 331 4445
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Email Address :</Label> demo2222@gmail
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Relation :</Label> Daughter
            </Info>
          </Col>

          <Col span={24}>
            <PageHeading>Member 2 Details</PageHeading>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Name :</Label> jack Miller
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Gender :</Label> Male
            </Info>
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Date of birth :</Label> 07/09/2001
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Phone Number :</Label> 111 331 4445
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Email Address :</Label> demo2222@gmail
            </Info>
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Info>
              <Label>Relation :</Label> Son
            </Info>
          </Col>
        </Row>

        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

export default ViewSeekerModal;
