const colors = {
  primary1: "#419fe3",
  primary: "#004d8c",
  secondary: "#ef7b14",
  primaryBorderColor: "#d9d9d9",
  background: "#004d8c1f",
  backgroundPrimary: "#004d8c0a",
  backgroundSecondary: "#ef7b141f",
  black: "#000000",
  white: "#ffffff",
  offWhite: "#f8f9f9",
  bordercolor: "#a9a9a9",
  textGrey: "#6e6e6e",
  textGrey1: "#4f4f4f",
  red2: "#ff4d4f",
  darkGrey: "#4F4F4F",
  red: "#E63946",
  green: "#228B22",
  orange: "#FF6802",
  cream: "#FFF9F5",
  lightCream: "#FFFDFB",
  smoke: "#FBFBFF",
  darkBlue: "#083D77",
  grey1: "#333333",
  grey2: "#4F4F4F",
  grey3: "#828282",
  grey4: "#f9f9f9",
  grey5: "#E0E0E0",
  grey6: "#F2F2F2",
  primary2: "#44b6e74a",
  yellow: "#FFBA49",
  purple: "#1E1449",
  lightPurple: " #9999cc",
  lightgrey:"#d3d3d3",
};
export default colors;
