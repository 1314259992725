// import { CloudUploadOutlined } from "@ant-design/icons";
import { Col, Form, Row, Input as AntInput } from "antd";
// import moment from "moment";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/Colors";
import { errorMessages } from "../../utils/errorMessages";
import { regErrorMessages } from "../../utils/regErrorMessages";
import { regExpPattern } from "../../utils/regExpPattern";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import Button from "../elements/button/Button";
import useMutate from "../../api/hooks/useMutate";
import EMPLOYEE_URLS from "../../api/urls/EMPLOYEE_URL";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import Input from "../elements/input/Input";
import { Container, StyledForm } from "./EditCampaignModal";
// import TextArea from "antd/lib/input/TextArea";
import TextAreaB from "../elements/textArea/TextArea";
import { ButtonContainer } from "./NewSeekerModal";

const Wrapper = styled.div`
  padding: 10px;
  @media screen and (max-width: 767px) {
    margin: 0 0 10px 0;
  }
`;

export const HouseDetailModalExclamation = ({
  header,
  info,
  close,
  data,
  setMemberStatus,
  setStatusData,
  loader,
  id,
}) => {
  const [form] = Form.useForm();
  const [isAllowed, setIsAllowed] = useState(true);
  const handleChange = (e) => {
    const value = e.target.value;
    const reason = value.trim();
    setIsAllowed(!(reason.length > 0));
  };
  const onSubmit = (values) => {
    if (!id) {
      return setStatusData({
        Reason: values?.reason,
        isApproved: false,
      });
    }
    setMemberStatus({
      reason: values?.reason.trim(),
      memberId: id,
    });
  };
  return (
    <Container>
      <Wrapper>
        <StyledForm
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          onFinish={onSubmit}
          form={form}
        >
          <Row>
            <Col span={24}>
              <TextAreaB
                label="Reason"
                name="reason"
                required={true}
                onChange={handleChange}
                requiredMessage={errorMessages.REASON}
                initialValue={data?.reason || ""}
              />
            </Col>
          </Row>
          <ButtonContainer noBorder>
            <Button
              text="Cancel"
              className={"SecondaryBtn"}
              onClick={() => close()}
            />
            <Button
              text="Submit"
              htmlType="submit"
              loading={loader}
              disabled={isAllowed}
            />
          </ButtonContainer>
        </StyledForm>
      </Wrapper>
    </Container>
  );
};

export default HouseDetailModalExclamation;
