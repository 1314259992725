import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled from "styled-components";
import colors from "../../styles/Colors";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
const LoaderContainer = styled.div`
  .ant-spin {
    height: calc(100% - 100px);
    width: calc(100% - 180px);
    top: 100px;
    left: 180px;
    position: absolute;

    @media (max-width: 991px) {
      height: calc(100% - 0px);
      width: calc(100% - 0px);
      top: initial;
      left: initial;
    }

    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000011;
    svg {
      font-size: 35px;
    }
  }
`;
const LoaderWrapper = styled.div``;

const LoaderDashboard = () => {
  return (
    <>
      <LoaderContainer>
        <LoaderWrapper>
          <Spin indicator={antIcon} />
        </LoaderWrapper>
      </LoaderContainer>
    </>
  );
};

export default LoaderDashboard;
