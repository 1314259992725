import styled from "styled-components";
import colors from "../../styles/Colors";
export const SideBarContainer = styled.div`
  box-shadow: 3px 0px 3px #00000017;
  background-color: ${colors.white};
  width: 180px;
  position: fixed;
  top: 100px;
  bottom: 0;
  @media screen and (max-width: 991px) {
    top: 70px;
    z-index: 99999;
    left: ${(props) => (props.isActive ? "0" : "-200px")};
    transition: all.5s;
  }
`;
export const SideNav = styled.div`
  height: calc(100vh - 144px);
  overflow: auto;
  @media screen and (max-width: 991px) {
    height: calc(100vh - 113px);
  }
`;

export const Logo = styled.div`
  width: 110px;
  margin: 0 auto;
`;

export const NavList = styled.ul`
  padding: 0;
  margin: 0;
`;

export const NavLink = styled.li`
  margin: 0;
  list-style: none;
  padding: 10px 20px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 15px;
  background-color: ${(props) => (props.active ? "#ef7b14" : null)};
  color: ${(props) => (props.active ? "#ffffff" : "#004d8c")};
  transition: all 0.8s;
  font-weight: 600;
  &:hover {
    transition: all 0.5s;
    color: ${colors.primary};
    background-color: ${colors.background};
  }
`;
export const RoleHeading = styled.h2`
  font-size: 18px;
  padding: 5px 20px;
  background: #004d8cd1;
  color: #fff;
  margin-top: 15px;
`;

export const BottomNav = styled.div`
  position: absolute;
  bottom: 0;
  width: 180px;
  background-color: ${colors.primary};
  z-index: 9999;
  li {
    color: ${colors.white};
    &:hover {
      color: ${colors.white};
    }
  }
`;
