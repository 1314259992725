import styled from "styled-components";
import colors from "../../../../styles/Colors";
export const Moreicon = styled.div`
  height: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.background};
  padding: 5px;
  cursor: pointer;
  width: max-content;
  &:hover {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
`;
