import { Row, Table } from "antd";
import { useNavigate } from "react-router-dom";
import {
  MainContent,
  Section,
  ActionIcon,
} from "../../donor/familyList/FamilyListStyle";
import { EyeOutlined } from "@ant-design/icons";
import CAMPAIGN_URL from "../../../../api/urls/CAMPAIGN_URL";
import useMutate from "../../../../api/hooks/useMutate";
import { useEffect, useState } from "react";
import { formatDate } from "../../../../utils/dateTime";
import { useSelector } from "react-redux";
import {
  PageHeading,
  SearchContainer,
} from "../../admin/campaigns/CampaignStyle";
import { debounce } from "../../../../utils/Debounce";
import Input from "../../../elements/input/Input";
import { COLUMN_WIDTH } from "../../admin/seekers/viewclient/Viewclient";

// import { Link } from "../../elements/links/Links";
let pagination = "";
function ReceivedDination() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortTable, setSortTable] = useState({
    column: "campaignName",
    order: "ASC",
  });
  const { user } = useSelector((state) => state.auth);
  const id = user?.id;
  const [getCampaignsList, { response, loading }] = useMutate({
    path: CAMPAIGN_URL.CAMPAIGNBYSEEKER,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const param = {
    pageNo: page || 1,
    PageSize: 100,
    searchValue: searchValue,
    sortColumn: sortTable.column,
    sortOrder: sortTable.order,
    // seekerId:id,
  };
  useEffect(() => {
    getCampaignsList({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [page, sortTable, searchValue]);
  pagination = response?.data?.pagination;
  const columns = [
    {
      title: "Event Name",
      dataIndex: "campaignName",
      sorter: true,
      defaultSortOrder: "ascend",
      // render: (text) => <Link text={text} to={"/view-family-details"} />,
    },
    {
      title: "Start Date",
      dataIndex: "campaignStartDate",
      sorter: true,
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
    },
    {
      title: "End Date",
      dataIndex: "campaignEndDate",
      sorter: true,
      render: (date) => <span>{formatDate(date, "MM/DD/YYYY")}</span>,
    },
    {
      title: "Attended Event",
      dataIndex: "donationStatus",
      sorter: true,
      render: (date) => <span>Yes</span>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <ActionIcon
          onClick={() =>
            navigate(`/client-donation-details?id=${record.campaignId}`)
          }
        >
          <EyeOutlined />
        </ActionIcon>
      ),
    },
  ];
  const handleChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    if (Object.keys(sorter).length) {
      setSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const search = debounce(handleSearch);
  return (
    <>
      <MainContent>
        <Section>
          <Row>
            <PageHeading>
              Activity History
              <SearchContainer>
                <Input
                  placeholder="Search by Event Name"
                  // onSearch={onSearch}
                  // style={{
                  //   width: "auto",
                  // }}
                  onChange={search}
                />
              </SearchContainer>
            </PageHeading>
          </Row>
          <Table
            dataSource={response?.data?.records}
            columns={columns}
            scroll={{ y: 400, x: columns.length * COLUMN_WIDTH }}
            loading={loading}
            onChange={handleChange}
            pagination={{
              pageSize: 100,
              defaultCurrent: page,
              total: pagination?.totalRecordsCount,
              showSizeChanger: false,
              hideOnSinglePage: true,
            }}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend", "ascend"]}
          />
        </Section>
      </MainContent>
    </>
  );
}
export default ReceivedDination;
