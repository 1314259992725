import { Checkbox, Col, Row, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalFirstLetter, formatDate } from "../../../../utils/dateTime";
import { formatPhoneNumber } from "../../../../utils/phoneNumber";
import Button from "../../../elements/button/Button";
import Link from "../../../elements/links/Links";
import {
  ButtonContainer,
  Info,
  Label,
} from "../../donor/familyDetails/FamilyDetailsStyle";
import {
  CampaignImage,
  MainContent,
} from "../../donor/familyList/FamilyListStyle";
import { PageHeading } from "../campaigns/CampaignStyle";
import { useQuery } from "../../../../utils/useQuery";
import useMutate from "../../../../api/hooks/useMutate";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import ORG_URL from "../../../../api/urls/ORG_URL";
import { ActionIconWrapper } from "../donors/DonorsStyle";
import styled from "styled-components";
import { Spacer } from "../donors/ViewDonordetails";
import LoaderDashboard from "../../../loader/LoaderDashboard";
import toast from "react-hot-toast";
import { COLUMN_WIDTH } from "../seekers/viewclient/Viewclient";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import downloadFile from "../../../../utils/downloadFile";

const FamilyCount = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 5px 0px;
  font-size: 15px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: end;
    gap: 12px;
  }
`;
const Stepper = styled.span`
  padding-right: 20px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;
let donorPagination;
let clientPagination;
const ViewEvent = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const id = query.get("id");
  const [isSuccess, setIsSuccess] = useState(false);
  const [filter, setFilter] = useState("All");
  const [clientPage, setClientPage] = useState(1);
  const [donorPage, setDonorPage] = useState(1);
  const [clientSortTable, setClientSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [donorSortTable, setDonorSortTable] = useState({
    column: "CreatedDate",
    order: "desc",
  });
  const [selectedDonors, setSelectedDonors] = useState({
    donors: [],
  });
  const [selectedClients, setSelectedClients] = useState({
    seekers: [],
  });
  const [
    getCampaignDetail,
    { response: campaignDetails, loading: campaignLoading },
  ] = useMutate({
    path: SEEKER_URLS.CAMPAIGNDETAIL,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [addfamilyForDonor, { loading: addfamilyForDonorLoading }] = useMutate({
    path: ORG_URL.SENDMAILTODONOR,
    method: "post",
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });

  const [exportDonors, { loading: exportDonorLoader }] = useMutate({
    path: DONOR_URLS.EXPORTDONORS,
    // successToast: true,
    errorToast: true,
    method: "post",
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const [exportClients, { loading: exportClientLoader }] = useMutate({
    path: SEEKER_URLS.EXPORTSEEKER,
    // successToast: true,
    errorToast: true,
    method: "post",
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });

  const [addfamilyForSeeker, { loading: addfamilyForSeekerLoading }] =
    useMutate({
      path: ORG_URL.SENDMAILTOSEEKER,
      method: "post",
      errorToast: true,
      onCompleted: () => {},
      onError: () => {},
    });
  const [
    getDonorTablesList,
    { response: donorResponse, loading: donorResponseLoading },
  ] = useMutate({
    path: ORG_URL.DONORS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [
    getClientTableList,
    { response: clientResponse, loading: clientResponseLoading },
  ] = useMutate({
    path: ORG_URL.SEEKER,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      render: (text, record) => (
        <a onClick={() => navigate(`/view-donor-details?id=${record.userId}`)}>
          {`${capitalFirstLetter(
            record?.firstName
          )} ${" "} ${capitalFirstLetter(record.lastName)}`}
        </a>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Phone Number ",
      dataIndex: "phoneNo",
      sorter: true,
      render: (phoneNumber) => formatPhoneNumber(phoneNumber),
    },
    {
      title: "Attended Event",
      dataIndex: "isAttendedEvent",
      sorter: true,
      render: (isAttendedEvent) => (
        <span>{isAttendedEvent ? "Yes" : "No"}</span>
      ),
    },
    {
      title: "Send Mail",
      dataIndex: "sendMail",
      render: (_, record) => (
        <ActionIconWrapper>
          {!record?.isAttendedEvent ? (
            <Checkbox
              checked={selectedDonors?.donors?.find((check) => {
                return check === record?.userId;
              })}
              onChange={(e) => selectDonors(e, record)}
            >
              Select Donor
            </Checkbox>
          ) : (
            "Already Participated"
          )}
        </ActionIconWrapper>
      ),
    },
  ];

  const columns2 = [
    {
      title: "FAM #",
      dataIndex: "userName",
      sorter: true,
      defaultSortOrder: "ascend",
      render: (text, record) => (
        <>
          {text ? (
            // <Link text={text} to={`/view-client-detail?id=${record?.userId}`} />
            <Link
              text={text}
              to={`/client-donation-details-admin?id=${id}&seekerId=${record?.userId}&admin=true`}
            />
          ) : (
            "Not Assigned"
          )}
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      render: (_, record) => `${record?.firstName} ${record.lastName}`,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
  ];

  const columns1 = [
    {
      title: "FAM #",
      dataIndex: "userName",
      sorter: true,
      defaultSortOrder: "ascend",
      render: (text, record) => (
        <>
          {text ? (
            <Link text={text} to={`/view-client-detail?id=${record?.userId}`} />
          ) : (
            "Not Assigned"
          )}
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "firstName",
      sorter: true,
      render: (_, record) => `${record?.firstName} ${record.lastName}`,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: "Attended Event",
      dataIndex: "isAttendedEvent",
      sorter: true,
      render: (isAttendedEvent) => (
        <span>{isAttendedEvent ? "Yes" : "No"}</span>
      ),
    },
    {
      title: "Send Mail",
      dataIndex: "sendMail",
      render: (_, record) => (
        <ActionIconWrapper>
          {!record?.isAttendedEvent ? (
            <Checkbox
              checked={selectedClients?.seekers?.find((check) => {
                return check === record.userId;
              })}
              onChange={(e) => selectClients(e, record)}
            >
              Select family
            </Checkbox>
          ) : (
            "Already Participated"
          )}
        </ActionIconWrapper>
      ),
    },
  ];

  const handleClientDetailsChange = (pagination, filters, sorter) => {
    setClientPage(pagination.current);
    if (Object.keys(sorter).length) {
      setClientSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const handleDonorDetailsChange = (pagination, filters, sorter) => {
    setDonorPage(pagination.current);
    if (Object.keys(sorter).length) {
      setDonorSortTable({
        column: sorter.field,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    }
  };
  const selectDonors = (e, record) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedDonors((val) => ({
        ...val,
        donors: [...val.donors, record.userId],
      }));
    } else {
      const filteredDonors = selectedDonors?.donors?.filter((family) => {
        return family !== record.userId;
      });
      setSelectedDonors((val) => ({
        ...val,
        donors: [...filteredDonors],
      }));
    }
  };

  const selectClients = (e, record) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedClients((val) => ({
        ...val,
        seekers: [...val.seekers, record.userId],
      }));
    } else {
      const filterClients = selectedClients?.seekers?.filter((family) => {
        return family !== record.userId;
      });
      setSelectedClients((val) => ({
        ...val,
        seekers: [...filterClients],
      }));
    }
  };
  const submitDonorSelectedFamilies = () => {
    const variables = {
      campaignId: id,
      donors: JSON.stringify(selectedDonors?.donors),
    };
    addfamilyForDonor({
      variables,
      onCompleted: () => {
        toast.success("Reminder sent successfully");
        setSelectedDonors({
          donors: [],
        });
      },
      onError: () => {},
    });
  };
  const submitClientSelectedFamilies = () => {
    const variables = {
      campaignId: id,
      seekers: JSON.stringify(selectedClients?.seekers),
    };
    addfamilyForSeeker({
      variables,
      onCompleted: () => {
        toast.success("Reminder sent successfully");
        setSelectedClients({
          seekers: [],
        });
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    getCampaignDetail({
      params: {
        campaignId: id,
      },
      onCompleted: () => {},
      onError: () => {},
    });
  }, []);
  useEffect(() => {
    const param = {
      pageNo: donorPage || 1,
      PageSize: 100,
      searchValue: "",
      sortColumn: donorSortTable.column,
      sortOrder: donorSortTable.order,
      CampaignId: id,
    };
    getDonorTablesList({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [donorPage, donorSortTable]);
  donorPagination = donorResponse?.data?.pagination;
  useEffect(() => {
    const param = {
      PageNo: clientPage || 1,
      PageSize: 100,
      SearchValue: "",
      SortColumn: clientSortTable.column,
      SortOrder: clientSortTable.order,
      CampaignId: id,
      filter: filter,
    };
    getClientTableList({
      params: param,
      onCompleted: () => {},
      onError: () => {},
    });
  }, [clientPage, clientSortTable, filter]);
  clientPagination = clientResponse?.data?.pagination;
  const handleTabChange = (value) => {
    if (value === "1") {
      setFilter("All");
    } else if (value === "2") {
      setFilter("approvedWishes");
    } else if (value === "3") {
      setFilter("unapprovedWishes");
    } else if (value === "4") {
      setFilter("notOptIn");
    }
    setClientPage(1);
    setClientSortTable({
      column: "CreatedDate",
      order: "desc",
    });
  };
  const exportEventDonors = () => {
    const params = {
      CampaignId: id,
    };
    exportDonors({
      params,
      onCompleted: (response) => {
        downloadFile(response);
      },
      onError: () => {},
    });
  };
  const exportEventClients = () => {
    const params = {
      CampaignId: id,
    };
    exportClients({
      params,
      onCompleted: (response) => {
        downloadFile(response);
      },
      onError: () => {},
    });
  };
  return (
    <>
      {!campaignLoading ? (
        <MainContent>
          <Row>
            <Col
              style={{
                display: "flex",
                flex: "0 0 100%",
                flexDirection: "column",
                maxWidth: "100%",
              }}
              span={24}
            >
              <PageHeading>
                {campaignDetails?.data?.campaignName}
                <Button text="Back" onClick={() => navigate(-1)} />
              </PageHeading>

              <CampaignImage src={campaignDetails?.data?.imageFile} />
            </Col>
            <Col span={24}>
              <Info>
                <Label>{campaignDetails?.data?.description}</Label>
              </Info>
            </Col>

            <Col span={24} lg={{ span: 12 }}>
              <Info>
                <Label>Start date :</Label>
                {campaignDetails?.data?.campaignStartDate &&
                  formatDate(
                    campaignDetails?.data?.campaignStartDate,
                    "MM/DD/YYYY"
                  )}{" "}
              </Info>
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <Info>
                <Label>End date :</Label>
                {campaignDetails?.data?.campaignEndDate &&
                  formatDate(
                    campaignDetails?.data?.campaignEndDate,
                    "MM/DD/YYYY"
                  )}
              </Info>
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <Info>
                <Label>Enrollment period :</Label>
                {campaignDetails?.data?.enrollmentPeriodStartDate &&
                  formatDate(
                    campaignDetails?.data?.enrollmentPeriodStartDate,
                    "MM/DD/YYYY"
                  )}{" "}
                To{" "}
                {campaignDetails?.data?.enrollmentPeriodEndDate &&
                  formatDate(
                    campaignDetails?.data?.enrollmentPeriodEndDate,
                    "MM/DD/YYYY"
                  )}
              </Info>
            </Col>

            <Col span={24} lg={{ span: 12 }}>
              <Info>
                <Label>Donations accepted :</Label>
                {campaignDetails?.data?.donorPeriodStartDate &&
                  formatDate(
                    campaignDetails?.data?.donorPeriodStartDate,
                    "MM/DD/YYYY"
                  )}{" "}
                To{" "}
                {campaignDetails?.data?.donorPeriodEndDate &&
                  formatDate(
                    campaignDetails?.data?.donorPeriodEndDate,
                    "MM/DD/YYYY"
                  )}
              </Info>
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <Info>
                <Label>Donation drop-off :</Label>
                {campaignDetails?.data?.dropOfAddress}
              </Info>
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <Info>
                <Label>Client pickup address :</Label>
                {campaignDetails?.data?.pickupAddress}
              </Info>
            </Col>
            <Col span={24} lg={{ span: 12 }}>
              <Info>
                <Label>Client pickup dates :</Label>
                {campaignDetails?.data?.pickupStartDate &&
                  formatDate(
                    campaignDetails?.data?.pickupStartDate,
                    "MM/DD/YYYY"
                  )}{" "}
                To{" "}
                {campaignDetails?.data?.pickupEndDate &&
                  formatDate(
                    campaignDetails?.data?.pickupEndDate,
                    "MM/DD/YYYY"
                  )}
              </Info>
            </Col>

            <Col span={24} lg={{ span: 12 }}>
              <Info>
                <Label>Questions Visit :</Label>
                {campaignDetails?.data?.questionsVisitAddress}
              </Info>
            </Col>
            <Col span={24} lg={{ span: 24 }}>
              <Spacer />
              <PageHeading>
                Donors
                <FamilyCount>
                  <Stepper>
                    <ButtonContainer style={{ margin: "0" }}>
                      <Button
                        text="Export Opted-in Donors"
                        onClick={exportEventDonors}
                        loading={exportDonorLoader}
                      />
                    </ButtonContainer>
                  </Stepper>
                  <Label>
                    Selected Donors Count: {selectedDonors?.donors?.length}
                  </Label>
                </FamilyCount>
              </PageHeading>
            </Col>
            <Col span={24} lg={{ span: 24 }}>
              <Table
                dataSource={donorResponse?.data?.records}
                loading={donorResponseLoading}
                sortDirections={["ascend", "descend", "ascend"]}
                columns={columns}
                onChange={handleDonorDetailsChange}
                scroll={{ y: 400, x: columns.length * 200 }}
                pagination={{
                  pageSize: 100,
                  defaultCurrent: donorPage,
                  total: donorPagination?.totalRecordsCount,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
                showSorterTooltip={false}
              />
              {donorPagination?.totalRecordsCount > 0 && (
                <ButtonContainer>
                  <Button
                    text="Send Mail"
                    disabled={!selectedDonors?.donors?.length ? true : false}
                    onClick={submitDonorSelectedFamilies}
                    loading={addfamilyForDonorLoading}
                  />
                </ButtonContainer>
              )}
            </Col>

            <Col span={24} lg={{ span: 24 }}>
              <Spacer />
              <PageHeading>
                Clients
                <FamilyCount>
                  <Stepper>
                    <ButtonContainer style={{ margin: 0 }}>
                      <Button
                        text="Export Opted-in Clients"
                        onClick={exportEventClients}
                        loading={exportClientLoader}
                      />
                    </ButtonContainer>
                  </Stepper>
                  <Label>
                    Selected Families Count: {selectedClients?.seekers?.length}
                  </Label>
                </FamilyCount>
              </PageHeading>
              <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                <Tabs.TabPane tab="All" key="1">
                  <Table
                    dataSource={clientResponse?.data?.records}
                    loading={clientResponseLoading}
                    sortDirections={["ascend", "descend", "ascend"]}
                    columns={columns1}
                    onChange={handleClientDetailsChange}
                    showSorterTooltip={false}
                    scroll={{ y: 400, x: columns1.length * COLUMN_WIDTH }}
                    pagination={{
                      pageSize: 100,
                      defaultCurrent: clientPage,
                      total: clientPagination?.totalRecordsCount,
                      showSizeChanger: false,
                      hideOnSinglePage: true,
                    }}
                  />
                  {clientPagination?.totalRecordsCount > 0 && (
                    <ButtonContainer style={{ margin: 0 }}>
                      <Button
                        text="Send Mail"
                        disabled={
                          !selectedClients?.seekers?.length ? true : false
                        }
                        onClick={submitClientSelectedFamilies}
                        loading={addfamilyForSeekerLoading}
                      />
                    </ButtonContainer>
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Approved" key="2">
                  <Table
                    dataSource={clientResponse?.data?.records}
                    loading={clientResponseLoading}
                    sortDirections={["ascend", "descend", "ascend"]}
                    columns={columns2}
                    onChange={handleClientDetailsChange}
                    showSorterTooltip={false}
                    scroll={{ y: 400, x: columns2.length * COLUMN_WIDTH }}
                    pagination={{
                      pageSize: 100,
                      defaultCurrent: clientPage,
                      total: clientPagination?.totalRecordsCount,
                      showSizeChanger: false,
                      hideOnSinglePage: true,
                    }}
                  />
                  {/* {clientPagination?.totalRecordsCount > 0 && (
                  <ButtonContainer>
                    <Button
                      text="Send Mail"
                      onClick={submitClientSelectedFamilies}
                    />
                  </ButtonContainer>
                )} */}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Unapproved" key="3">
                  <Table
                    dataSource={clientResponse?.data?.records}
                    loading={clientResponseLoading}
                    sortDirections={["ascend", "descend", "ascend"]}
                    columns={columns2}
                    onChange={handleClientDetailsChange}
                    showSorterTooltip={false}
                    scroll={{ y: 400, x: columns2.length * COLUMN_WIDTH }}
                    pagination={{
                      pageSize: 100,
                      defaultCurrent: clientPage,
                      total: clientPagination?.totalRecordsCount,
                      showSizeChanger: false,
                      hideOnSinglePage: true,
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Not Adopted" key="4">
                  <Table
                    dataSource={clientResponse?.data?.records}
                    loading={clientResponseLoading}
                    sortDirections={["ascend", "descend", "ascend"]}
                    columns={columns2}
                    onChange={handleClientDetailsChange}
                    showSorterTooltip={false}
                    scroll={{ y: 400, x: columns2.length * COLUMN_WIDTH }}
                    pagination={{
                      pageSize: 100,
                      defaultCurrent: clientPage,
                      total: clientPagination?.totalRecordsCount,
                      showSizeChanger: false,
                      hideOnSinglePage: true,
                    }}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </MainContent>
      ) : (
        <LoaderDashboard />
      )}
    </>
  );
};

export default ViewEvent;
