import styled from "styled-components";
import { Row, Col, Image, Form, Switch } from "antd";
import Logo from "../../../../assets/images/logo.png";
import Input from "../../../elements/input/Input";
import Button from "../../../elements/button/Button";
import DatePickerB from "../../../elements/datePicker/DatePicker";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { errorMessages } from "../../../../utils/errorMessages";
import {
  LayoutContainer,
  OuterWrapper,
  LeftContainer,
  RightContainer,
  Heading,
} from "../../admin/signUp/SingupStlyes";

import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import AddressSearchDropdown from "../../../elements/address/GoogleAddressInput";
import {
  CloseOutlined,
  CloudUploadOutlined,
  FilePdfOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import colors from "../../../../styles/Colors";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import Select from "../../../elements/select/Select";
import { PhoneNumberInput } from "../../../elements/input/phoneNumberInput";
import useMutate from "../../../../api/hooks/useMutate";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import { genderFormat } from "../../../../utils/genderFormat";
import { getAgeFromTs, getTs, removeSpaces } from "../../../../utils/dateTime";
import { AddNewMember } from "../addFamilyDetails/addFamilyDetailsStyle";
import { DocItem } from "../profile/Profile";
import { useRef } from "react";
import Link from "../../../elements/links/Links";
import CustomImage from "../../../image/LeftContainer";

export const FormFields = styled.div`
  position: relative;
  padding: 0 20px 10px;
  overflow: auto;
  max-height: 80vh;
  @media screen and (max-width: 1199px) {
    max-height: 400px;
    padding: 0 20px 25px;
  }
`;

export const InnerContent = styled.div`
  width: 80%;
  min-height: 60%;
  max-height: 98%;
  border: 1px solid #ccc;
  background: ${colors.white};
  border-radius: 7px;
  @media screen and (max-width: 576px) {
    width: 90%;
  }
`;

export const BottomForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 10px 30px 10px 40px;
  align-items: center;
  background: #fff;
  z-index: 999;
  button {
    margin: initial !important;
  }
  @media screen and (max-width: 576px) {
    flex-direction: column-reverse;
    gap: 10px;
  }
`;

export const ImagePlaceholder = styled.div`
  height: 100px;
  width: 100%;
  margin: 10px 0;
  border: 1.5px dashed #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  svg {
    font-size: 50px;
    fill: #ccc;
  }
`;

export const NewMemberWrapper = styled.div`
  margin: 10px 0;
`;

export const PlaceHolderWrappper = styled.div`
  width: 70%;
  margin: 20px auto 20px;
  position: relative;
  input {
    height: 100px;
    width: 100%;
    opacity: 0;
    margin-top: -110px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  label {
    display: none;
  }
  .ant-image {
    width: 100%;
  }
  span {
    color: ${colors.grey3};
    padding: 0 10px;
    font-weight: 600;

    &.anticon.anticon-close {
      position: absolute;
      top: -6px;
      right: -8px;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #ccc;
      padding: 2px;
    }
  }
`;

export const Note = styled.div`
  padding: 10px 10px;
  // text-align: center;
  span {
    font-weight: 600;
    color: #909090;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;
export const Requiredwrapper = styled.div`
  position: relative;

  .validdata {
    color: ${colors.red2};
    position: absolute;
    top: 6px;
    left: 8px;
    font-size: 14px;
    font-weight: 300;
  }
`;

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  @media screen and (max-width: 575px) {
    margin-top: -10px;
    margin-bottom: 10px;
  }
`;

export const ModalContent = styled.div`
  padding: 20px 10px;
`;

export const NewMemberDetails = styled.div`
  background: ${colors.white};
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  padding: 15px 25px 10px 15px;
  margin-bottom: 30px;
`;

export const Label = styled.div`
  font-weight: 500;
  min-width: 110px;
  font-size: 14px;
  .required {
    color: ${colors.red};
    margin-left: 13px;
  }
`;

export const Details = styled.div`
  display: flex;
`;

const GenderOptions = [
  {
    value: "Male",
  },
  {
    value: "Female",
    key: 2,
  },
  {
    value: "Other",
    key: 3,
  },
];

export const relationOptions = [
  { key: "1", value: "Self" },
  { key: "2", value: "Husband" },
  { key: "3", value: "Wife" },
  { key: "4", value: "Father" },
  { key: "5", value: "Mother" },
  { key: "6", value: "Partner" },
  { key: "7", value: "Domestic Partner" },
  { key: "8", value: "Son" },
  { key: "9", value: "Daughter" },
  { key: "10", value: "Brother" },
  { key: "11", value: "Sister" },
  { key: "12", value: "Grandfather" },
  { key: "13", value: "Grandmother" },
  { key: "14", value: "Grandson" },
  { key: "15", value: "Granddaughter" },
  { key: "16", value: "Aunt" },
  { key: "17", value: "Uncle" },
  { key: "18", value: "Niece" },
  { key: "19", value: "Nephew" },
  { key: "20", value: "Cousion" },
  { key: "21", value: "Friend" },
  { key: "22", value: "Other" },
  { key: "23", value: "Great Grandchild" },
];

function RegisterSeeker() {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const [seekerRegistrationModal, setSeekerRegistrationModal] = useState(false);
  const [addressError, setAddressError] = useState("");
  const [uploadMemberDoucment, setUploadMemberDoucment] = useState({
    preview: "",
    value: "",
  });
  const [postSeekerSignUp, { response, error, loading }] = useMutate({
    path: SEEKER_URLS.SIGNUP,

    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // const location = useLocation();

  const onSubmit = (values) => {
    const houseHoldMembers =
      registerationForm?.member?.length > 0
        ? registerationForm?.member?.map((item) => {
            if (item?.documents?.[0]?.file.length) {
              item.documents = [];
            }
            return item;
          })
        : [];
    const variables = {
      isHouseHold: 1,
      firstName: values.MemberfirstName,
      lastName: values.MemberlastName,
      email: values.MemberEmail,
      gender: genderFormat(values.Membergender),
      // birthDate: moment(values?.memberDOB).format("YYYY-MM-DD"),
      birthDate: getTs(values.memberDOB),
      phoneNumber: values?.MemberContactNumber?.replace(/\s+/g, "")?.replace(
        /\s+/g,
        ""
      ),
      ...address,
      longitude: address?.lng,
      latitude: address?.lat,
      documents: [
        {
          file: uploadMemberDoucment.preview,
          type: uploadMemberDoucment.value.type,
        },
      ],
      members: houseHoldMembers,
    };

    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }

    postSeekerSignUp({
      variables,
      onCompleted: () => {
        setSeekerRegistrationModal(true);
      },
      onError: () => {},
    });
  };
  const [uploadOtherDocuments, setUploadOtherDocuments] = useState({
    value: "",
    preview: "",
  });
  const [antherHouseHold, setAntherHouseHold] = useState(false);
  const getOtherMember = (checked) => {
    if (checked) {
      setRegisterationForm((val) => ({
        ...val,
        member: [...val.member, ...members],
      }));
      setIsAllowed((old) => [...old, true]);
    } else {
      setRegisterationForm((val) => ({
        ...val,
        member: [],
      }));
      setIsAllowed([]);
    }
    setAntherHouseHold(checked);
  };
  const fileUpload = (e, check) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      if (check === "housemember") {
        setUploadMemberDoucment({
          value: files,
          preview: reader.result,
        });
      } else {
        setUploadOtherDocuments({
          value: files,
          preview: reader.result,
        });
      }
    };
    reader.readAsDataURL(files);
  };
  const members = [
    {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      birthDate: "",
      address: "panchkula",
      relation: "",
      gender: "",
      documents: [
        {
          file: "",
          type: "",
        },
      ],
    },
  ];
  const dateFormat = "MM/DD/YYYY";
  const regForm = {
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    address: "",
    contactNumber: "",
    email: "",
    password: "",
    files: "",
    member: [],
  };
  const [registerationForm, setRegisterationForm] = useState(regForm);
  const isOrgLoaded = useRef(false);
  const [isAllowed, setIsAllowed] = useState([]);
  const [address, setAddress] = useState({});
  const addMember = () => {
    setRegisterationForm((val) => ({
      ...val,
      member: [...val.member, ...members],
    }));
    setIsAllowed((old) => [...old, true]);
  };

  const handleFirstName = (e, id) => {
    const { value } = e.target;
    const updatedData = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.firstName = value;
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };
  const handleDob = (e, id) => {
    const updatedData = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.birthDate = getTs(e);
        const date = getTs(e);
        const allowedMembers = isAllowed?.map((memInfo, memIndex) => {
          if (memIndex === id) {
            if (getAgeFromTs(date) <= 18) {
              memInfo = false;
            } else {
              memInfo = true;
            }
          }

          return memInfo;
        });
        setIsAllowed(allowedMembers);
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };
  const handleLastName = (e, id) => {
    const { value } = e.target;
    const updatedData = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.lastName = value;
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };
  const handleRelation = (value, id) => {
    const updatedData = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.relation = value;
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };

  const handleGenderSelect = (value, id) => {
    const updatedData = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.gender = genderFormat(value);
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };

  const handlePhoneNumber = (e, id) => {
    const { value } = e.target;
    const updatedData = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.phoneNumber = value;
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...updatedData],
    }));
  };
  const handleRemvoveMemberImage = (id) => {
    const filteredImages = registerationForm.member.map((member, index) => {
      if (index === id) {
        member.documents = [
          {
            file: "",
            type: "",
          },
        ];
      }
      return member;
    });
    setRegisterationForm((val) => ({
      ...val,
      member: [...filteredImages],
    }));
  };
  const handleImagesUpload = (e, id) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    let updatedData;
    reader.readAsDataURL(files);
    reader.onloadend = () => {
      updatedData = registerationForm.member.map((item, index) => {
        if (index === id) {
          let items = item?.documents?.[0];
          items.file = reader.result;
          items.type = files.type;
        }
        return item;
      });

      setRegisterationForm((val) => ({
        ...val,
        member: [...updatedData],
      }));
    };
  };
  const handleRemoveImage = () => {
    setUploadMemberDoucment({
      value: "",
      preview: "",
    });
  };

  const BirthDateFormate = (d) => {
    return !d || d.isAfter(new Date()) || d.isSameOrBefore("1960-01-01");
  };

  return (
    <>
      <LayoutContainer>
        <InnerContent>
          <OuterWrapper>
            <Row>
              <Col xl={{ span: 7 }} sm={{ span: 24 }}>
                {/* <LeftContainer>
                  <Image preview={false} src={Logo} width={150}></Image>
                </LeftContainer> */}
                <CustomImage />
              </Col>
              <Col xl={{ span: 17 }} sm={{ span: 24 }}>
                <RightContainer>
                  <Heading>Register as FAM Client</Heading>
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    form={form}
                    onFinish={onSubmit}
                    style={{ padding: "0 0 20px" }}
                  >
                    <FormFields>
                      <Row>
                        <Col span={24} md={{ span: 12 }}>
                          <Input
                            label="First Name"
                            name="MemberfirstName"
                            required={true}
                            initialValue={registerationForm.firstName}
                            requiredMessage={errorMessages.FIRSTNAME}
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <Input
                            label="Last Name"
                            name="MemberlastName"
                            initialValue={registerationForm.lastName}
                            required={true}
                            requiredMessage={errorMessages.LASTNAME}
                          />
                        </Col>

                        <Col span={24} md={{ span: 12 }}>
                          <Select
                            placeholder="Select Gender"
                            name="Membergender"
                            FormFields
                            label={"Gender"}
                            initialValue={registerationForm.gender}
                            option={GenderOptions}
                            required={true}
                            requiredMessage={errorMessages.SELECTGENDER}
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <DatePickerB
                            name="memberDOB"
                            label="Date of Birth"
                            // picker="HeadofHouseholdDateofBirth"
                            disabledDate={BirthDateFormate}
                            required={true}
                            initialValue={registerationForm.dob}
                            requiredMessage={errorMessages.DATEOFBIRTH}
                            onChange={() => {}}
                          />
                        </Col>

                        <Col span={24}>
                          <Requiredwrapper>
                            <span className="validdata">*</span>
                          </Requiredwrapper>
                          <AddressSearchDropdown
                            required={true}
                            addressError={addressError}
                            onChange={(address) => {
                              if (Object?.keys(address)?.length) {
                                if (address?.city?.length) {
                                  setAddressError("");
                                } else {
                                  setAddressError(
                                    "Please select address with city"
                                  );
                                }
                              } else {
                                if (isOrgLoaded.current) {
                                  setAddressError("Please select address");
                                }
                              }
                              setAddress(address);
                              isOrgLoaded.current = true;
                            }}
                            value={address}
                            label="Street Address"
                          />

                          {/* {addressError && (
                            <span className="errormsg">{addressError}</span>
                          )} */}
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <PhoneNumberInput
                            type="tel"
                            label="Phone Number"
                            name="MemberContactNumber"
                            required={true}
                            initialValue={registerationForm.contactNumber}
                            requiredMessage={errorMessages?.CONTACTNUMBER}
                            width="100%"
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <Input
                            label="Email Address"
                            name="MemberEmail"
                            pattern={regExpPattern.EMAIL}
                            required={true}
                            initialValue={registerationForm.email}
                            regExpMessage={regErrorMessages.EMAIL}
                            requiredMessage={errorMessages.EMAIL}
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <PlaceHolderWrappper>
                            {!uploadMemberDoucment.preview?.length > 0 ? (
                              <>
                                <Form.Item
                                  label=""
                                  name="memberImages"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please insert the file !",
                                    },
                                  ]}
                                  initialValue={registerationForm.files}
                                >
                                  <ImagePlaceholder>
                                    <CloudUploadOutlined />
                                    Upload File
                                  </ImagePlaceholder>
                                  <input
                                    name="documents"
                                    type={"file"}
                                    className={"uploadImage"}
                                    accept=".png, .jpg, .jpeg,.pdf "
                                    onChange={(e) =>
                                      fileUpload(e, "housemember")
                                    }
                                  />
                                </Form.Item>
                              </>
                            ) : (
                              <>
                                {uploadMemberDoucment.value.type !==
                                "application/pdf" ? (
                                  <Image src={uploadMemberDoucment.preview} />
                                ) : (
                                  <DocItem>
                                    <FilePdfOutlined />
                                  </DocItem>
                                )}
                                <CloseOutlined onClick={handleRemoveImage} />
                              </>
                            )}
                          </PlaceHolderWrappper>

                          <Note>
                            <span>
                              (Please upload one of the following files:
                              Driver's License, Utility Bill, Birth
                              Certificate.)
                            </span>
                          </Note>
                        </Col>
                        <Col xl={{ span: 24 }} sm={{ span: 24 }} span={24}>
                          <Note>
                            Do you have anyone else in your Household ?
                            <span>
                              <span style={{ paddingLeft: "30px" }}>No</span>
                              <Switch
                                checked={antherHouseHold}
                                onChange={getOtherMember}
                                style={{ margin: "0 10px" }}
                              />
                              <span style={{ color: `#004d8c` }}>Yes</span>
                            </span>
                          </Note>
                        </Col>

                        <Col span={24}>
                          <p>&nbsp;</p>
                        </Col>
                      </Row>
                      {antherHouseHold && (
                        <>
                          <NewMemberDetails>
                            {registerationForm?.member?.length > 0 &&
                              registerationForm?.member.map((member, index) => {
                                return (
                                  (member.memberId = index),
                                  (
                                    <Row>
                                      <Col span={24} md={{ span: 12 }}>
                                        <Input
                                          name={`firstName + ${index}`}
                                          label="First Name"
                                          initialValue={member?.firstName}
                                          onChange={(e) =>
                                            handleFirstName(e, member.memberId)
                                          }
                                          required={true}
                                          requiredMessage={
                                            errorMessages.FIRSTNAME
                                          }
                                        />
                                      </Col>
                                      <Col span={24} md={{ span: 12 }}>
                                        <Input
                                          name={`lastName + ${index}`}
                                          label="Last Name"
                                          onChange={(e) =>
                                            handleLastName(e, member.memberId)
                                          }
                                          initialValue={member?.lastName}
                                          required={true}
                                          requiredMessage={
                                            errorMessages.LASTNAME
                                          }
                                        />
                                      </Col>
                                      <Col span={24} md={{ span: 12 }}>
                                        <DatePickerB
                                          name={`dob + ${index}`}
                                          label="Date of Birth"
                                          // picker="Member1DateofBirth"
                                          disabledDate={BirthDateFormate}
                                          required={true}
                                          onChange={(e) =>
                                            handleDob(e, member.memberId)
                                          }
                                          initialValue={member.birthDate}
                                          requiredMessage={
                                            errorMessages.DATEOFBIRTH
                                          }
                                          format={dateFormat}
                                        />
                                      </Col>
                                      <Col span={24} md={{ span: 12 }}>
                                        <Select
                                          name={`relation + ${index}`}
                                          placeholder="Select"
                                          label={"Relation"}
                                          initialValue={member.relation}
                                          option={relationOptions}
                                          onChange={(e) =>
                                            handleRelation(e, member.memberId)
                                          }
                                          required={true}
                                          requiredMessage={
                                            errorMessages.RELATION
                                          }
                                          defaultValue={member.relation}
                                        />
                                      </Col>
                                      <Col span={24} md={{ span: 12 }}>
                                        <Select
                                          placeholder="Select Gender"
                                          name={`gender + ${index}`}
                                          label={"Gender"}
                                          option={GenderOptions}
                                          initialValue={member.gender}
                                          onChange={(e) => {
                                            handleGenderSelect(
                                              e,
                                              member.memberId
                                            );
                                          }}
                                          required={true}
                                          defaultValue="Female"
                                          requiredMessage={
                                            errorMessages.SELECTGENDER
                                          }
                                        />
                                      </Col>
                                      <Col span={24} md={{ span: 12 }}>
                                        <PhoneNumberInput
                                          type="tel"
                                          label="Phone Number"
                                          name={`contactNumber + ${index}`}
                                          onChange={(e) =>
                                            handlePhoneNumber(
                                              e,
                                              member.memberId
                                            )
                                          }
                                          initialValue={member.phoneNumber}
                                          required={false}
                                          requiredMessage={
                                            errorMessages?.CONTACTNUMBER
                                          }
                                          width="100%"
                                        />
                                      </Col>
                                      <Col span={24} md={{ span: 12 }}>
                                        <PlaceHolderWrappper>
                                          {!member.documents?.[0]?.file
                                            ?.length > 0 ? (
                                            <>
                                              <Form.Item
                                                label=""
                                                name={`memberImages${index}`}
                                                rules={[
                                                  {
                                                    required:
                                                      isAllowed?.[
                                                        member.memberId
                                                      ],
                                                    message:
                                                      "Please insert the file !",
                                                  },
                                                ]}
                                              >
                                                <ImagePlaceholder>
                                                  <CloudUploadOutlined />
                                                  Upload File
                                                </ImagePlaceholder>
                                                <input
                                                  name="documents"
                                                  type={"file"}
                                                  className={"uploadImage"}
                                                  accept=".png, .jpg, .jpeg,.pdf "
                                                  onChange={(e) =>
                                                    handleImagesUpload(
                                                      e,
                                                      member.memberId
                                                    )
                                                  }
                                                />
                                              </Form.Item>
                                            </>
                                          ) : (
                                            <>
                                              {member.documents?.[0]?.type !==
                                              "application/pdf" ? (
                                                <Image
                                                  width={200}
                                                  src={
                                                    member.documents?.[0]?.file
                                                  }
                                                />
                                              ) : (
                                                <DocItem>
                                                  <FilePdfOutlined
                                                    onClick={() => {
                                                      <a
                                                        href={
                                                          member.documents?.[0]
                                                            ?.file
                                                        }
                                                        target="blank"
                                                      />;
                                                    }}
                                                  />
                                                </DocItem>
                                              )}

                                              <CloseOutlined
                                                onClick={() =>
                                                  handleRemvoveMemberImage(
                                                    member.memberId
                                                  )
                                                }
                                              />
                                            </>
                                          )}
                                        </PlaceHolderWrappper>

                                        <Note>
                                          <span>
                                            (Please upload one of the following
                                            files: Driver's License, Utility
                                            Bill, Birth Certificate.)
                                          </span>
                                        </Note>
                                      </Col>

                                      <Col span={24}>
                                        <hr />
                                      </Col>
                                    </Row>
                                  )
                                );
                              })}
                          </NewMemberDetails>
                          <Col xl={{ span: 24 }} sm={{ span: 24 }} span={24}>
                            <AddButtonContainer
                              style={{ marginBottom: "20px" }}
                            >
                              <AddNewMember onClick={() => addMember()}>
                                <PlusCircleOutlined title="New Member" />
                                Add New Member
                              </AddNewMember>
                            </AddButtonContainer>
                            &nbsp;
                          </Col>
                        </>
                      )}
                    </FormFields>
                    <BottomForm>
                      <p style={{ margin: "0" }}>
                        Already have an account? &nbsp;
                        <Link to="/client-login" text="LogIn" />
                      </p>
                      <Button
                        htmlType="button"
                        disabled={seekerRegistrationModal}
                        onClick={() => {
                          if (!Object?.keys(address)?.length) {
                            setAddressError("Please enter the address !");
                          }
                          form.submit();
                        }}
                        text={"Register"}
                        loading={loading}
                        // onClick={showModal}
                      />
                    </BottomForm>
                  </Form>
                </RightContainer>
              </Col>
            </Row>
          </OuterWrapper>
        </InnerContent>
      </LayoutContainer>
      <Modal
        title="Approval Requested"
        open={seekerRegistrationModal}
        setOpenModal={setSeekerRegistrationModal}
        modalType={ModalList.SeekerRegisterModal}
        isClosable={false}
      />
    </>
  );
}

export default RegisterSeeker;
