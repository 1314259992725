import { Image } from "antd";
import React from "react";
import styled from "styled-components";
import colors from "../../../styles/Colors";
import logo from "./../../../assets/images/logo.png";

export const Verifywrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  //   padding: 30px;
  width: 100%;
  background: ${colors.backgroundPrimary};
`;
export const Verifycontainer = styled.div`
  //   height: 100vh;
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding: 60px;
  align-items: flex-start;
  height: 80vh;
  background: #004d8ced;
  border-radius: 25px;
  @media screen and (max-width: 991px) {
    padding: 30px;
  }
`;
export const Verifysection = styled.div`
  display: flex;
  gap: 50px;
  flex-direction: column;
  @media screen and (max-width: 991px) {
    gap: 60px;
  }

  h1 {
    font-size: 35px;
    color: ${colors.white};
    margin: 0;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 4vw;
    }
    @media screen and (max-width: 767px) {
      font-size: 25px;
    }
    @media screen and (max-width: 575px) {
      font-size: 18px;
    }
  }
  img {
    height: 150px;
    width: 200px;
    @media screen and (max-width: 991px) {
      height: 100px;
      width: 150px;
    }
  }
`;

const VerifyEmail = () => {
  return (
    <>
      <Verifywrapper>
        <Verifycontainer>
          <Verifysection>
            <Image src={logo} preview={false} />
            <h1>
              Email has already been confirmed! You'll receive an email shortly
              to complete the process.
            </h1>
          </Verifysection>
        </Verifycontainer>
      </Verifywrapper>
    </>
  );
};

export default VerifyEmail;
