import { Row, Col, Image, Form } from "antd";
import LogoImg from "../../../assets/images/logo.png";
import Input from "../../elements/input/Input";
import Button from "../../elements/button/Button";
import { regExpPattern } from "../../../utils/regExpPattern";

import {
  BottomForm,
  LayoutContainer,
  InnerContent,
  OuterWrapper,
  Heading,
  RightContainer,
} from "./ForgotPasswordStyles";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useMutate from "../../../api/hooks/useMutate";
import { regErrorMessages } from "../../../utils/regErrorMessages";
import { errorMessages } from "../../../utils/errorMessages";
import COMMON_URL from "../../../api/urls/COMMON_URL";
import { LeftContainer } from "../admin/logIn/LoginStyles";
import { Logo } from "../admin/signUp/SingupStlyes";
import CustomImage from "../../image/LeftContainer";

function ForgotPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [postForgotPassword, { loading }] = useMutate({
    path: COMMON_URL.FORGOTPASSWORD,
    method: "put",
    onCompleted: () => {},
    onError: () => {},
  });

  const onSubmit = (values) => {
    postForgotPassword({
      variables: {
        email: values.email,
      },
      onCompleted: () => {
        toast.success("Please check your email.");
      },
      onError: () => {},
    });
  };

  return (
    <>
      <LayoutContainer>
        <InnerContent style={{ maxWidth: "820px", minHeight: "360px" }}>
          <OuterWrapper>
            <Row>
              <Col lg={{ span: 8 }} span={24}>
                {/* <LeftContainer>
                  <Logo>
                    <Image width={200} preview={false} src={LogoImg}></Image>
                  </Logo>
                </LeftContainer> */}
                <CustomImage />
              </Col>
              <Col lg={{ span: 16 }} span={24}>
                <RightContainer>
                  <Form
                    name="basic"
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    onFinish={onSubmit}
                  >
                    <Heading>Forgot Password</Heading>
                    <Row>
                      <Col span={24}>
                        <Input
                          type="text"
                          pattern={regExpPattern.EMAIL}
                          regExpMessage={regErrorMessages.EMAIL}
                          label="Enter Your Email"
                          name="email"
                          required={true}
                          requiredMessage={errorMessages.EMAIL}
                        />
                      </Col>

                      <Col span={24}>
                        <BottomForm>
                          <div>
                            <div>
                              Back to &nbsp;
                              <a onClick={() => navigate(-1)}>Login</a>
                            </div>
                          </div>
                          <Button
                            text={"Submit"}
                            htmlType={"sumbit"}
                            loading={loading}
                          />
                        </BottomForm>
                      </Col>
                    </Row>
                  </Form>
                </RightContainer>
              </Col>
            </Row>
          </OuterWrapper>
        </InnerContent>
      </LayoutContainer>
      <Toaster
        toastOptions={{
          duration: 3000,
          style: {
            background: "#363636",
            color: "#fff",
            fontSize: "14px",
          },
        }}
        position="top-right"
      />
    </>
  );
}

export default ForgotPassword;
