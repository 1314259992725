import { ConfigProvider } from "antd";
import { createGlobalStyle } from "styled-components";
import colors from "./Colors";

const Style = createGlobalStyle`


  *{
    box-sizing: border-box;
  }

  body.hideScroll{
    overflow: hidden;
  }
.errormsg {
  margin: 0;
  color: #ff4d4f;
  position: relative;
  font-size: 12px;
  line-height: 17px;
  z-index: 1;
  bottom: 0;
}

  .ant-image-img{
    max-width: 100%;
    height: 100%;
  }
  .ant-tabs{
    @media (max-width: 575px){
      padding-top: 15px;
    }
  }
  .ant-tabs-tabpane {
    min-height: 60vh;
    position: relative;
  }

  .ant-input-affix-wrapper{
    border-radius: 4px;
  }
  .ant-form label {;
    font-weight: 500;
  }
  .ant-checkbox+span{
    // white-space: nowrap;
  }
  .ant-form-item {
    margin: 0 0 8px 10px;
  }

  .ant-tabs-tab{
    padding: 3px 18px; 
  }

  .ant-tabs-top>.ant-tabs-nav:before{
    border-bottom: none;
  }

  .ant-tabs-tab+.ant-tabs-tab{
    margin: 0 0 0 10px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #004d8c;
  }
  
  .ant-tabs-ink-bar {
    display: none;
  }
  
  .ant-tabs-tab:hover {
    color: ${colors.primary};
  }
  
  .ant-tabs-nav{
    position:relative;
  .badge{
    position: absolute;
    background: ${colors.red};
    color:${colors.white};
    border-radius: 50%;
    top: 2px;
    right: -5px;
    min-width: 20px;
    min-height: 20px;
    font-size:12px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${colors.primary};
    font-weight: 600;
    background: #004d8c12;
    border-radius: 7px;
  }
  
 .ant-table-body{
  overflow: auto !important;
 }

  [class*='ant-table-body'] {
    ::-webkit-scrollbar {
        height: 12px !important;
        width: 12px !important;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(#333, .2);
        border: 3px solid transparent;
        border-radius: 4px;
    }
}

  .ant-table-cell-scrollbar:not([rowspan]){
    box-shadow: none;
  }

  

  thead.ant-table-thead tr th {
    background-color: ${colors.primary};
    color: ${colors.white};
    padding: 10px 20px;
    white-space: nowrap;
    &:hover {
      background-color: ${colors.primary} !important;
    }
    .ant-table-column-title {
      flex: initial;
    }
    ant-table-column-has-sorters{
      &:focus-visible{
        color: #fff;
      }
    }
  }

  .ant-table-column-sorters{
  gap:10px;
  justify-content:start !important; 
  } 
  
  
  tbody.ant-table-tbody tr {
    &:nth-child(even) {
      td {
        background-color: ${colors.backgroundPrimary};
        color: ${colors.black};
      }
    }
    .ant-checkbox-wrapper{
      width: max-content;
    }
  }
  &.ant-table-wrapper {
    overflow: auto;
  }

  .ant-table-thead th.ant-table-column-sort{
    background-color: ${colors.primary};

  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width:10px;
  }    
  

  .ant-form-item-label{
    padding:0 !important;
  }

  .anticon{
    cursor: pointer;
  }
  
  @media (max-width: 575px){
    .ant-form .ant-form-item{
      width: calc(100% - 10px);
      button{
        width: 100%;
      }
    }
}  

// @media screen and (-webkit-min-device-pixel-ratio:0) { 
//   select,
//   textarea,
//   
// }

// @supports (-webkit-overflow-scrolling: touch) {
//   select,
//   textarea,
//    input:active {
//     font-size: 16px;
//   }
// }
.wisheserror{
  color:${colors.red2}
  position:absolute;
  top:1px;
  right:10px;
  
}

.ant-input,.ant-select-selector {
  @media (max-width: 767px){
    font-size: 16px;
    padding: 2.5px 11px;
      }
  }
`;

const GlobalStyles = () => {
  ConfigProvider.config({
    theme: {
      primaryColor: "#004d8c",
    },
  });

  return <Style />;
};

export default GlobalStyles;
