// import { CloudUploadOutlined } from "@ant-design/icons";
import { Col, Form, Row, Input as AntInput } from "antd";
// import moment from "moment";
import React from "react";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/Colors";
import { errorMessages } from "../../utils/errorMessages";
import { regErrorMessages } from "../../utils/regErrorMessages";
import { regExpPattern } from "../../utils/regExpPattern";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import Button from "../elements/button/Button";
import useMutate from "../../api/hooks/useMutate";
import EMPLOYEE_URLS from "../../api/urls/EMPLOYEE_URL";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import Input from "../elements/input/Input";
import { Container, StyledForm } from "./EditCampaignModal";
import TextArea from "antd/lib/input/TextArea";
import { ButtonContainer } from "./NewSeekerModal";

const Wrapper = styled.div`
  padding: 10px;
`;

export const HouseDetailCheckModal = ({
  header,
  info,
  close,
  data,
  setStatusData,
  loader,
}) => {
  const [form] = Form.useForm();
  const onSubmit = (values) => {
    setStatusData({
      FamId: values?.reason,
      isApproved: true,
    });
  };
  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
        style={{ width: "100%" }}
      >
        <Row>
          <Col span={24}>
            <Input
              label="FAM ID"
              name="reason"
              required={true}
              requiredMessage={errorMessages.FAMID}
              pattern={regExpPattern.SUBDOMAIN}
              regExpMessage={regErrorMessages.SUBDOMAIN}
              initialValue={data?.reason}
            />
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button text="Submit" htmlType="submit" loading={loader} />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

export default HouseDetailCheckModal;
