import { Col, Row, Form } from "antd";
import React from "react";
import Button from "../elements/button/Button";
import { StyledForm } from "../pages/admin/signUp/SingupStlyes";
import { Container } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";
import TextAreaB from "../elements/textArea/TextArea";
import { errorMessages } from "../../utils/errorMessages";
import CAMPAIGN_URL from "../../api/urls/CAMPAIGN_URL";
import useMutate from "../../api/hooks/useMutate";

export const EditWishesmodal = ({ close, setIsSuccess, clientDetails }) => {
  const [form] = Form.useForm();

  const [updateStatus, { loading: statusLoading }] = useMutate({
    path: CAMPAIGN_URL.UPDATE_SEEKER_WISHES,
    method: "post",
    errorToast: true,
    successToast: true,
  });
  let wishes = clientDetails?.wishes;
  const handleWishes = (e, index) => {
    wishes = clientDetails?.wishes?.map((wishInfo, wishIndex) => {
      // debugger;
      if (index === wishIndex) {
        wishInfo.description = e.target.value;
      }
      return wishInfo;
    });
    console.log(wishes);
  };
  const onSubmit = (values) => {
    // debugger;
    const variables = {
      UserId: 0,
      CampaignId: clientDetails?.campaignId,
      MemberId: clientDetails?.memberId,
      Wishes: JSON.stringify(wishes),
    };
    updateStatus({
      variables,
      onCompleted: () => {
        close();
        setIsSuccess(true);
      },
      onError: () => {},
    });
  };
  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        form={form}
        onFinish={onSubmit}
      >
        <Row>
          {clientDetails?.wishes?.map((wishInfo, wishIndex) => {
            return (
              <Col span={24}>
                <TextAreaB
                  label={`Wish ${wishIndex + 1}`}
                  name={`${wishIndex + 1}`}
                  initialValue={wishInfo?.description}
                  required={true}
                  requiredMessage={errorMessages.WISH}
                  showCount={true}
                  maxLength={100}
                  onChange={(e) => handleWishes(e, wishIndex)}
                  placeholder="Enter Wish"
                />
              </Col>
            );
          })}
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            type="button"
            onClick={() => close()}
          />
          <Button
            text="Update"
            onClick={() => form.submit()}
            loading={statusLoading}
          />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

export default EditWishesmodal;
