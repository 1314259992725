// import { CloudUploadOutlined } from "@ant-design/icons";
import { Col, Form, Row, Input as AntInput } from "antd";
// import moment from "moment";
import React, { useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { errorMessages } from "../../utils/errorMessages";
import { regErrorMessages } from "../../utils/regErrorMessages";
import { regExpPattern } from "../../utils/regExpPattern";
import AddressSearchDropdown from "../elements/address/GoogleAddressInput";
import Button from "../elements/button/Button";
import useMutate from "../../api/hooks/useMutate";
import EMPLOYEE_URLS from "../../api/urls/EMPLOYEE_URL";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import Input from "../elements/input/Input";
import { Container, StyledForm } from "./EditCampaignModal";
import { ButtonContainer } from "./NewSeekerModal";
import { Requiredwrapper } from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import toast from "react-hot-toast";

export const AddNewSeekerModal = ({
  header,
  info,
  close,
  data,
  setIsSuccess,
  isEditModal,
}) => {
  // const navigate = useNavigate();
  const [form] = Form.useForm();
  const [address, setAddress] = useState(
    data
      ? {
          placeName: data?.placeName,
          address1: data?.address1,
          address2: data?.address2,
          lat: data?.latitude,
          lng: data?.longitude,
          placeId: data?.placeId,
          state: data?.state,
          zip: data?.zip,
          city: data?.city,
          country: data?.country,
        }
      : ""
  );
  const isOrgLoaded = useRef(false);
  const [addressError, setAddressError] = useState("");
  const [postDonorSignUp, { loading }] = useMutate({
    path: EMPLOYEE_URLS.CREATEEMPLOYEE,
    successToast: true,
    errorToast: true,
    method: "post",
  });

  const onSubmit = (values) => {
    const variables = {
      organisationId: 1,
      firstname: values.firstName,
      employeeId: data?.userId || null,
      lastname: values.lastName,
      emailId: values.email,
      password: values?.password,
      contactNumber: values.MemberContactNumber.replace(/\s+/g, ""),
      ...address,
      longitude: address?.lng,
      latitude: address?.lat,
    };
    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }
    postDonorSignUp({
      variables,
      errorToast: true,
      onCompleted: () => {
        setIsSuccess(true);
        close();
      },
      onError: () => {},
    });
  };
  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        onFinish={onSubmit}
        form={form}
      >
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="First Name"
              name="firstName"
              required={true}
              initialValue={data?.firstName || ""}
              requiredMessage={errorMessages.FIRSTNAME}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Last Name"
              name="lastName"
              required={true}
              initialValue={data?.lastName || ""}
              requiredMessage={errorMessages.LASTNAME}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Email Address"
              name="email"
              pattern={regExpPattern.EMAIL}
              required={true}
              initialValue={data?.email || ""}
              regExpMessage={regErrorMessages.EMAIL}
              requiredMessage={errorMessages.EMAIL}
            />
          </Col>

          <Col span={24} lg={{ span: 12 }}>
            <PhoneNumberInput
              type="tel"
              label="Phone Number"
              name="MemberContactNumber"
              required={true}
              initialValue={data?.phoneNo || ""}
              requiredMessage={errorMessages?.CONTACTNUMBER}
              width="100%"
            />
          </Col>
          {!isEditModal && (
            <>
              <Col span={24} lg={{ span: 12 }}>
                <Input
                  type="password"
                  label="Password"
                  name="password"
                  required={true}
                  pattern={regExpPattern.PASSWORD}
                  regExpMessage={regErrorMessages.PASSWORD}
                  requiredMessage={errorMessages.PASSWORD}
                />
              </Col>
              <Col span={24} sm={{ span: 12 }}>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Password does not match !")
                        );
                      },
                    }),
                  ]}
                >
                  <AntInput.Password />
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={24} lg={{ span: 24 }}>
            <Requiredwrapper>
              <span className="validdata">*</span>
            </Requiredwrapper>
            <AddressSearchDropdown
              onChange={(address) => {
                if (Object?.keys(address)?.length) {
                  if (address?.city?.length) {
                    setAddressError("");
                  } else {
                    setAddressError("Please select address with city");
                  }
                } else {
                  if (isOrgLoaded.current) {
                    setAddressError("Please select address");
                  }
                }
                setAddress(address);
                isOrgLoaded.current = true;
              }}
              value={address}
              requiredMessage={errorMessages.ADDRESS}
              label="Street Address"
              addressError={addressError}
            />
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button
            text={data ? "Update" : "Save"}
            htmlType="submit"
            loading={loading}
          />
        </ButtonContainer>
      </StyledForm>

      {info && (
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>{info}</p>
      )}
    </Container>
  );
};

export default AddNewSeekerModal;
