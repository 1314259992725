import { Tabs } from "antd";
import { useState } from "react";
import Button from "../../../elements/button/Button";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import React from "react";
import { Row, Col, Form, Input as AntInput } from "antd";
import { errorMessages } from "../../../../utils/errorMessages";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import { StyledForm } from "../../admin/signUp/SingupStlyes";
import { PhoneNumberInput } from "../../../elements/input/phoneNumberInput";
import {
  BottomForm,
  FormFields,
  Profiletabs,
} from "../../seeker/profile/ProfileStyle";
import Input from "../../../elements/input/Input";
import { MainContent } from "../../donor/familyList/FamilyListStyle";
import { Profilelayout } from "../profile/profileStyle";
import {
  Info,
  Label,
  LabelCard,
  LabelCardHeader,
  LabelCardSamples,
} from "./SettingsStyle";
import { Lableinfo } from "./SettingsStyle";

function EmployeeSetting() {
  const [openNewLabelsModal, setOpenNewLabelsModal] = useState(false);
  return (
    <>
      <MainContent>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Profile" key="1">
            <Profilelayout>
              <Row gutter={[0, 12]}>
                <Col span={24} xl={{ span: 24 }}>
                  <Profiletabs style={{ width: "100%" }}>
                    <StyledForm
                      name="basic"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      autoComplete="off"
                    >
                      <FormFields>
                        <Row gutter={[0, 12]}>
                          <Col span={24} md={{ span: 12 }}>
                            <Input
                              name="firstname"
                              required={true}
                              label="First Name"
                              requiredMessage={errorMessages.FIRSTNAME}
                              initialValue="john"
                            />
                          </Col>
                          <Col span={24} md={{ span: 12 }}>
                            <Input
                              name="lastname"
                              required={true}
                              label="Last Name"
                              requiredMessage={errorMessages.LASTNAME}
                              initialValue="doe"
                            />
                          </Col>
                          <Col span={24} md={{ span: 12 }}>
                            <Input
                              name="email"
                              pattern={regExpPattern.EMAIL}
                              required={true}
                              regExpMessage={regErrorMessages.EMAIL}
                              label="Email"
                              requiredMessage={errorMessages.EMAIL}
                              initialValue="demo@gmail.com "
                            />
                          </Col>
                          <Col span={24} md={{ span: 12 }}>
                            <PhoneNumberInput
                              type="tel"
                              label="Phone Number"
                              name="contactNumber"
                              required={true}
                              requiredMessage={errorMessages.CONTACTNUMBER}
                              width="100%"
                            />
                          </Col>
                          <Col span={24}>
                            <Input
                              name="address"
                              // pattern={regExpPattern.EMAIL}
                              required={true}
                              // regExpMessage={regErrorMessages.EMAIL}
                              label="Street Address"
                              requiredMessage={errorMessages.ADDRESS}
                              initialValue="4774 Bruce Street "
                            />
                          </Col>
                        </Row>
                      </FormFields>
                      <BottomForm>
                        <Button text={"Save"} htmlType="submit" />
                      </BottomForm>
                    </StyledForm>
                  </Profiletabs>
                </Col>
              </Row>
            </Profilelayout>
          </Tabs.TabPane>
          <Tabs.TabPane tab="  Labels Format" key="2">
            <LabelCardSamples>
              <LabelCard>
                <LabelCardHeader>Fam Organisation</LabelCardHeader>
                <Lableinfo>
                  <Info>
                    <Label> FAM Id: </Label> {"{ Client's FAM Id }"}
                  </Info>
                  <Info>
                    <Label>Name: </Label> {"{ Client's Name }"}
                  </Info>
                  <Info>
                    <Label>Phone Number :</Label> {"{ Client's Phone Number  }"}
                  </Info>
                  <Info>
                    <Label>Client pickup address:</Label>
                    {"{ FAM WareHouse Address }"}
                  </Info>
                  <Info>
                    <Label>Client pickup dates:</Label>
                    {"{ Pick-up Date for Client  }"}
                  </Info>
                  <Info>
                    <Label>No. Of Items:</Label> {"{ No. Of Items }"}
                  </Info>
                </Lableinfo>
              </LabelCard>
            </LabelCardSamples>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Change Password" key="3">
            <Profilelayout>
              <Profiletabs style={{ width: "100%", padding: "20px 10px 0" }}>
                <StyledForm
                  name="basic"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  autoComplete="off"
                  // form={form}
                >
                  <FormFields>
                    <Row>
                      <Col span={24} md={{ span: 8 }}>
                        <Input
                          label="Old Password"
                          name="OldPassword"
                          required={true}
                          requiredMessage={errorMessages.OLDPASSWORD}
                        />
                      </Col>
                      <Col span={24} md={{ span: 8 }}>
                        <Input
                          type="password"
                          label="New Password"
                          name="NewPassword"
                          required={true}
                          requiredMessage={errorMessages.NEWPASSWORD}
                          pattern={regExpPattern.PASSWORD}
                          regExpMessage={regErrorMessages.PASSWORD}
                        />
                      </Col>
                      <Col span={24} md={{ span: 8 }}>
                        <Form.Item
                          name="confirm"
                          label="Confirm Password"
                          dependencies={["NewPassword"]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("NewPassword") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Password does not match !")
                                );
                              },
                            }),
                          ]}
                        >
                          <AntInput.Password />
                        </Form.Item>
                      </Col>
                    </Row>
                  </FormFields>
                  <BottomForm style={{ padding: "0 10px" }}>
                    <Button text={"Submit"} htmlType="submit" />
                  </BottomForm>
                </StyledForm>
              </Profiletabs>
            </Profilelayout>
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
      <Modal
        title="Add New Label"
        open={openNewLabelsModal}
        setOpenModal={setOpenNewLabelsModal}
        modalType={ModalList.NewLabelsModal}
      />
    </>
  );
}

export default EmployeeSetting;
