import { Row, Col, Image, Form, Checkbox, Input as AntInput } from "antd";
import LogoImg from "../../../../assets/images/logo.png";
import Input from "../../../elements/input/Input";
import Button from "../../../elements/button/Button";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import useMutate from "../../../../api/hooks/useMutate";
import { errorMessages } from "../../../../utils/errorMessages";
import {
  LayoutContainer,
  OuterWrapper,
  LeftContainer,
  RightContainer,
  Heading,
  InnerContent,
  StyledForm,
  BottomForm,
  Logo,
} from "../../admin/signUp/SingupStlyes";
import { PhoneNumberInput } from "../../../elements/input/phoneNumberInput";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import AddressSearchDropdown from "../../../elements/address/GoogleAddressInput";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import styled from "styled-components";
import { Requiredwrapper } from "../../seeker/RegisterSeeker/RegisterSeeker";
import Link from "../../../elements/links/Links";
import CustomImage from "../../../image/LeftContainer";
import toast from "react-hot-toast";
import { ROLES_INITIAL_ROUTES } from "../../../../hooks/useSetAppConfigs";
// const FormItem = styled(Form.Item)`
//   .ant-form-item-label {
//     padding: 0;
//   }
// `;
const FormFields = styled.div`
  position: relative;
  padding: 0 20px 40px;
  overflow: auto;
  @media screen and (max-width: 1199px) {
    max-height: 450px;
    overflow: auto;
  }

  @media screen and (max-width: 575px) {
    padding: 0 20px 65px;
  }
  @media screen and (max-width: 375px) {
    max-height: 350px;
  }
`;

const GenderOptions = [
  {
    key: "1",
    value: "Male",
  },
  {
    value: "Female",
    key: 2,
  },
  {
    value: "Other",
    key: 3,
  },
];

function DonorRegister() {
  const navigate = useNavigate();
  const [postDonorSignUp, { response, error, loading }] = useMutate({
    path: DONOR_URLS.SIGNUP,
    errorToast: true,
  });
  const [form] = Form.useForm();
  const [address, setAddress] = useState({});
  const isOrgLoaded = useRef(false);
  const [addressError, setAddressError] = useState("");
  const [checked, setChecked] = useState();

  const onCheckboxChange = (e) => {
    if (e.target.checked) {
      setChecked(e.target.checked);
    } else {
      setChecked(false);
    }
  };

  const onSubmit = (values) => {
    const variables = {
      userId: null,
      firstname: values.firstName,
      // gender: genderFormat(values.Membergender),
      lastname: values.lastName,
      email: values.email,
      password: values.password,
      phoneno: values.contactNumber,
      ...address,
      longitude: address?.lng,
      latitude: address?.lat,
      termAndCondition: true,
    };

    if (!Object?.keys(address)?.length) {
      return setAddressError("Please enter the address !");
    }
    if (!address?.city?.length) {
      return setAddressError("Please select address with city");
    }

    postDonorSignUp({
      variables,
      onCompleted: (data) => {
        // toast.success("Registered Successfully");
        // navigate("/donor-login");
        localStorage.setItem("accessToken", data.token);
        navigate(ROLES_INITIAL_ROUTES[data.roleName]);
      },
      onError: () => {},
    });
  };
  return (
    <>
      <LayoutContainer>
        <InnerContent style={{ maxWidth: "900px", minHeight: "350px" }}>
          <OuterWrapper>
            <Row>
              <Col xl={{ span: 7 }} span={24}>
                {/* <LeftContainer>
                  <Logo>
                    <Image preview={false} src={LogoImg} />
                  </Logo>
                </LeftContainer> */}
                <CustomImage />
              </Col>
              <Col xl={{ span: 17 }} span={24}>
                <RightContainer>
                  <Heading>Donor Registration</Heading>
                  <StyledForm
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    form={form}
                    onFinish={onSubmit}
                  >
                    <FormFields>
                      <Row gutter={[0, 8]}>
                        <Col span={24} md={{ span: 12 }}>
                          <Input
                            label="First Name"
                            name="firstName"
                            required={true}
                            requiredMessage={errorMessages.FIRSTNAME}
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <Input
                            label="Last Name"
                            name="lastName"
                            required={true}
                            requiredMessage={errorMessages.LASTNAME}
                          />
                        </Col>
                        {/* <Col span={24} md={{ span: 12 }}>
                            <Select
                              placeholder="Select Gender"
                              name="Membergender"
                              FormFields
                              label={"Gender"}
                              option={GenderOptions}
                              required={true}
                              requiredMessage={errorMessages.SELECTGENDER}
                            />
                          </Col> */}
                        <Col span={24}>
                          <Requiredwrapper>
                            <span className="validdata">*</span>
                          </Requiredwrapper>
                          <AddressSearchDropdown
                            onChange={(address) => {
                              if (Object?.keys(address)?.length) {
                                if (address?.city?.length) {
                                  setAddressError("");
                                } else {
                                  setAddressError(
                                    "Please select address with city"
                                  );
                                }
                              } else {
                                if (isOrgLoaded.current) {
                                  setAddressError("Please select address");
                                }
                              }
                              setAddress(address);
                              isOrgLoaded.current = true;
                            }}
                            value={address}
                            requiredMessage={errorMessages.ADDRESS}
                            label="Street Address"
                            addressError={addressError}
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <PhoneNumberInput
                            type="tel"
                            label="Phone Number"
                            name="contactNumber"
                            required={true}
                            requiredMessage={errorMessages.CONTACTNUMBER}
                            width="100%"
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <Input
                            label="Email"
                            name="email"
                            required={true}
                            type="email"
                            requiredMessage={errorMessages.EMAIL}
                            pattern={regExpPattern.EMAIL}
                            regExpMessage={regErrorMessages.EMAIL}
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <Input
                            type="password"
                            label="Password"
                            name="password"
                            required={true}
                            pattern={regExpPattern.PASSWORD}
                            regExpMessage={regErrorMessages.PASSWORD}
                            requiredMessage={errorMessages.PASSWORD}
                          />
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <Form.Item
                            name="confirm"
                            label="Confirm Password"
                            dependencies={["password"]}
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("Password does not match !")
                                  );
                                },
                              }),
                            ]}
                          >
                            <AntInput.Password />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            style={{ margin: "0px" }}
                            name="checkBox"
                            rules={[
                              {
                                required: !checked,
                                message: "Please accept privacy policy!",
                              },
                            ]}
                          >
                            <Checkbox
                              style={{ padding: "0 10px" }}
                              checked={checked}
                              onChange={onCheckboxChange}
                            >
                              Yes, I understand and agree with the &nbsp;
                              {/* <Link
                                  to="/terms-and-conditions"
                                  text="Terms & Conditions"
                                />
                                &nbsp; and &nbsp; */}
                              <Link
                                to="//www.family-assistance.org/privacy-policy"
                                text="Privacy Policy"
                                target="_blank"
                              />
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </FormFields>
                    <BottomForm>
                      <p style={{ margin: "0" }}>
                        Already have an account?&nbsp;
                        <Link text="login" to="/donor-login" />
                      </p>
                      <Button
                        text={"Register"}
                        loading={loading}
                        onClick={() => {
                          if (!Object?.keys(address)?.length) {
                            setAddressError("Please enter the address !");
                          }
                          form.submit();
                        }}
                        htmlType="button"
                      />
                    </BottomForm>
                  </StyledForm>
                </RightContainer>
              </Col>
            </Row>
          </OuterWrapper>
        </InnerContent>
      </LayoutContainer>
    </>
  );
}

export default DonorRegister;
