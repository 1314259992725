import styled from "styled-components";
import { Card } from "antd";
import Colors from "../../../styles/Colors";

export const MainCard = styled(Card)`
  border-color: ${Colors.background};
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  height: 95%;
  margin-bottom: 20px;
  .ant-card-body {
    border-color: ${Colors.primary};
    border-radius: 8px;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    padding: 0 0 48px;
  }
  &.emptyCard {
    height: 100px;
  }
`;

export const CardImage = styled.div`
  .ant-image {
    width: 100%;
    height: 180px;
    img {
      max-width: 100%;
      padding: 0px;
      border-radius: 8px 8px 0px 0px;
      max-height: 100%;
      border-right: 1px solid ${Colors.background};
      height: 100%;
    }
  }
`;
export const CardHeading = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${Colors.primary};
  margin: 0;
`;

export const CardDescription = styled.div`
  font-size: 14px;
  display: -webkit-box;
  min-height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 575px) {
    display: block;
    height: auto;
  }
`;
export const CardContent = styled.div`
  padding: 15px;
`;

export const CampaignData = styled.div`
  margin-right: 20px;
  font-size: 13px;
  line-height: 22px;
  .bold {
    font-weight: 700;
  }
`;

export const CardActions = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  button {
    display: flex;
    justify-content: center;
    border-radius: 0 0 8px 8px;
    padding: 12px 0;
    width: 100%;
    &:hover {
      background-color: ${Colors.secondary};
      border-color: ${Colors.secondary};
      color: ${Colors.white};
    }
  }
`;

export const ActionIcon = styled.div`
  height: 100%;
  width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.background};
  padding: 10px 0;
  &:hover {
    background-color: ${Colors.primary};
    color: ${Colors.white};
  }
`;
