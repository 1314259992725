import React, { useEffect, useState } from "react";
import { StyledForm } from "../../admin/signUp/SingupStlyes";
import { Col, Form, Row, Input as AntInput, Image } from "antd";
import Input from "../../../elements/input/Input";
import TextAreaB from "../../../elements/textArea/TextArea";
import Button from "../../../elements/button/Button";
import { errorMessages } from "../../../../utils/errorMessages";
import { regExpPattern } from "../../../../utils/regExpPattern";
import { regErrorMessages } from "../../../../utils/regErrorMessages";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import { capitalFirstLetter, formatDate } from "../../../../utils/dateTime";
import { formatPhoneNumber } from "../../../../utils/phoneNumber";
import { ClientDocImage } from "../../seeker/profile/ProfileStyle";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import useMutate from "../../../../api/hooks/useMutate";
import DONOR_URLS from "../../../../api/urls/DONOR_URL";
import Loader from "../../../loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import ORG_URL from "../../../../api/urls/ORG_URL";
import {
  MainContent,
  SectionHeading,
} from "../../donor/familyList/FamilyListStyle";
import {
  BottomForm,
  FormFields,
  Profiletabs,
} from "../../donor/profile/ProfileStyle";
import { Info, Label } from "../../donor/familyDetails/FamilyDetailsStyle";
import EMPLOYEE_URLS from "../../../../api/urls/EMPLOYEE_URL";
import { ROLES_INITIAL_ROUTES, ROLES_LOGOUT_ROUTES } from "../../../../hooks/useSetAppConfigs";
import toast from "react-hot-toast";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function EmpProfile() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isSuccess, setIsSuccess] = useState(false);

  const query = useQuery();
  const admin = query.get("admin");
  const id = query.get("id");
  const [getEmployeeDetails, { response, loading }] = useMutate({
    path: EMPLOYEE_URLS.GETEMPLOYEELIST,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [changePassword, { loading: changePasswordLoading }] = useMutate({
    path: ORG_URL.CHANGEPASSWORD,
    method: "put",
    errorToast: true,
    onCompleted: () => {},
    onError: () => {},
  });
  const { user } = useSelector((state) => state.auth);
  const [editHouseHoldModal, setEditHouseHoldModal] = useState(false);
  const onSubmit = (values) => {
    const variables = {
      oldPassword: values?.OldPassword,
      newPassword: values?.NewPassword,
    };
    changePassword({
      variables,
      onCompleted: (data) => {
        toast.success("Updated Successfully");
        localStorage.removeItem("accessToken");
        navigate(ROLES_LOGOUT_ROUTES[user.roleName]);
      },
      onError: () => {},
    });
  };
  const openProfile = () => {
    setEditHouseHoldModal(true);
  };
  useEffect(() => {
    if (!user?.id) return;

    getEmployeeDetails({
      params: {
        userId: id || user?.id,
      },
      onCompleted: () => {},
      onError: () => {},
    });
    setIsSuccess(false);
  }, [isSuccess]);

  return (
    <>
      <MainContent>
        <Row gutter={20}>
          <Col
            style={{ display: "flex", justifyContent: "space-between" }}
            span={24}
          >
            <SectionHeading>Profile </SectionHeading>
            {admin && <Button text={"Back"} onClick={() => navigate(-1)} />}
          </Col>
        </Row>
        <Col span={24}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Profile" key="1">
              {!loading ? (
                <Profiletabs
                  style={{ width: "100%", padding: "20px 20px 8px" }}
                >
                  <Row gutter={20}>
                    <Col span={24} lg={{ span: 12 }}>
                      <Info>
                        <Label>Name: </Label>
                        {} {capitalFirstLetter(response?.data?.firstName)}{" "}
                        &nbsp;
                        {capitalFirstLetter(response?.data?.lastName)}
                      </Info>
                    </Col>

                    <Col span={24} lg={{ span: 12 }}>
                      <Info>
                        <Label>Street Address: </Label>{" "}
                        {response?.data?.placeName}
                      </Info>
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                      <Info>
                        <Label>City: </Label>
                        {response?.data?.city}
                      </Info>
                    </Col>

                    <Col span={24} lg={{ span: 12 }}>
                      <Info>
                        <Label>Email Address: </Label> {response?.data?.email}
                      </Info>
                    </Col>
                    <Col span={24} lg={{ span: 12 }}>
                      <Info>
                        <Label>Phone Number: </Label>{" "}
                        {formatPhoneNumber(response?.data?.phoneNo)}
                      </Info>
                    </Col>
                    <Col span={24} lg={{ span: 24 }}>
                      <BottomForm style={{ padding: "15px 0 0" }}>
                        <Button text={"Edit"} onClick={openProfile} />
                      </BottomForm>
                    </Col>
                  </Row>
                </Profiletabs>
              ) : (
                <Loader />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Change Password" key="2">
              {!loading ? (
                <Profiletabs style={{ width: "100%" }}>
                  <StyledForm
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    autoComplete="off"
                    form={form}
                    onFinish={onSubmit}
                  >
                    <FormFields>
                      <Row>
                        <Col span={24} md={{ span: 8 }}>
                          <Input
                            type="password"
                            label="Old Password"
                            name="OldPassword"
                            required={true}
                            requiredMessage={errorMessages.OLDPASSWORD}
                          />
                        </Col>
                        <Col span={24} md={{ span: 8 }}>
                          <Input
                            type="password"
                            label="New Password"
                            name="NewPassword"
                            required={true}
                            pattern={regExpPattern.PASSWORD}
                            regExpMessage={regErrorMessages.PASSWORD}
                            requiredMessage={errorMessages.PASSWORD}
                          />
                        </Col>
                        <Col span={24} md={{ span: 8 }}>
                          <Form.Item
                            name="confirm"
                            type="password"
                            label="Confirm Password"
                            dependencies={["NewPassword"]}
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("NewPassword") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error("Password does not match !")
                                  );
                                },
                              }),
                            ]}
                          >
                            <AntInput.Password />
                          </Form.Item>
                        </Col>
                      </Row>
                    </FormFields>
                    <BottomForm style={{ padding: "0 10px" }}>
                      <Button
                        text={"Submit"}
                        htmlType="submit"
                        loading={changePasswordLoading}
                      />
                    </BottomForm>
                  </StyledForm>
                </Profiletabs>
              ) : (
                <Loader />
              )}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </MainContent>
      <Modal
        title={"Edit Profile"}
        open={editHouseHoldModal}
        setOpenModal={setEditHouseHoldModal}
        modalType={ModalList.EditDonorProfile}
        setIsSuccess={setIsSuccess}
        data={response?.data}
        isEmployee={true}
        id={id || user?.id}
      />
    </>
  );
}

export default EmpProfile;
