import styled from "styled-components";
import { Form, Input as AntInput } from "antd";
import colors from "../../../styles/Colors";

export const InputContainer = styled.div`
  margin: ${(props) => props.margin || "0 auto"};
  padding: ${(props) => props.padding || "auto"};
  width: 100%;
  .ant-form-item .ant-form-item-control-input input {
    ${(props) => props.error && "border-color: red"}
  }
  .ant-form-item,
  .ant-select-in-form-item {
    // margin: 0 0 8px 10px !important;

    .ant-form-item-label {
      padding: 0 !important;
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
      border-radius: 4px;
      width: 100%;
      box-shadow: none;

      &:focus {
        box-shadow: none;
      }
      &:hover,
      &:focus {
        border-color: ${colors.primary};
        box-shadow: none;
      }
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: none !important;
      border-color: ${colors.primary};
    }

    .ant-form-item-control-input {
      input {
        border-radius: 4px;
        width: 100%;

        &:hover,
        &:focus {
          border-color: ${colors.primary};
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .ant-form-item-label > label::after {
    content: "";
    position: absolute;
    top: 0;
    margin: 0;
  }
  input {
    color: ${colors.black};
  }
`;

const Input = ({
  label = "",
  name,
  requiredMessage,
  required = false,
  type,
  margin,
  padding,
  width,
  className,
  pattern = "",
  regExpMessage,
  placeholder,
  formMargin,
  initialValue,
  value,
  onChange = () => {},
  restProps,
  onFocus = () => {},
  fieldKey,
  disabled,
  error,
}) => {
  const inputTags = (type) => {
    let inputType = "";
    switch (type) {
      case "password": {
        inputType = (
          <AntInput.Password
            width={width}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e)}
          />
        );
        break;
      }
      case type: {
        inputType = (
          <AntInput
            initialValue={initialValue}
            onFocus={(e) => onFocus(e)}
            width={width}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e)}
            disabled={disabled}
          />
        );
        break;
      }
      default: {
      }
    }

    return inputType;
  };
  return (
    <InputContainer
      margin={margin}
      padding={padding}
      className={className}
      placeholder={placeholder}
      type={type}
      error={error}
    >
      <Form.Item
        label={<>{label}</>}
        {...restProps}
        fieldKey={fieldKey}
        name={name}
        margin={formMargin}
        initialValue={initialValue}
        rules={[
          {
            required: required,
            message: required && requiredMessage,
          },

          pattern && {
            pattern: pattern,
            message: regExpMessage,
          },
        ]}
      >
        {inputTags(type)}
      </Form.Item>
    </InputContainer>
  );
};

export default Input;
