import styled from "styled-components";
import colors from "../../../../styles/Colors";

export const DashboardTile = styled.div`
  width: 100%;
  background: ${colors.white};
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  // height: 150px;
  padding: 20px;
`;

export const DashboardTiles = styled.div`
  padding: 10px 0px;
`;

export const Count = styled.div`
  font-size: 36px;
  font-weight: 700;
  color: ${colors.textGrey1};
`;

export const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.textGrey};
`;
