import { CloseOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Col, Row, Form, Image } from "antd";
import React, { useState } from "react";
import { errorMessages } from "../../utils/errorMessages";
import Button from "../elements/button/Button";
import DatePickerB from "../elements/datePicker/DatePicker";
import Input from "../elements/input/Input";
import Select from "../elements/select/Select";
import {
  ImagePlaceholder,
  Note,
  PlaceHolderWrappper,
  relationOptions,
} from "../pages/seeker/RegisterSeeker/RegisterSeeker";
import { StyledForm } from "../pages/admin/signUp/SingupStlyes";
import { Container } from "./EditCampaignModal";
import { PhoneNumberInput } from "../elements/input/phoneNumberInput";
import { genderAPIFormat, genderFormat } from "../../utils/genderFormat";
import { ButtonContainer } from "./NewSeekerModal";
import {
  formatDate,
  getAgeFromTs,
  getTs,
  initialDateTime,
} from "../../utils/dateTime";
import useMutate from "../../api/hooks/useMutate";
import SEEKER_URLS from "../../api/urls/SEEKER_URL";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export const AddNewFamilyMemberModal = ({
  header,
  info,
  close,
  setIsSuccess,
  setNewMemberDetails,
  isAddMember = false,
  data,
  id,
  isMemberEdit,
}) => {
  // const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const [editImage, seteditImage] = useState("");
  const [isAllowed, setIsAllowed] = useState(
    getAgeFromTs(data?.dob) <= 18 ? false : true
  );
  const [uploadMemberDoucment, setUploadMemberDoucment] = useState({
    preview: data?.documents?.[0]?.file,
    type: data?.documents?.[0]?.type,
    value: "",
  });
  const [addFamilyMember, { response, loading }] = useMutate({
    path: SEEKER_URLS.ADD_FAMILY_MEMBER,
    method: "post",
    errorToast: true,
  });
  const [form] = Form.useForm();

  const BirthDateFormate = (d) => {
    return !d || d.isAfter(new Date()) || d.isSameOrBefore("1960-01-01");
  };
  const GenderOptions = [
    {
      key: "1",
      value: "Male",
    },
    {
      value: "Female",
      key: 2,
    },
    {
      value: "Other",
      key: 3,
    },
  ];
  const fileUpload = (e) => {
    seteditImage(true);
    const files = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadMemberDoucment({
        value: files,
        preview: reader.result,
      });
    };
    reader.readAsDataURL(files);
  };
  const handleRemoveImage = () => {
    setUploadMemberDoucment({
      value: "",
      preview: "",
    });
  };
  const onSubmit = (values) => {
    if (isAddMember) {
      const variables = {
        userId: 0,
        phoneNumber: values.contactNumber?.replace(/\s+/g, ""),
        dob: getTs(values?.dateOFBirthOfDaughter),
        firstName: values.firstName,
        lastName: values.lastName,
        gender: genderFormat(values.gender),
        relation: values.selectRelation,
        wishes: [
          {
            wishId: "1",
            wish: "",
          },
          {
            wishId: "2",
            wish: "",
          },
          {
            wishId: "3",
            wish: "",
          },
        ],
        documents: [],
      };
      if (uploadMemberDoucment?.preview?.length) {
        variables.documents.push({
          file: uploadMemberDoucment?.preview,
          type: uploadMemberDoucment?.value?.type,
        });
      }
      setNewMemberDetails([variables]);

      close();
    } else {
      const variables = {
        seekerId: id || "",
        seekerMemberId: data?.seekerMemberId || "",
        phoneNumber: values.contactNumber?.replace(/\s+/g, ""),
        birthdate: getTs(values?.dateOFBirthOfDaughter),
        firstName: values.firstName,
        lastName: values.lastName,
        gender: genderFormat(values.gender),
        relation: values.selectRelation,
        documents: [
          {
            file: uploadMemberDoucment?.preview || "",
            type: editImage
              ? uploadMemberDoucment?.value?.type || ""
              : uploadMemberDoucment?.preview?.split(".").pop() || "",
          },
        ],
      };
      addFamilyMember({
        variables,
        onCompleted: () => {
          if (!isMemberEdit) {
            toast.success("Added Successfully");
          } else {
            toast.success("Updated Successfully");
          }

          setIsSuccess(true);
          close();
        },
        onError: () => {},
      });
    }
  };
  const documentRequirement = (value) => {
    const date = getTs(value);
    if (getAgeFromTs(date) <= 18) {
      return setIsAllowed(false);
    } else {
      return setIsAllowed(true);
    }
  };

  return (
    <Container>
      <StyledForm
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        form={form}
        onFinish={onSubmit}
      >
        <Row>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="First Name :"
              name="firstName"
              required={true}
              requiredMessage={errorMessages.FIRSTNAME}
              initialValue={data?.firstName}
            />
          </Col>
          <Col span={24} lg={{ span: 12 }}>
            <Input
              label="Last Name :"
              name="lastName"
              required={true}
              requiredMessage={errorMessages.LASTNAME}
              initialValue={data?.lastName}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <Select
              placeholder="Select Relation"
              name={"selectRelation"}
              label={"Relation :"}
              option={relationOptions}
              initialValue={data?.relation}
              required={true}
              requiredMessage={errorMessages.RELATION}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <DatePickerB
              name="dateOFBirthOfDaughter"
              label="Date of Birth :"
              // picker="dateOFBirthOfDaughter"
              disabledDate={BirthDateFormate}
              disabled={isMemberEdit}
              onChange={documentRequirement}
              required={true}
              initialValue={data?.dob ? initialDateTime(data?.dob) : ""}
              requiredMessage={errorMessages.DATEOFBIRTH}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <PhoneNumberInput
              type="tel"
              label="Phone Number :"
              name="contactNumber"
              required={false}
              initialValue={data?.phoneNumber}
              requiredMessage={errorMessages.CONTACTNUMBER}
              width="100%"
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <Select
              placeholder="Select Gender"
              name={"gender"}
              label={"Gender :"}
              option={GenderOptions}
              required={true}
              initialValue={genderAPIFormat(data?.gender)}
              requiredMessage={errorMessages.SELECTGENDER}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <PlaceHolderWrappper>
              {!uploadMemberDoucment.preview?.length > 0 ? (
                <>
                  <Form.Item
                    label=""
                    name="memberImages"
                    rules={[
                      {
                        required: isAllowed,
                        message: "Please insert the file !",
                      },
                    ]}
                  >
                    <ImagePlaceholder>
                      <CloudUploadOutlined />
                      Upload File
                    </ImagePlaceholder>
                    <input
                      name="documents"
                      type={"file"}
                      className={"uploadImage"}
                      accept=".png,.jpg,.jpeg, .pdf"
                      onChange={(e) => fileUpload(e)}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Image src={uploadMemberDoucment.preview} />
                  <CloseOutlined onClick={handleRemoveImage} />
                </>
              )}
            </PlaceHolderWrappper>

            <Note>
              <span>
                (Please upload one of the following files: Driver's License,
                Utility Bill, Birth Certificate.)
              </span>
            </Note>
          </Col>
        </Row>
        <ButtonContainer noBorder>
          <Button
            text="Cancel"
            className={"SecondaryBtn"}
            onClick={() => close()}
          />
          <Button
            text={isMemberEdit ? "Update" : "Save"}
            htmlType="submit"
            loading={loading}
          />
        </ButtonContainer>
      </StyledForm>

      {info && (
        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>{info}</p>
      )}
    </Container>
  );
};

export default AddNewFamilyMemberModal;
