import React from "react";
import styled from "styled-components";
import colors from "../../../styles/Colors";

export const SearchDropdownTypes = {
  MultiSelect: "Multi-Select",
  SingleSelect: "Single-Select",
};

const Container = styled.div`
  position: relative;
  height: ${(props) => (props.hasLabel ? "65px" : "32px")};
`;

const Inner = styled.div`
  position: relative;
  height: ${(props) => (props.open ? "187px" : "32px")};
  width: ${(props) => props.width};
  background-color: ${colors.white};
  border: 1px solid
    ${(props) => (props.addressError ? "#ff4d4f" : colors.primaryBorderColor)};
  box-sizing: border-box;
  border-radius: 3px;
  transition: all 0.2s;
  z-index: ${(props) => (props.open ? 9999 : 0)};
  box-shadow: ${(props) =>
    props.open ? "0px 4px 16px rgba(0, 0, 0, 0.05)" : ""};
  overflow: hidden;
  &:hover,
  &:focus,
  &:active {
    border-color: ${colors.primary};
    outline: 0px;
  }
`;

const TopRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 0 5px;
  z-index: 999;
  background-color: ${colors.white};
`;

const Input = styled.input`
  font-size: 14px;
  font-weight: 500;
  border: 0px;
  outline: 0px;
  width: calc(100% - 30px);
  margin-left: 8px;
  height: 38px;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 500;
  padding: 0;
  ::placeholder {
    color: #ccc;
  }
`;

const ItemsContainer = styled.div`
  position: relative;
  // max-height: ${(props) => (props.footer ? "108px" : "158px")};
`;

const NoItems = styled.div`
  font-size: 18px;
  font-weight: 500;
  height: 30px;
  padding: 0 16px;
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  line-height: 20px;
  background-color: ${(props) =>
    props.selected ? colors.background : colors.white};
  font-size: 16px;
  transition: all 0.2s;
  z-index: 999;

  &:hover {
    cursor: pointer;
    background-color: ${colors.background};
  }
  @media (max-width: 767px) {
    font-size: 16px;
    padding: 2.5px 11px;
  }
`;

const Footer = styled.div`
  // position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  border-top: 1px solid ${colors.background};
  width: fill-available;
`;

export default function SearchDropdown({
  type = SearchDropdownTypes.SingleSelect,
  value,
  onChange,
  placeholder,
  width = "",
  searchQuery,
  setSearchQuery,
  items,
  footer,
  onClear,
  label,
  tip,
  subLabel,
  icon = null,
  defaultValue,
  addressError = "",
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Container hasLabel={Boolean(label)}>
      {/* {label && <Label text={label} tip={tip} subText={subLabel} />} */}
      <Inner
        tabIndex={1}
        open={open}
        addressError={addressError}
        width={width}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
      >
        <TopRow>
          {/* <Icon icon={icon} size={14} color={colors.Grey4} /> */}
          <Input
            placeholder={placeholder}
            value={(() => {
              switch (type) {
                case SearchDropdownTypes.SingleSelect:
                  if (open) return searchQuery ?? "";
                  else {
                    const itemValue = items.find(
                      (item) => item.value === value
                    )?.text;
                    if (itemValue) return itemValue;
                    else if (Boolean(value)) return "Loading...";
                    else return "";
                  }

                case SearchDropdownTypes.MultiSelect:
                  if (open) return searchQuery ?? "";
                  else return "";
              }
            })()}
            onChange={(e) => {
              if (open && setSearchQuery) {
                setSearchQuery(e.target.value);
              }
            }}
          />
          {onClear && value && (
            // <Icon
            //   icon={null}
            //   size={14}
            //   color={colors.Grey5}
            //   onClick={() => onClear()}
            // />
            <></>
          )}
        </TopRow>
        <ItemsContainer defaultValue={defaultValue} footer={Boolean(footer)}>
          {items &&
            items?.map((item) => {
              return (
                <Item
                  key={item.value}
                  selected={item.value === value}
                  onClick={() => {
                    setOpen(false);
                    onChange(item.value);
                  }}
                >
                  {item.text}
                </Item>
              );
            })}
          {!items || (!items.length && <NoItems>No results found...</NoItems>)}
        </ItemsContainer>
        {footer && <Footer>{footer}</Footer>}
      </Inner>
      {addressError && <span className="errormsg">{addressError}</span>}
    </Container>
  );
}
