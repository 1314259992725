import React, { useEffect, useState } from "react";
import {
  BottomForm,
  Profiletabs,
  AddButtonContainer,
  ViewProfile,
  ClientDocImage,
} from "./ProfileStyle";
import { Col, Form, Image, Row, Tabs } from "antd";
import Button from "../../../elements/button/Button";
import {
  MainContent,
  SectionHeading,
} from "../../donor/familyList/FamilyListStyle";
import Modal, { ModalList } from "../../../modal/basemodal/BaseModal";
import { Unitfeild } from "./ProfileStyle";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../../../styles/Colors";
import { Label } from "../RegisterSeeker/RegisterSeeker";
import useMutate from "../../../../api/hooks/useMutate";
import SEEKER_URLS from "../../../../api/urls/SEEKER_URL";
import { useSelector } from "react-redux";
import Loader from "../../../loader/Loader";
import NoDataFound from "../../../noData/NoDataFound";
import { Info } from "../../donor/familyDetails/FamilyDetailsStyle";
import { genderAPIFormat, genderFormat } from "../../../../utils/genderFormat";
import { formatDate, getAgeFromTs } from "../../../../utils/dateTime";
import { formatPhoneNumber } from "../../../../utils/phoneNumber";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import pdfSampleImage from "../../../../assets/images/pdf.png";

export const DocItem = styled.div`
  font-size: 50px;
  margin: 0 0 0 20px;
  color: ${colors.grey5};
  padding: 10px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Approvedmembers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 10px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ApprovedHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.green};
  text-align: right;
  // position: absolute;
  right: -10px;
  top: -25px;
  white-space: nowrap;
  svg {
    font-size: 13px;
  }
`;
export const PendingHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.secondary};
  text-align: right;
  // position: absolute;
  right: -10px;
  top: -25px;
  white-space: nowrap;
`;

const ClientDocImageAnchor = styled.a`
  display: flex;
  width: 100%;
  img {
    height: 300px;
  }
  @media screen and (max-width: 767px) {
    height: auto;
  }
`;

const BirthDateFormate = (d) => {
  return !d || d.isAfter(new Date()) || d.isSameOrBefore("1960-01-01");
};

export const Documents = ({ documents }) => {
  return (
    <>
      {documents?.map((doc) => {
        return (
          <>
            {doc?.type === "application/pdf" ? (
              <ClientDocImageAnchor target="_blank" href={doc?.file}>
                <img src={pdfSampleImage} />
              </ClientDocImageAnchor>
            ) : (
              <ClientDocImage>
                <Image src={doc?.file} />
              </ClientDocImage>
            )}
          </>
        );
      })}
    </>
  );
};

function Profile() {
  // const navigate = useNavigate();
  const [newMemberDetails, setNewMemberDetails] = useState([]);
  const [addNewFamilyMemberModal, setAddNewFamilyMemberModal] = useState(false);
  const [data, setdata] = useState({});
  const [editHouseHoldModal, setEditHouseHoldModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [memberDetails, setMemberDetails] = useState();
  const [editData, setEditData] = useState();
  const [isMemberEdit, setIsMemberEdit] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const admin = query.get("admin");
  const id = query.get("id");

  const navigate = useNavigate();

  const [getFamilyDetails, { response, loading }] = useMutate({
    path: SEEKER_URLS.SEEKER_FAMILY_DETAILS,
    method: "get",
    onCompleted: () => {},
    onError: () => {},
  });
  const [
    deleteMember,
    {
      response: deleteEmployeeResponse,
      error: deleteError,
      loading: deleteEmployeeLoading,
    },
  ] = useMutate({
    path: SEEKER_URLS.DELETEMEMBER,
    method: "delete",
    onCompleted: () => {},
    onError: () => {},
  });

  useEffect(() => {
    if (!user?.id) return;

    getFamilyDetails({
      params: {
        userId: id || user?.id,
      },
      onCompleted: (responseData) => {
        setdata(responseData);
      },
      onError: () => {},
    });
    setIsSuccess(false);
  }, [isSuccess]);
  const [deleteMemberId, setdeleteMemberId] = useState();
  const [openConfirmDeleteEmployee, setOpenConfirmDeleteEmployee] =
    useState(false);

  const addNewMember = () => {
    setIsMemberEdit(false);
    setEditData("");
    setAddNewFamilyMemberModal(true);
  };
  useEffect(() => {
    if (newMemberDetails.length > 0) {
      setdata((val) => ({
        ...val,
        members: [...val?.members, ...newMemberDetails],
      }));
      setNewMemberDetails([]);
    }
  }, [newMemberDetails]);

  const editHouseHoldInfo = () => {
    setEditData("");
    const information = {
      email: data?.email,
      address: data?.address,
      phoneNumber: data?.phoneNumber.replace(/\s+/g, "").replace(/\s+/g, ""),
      firstName: data?.firstName,
      lastName: data?.lastName,
      documents: data?.documents,
      dob: data?.dob,
      userId: data?.userId,
      gender: data?.gender,
      ...data,
    };
    setEditData(information);
    setEditHouseHoldModal(true);
  };
  const handleMemberDetails = (id) => {
    const memberDetails = data?.members?.find(
      (info) => info.seekerMemberId === id
    );

    setEditData(memberDetails);
    setIsMemberEdit(true);
    setAddNewFamilyMemberModal(true);
  };
  const ConfirmDeleteEmployee = () => {
    setOpenConfirmDeleteEmployee(true);
  };
  const deleteFamilyMembers = () => {
    const params = {
      SeekerMemberId: deleteMemberId,
    };
    deleteMember({
      params,
      onCompleted: () => {
        setOpenConfirmDeleteEmployee(false);
        setIsSuccess(true);
      },
      onError: () => {},
    });
  };
  return (
    <>
      <MainContent>
        <Row gutter={20}>
          <Col
            style={{ display: "flex", justifyContent: "space-between" }}
            span={24}
          >
            <SectionHeading>Family Details </SectionHeading>

            {admin && <Button text={"Back"} onClick={() => navigate(-1)} />}
          </Col>

          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Household Information" key="1">
                {!loading ? (
                  <Profiletabs style={{ width: "100%" }}>
                    <Row gutter={20}>
                      <Col span={24} lg={{ span: 12 }}>
                        <Info>
                          <Label>Name: </Label> {data?.firstName} &nbsp;
                          {data?.lastName}
                        </Info>
                      </Col>

                      <Col span={24} lg={{ span: 12 }}>
                        <Info>
                          <Label>City: </Label>
                          {data?.city || data?.state}
                        </Info>
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <Info>
                          <Label>Date of Birth: </Label>
                          {formatDate(data?.dob, "MM/DD/YYYY")}
                        </Info>
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <Info>
                          <Label>Age: </Label>
                          {getAgeFromTs(data?.dob)}
                        </Info>
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <Info>
                          <Label>Email Address: </Label> {data?.email}
                        </Info>
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <Info>
                          <Label>Phone Number: </Label>{" "}
                          {formatPhoneNumber(data?.phoneNumber)}
                        </Info>
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <Info>
                          <Label>Gender:</Label>
                          {genderAPIFormat(data?.gender)}
                        </Info>
                      </Col>
                      <Col span={24} lg={{ span: 24 }}>
                        <Info>
                          <Label>Street Address: </Label> {data?.placeName}
                        </Info>
                      </Col>
                      <Col span={24} lg={{ span: 12 }}>
                        <>
                          <Label style={{ padding: "5px 0 8px" }}>
                            Document:
                          </Label>
                          <Documents documents={data?.documents} />
                        </>
                      </Col>

                      <Col span={24} lg={{ span: 24 }}>
                        <BottomForm style={{ padding: "15px 0 0" }}>
                          <Button text={"Edit"} onClick={editHouseHoldInfo} />
                        </BottomForm>
                      </Col>
                    </Row>
                  </Profiletabs>
                ) : (
                  <Loader />
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Members" key="2">
                <Unitfeild>
                  <AddButtonContainer>
                    <Button
                      text={"Add New Family Member"}
                      onClick={addNewMember}
                    />
                  </AddButtonContainer>

                  {loading && <Loader />}

                  {!loading && !data?.members?.length && <NoDataFound />}

                  {!loading && data?.members?.length && (
                    <>
                      <Row gutter={[24, 16]}>
                        {data?.members?.map((memberInfo, memberIndex) => {
                          return (
                            <>
                              <Col span={24} md={{ span: 12 }}>
                                <ViewProfile>
                                  <Row gutter={20}>
                                    <Col span={24}>
                                      {!memberInfo.isApproved ? (
                                        <PendingHeading>
                                          <ClockCircleOutlined /> Yet to be
                                          approved
                                        </PendingHeading>
                                      ) : (
                                        <ApprovedHeading>
                                          <CheckCircleOutlined /> Approved
                                        </ApprovedHeading>
                                      )}
                                    </Col>
                                    <Col span={24} lg={{ span: 24 }}>
                                      <Info>
                                        <Label>Name: </Label>
                                        {memberInfo?.firstName} &nbsp;
                                        {memberInfo?.lastName}
                                      </Info>
                                    </Col>

                                    <Col span={24} lg={{ span: 24 }}>
                                      <Info>
                                        <Label>Relation : </Label>
                                        {memberInfo?.relation}
                                      </Info>
                                    </Col>

                                    <Col span={24} lg={{ span: 24 }}>
                                      <Info>
                                        <Label>Date of Birth: </Label>
                                        {formatDate(
                                          memberInfo.dob,
                                          "MM/DD/YYYY"
                                        )}
                                      </Info>
                                    </Col>
                                    <Col span={24} lg={{ span: 24 }}>
                                      <Info>
                                        <Label>Age: </Label>
                                        {getAgeFromTs(memberInfo.dob)}
                                      </Info>
                                    </Col>
                                    <Col span={24} lg={{ span: 24 }}>
                                      <Info>
                                        <Label>Phone Number: </Label>{" "}
                                        &nbsp;&nbsp;
                                        {formatPhoneNumber(
                                          memberInfo?.phoneNumber
                                        )}
                                      </Info>
                                    </Col>
                                    <Col span={24} lg={{ span: 24 }}>
                                      <Info>
                                        <Label>Gender: </Label>
                                        {genderAPIFormat(memberInfo?.gender)}
                                      </Info>
                                    </Col>
                                    <Col span={24} lg={{ span: 24 }}>
                                      <Label style={{ padding: "5px 0 8px" }}>
                                        Document:
                                      </Label>
                                      {memberInfo?.documents?.[0]?.file
                                        ?.length && (
                                        <Documents
                                          documents={memberInfo?.documents}
                                        />
                                      )}
                                    </Col>
                                    <Col span={24}>
                                      <BottomForm
                                        style={{ padding: " 25px 0 20px 0" }}
                                      >
                                        <Button
                                          text={"Edit"}
                                          onClick={() =>
                                            handleMemberDetails(
                                              memberInfo?.seekerMemberId
                                            )
                                          }
                                        />
                                        <Button
                                          className="secondary"
                                          text={"Delete"}
                                          onClick={() => {
                                            ConfirmDeleteEmployee();
                                            setdeleteMemberId(
                                              memberInfo?.seekerMemberId
                                            );
                                          }}
                                        />
                                      </BottomForm>
                                    </Col>
                                  </Row>
                                </ViewProfile>
                              </Col>
                            </>
                          );
                        })}
                      </Row>
                    </>
                  )}
                </Unitfeild>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </MainContent>

      <Modal
        title={!isMemberEdit ? "Add New Family Member" : "Edit Family Member"}
        open={addNewFamilyMemberModal}
        setOpenModal={setAddNewFamilyMemberModal}
        modalType={ModalList.AddNewFamilyMemberModal}
        setIsSuccess={setIsSuccess}
        data={editData}
        id={data?.userId}
        isMemberEdit={isMemberEdit}
      />
      <Modal
        title={"Edit Household Information"}
        open={editHouseHoldModal}
        setOpenModal={setEditHouseHoldModal}
        modalType={ModalList.EditHouseHoldModal}
        setIsSuccess={setIsSuccess}
        data={editData}
        id={data?.userId}
      />
      <Modal
        title="Delete?"
        open={openConfirmDeleteEmployee}
        setOpenModal={setOpenConfirmDeleteEmployee}
        modalType={ModalList.ConfirmDeleteEmployee}
        onConfirm={deleteFamilyMembers}
        loading={deleteEmployeeLoading}
      />
    </>
  );
}

export default Profile;
